/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboMicrosoftMicrosoftOrganizationSubscription } from './JumboMicrosoftMicrosoftOrganizationSubscription';
import {
    JumboMicrosoftMicrosoftOrganizationSubscriptionFromJSON,
    JumboMicrosoftMicrosoftOrganizationSubscriptionFromJSONTyped,
    JumboMicrosoftMicrosoftOrganizationSubscriptionToJSON,
} from './JumboMicrosoftMicrosoftOrganizationSubscription';

/**
 * 
 * @export
 * @interface JumboGetorgsubscriptionsGetOrgSubscriptionsResponse
 */
export interface JumboGetorgsubscriptionsGetOrgSubscriptionsResponse {
    /**
     * 
     * @type {Array<JumboMicrosoftMicrosoftOrganizationSubscription>}
     * @memberof JumboGetorgsubscriptionsGetOrgSubscriptionsResponse
     */
    subscriptions?: Array<JumboMicrosoftMicrosoftOrganizationSubscription>;
}

/**
 * Check if a given object implements the JumboGetorgsubscriptionsGetOrgSubscriptionsResponse interface.
 */
export function instanceOfJumboGetorgsubscriptionsGetOrgSubscriptionsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetorgsubscriptionsGetOrgSubscriptionsResponseFromJSON(json: any): JumboGetorgsubscriptionsGetOrgSubscriptionsResponse {
    return JumboGetorgsubscriptionsGetOrgSubscriptionsResponseFromJSONTyped(json, false);
}

export function JumboGetorgsubscriptionsGetOrgSubscriptionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetorgsubscriptionsGetOrgSubscriptionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptions': !exists(json, 'subscriptions') ? undefined : ((json['subscriptions'] as Array<any>).map(JumboMicrosoftMicrosoftOrganizationSubscriptionFromJSON)),
    };
}

export function JumboGetorgsubscriptionsGetOrgSubscriptionsResponseToJSON(value?: JumboGetorgsubscriptionsGetOrgSubscriptionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptions': value.subscriptions === undefined ? undefined : ((value.subscriptions as Array<any>).map(JumboMicrosoftMicrosoftOrganizationSubscriptionToJSON)),
    };
}

