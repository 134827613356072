/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetsentineloneapplicationsApplicationResult } from './JumboGetsentineloneapplicationsApplicationResult';
import {
    JumboGetsentineloneapplicationsApplicationResultFromJSON,
    JumboGetsentineloneapplicationsApplicationResultFromJSONTyped,
    JumboGetsentineloneapplicationsApplicationResultToJSON,
} from './JumboGetsentineloneapplicationsApplicationResult';

/**
 * 
 * @export
 * @interface JumboGetsentineloneapplicationsGetSentineloneApplicationsResult
 */
export interface JumboGetsentineloneapplicationsGetSentineloneApplicationsResult {
    /**
     * 
     * @type {Array<JumboGetsentineloneapplicationsApplicationResult>}
     * @memberof JumboGetsentineloneapplicationsGetSentineloneApplicationsResult
     */
    applications?: Array<JumboGetsentineloneapplicationsApplicationResult>;
}

/**
 * Check if a given object implements the JumboGetsentineloneapplicationsGetSentineloneApplicationsResult interface.
 */
export function instanceOfJumboGetsentineloneapplicationsGetSentineloneApplicationsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetsentineloneapplicationsGetSentineloneApplicationsResultFromJSON(json: any): JumboGetsentineloneapplicationsGetSentineloneApplicationsResult {
    return JumboGetsentineloneapplicationsGetSentineloneApplicationsResultFromJSONTyped(json, false);
}

export function JumboGetsentineloneapplicationsGetSentineloneApplicationsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetsentineloneapplicationsGetSentineloneApplicationsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applications': !exists(json, 'applications') ? undefined : ((json['applications'] as Array<any>).map(JumboGetsentineloneapplicationsApplicationResultFromJSON)),
    };
}

export function JumboGetsentineloneapplicationsGetSentineloneApplicationsResultToJSON(value?: JumboGetsentineloneapplicationsGetSentineloneApplicationsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applications': value.applications === undefined ? undefined : ((value.applications as Array<any>).map(JumboGetsentineloneapplicationsApplicationResultToJSON)),
    };
}

