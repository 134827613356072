/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult
 */
export interface JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult {
    /**
     * 
     * @type {string}
     * @memberof JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult
     */
    installedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult
     */
    riskLevel?: JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultRiskLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult
     */
    version?: string;
}


/**
 * @export
 */
export const JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultRiskLevelEnum = {
    Critical: 'CRITICAL',
    High: 'HIGH',
    Medium: 'MEDIUM',
    Low: 'LOW',
    None: 'NONE'
} as const;
export type JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultRiskLevelEnum = typeof JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultRiskLevelEnum[keyof typeof JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultRiskLevelEnum];


/**
 * Check if a given object implements the JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult interface.
 */
export function instanceOfJumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultFromJSON(json: any): JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult {
    return JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultFromJSONTyped(json, false);
}

export function JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'installedDate': !exists(json, 'installedDate') ? undefined : json['installedDate'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'riskLevel': !exists(json, 'riskLevel') ? undefined : json['riskLevel'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultToJSON(value?: JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'installedDate': value.installedDate,
        'name': value.name,
        'riskLevel': value.riskLevel,
        'version': value.version,
    };
}

