/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetintegrationsIntegrationResponse } from './JumboGetintegrationsIntegrationResponse';
import {
    JumboGetintegrationsIntegrationResponseFromJSON,
    JumboGetintegrationsIntegrationResponseFromJSONTyped,
    JumboGetintegrationsIntegrationResponseToJSON,
} from './JumboGetintegrationsIntegrationResponse';

/**
 * 
 * @export
 * @interface JumboGetintegrationsGetIntegrationsResponse
 */
export interface JumboGetintegrationsGetIntegrationsResponse {
    /**
     * 
     * @type {JumboGetintegrationsIntegrationResponse}
     * @memberof JumboGetintegrationsGetIntegrationsResponse
     */
    aws?: JumboGetintegrationsIntegrationResponse;
    /**
     * 
     * @type {JumboGetintegrationsIntegrationResponse}
     * @memberof JumboGetintegrationsGetIntegrationsResponse
     */
    googleWorkspace?: JumboGetintegrationsIntegrationResponse;
    /**
     * 
     * @type {JumboGetintegrationsIntegrationResponse}
     * @memberof JumboGetintegrationsGetIntegrationsResponse
     */
    microsoft365?: JumboGetintegrationsIntegrationResponse;
    /**
     * 
     * @type {JumboGetintegrationsIntegrationResponse}
     * @memberof JumboGetintegrationsGetIntegrationsResponse
     */
    microsoftDefender?: JumboGetintegrationsIntegrationResponse;
    /**
     * 
     * @type {JumboGetintegrationsIntegrationResponse}
     * @memberof JumboGetintegrationsGetIntegrationsResponse
     */
    plaid?: JumboGetintegrationsIntegrationResponse;
    /**
     * 
     * @type {JumboGetintegrationsIntegrationResponse}
     * @memberof JumboGetintegrationsGetIntegrationsResponse
     */
    sentinelOne?: JumboGetintegrationsIntegrationResponse;
    /**
     * 
     * @type {JumboGetintegrationsIntegrationResponse}
     * @memberof JumboGetintegrationsGetIntegrationsResponse
     */
    wizer?: JumboGetintegrationsIntegrationResponse;
}

/**
 * Check if a given object implements the JumboGetintegrationsGetIntegrationsResponse interface.
 */
export function instanceOfJumboGetintegrationsGetIntegrationsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetintegrationsGetIntegrationsResponseFromJSON(json: any): JumboGetintegrationsGetIntegrationsResponse {
    return JumboGetintegrationsGetIntegrationsResponseFromJSONTyped(json, false);
}

export function JumboGetintegrationsGetIntegrationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetintegrationsGetIntegrationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aws': !exists(json, 'aws') ? undefined : JumboGetintegrationsIntegrationResponseFromJSON(json['aws']),
        'googleWorkspace': !exists(json, 'googleWorkspace') ? undefined : JumboGetintegrationsIntegrationResponseFromJSON(json['googleWorkspace']),
        'microsoft365': !exists(json, 'microsoft365') ? undefined : JumboGetintegrationsIntegrationResponseFromJSON(json['microsoft365']),
        'microsoftDefender': !exists(json, 'microsoftDefender') ? undefined : JumboGetintegrationsIntegrationResponseFromJSON(json['microsoftDefender']),
        'plaid': !exists(json, 'plaid') ? undefined : JumboGetintegrationsIntegrationResponseFromJSON(json['plaid']),
        'sentinelOne': !exists(json, 'sentinelOne') ? undefined : JumboGetintegrationsIntegrationResponseFromJSON(json['sentinelOne']),
        'wizer': !exists(json, 'wizer') ? undefined : JumboGetintegrationsIntegrationResponseFromJSON(json['wizer']),
    };
}

export function JumboGetintegrationsGetIntegrationsResponseToJSON(value?: JumboGetintegrationsGetIntegrationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aws': JumboGetintegrationsIntegrationResponseToJSON(value.aws),
        'googleWorkspace': JumboGetintegrationsIntegrationResponseToJSON(value.googleWorkspace),
        'microsoft365': JumboGetintegrationsIntegrationResponseToJSON(value.microsoft365),
        'microsoftDefender': JumboGetintegrationsIntegrationResponseToJSON(value.microsoftDefender),
        'plaid': JumboGetintegrationsIntegrationResponseToJSON(value.plaid),
        'sentinelOne': JumboGetintegrationsIntegrationResponseToJSON(value.sentinelOne),
        'wizer': JumboGetintegrationsIntegrationResponseToJSON(value.wizer),
    };
}

