/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisplayCondition } from './DisplayCondition';
import {
    DisplayConditionFromJSON,
    DisplayConditionFromJSONTyped,
    DisplayConditionToJSON,
} from './DisplayCondition';
import type { Field } from './Field';
import {
    FieldFromJSON,
    FieldFromJSONTyped,
    FieldToJSON,
} from './Field';
import type { LongDescription } from './LongDescription';
import {
    LongDescriptionFromJSON,
    LongDescriptionFromJSONTyped,
    LongDescriptionToJSON,
} from './LongDescription';
import type { ShortDescription } from './ShortDescription';
import {
    ShortDescriptionFromJSON,
    ShortDescriptionFromJSONTyped,
    ShortDescriptionToJSON,
} from './ShortDescription';
import type { Title } from './Title';
import {
    TitleFromJSON,
    TitleFromJSONTyped,
    TitleToJSON,
} from './Title';

/**
 * 
 * @export
 * @interface ControlQuestionDefinition
 */
export interface ControlQuestionDefinition {
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionDefinition
     */
    id: string;
    /**
     * 
     * @type {Title}
     * @memberof ControlQuestionDefinition
     */
    title: Title;
    /**
     * 
     * @type {ShortDescription}
     * @memberof ControlQuestionDefinition
     */
    short_description?: ShortDescription;
    /**
     * 
     * @type {LongDescription}
     * @memberof ControlQuestionDefinition
     */
    long_description?: LongDescription;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionDefinition
     */
    category_id: string;
    /**
     * 
     * @type {Field}
     * @memberof ControlQuestionDefinition
     */
    field?: Field;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestionDefinition
     */
    allow_evidence?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionDefinition
     */
    match_key?: string;
    /**
     * 
     * @type {any}
     * @memberof ControlQuestionDefinition
     */
    match_key_transformer?: any | null;
    /**
     * 
     * @type {DisplayCondition}
     * @memberof ControlQuestionDefinition
     */
    display_condition?: DisplayCondition;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestionDefinition
     */
    optional?: boolean;
}

/**
 * Check if a given object implements the ControlQuestionDefinition interface.
 */
export function instanceOfControlQuestionDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "category_id" in value;

    return isInstance;
}

export function ControlQuestionDefinitionFromJSON(json: any): ControlQuestionDefinition {
    return ControlQuestionDefinitionFromJSONTyped(json, false);
}

export function ControlQuestionDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlQuestionDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': TitleFromJSON(json['title']),
        'short_description': !exists(json, 'short_description') ? undefined : ShortDescriptionFromJSON(json['short_description']),
        'long_description': !exists(json, 'long_description') ? undefined : LongDescriptionFromJSON(json['long_description']),
        'category_id': json['category_id'],
        'field': !exists(json, 'field') ? undefined : FieldFromJSON(json['field']),
        'allow_evidence': !exists(json, 'allow_evidence') ? undefined : json['allow_evidence'],
        'match_key': !exists(json, 'match_key') ? undefined : json['match_key'],
        'match_key_transformer': !exists(json, 'match_key_transformer') ? undefined : json['match_key_transformer'],
        'display_condition': !exists(json, 'display_condition') ? undefined : DisplayConditionFromJSON(json['display_condition']),
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
    };
}

export function ControlQuestionDefinitionToJSON(value?: ControlQuestionDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': TitleToJSON(value.title),
        'short_description': ShortDescriptionToJSON(value.short_description),
        'long_description': LongDescriptionToJSON(value.long_description),
        'category_id': value.category_id,
        'field': FieldToJSON(value.field),
        'allow_evidence': value.allow_evidence,
        'match_key': value.match_key,
        'match_key_transformer': value.match_key_transformer,
        'display_condition': DisplayConditionToJSON(value.display_condition),
        'optional': value.optional,
    };
}

