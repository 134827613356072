/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor
     */
    PersonNm?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor
     */
    BusinessName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': !exists(json, 'PersonNm') ? undefined : json['PersonNm'],
        'BusinessName': !exists(json, 'BusinessName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'BusinessName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
    };
}

