/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus } from './ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus';
import {
    ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusFromJSON,
    ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusToJSON,
} from './ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse
 */
export interface ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    status?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    scan_status?: ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    started: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse
     */
    finished?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "started" in value;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponseFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse {
    return ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponseFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': json['process_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'scan_status': !exists(json, 'scan_status') ? undefined : ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusFromJSON(json['scan_status']),
        'started': json['started'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'finished': !exists(json, 'finished') ? undefined : json['finished'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponseToJSON(value?: ExecRiskApiAppApiV1EagerTasksStatusModelsProcessStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'status': value.status,
        'scan_status': ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusToJSON(value.scan_status),
        'started': value.started,
        'updated': value.updated,
        'finished': value.finished,
    };
}

