/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Builtwith1 } from './Builtwith1';
import {
    Builtwith1FromJSON,
    Builtwith1FromJSONTyped,
    Builtwith1ToJSON,
} from './Builtwith1';
import type { CRSFSecReviewEmailLeaksAggregatedResponse } from './CRSFSecReviewEmailLeaksAggregatedResponse';
import {
    CRSFSecReviewEmailLeaksAggregatedResponseFromJSON,
    CRSFSecReviewEmailLeaksAggregatedResponseFromJSONTyped,
    CRSFSecReviewEmailLeaksAggregatedResponseToJSON,
} from './CRSFSecReviewEmailLeaksAggregatedResponse';
import type { CRSFSecReviewScanEventRecord } from './CRSFSecReviewScanEventRecord';
import {
    CRSFSecReviewScanEventRecordFromJSON,
    CRSFSecReviewScanEventRecordFromJSONTyped,
    CRSFSecReviewScanEventRecordToJSON,
} from './CRSFSecReviewScanEventRecord';
import type { CRSFSecReviewSourcelistsData } from './CRSFSecReviewSourcelistsData';
import {
    CRSFSecReviewSourcelistsDataFromJSON,
    CRSFSecReviewSourcelistsDataFromJSONTyped,
    CRSFSecReviewSourcelistsDataToJSON,
} from './CRSFSecReviewSourcelistsData';
import type { CRSFSecReviewSpidersAggregatedResponse } from './CRSFSecReviewSpidersAggregatedResponse';
import {
    CRSFSecReviewSpidersAggregatedResponseFromJSON,
    CRSFSecReviewSpidersAggregatedResponseFromJSONTyped,
    CRSFSecReviewSpidersAggregatedResponseToJSON,
} from './CRSFSecReviewSpidersAggregatedResponse';
import type { CRSFSecReviewWappalyzerAggregatedData } from './CRSFSecReviewWappalyzerAggregatedData';
import {
    CRSFSecReviewWappalyzerAggregatedDataFromJSON,
    CRSFSecReviewWappalyzerAggregatedDataFromJSONTyped,
    CRSFSecReviewWappalyzerAggregatedDataToJSON,
} from './CRSFSecReviewWappalyzerAggregatedData';
import type { RiskSummary1 } from './RiskSummary1';
import {
    RiskSummary1FromJSON,
    RiskSummary1FromJSONTyped,
    RiskSummary1ToJSON,
} from './RiskSummary1';
import type { SourceCount1 } from './SourceCount1';
import {
    SourceCount1FromJSON,
    SourceCount1FromJSONTyped,
    SourceCount1ToJSON,
} from './SourceCount1';
import type { Virustotal1 } from './Virustotal1';
import {
    Virustotal1FromJSON,
    Virustotal1FromJSONTyped,
    Virustotal1ToJSON,
} from './Virustotal1';

/**
 * 
 * @export
 * @interface CRSFSecReviewAggregatedResponse
 */
export interface CRSFSecReviewAggregatedResponse {
    /**
     * 
     * @type {Builtwith1}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    builtwith: Builtwith1;
    /**
     * 
     * @type {CRSFSecReviewEmailLeaksAggregatedResponse}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    dataleaks: CRSFSecReviewEmailLeaksAggregatedResponse;
    /**
     * 
     * @type {object}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    enumerated: object;
    /**
     * 
     * @type {Array<CRSFSecReviewScanEventRecord>}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    events: Array<CRSFSecReviewScanEventRecord>;
    /**
     * 
     * @type {RiskSummary1}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    risk_summary: RiskSummary1;
    /**
     * 
     * @type {SourceCount1}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    source_count: SourceCount1;
    /**
     * 
     * @type {CRSFSecReviewSourcelistsData}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    sourcelists: CRSFSecReviewSourcelistsData;
    /**
     * 
     * @type {CRSFSecReviewSpidersAggregatedResponse}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    spiders: CRSFSecReviewSpidersAggregatedResponse;
    /**
     * 
     * @type {Virustotal1}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    virustotal: Virustotal1;
    /**
     * 
     * @type {Array<CRSFSecReviewWappalyzerAggregatedData>}
     * @memberof CRSFSecReviewAggregatedResponse
     */
    wappalyzer?: Array<CRSFSecReviewWappalyzerAggregatedData>;
}

/**
 * Check if a given object implements the CRSFSecReviewAggregatedResponse interface.
 */
export function instanceOfCRSFSecReviewAggregatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "builtwith" in value;
    isInstance = isInstance && "dataleaks" in value;
    isInstance = isInstance && "enumerated" in value;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "risk_summary" in value;
    isInstance = isInstance && "source_count" in value;
    isInstance = isInstance && "sourcelists" in value;
    isInstance = isInstance && "spiders" in value;
    isInstance = isInstance && "virustotal" in value;

    return isInstance;
}

export function CRSFSecReviewAggregatedResponseFromJSON(json: any): CRSFSecReviewAggregatedResponse {
    return CRSFSecReviewAggregatedResponseFromJSONTyped(json, false);
}

export function CRSFSecReviewAggregatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewAggregatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'builtwith': Builtwith1FromJSON(json['builtwith']),
        'dataleaks': CRSFSecReviewEmailLeaksAggregatedResponseFromJSON(json['dataleaks']),
        'enumerated': json['enumerated'],
        'events': ((json['events'] as Array<any>).map(CRSFSecReviewScanEventRecordFromJSON)),
        'risk_summary': RiskSummary1FromJSON(json['risk_summary']),
        'source_count': SourceCount1FromJSON(json['source_count']),
        'sourcelists': CRSFSecReviewSourcelistsDataFromJSON(json['sourcelists']),
        'spiders': CRSFSecReviewSpidersAggregatedResponseFromJSON(json['spiders']),
        'virustotal': Virustotal1FromJSON(json['virustotal']),
        'wappalyzer': !exists(json, 'wappalyzer') ? undefined : ((json['wappalyzer'] as Array<any>).map(CRSFSecReviewWappalyzerAggregatedDataFromJSON)),
    };
}

export function CRSFSecReviewAggregatedResponseToJSON(value?: CRSFSecReviewAggregatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'builtwith': Builtwith1ToJSON(value.builtwith),
        'dataleaks': CRSFSecReviewEmailLeaksAggregatedResponseToJSON(value.dataleaks),
        'enumerated': value.enumerated,
        'events': ((value.events as Array<any>).map(CRSFSecReviewScanEventRecordToJSON)),
        'risk_summary': RiskSummary1ToJSON(value.risk_summary),
        'source_count': SourceCount1ToJSON(value.source_count),
        'sourcelists': CRSFSecReviewSourcelistsDataToJSON(value.sourcelists),
        'spiders': CRSFSecReviewSpidersAggregatedResponseToJSON(value.spiders),
        'virustotal': Virustotal1ToJSON(value.virustotal),
        'wappalyzer': value.wappalyzer === undefined ? undefined : ((value.wappalyzer as Array<any>).map(CRSFSecReviewWappalyzerAggregatedDataToJSON)),
    };
}

