/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MonitRelationType = {
    Broker: 'broker',
    Policyholder: 'policyholder',
    Freemium: 'freemium',
    General: 'general',
    Re: 're'
} as const;
export type MonitRelationType = typeof MonitRelationType[keyof typeof MonitRelationType];


export function MonitRelationTypeFromJSON(json: any): MonitRelationType {
    return MonitRelationTypeFromJSONTyped(json, false);
}

export function MonitRelationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitRelationType {
    return json as MonitRelationType;
}

export function MonitRelationTypeToJSON(value?: MonitRelationType | null): any {
    return value as any;
}

