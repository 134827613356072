/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboPostawsauthaccountidPostAWSAuthAccountIDResult
 */
export interface JumboPostawsauthaccountidPostAWSAuthAccountIDResult {
    /**
     * 
     * @type {string}
     * @memberof JumboPostawsauthaccountidPostAWSAuthAccountIDResult
     */
    createStackURL?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboPostawsauthaccountidPostAWSAuthAccountIDResult
     */
    userAccountID?: string;
}

/**
 * Check if a given object implements the JumboPostawsauthaccountidPostAWSAuthAccountIDResult interface.
 */
export function instanceOfJumboPostawsauthaccountidPostAWSAuthAccountIDResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboPostawsauthaccountidPostAWSAuthAccountIDResultFromJSON(json: any): JumboPostawsauthaccountidPostAWSAuthAccountIDResult {
    return JumboPostawsauthaccountidPostAWSAuthAccountIDResultFromJSONTyped(json, false);
}

export function JumboPostawsauthaccountidPostAWSAuthAccountIDResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboPostawsauthaccountidPostAWSAuthAccountIDResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createStackURL': !exists(json, 'createStackURL') ? undefined : json['createStackURL'],
        'userAccountID': !exists(json, 'userAccountID') ? undefined : json['userAccountID'],
    };
}

export function JumboPostawsauthaccountidPostAWSAuthAccountIDResultToJSON(value?: JumboPostawsauthaccountidPostAWSAuthAccountIDResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createStackURL': value.createStackURL,
        'userAccountID': value.userAccountID,
    };
}

