/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskSalaryRange } from './ExecRiskSalaryRange';
import {
    ExecRiskSalaryRangeFromJSON,
    ExecRiskSalaryRangeFromJSONTyped,
    ExecRiskSalaryRangeToJSON,
} from './ExecRiskSalaryRange';
import type { ExecRiskScraperSources } from './ExecRiskScraperSources';
import {
    ExecRiskScraperSourcesFromJSON,
    ExecRiskScraperSourcesFromJSONTyped,
    ExecRiskScraperSourcesToJSON,
} from './ExecRiskScraperSources';

/**
 * 
 * @export
 * @interface ExecRiskPaginatedSalaryRangeResponse
 */
export interface ExecRiskPaginatedSalaryRangeResponse {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ExecRiskSalaryRange>}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    results: Array<ExecRiskSalaryRange>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    process_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    has_jobs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    all_have_salary_range?: boolean;
    /**
     * 
     * @type {ExecRiskScraperSources}
     * @memberof ExecRiskPaginatedSalaryRangeResponse
     */
    sources?: ExecRiskScraperSources;
}

/**
 * Check if a given object implements the ExecRiskPaginatedSalaryRangeResponse interface.
 */
export function instanceOfExecRiskPaginatedSalaryRangeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "has_jobs" in value;

    return isInstance;
}

export function ExecRiskPaginatedSalaryRangeResponseFromJSON(json: any): ExecRiskPaginatedSalaryRangeResponse {
    return ExecRiskPaginatedSalaryRangeResponseFromJSONTyped(json, false);
}

export function ExecRiskPaginatedSalaryRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPaginatedSalaryRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': ((json['results'] as Array<any>).map(ExecRiskSalaryRangeFromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'process_id': json['process_id'],
        'has_jobs': json['has_jobs'],
        'all_have_salary_range': !exists(json, 'all_have_salary_range') ? undefined : json['all_have_salary_range'],
        'sources': !exists(json, 'sources') ? undefined : ExecRiskScraperSourcesFromJSON(json['sources']),
    };
}

export function ExecRiskPaginatedSalaryRangeResponseToJSON(value?: ExecRiskPaginatedSalaryRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ExecRiskSalaryRangeToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
        'process_id': value.process_id,
        'has_jobs': value.has_jobs,
        'all_have_salary_range': value.all_have_salary_range,
        'sources': ExecRiskScraperSourcesToJSON(value.sources),
    };
}

