/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment
     */
    DescriptionProgramSrvcAccomTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment
     */
    GrantsAndAllocationsAmt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment
     */
    ForeignGrantsInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment
     */
    ProgramServiceExpensesAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DescriptionProgramSrvcAccomTxt': !exists(json, 'DescriptionProgramSrvcAccomTxt') ? undefined : json['DescriptionProgramSrvcAccomTxt'],
        'GrantsAndAllocationsAmt': !exists(json, 'GrantsAndAllocationsAmt') ? undefined : json['GrantsAndAllocationsAmt'],
        'ForeignGrantsInd': !exists(json, 'ForeignGrantsInd') ? undefined : json['ForeignGrantsInd'],
        'ProgramServiceExpensesAmt': !exists(json, 'ProgramServiceExpensesAmt') ? undefined : json['ProgramServiceExpensesAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DescriptionProgramSrvcAccomTxt': value.DescriptionProgramSrvcAccomTxt,
        'GrantsAndAllocationsAmt': value.GrantsAndAllocationsAmt,
        'ForeignGrantsInd': value.ForeignGrantsInd,
        'ProgramServiceExpensesAmt': value.ProgramServiceExpensesAmt,
    };
}

