/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboPutwizeradminlinksPutWizerAdminLinksResult
 */
export interface JumboPutwizeradminlinksPutWizerAdminLinksResult {
    /**
     * 
     * @type {boolean}
     * @memberof JumboPutwizeradminlinksPutWizerAdminLinksResult
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboPutwizeradminlinksPutWizerAdminLinksResult
     */
    wizerAdminTrainingsURL?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboPutwizeradminlinksPutWizerAdminLinksResult
     */
    wizerAdminUsersURL?: string;
}

/**
 * Check if a given object implements the JumboPutwizeradminlinksPutWizerAdminLinksResult interface.
 */
export function instanceOfJumboPutwizeradminlinksPutWizerAdminLinksResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboPutwizeradminlinksPutWizerAdminLinksResultFromJSON(json: any): JumboPutwizeradminlinksPutWizerAdminLinksResult {
    return JumboPutwizeradminlinksPutWizerAdminLinksResultFromJSONTyped(json, false);
}

export function JumboPutwizeradminlinksPutWizerAdminLinksResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboPutwizeradminlinksPutWizerAdminLinksResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'wizerAdminTrainingsURL': !exists(json, 'wizerAdminTrainingsURL') ? undefined : json['wizerAdminTrainingsURL'],
        'wizerAdminUsersURL': !exists(json, 'wizerAdminUsersURL') ? undefined : json['wizerAdminUsersURL'],
    };
}

export function JumboPutwizeradminlinksPutWizerAdminLinksResultToJSON(value?: JumboPutwizeradminlinksPutWizerAdminLinksResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isAdmin': value.isAdmin,
        'wizerAdminTrainingsURL': value.wizerAdminTrainingsURL,
        'wizerAdminUsersURL': value.wizerAdminUsersURL,
    };
}

