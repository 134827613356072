/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp } from './ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp';
import {
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpFromJSON,
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpFromJSONTyped,
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpToJSON,
} from './ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp';
import type { ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler } from './ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler';
import {
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerFromJSON,
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerFromJSONTyped,
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerToJSON,
} from './ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler';
import type { ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp } from './ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp';
import {
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpFromJSON,
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpFromJSONTyped,
    ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpToJSON,
} from './ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp';

/**
 * 
 * @export
 * @interface ExecRiskBaseFilingHeader
 */
export interface ExecRiskBaseFilingHeader {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler}
     * @memberof ExecRiskBaseFilingHeader
     */
    filer?: ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBaseFilingHeader
     */
    tax_yr?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBaseFilingHeader
     */
    build_ts?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBaseFilingHeader
     */
    return_ts?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBaseFilingHeader
     */
    return_type_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBaseFilingHeader
     */
    tax_period_end_dt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBaseFilingHeader
     */
    tax_period_begin_dt?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp}
     * @memberof ExecRiskBaseFilingHeader
     */
    preparer_person_grp?: ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrp;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp}
     * @memberof ExecRiskBaseFilingHeader
     */
    business_officer_grp?: ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp;
}

/**
 * Check if a given object implements the ExecRiskBaseFilingHeader interface.
 */
export function instanceOfExecRiskBaseFilingHeader(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskBaseFilingHeaderFromJSON(json: any): ExecRiskBaseFilingHeader {
    return ExecRiskBaseFilingHeaderFromJSONTyped(json, false);
}

export function ExecRiskBaseFilingHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskBaseFilingHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filer': !exists(json, 'filer') ? undefined : ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerFromJSON(json['filer']),
        'tax_yr': !exists(json, 'tax_yr') ? undefined : json['tax_yr'],
        'build_ts': !exists(json, 'build_ts') ? undefined : json['build_ts'],
        'return_ts': !exists(json, 'return_ts') ? undefined : json['return_ts'],
        'return_type_cd': !exists(json, 'return_type_cd') ? undefined : json['return_type_cd'],
        'tax_period_end_dt': !exists(json, 'tax_period_end_dt') ? undefined : json['tax_period_end_dt'],
        'tax_period_begin_dt': !exists(json, 'tax_period_begin_dt') ? undefined : json['tax_period_begin_dt'],
        'preparer_person_grp': !exists(json, 'preparer_person_grp') ? undefined : ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpFromJSON(json['preparer_person_grp']),
        'business_officer_grp': !exists(json, 'business_officer_grp') ? undefined : ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpFromJSON(json['business_officer_grp']),
    };
}

export function ExecRiskBaseFilingHeaderToJSON(value?: ExecRiskBaseFilingHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filer': ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerToJSON(value.filer),
        'tax_yr': value.tax_yr,
        'build_ts': value.build_ts,
        'return_ts': value.return_ts,
        'return_type_cd': value.return_type_cd,
        'tax_period_end_dt': value.tax_period_end_dt,
        'tax_period_begin_dt': value.tax_period_begin_dt,
        'preparer_person_grp': ExecRiskApiAppCoreModelsScansBaseFilingHeaderPreparerPersonGrpToJSON(value.preparer_person_grp),
        'business_officer_grp': ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpToJSON(value.business_officer_grp),
    };
}

