/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    supported_organization_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    organization_type_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    governing_document_listed_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    support_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation
     */
    other_support_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supported_organization_name': !exists(json, 'supported_organization_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['supported_organization_name']),
        'ein': !exists(json, 'ein') ? undefined : json['ein'],
        'organization_type_cd': !exists(json, 'organization_type_cd') ? undefined : json['organization_type_cd'],
        'governing_document_listed_ind': !exists(json, 'governing_document_listed_ind') ? undefined : json['governing_document_listed_ind'],
        'support_amt': !exists(json, 'support_amt') ? undefined : json['support_amt'],
        'other_support_amt': !exists(json, 'other_support_amt') ? undefined : json['other_support_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supported_organization_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.supported_organization_name),
        'ein': value.ein,
        'organization_type_cd': value.organization_type_cd,
        'governing_document_listed_ind': value.governing_document_listed_ind,
        'support_amt': value.support_amt,
        'other_support_amt': value.other_support_amt,
    };
}

