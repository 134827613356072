/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitLocale } from './MonitLocale';
import {
    MonitLocaleFromJSON,
    MonitLocaleFromJSONTyped,
    MonitLocaleToJSON,
} from './MonitLocale';

/**
 * 
 * @export
 * @interface MonitEntityPdfQueries
 */
export interface MonitEntityPdfQueries {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityPdfQueries
     */
    process_id?: string;
    /**
     * 
     * @type {MonitLocale}
     * @memberof MonitEntityPdfQueries
     */
    locale?: MonitLocale;
}

/**
 * Check if a given object implements the MonitEntityPdfQueries interface.
 */
export function instanceOfMonitEntityPdfQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityPdfQueriesFromJSON(json: any): MonitEntityPdfQueries {
    return MonitEntityPdfQueriesFromJSONTyped(json, false);
}

export function MonitEntityPdfQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityPdfQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': !exists(json, 'process_id') ? undefined : json['process_id'],
        'locale': !exists(json, 'locale') ? undefined : MonitLocaleFromJSON(json['locale']),
    };
}

export function MonitEntityPdfQueriesToJSON(value?: MonitEntityPdfQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'locale': MonitLocaleToJSON(value.locale),
    };
}

