/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsTriggerStatus } from './ExecRiskApiAppCoreModelsTriggerStatus';
import {
    ExecRiskApiAppCoreModelsTriggerStatusFromJSON,
    ExecRiskApiAppCoreModelsTriggerStatusFromJSONTyped,
    ExecRiskApiAppCoreModelsTriggerStatusToJSON,
} from './ExecRiskApiAppCoreModelsTriggerStatus';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsTriggerScanStatus
 */
export interface ExecRiskApiAppCoreModelsTriggerScanStatus {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    ppploans?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    alexa?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    glassdoor?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    watchlist?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    form990?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    docketalarm?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    experian?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    bingnews?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    wave?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    proximityone?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    layoffs?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    zoominfo?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    webhose?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    violations?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    investors?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    industry?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    everify?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    aylien?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    news?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    nativelands?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    greatdata?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    form5500?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    corelogic?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    salary_range_glassdoor?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    salary_range_indeed?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    salary_range_linkedin?: ExecRiskApiAppCoreModelsTriggerStatus;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerScanStatus
     */
    salary_range?: ExecRiskApiAppCoreModelsTriggerStatus;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsTriggerScanStatus interface.
 */
export function instanceOfExecRiskApiAppCoreModelsTriggerScanStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsTriggerScanStatusFromJSON(json: any): ExecRiskApiAppCoreModelsTriggerScanStatus {
    return ExecRiskApiAppCoreModelsTriggerScanStatusFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsTriggerScanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsTriggerScanStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ppploans': !exists(json, 'ppploans') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['ppploans']),
        'alexa': !exists(json, 'alexa') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['alexa']),
        'glassdoor': !exists(json, 'glassdoor') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['glassdoor']),
        'watchlist': !exists(json, 'watchlist') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['watchlist']),
        'form990': !exists(json, 'form990') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['form990']),
        'docketalarm': !exists(json, 'docketalarm') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['docketalarm']),
        'experian': !exists(json, 'experian') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['experian']),
        'bingnews': !exists(json, 'bingnews') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['bingnews']),
        'wave': !exists(json, 'wave') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['wave']),
        'proximityone': !exists(json, 'proximityone') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['proximityone']),
        'layoffs': !exists(json, 'layoffs') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['layoffs']),
        'zoominfo': !exists(json, 'zoominfo') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['zoominfo']),
        'webhose': !exists(json, 'webhose') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['webhose']),
        'violations': !exists(json, 'violations') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['violations']),
        'investors': !exists(json, 'investors') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['investors']),
        'industry': !exists(json, 'industry') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['industry']),
        'everify': !exists(json, 'everify') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['everify']),
        'aylien': !exists(json, 'aylien') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['aylien']),
        'news': !exists(json, 'news') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['news']),
        'nativelands': !exists(json, 'nativelands') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['nativelands']),
        'greatdata': !exists(json, 'greatdata') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['greatdata']),
        'form5500': !exists(json, 'form5500') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['form5500']),
        'corelogic': !exists(json, 'corelogic') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['corelogic']),
        'salary_range_glassdoor': !exists(json, 'salary_range_glassdoor') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['salary_range_glassdoor']),
        'salary_range_indeed': !exists(json, 'salary_range_indeed') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['salary_range_indeed']),
        'salary_range_linkedin': !exists(json, 'salary_range_linkedin') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['salary_range_linkedin']),
        'salary_range': !exists(json, 'salary_range') ? undefined : ExecRiskApiAppCoreModelsTriggerStatusFromJSON(json['salary_range']),
    };
}

export function ExecRiskApiAppCoreModelsTriggerScanStatusToJSON(value?: ExecRiskApiAppCoreModelsTriggerScanStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ppploans': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.ppploans),
        'alexa': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.alexa),
        'glassdoor': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.glassdoor),
        'watchlist': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.watchlist),
        'form990': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.form990),
        'docketalarm': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.docketalarm),
        'experian': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.experian),
        'bingnews': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.bingnews),
        'wave': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.wave),
        'proximityone': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.proximityone),
        'layoffs': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.layoffs),
        'zoominfo': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.zoominfo),
        'webhose': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.webhose),
        'violations': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.violations),
        'investors': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.investors),
        'industry': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.industry),
        'everify': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.everify),
        'aylien': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.aylien),
        'news': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.news),
        'nativelands': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.nativelands),
        'greatdata': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.greatdata),
        'form5500': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.form5500),
        'corelogic': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.corelogic),
        'salary_range_glassdoor': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.salary_range_glassdoor),
        'salary_range_indeed': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.salary_range_indeed),
        'salary_range_linkedin': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.salary_range_linkedin),
        'salary_range': ExecRiskApiAppCoreModelsTriggerStatusToJSON(value.salary_range),
    };
}

