/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler
 */
export interface ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler
     */
    ein: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler
     */
    us_address?: ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler
     */
    business_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler
     */
    business_name_control_txt?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ein" in value;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerFromJSON(json: any): ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler {
    return ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ein': json['ein'],
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
        'business_name_control_txt': !exists(json, 'business_name_control_txt') ? undefined : json['business_name_control_txt'],
    };
}

export function ExecRiskApiAppCoreModelsScansBaseFilingHeaderFilerToJSON(value?: ExecRiskApiAppCoreModelsScansBaseFilingHeaderFiler | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ein': value.ein,
        'us_address': ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'business_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
        'business_name_control_txt': value.business_name_control_txt,
    };
}

