/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult } from './JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult';
import {
    JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultFromJSON,
    JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultFromJSONTyped,
    JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultToJSON,
} from './JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult';
import type { JumboGetadminmfaenforcedPagination } from './JumboGetadminmfaenforcedPagination';
import {
    JumboGetadminmfaenforcedPaginationFromJSON,
    JumboGetadminmfaenforcedPaginationFromJSONTyped,
    JumboGetadminmfaenforcedPaginationToJSON,
} from './JumboGetadminmfaenforcedPagination';

/**
 * 
 * @export
 * @interface JumboGetadminmfaenforcedGetAdminMfaEnforcedResult
 */
export interface JumboGetadminmfaenforcedGetAdminMfaEnforcedResult {
    /**
     * 
     * @type {Array<JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult>}
     * @memberof JumboGetadminmfaenforcedGetAdminMfaEnforcedResult
     */
    items?: Array<JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult>;
    /**
     * 
     * @type {JumboGetadminmfaenforcedPagination}
     * @memberof JumboGetadminmfaenforcedGetAdminMfaEnforcedResult
     */
    pagination?: JumboGetadminmfaenforcedPagination;
}

/**
 * Check if a given object implements the JumboGetadminmfaenforcedGetAdminMfaEnforcedResult interface.
 */
export function instanceOfJumboGetadminmfaenforcedGetAdminMfaEnforcedResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetadminmfaenforcedGetAdminMfaEnforcedResultFromJSON(json: any): JumboGetadminmfaenforcedGetAdminMfaEnforcedResult {
    return JumboGetadminmfaenforcedGetAdminMfaEnforcedResultFromJSONTyped(json, false);
}

export function JumboGetadminmfaenforcedGetAdminMfaEnforcedResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetadminmfaenforcedGetAdminMfaEnforcedResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : JumboGetadminmfaenforcedPaginationFromJSON(json['pagination']),
    };
}

export function JumboGetadminmfaenforcedGetAdminMfaEnforcedResultToJSON(value?: JumboGetadminmfaenforcedGetAdminMfaEnforcedResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultToJSON)),
        'pagination': JumboGetadminmfaenforcedPaginationToJSON(value.pagination),
    };
}

