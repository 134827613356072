import { useV1APIMutation } from '@explorer/query';
import { useQuerySnackbar } from '@explorer/hooks';
import { V1IntegrationsWebAdminSentineloneAccountsPostRequest } from '@explorer/open-api-client';

export const useMutationAddSentinelOneAccount = (params: {
  client_id: string;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const { client_id } = params;
  const { snackError, snackSuccess } = useQuerySnackbar();

  return useV1APIMutation({
    mutationKey: ['v1IntegrationsWebAdminSentineloneAccountsPost'],
    mutationFn(
      args: V1IntegrationsWebAdminSentineloneAccountsPostRequest,
      client,
    ) {
      return client.v1IntegrationsWebAdminSentineloneAccountsPost({
        JumboPostsentineloneaccountsPostSentinelOneAccountsRequestBody: {
          accessToken:
            args.JumboPostsentineloneaccountsPostSentinelOneAccountsRequestBody
              .accessToken,
          sentinelOneManagementURL:
            args.JumboPostsentineloneaccountsPostSentinelOneAccountsRequestBody
              .sentinelOneManagementURL,
        },
      });
    },
    onSuccess: () => {
      if (params.onSuccess) {
        params.onSuccess();
      }

      snackSuccess('Account has been successfully connected.');
    },
    explorerAPIOptions: {
      clientId: client_id,
      onError: (error) => {
        if (params.onError) {
          params.onError(error);
        }

        snackError(
          'Failed to connect account. Please try again later.',
          error.error,
        );
      },
    },
  });
};
