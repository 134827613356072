/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    PersonNm?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    BusinessName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    USAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    ForeignAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    ServiceTypeTxt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    CompensationAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': !exists(json, 'PersonNm') ? undefined : json['PersonNm'],
        'BusinessName': !exists(json, 'BusinessName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': !exists(json, 'ForeignAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'ServiceTypeTxt': !exists(json, 'ServiceTypeTxt') ? undefined : json['ServiceTypeTxt'],
        'CompensationAmt': !exists(json, 'CompensationAmt') ? undefined : json['CompensationAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'BusinessName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
        'USAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'ServiceTypeTxt': value.ServiceTypeTxt,
        'CompensationAmt': value.CompensationAmt,
    };
}

