/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskItemI } from './ExecRiskItemI';
import {
    ExecRiskItemIFromJSON,
    ExecRiskItemIFromJSONTyped,
    ExecRiskItemIToJSON,
} from './ExecRiskItemI';
import type { ExecRiskItemII } from './ExecRiskItemII';
import {
    ExecRiskItemIIFromJSON,
    ExecRiskItemIIFromJSONTyped,
    ExecRiskItemIIToJSON,
} from './ExecRiskItemII';
import type { ExecRiskItemIII } from './ExecRiskItemIII';
import {
    ExecRiskItemIIIFromJSON,
    ExecRiskItemIIIFromJSONTyped,
    ExecRiskItemIIIToJSON,
} from './ExecRiskItemIII';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI
 */
export interface ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI {
    /**
     * 
     * @type {Array<ExecRiskItemI>}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI
     */
    item1: Array<ExecRiskItemI>;
    /**
     * 
     * @type {Array<ExecRiskItemII>}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI
     */
    item2: Array<ExecRiskItemII>;
    /**
     * 
     * @type {Array<ExecRiskItemIII>}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI
     */
    item3: Array<ExecRiskItemIII>;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "item1" in value;
    isInstance = isInstance && "item2" in value;
    isInstance = isInstance && "item3" in value;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartIFromJSON(json: any): ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI {
    return ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartIFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartIFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item1': ((json['item1'] as Array<any>).map(ExecRiskItemIFromJSON)),
        'item2': ((json['item2'] as Array<any>).map(ExecRiskItemIIFromJSON)),
        'item3': ((json['item3'] as Array<any>).map(ExecRiskItemIIIFromJSON)),
    };
}

export function ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartIToJSON(value?: ExecRiskApiAppCoreModelsScansLongForm5500ScheduleCDefPartI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item1': ((value.item1 as Array<any>).map(ExecRiskItemIToJSON)),
        'item2': ((value.item2 as Array<any>).map(ExecRiskItemIIToJSON)),
        'item3': ((value.item3 as Array<any>).map(ExecRiskItemIIIToJSON)),
    };
}

