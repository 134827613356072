/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsAWSS3BucketReport } from './JumboStructsAWSS3BucketReport';
import {
    JumboStructsAWSS3BucketReportFromJSON,
    JumboStructsAWSS3BucketReportFromJSONTyped,
    JumboStructsAWSS3BucketReportToJSON,
} from './JumboStructsAWSS3BucketReport';

/**
 * 
 * @export
 * @interface JumboStructsS3ListFinding
 */
export interface JumboStructsS3ListFinding {
    /**
     * 
     * @type {Array<JumboStructsAWSS3BucketReport>}
     * @memberof JumboStructsS3ListFinding
     */
    items?: Array<JumboStructsAWSS3BucketReport>;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsS3ListFinding
     */
    total?: number;
}

/**
 * Check if a given object implements the JumboStructsS3ListFinding interface.
 */
export function instanceOfJumboStructsS3ListFinding(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsS3ListFindingFromJSON(json: any): JumboStructsS3ListFinding {
    return JumboStructsS3ListFindingFromJSONTyped(json, false);
}

export function JumboStructsS3ListFindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsS3ListFinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JumboStructsAWSS3BucketReportFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function JumboStructsS3ListFindingToJSON(value?: JumboStructsS3ListFinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JumboStructsAWSS3BucketReportToJSON)),
        'total': value.total,
    };
}

