/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg
     */
    PowerAppointMajorityDirTrstInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg
     */
    OperateBenefitNonSuprtOrgInd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PowerAppointMajorityDirTrstInd': !exists(json, 'PowerAppointMajorityDirTrstInd') ? undefined : json['PowerAppointMajorityDirTrstInd'],
        'OperateBenefitNonSuprtOrgInd': !exists(json, 'OperateBenefitNonSuprtOrgInd') ? undefined : json['OperateBenefitNonSuprtOrgInd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PowerAppointMajorityDirTrstInd': value.PowerAppointMajorityDirTrstInd,
        'OperateBenefitNonSuprtOrgInd': value.OperateBenefitNonSuprtOrgInd,
    };
}

