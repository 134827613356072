import { useV1APIQuery } from '@explorer/query';
import {
  V1ControlWebAdminGetClientInfoClientIdGetRequest,
  ControlClientInfoResponse,
  ResponseError,
} from '@explorer/open-api-client';
import { useQuerySnackbar } from '@explorer/hooks';

const useGetClientInfoQuery = (setClient, decodedClientId, formik) => {
  const { snackError } = useQuerySnackbar();

  return useV1APIQuery<ControlClientInfoResponse>({
    queryKey: ['v1ControlWebAdminGetClientInfoClientIdGet'],
    queryFn: (client, context) => {
      const params: V1ControlWebAdminGetClientInfoClientIdGetRequest = {
        client_id: decodedClientId,
      };

      return client.v1ControlWebAdminGetClientInfoClientIdGet(params, {
        signal: context.signal,
      });
    },
    enabled: !!decodedClientId,
    onSuccess: (data) => {
      if (data) {
        setClient(data);
        formik.setSubmitting(false);
      }
    },
    onError: (error) => {
      snackError('Get Client Error', (error as ResponseError)?.response);
      if (setClient) setClient({});
      formik.setSubmitting(false);
      formik.resetForm({ values: { client_id: '' } });
    },
    // we don't want to retry, as usually the error is a 404
    retry: false,
    additionalParams: [decodedClientId],
  });
};

export default useGetClientInfoQuery;
