/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    Desc?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    TotalAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    ProgramServicesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    ManagementAndGeneralAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    FundraisingAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Desc': !exists(json, 'Desc') ? undefined : json['Desc'],
        'TotalAmt': !exists(json, 'TotalAmt') ? undefined : json['TotalAmt'],
        'ProgramServicesAmt': !exists(json, 'ProgramServicesAmt') ? undefined : json['ProgramServicesAmt'],
        'ManagementAndGeneralAmt': !exists(json, 'ManagementAndGeneralAmt') ? undefined : json['ManagementAndGeneralAmt'],
        'FundraisingAmt': !exists(json, 'FundraisingAmt') ? undefined : json['FundraisingAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Desc': value.Desc,
        'TotalAmt': value.TotalAmt,
        'ProgramServicesAmt': value.ProgramServicesAmt,
        'ManagementAndGeneralAmt': value.ManagementAndGeneralAmt,
        'FundraisingAmt': value.FundraisingAmt,
    };
}

