/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetawsaccountsAWSAccount } from './JumboGetawsaccountsAWSAccount';
import {
    JumboGetawsaccountsAWSAccountFromJSON,
    JumboGetawsaccountsAWSAccountFromJSONTyped,
    JumboGetawsaccountsAWSAccountToJSON,
} from './JumboGetawsaccountsAWSAccount';

/**
 * 
 * @export
 * @interface JumboGetawsaccountsGetAWSAccountsResult
 */
export interface JumboGetawsaccountsGetAWSAccountsResult {
    /**
     * 
     * @type {Array<JumboGetawsaccountsAWSAccount>}
     * @memberof JumboGetawsaccountsGetAWSAccountsResult
     */
    awsAccounts?: Array<JumboGetawsaccountsAWSAccount>;
}

/**
 * Check if a given object implements the JumboGetawsaccountsGetAWSAccountsResult interface.
 */
export function instanceOfJumboGetawsaccountsGetAWSAccountsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetawsaccountsGetAWSAccountsResultFromJSON(json: any): JumboGetawsaccountsGetAWSAccountsResult {
    return JumboGetawsaccountsGetAWSAccountsResultFromJSONTyped(json, false);
}

export function JumboGetawsaccountsGetAWSAccountsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetawsaccountsGetAWSAccountsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'awsAccounts': !exists(json, 'awsAccounts') ? undefined : ((json['awsAccounts'] as Array<any>).map(JumboGetawsaccountsAWSAccountFromJSON)),
    };
}

export function JumboGetawsaccountsGetAWSAccountsResultToJSON(value?: JumboGetawsaccountsGetAWSAccountsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'awsAccounts': value.awsAccounts === undefined ? undefined : ((value.awsAccounts as Array<any>).map(JumboGetawsaccountsAWSAccountToJSON)),
    };
}

