/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990ReturnData } from './ExecRiskApiAppCoreModelsScansForm990ReturnData';
import {
    ExecRiskApiAppCoreModelsScansForm990ReturnDataFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ReturnDataFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ReturnDataToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ReturnData';
import type { ExecRiskBaseFilingHeader } from './ExecRiskBaseFilingHeader';
import {
    ExecRiskBaseFilingHeaderFromJSON,
    ExecRiskBaseFilingHeaderFromJSONTyped,
    ExecRiskBaseFilingHeaderToJSON,
} from './ExecRiskBaseFilingHeader';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990
 */
export interface ExecRiskApiAppCoreModelsScansForm990 {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990
     */
    irs_object_id: string;
    /**
     * 
     * @type {ExecRiskBaseFilingHeader}
     * @memberof ExecRiskApiAppCoreModelsScansForm990
     */
    return_header?: ExecRiskBaseFilingHeader;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990ReturnData}
     * @memberof ExecRiskApiAppCoreModelsScansForm990
     */
    return_data?: ExecRiskApiAppCoreModelsScansForm990ReturnData;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990 interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "irs_object_id" in value;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990FromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990 {
    return ExecRiskApiAppCoreModelsScansForm990FromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'irs_object_id': json['irs_object_id'],
        'return_header': !exists(json, 'return_header') ? undefined : ExecRiskBaseFilingHeaderFromJSON(json['return_header']),
        'return_data': !exists(json, 'return_data') ? undefined : ExecRiskApiAppCoreModelsScansForm990ReturnDataFromJSON(json['return_data']),
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ToJSON(value?: ExecRiskApiAppCoreModelsScansForm990 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'irs_object_id': value.irs_object_id,
        'return_header': ExecRiskBaseFilingHeaderToJSON(value.return_header),
        'return_data': ExecRiskApiAppCoreModelsScansForm990ReturnDataToJSON(value.return_data),
    };
}

