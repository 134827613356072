import React, { useMemo, useState } from 'react';
import { Divider, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack } from '@crucible-risk/security-applications'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    Button,
    Typography,
  } from '@explorer/core';
import {
  Form,
  FieldText,
  FormFieldControl,
  FormSchemaOptions,
  FormSchemaReturn,
  SubmitButton,
} from '@explorer/forms';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { useMutationAddSentinelOneAccount } from './useMutationAddSentinelOneAccount';
import { useMutationVerifySentinelOneAccount } from './useMutationVerifySentinelOneAccount';

enum Steps {
    Verify = 1,  // Enter Token and Domain
    Connect = 2,  // Review Sites and Connect
}

export const validationSchema = (intl) =>
  yup
    .object({
      token: yup.string()
        .required(
          intl.formatMessage({
            id: 'mdr.sentinelone.token.required',
          }),
        ),
      domain: yup.string()
        .required(
          intl.formatMessage({
            id: 'mdr.sentinelone.domain.required',
          }),
        )
        .url(
          intl.formatMessage({
            id: 'mdr.sentinelone.domain.url',
          }),
        ),
    })
    .defined();

type FormValues = yup.InferType<ReturnType<typeof validationSchema>>;

interface VerifyStepProps {
  formSchema: FormSchemaReturn<FormValues>;
};

// Input token and domain
const VerifyStep = ({ formSchema }: VerifyStepProps) => {
    return (
        <Grid
          container
          spacing={1}
          direction="column"
        >
          <Stack gap={1.5}>
            <FormFieldControl {...formSchema.token} />
            <FormFieldControl {...formSchema.domain} />
          </Stack>
        </Grid>
    );
}

interface ConnectStepProps {
  sites: string[];
}

// View sites and connect
const ConnectStep = ({ sites }: ConnectStepProps) => {
  return (
      <Grid
          container
          justifyContent="flex-start"
          spacing={1}
          gap={1}
          direction="column"
      >
        {sites.length > 0 &&
          <>
            <Typography variant="body1" color="textPrimary" label={{ id: 'mdr.sentinelone.sites-found', values: { count: sites.length } }} />
            <Typography variant="body2" color="textPrimary" label={{ id: 'mdr.sentinelone.sites' }} sx={{ px: 2, pt: 1, fontWeight: 'bold !important' }}/>
            <Stack sx={{ maxHeight: 200, overflow: 'auto' }}>
              {sites.map((site) => (
                  <>
                    <Divider />
                    <Typography key={site} variant="body2" color="textPrimary" label={site} sx={{ py: 1, px: 2 }}/>
                  </>
              ))}
            </Stack>
          </>}
        {sites.length === 0 &&
          <Typography variant="body1" color="textPrimary" label={{ id: 'mdr.sentinelone.no-sites-found' }} />}
      </Grid>
  );
}

export const SentinelOneConnectModal = ({
  clientId,
  open = false,
  handleClose,
}: SentinelOneConnectModalProps) => {
  const intl = useIntl();
  const { palette } = useTheme();

  const connectFormId = 'connectForm';

  const [step, setStep] = useState<Steps>(Steps.Verify);

  const { 
    data,
    mutate: mutateVerify,
  } = useMutationVerifySentinelOneAccount({
    client_id: clientId,
    onSuccess: () => {
      setStep(Steps.Connect);
      formik.setSubmitting(false);
    },
    onError: () => {
      formik.setSubmitting(false);
    }
  });

  const { 
    mutate: mutateAdd, 
  } = useMutationAddSentinelOneAccount({
    client_id: clientId,
    onSuccess: () => {
      formik.setSubmitting(false);
      handleClose({ refetch: true });
    },
    onError: () => {
      formik.setSubmitting(false);
    }
  });

  const formik = useFormik<FormValues>({
    validationSchema: validationSchema(intl),
    initialValues: {
      token: '',
      domain: '',
    },
    onSubmit: (values) => {
      if (step === Steps.Verify) {
        mutateVerify({
          JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody: {
            accessToken: values.token,
            sentinelOneManagementURL: values.domain,
          }
        });
      } else {
        mutateAdd({
          JumboPostsentineloneaccountsPostSentinelOneAccountsRequestBody: {
            accessToken: values.token,
            sentinelOneManagementURL: values.domain,
          }
        });
      }
    },
  });

  const initializeFormSchema = ({
    formId,
    formik: innerFormik,
  }: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
    token: {
      formId,
      formik: innerFormik,
      name: 'token',
      renderField: (props) => (
        <FieldText
          {...props}
          size="small"
          label={{ id: 'mdr.sentinelone.token' }}
        />
      ),
    },
    domain: {
      formId,
      formik: innerFormik,
      name: 'domain',
      renderField: (props) => (
        <FieldText
          {...props}
          size="small"
          label={{ id: 'mdr.sentinelone.domain' }}
        />
      ),
    },
  });
  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId: connectFormId,
        formik,
      }),
    [formik],
  );

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
    >
        <Form formId={connectFormId} formik={formik}>
            <DialogTitle>
                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                >
                <Grid container item justifyContent="space-between" alignItems="center">
                    <Grid item xs={10}>
                        <Typography
                            variant="subtitleCapsL"
                            color="textSecondary"
                            textColor={palette.grey[400]}
                            label={{ id: 'mdr.sentinelone.connect-sentinelone' }}
                            noUserSelect
                        />
                    </Grid>
                    <Grid item>
                      <IconButton size="small" onClick={() => handleClose()}>
                          <CloseIcon style={{ color: palette.grey[400] }} />
                      </IconButton>
                    </Grid>
                </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ pt: '15px !important', pb: '0 !important' }}>
                {step === Steps.Verify && <VerifyStep formSchema={formSchema} />}
                {step === Steps.Connect && <ConnectStep sites={data.sites} />}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    label={{ id: 'global.cancel' }}
                    onClick={() => handleClose()}
                    mx={1}
                />
                <SubmitButton
                    formId={connectFormId}
                    formik={formik}
                    color="primary"
                    label={step === Steps.Verify ? {id: 'mdr.sentinelone.continue'} : {id: 'mdr.sentinelone.connect' }}
                    disabled={!formik.dirty || (formik.dirty && !formik.isValid)}
                    mx={1}
                    loading={formik.isSubmitting}
                />
            </DialogActions>
        </Form>
    </Dialog>
  )
};

export interface SentinelOneConnectModalProps {
  clientId: string;
  open: boolean;
  handleClose: (options?: { refetch: boolean }) => void;
}
