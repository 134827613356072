/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    average_monthly_fmv_of_sec_grp_prior_year_amt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    average_monthly_fmv_of_sec_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    average_monthly_cash_balances_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    fmv_other_non_exempt_use_asset_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    total_fmv_of_non_exempt_use_asset_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    discount_claimed_amt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    acquisition_indebtedness_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    adjusted_fmv_less_indebtedness_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    cash_deemed_charitable_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    net_vl_non_exempt_use_assets_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    pct_of_net_vl_non_exempt_use_ast_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    recoveries_py_distri_min_asset_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount
     */
    total_minimum_asset_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSec>;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'average_monthly_fmv_of_sec_grp_prior_year_amt': !exists(json, 'average_monthly_fmv_of_sec_grp_prior_year_amt') ? undefined : json['average_monthly_fmv_of_sec_grp_prior_year_amt'],
        'average_monthly_fmv_of_sec_grp': !exists(json, 'average_monthly_fmv_of_sec_grp') ? undefined : ((json['average_monthly_fmv_of_sec_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'average_monthly_cash_balances_grp': !exists(json, 'average_monthly_cash_balances_grp') ? undefined : ((json['average_monthly_cash_balances_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'fmv_other_non_exempt_use_asset_grp': !exists(json, 'fmv_other_non_exempt_use_asset_grp') ? undefined : ((json['fmv_other_non_exempt_use_asset_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'total_fmv_of_non_exempt_use_asset_grp': !exists(json, 'total_fmv_of_non_exempt_use_asset_grp') ? undefined : ((json['total_fmv_of_non_exempt_use_asset_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'discount_claimed_amt': !exists(json, 'discount_claimed_amt') ? undefined : json['discount_claimed_amt'],
        'acquisition_indebtedness_grp': !exists(json, 'acquisition_indebtedness_grp') ? undefined : ((json['acquisition_indebtedness_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'adjusted_fmv_less_indebtedness_grp': !exists(json, 'adjusted_fmv_less_indebtedness_grp') ? undefined : ((json['adjusted_fmv_less_indebtedness_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'cash_deemed_charitable_grp': !exists(json, 'cash_deemed_charitable_grp') ? undefined : ((json['cash_deemed_charitable_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'net_vl_non_exempt_use_assets_grp': !exists(json, 'net_vl_non_exempt_use_assets_grp') ? undefined : ((json['net_vl_non_exempt_use_assets_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'pct_of_net_vl_non_exempt_use_ast_grp': !exists(json, 'pct_of_net_vl_non_exempt_use_ast_grp') ? undefined : ((json['pct_of_net_vl_non_exempt_use_ast_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'recoveries_py_distri_min_asset_grp': !exists(json, 'recoveries_py_distri_min_asset_grp') ? undefined : ((json['recoveries_py_distri_min_asset_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
        'total_minimum_asset_grp': !exists(json, 'total_minimum_asset_grp') ? undefined : ((json['total_minimum_asset_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecFromJSON)),
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'average_monthly_fmv_of_sec_grp_prior_year_amt': value.average_monthly_fmv_of_sec_grp_prior_year_amt,
        'average_monthly_fmv_of_sec_grp': value.average_monthly_fmv_of_sec_grp === undefined ? undefined : ((value.average_monthly_fmv_of_sec_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'average_monthly_cash_balances_grp': value.average_monthly_cash_balances_grp === undefined ? undefined : ((value.average_monthly_cash_balances_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'fmv_other_non_exempt_use_asset_grp': value.fmv_other_non_exempt_use_asset_grp === undefined ? undefined : ((value.fmv_other_non_exempt_use_asset_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'total_fmv_of_non_exempt_use_asset_grp': value.total_fmv_of_non_exempt_use_asset_grp === undefined ? undefined : ((value.total_fmv_of_non_exempt_use_asset_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'discount_claimed_amt': value.discount_claimed_amt,
        'acquisition_indebtedness_grp': value.acquisition_indebtedness_grp === undefined ? undefined : ((value.acquisition_indebtedness_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'adjusted_fmv_less_indebtedness_grp': value.adjusted_fmv_less_indebtedness_grp === undefined ? undefined : ((value.adjusted_fmv_less_indebtedness_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'cash_deemed_charitable_grp': value.cash_deemed_charitable_grp === undefined ? undefined : ((value.cash_deemed_charitable_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'net_vl_non_exempt_use_assets_grp': value.net_vl_non_exempt_use_assets_grp === undefined ? undefined : ((value.net_vl_non_exempt_use_assets_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'pct_of_net_vl_non_exempt_use_ast_grp': value.pct_of_net_vl_non_exempt_use_ast_grp === undefined ? undefined : ((value.pct_of_net_vl_non_exempt_use_ast_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'recoveries_py_distri_min_asset_grp': value.recoveries_py_distri_min_asset_grp === undefined ? undefined : ((value.recoveries_py_distri_min_asset_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
        'total_minimum_asset_grp': value.total_minimum_asset_grp === undefined ? undefined : ((value.total_minimum_asset_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAverageMonthlyFMVOfSecToJSON)),
    };
}

