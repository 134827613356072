/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlClientRoles } from './ControlClientRoles';
import {
    ControlClientRolesFromJSON,
    ControlClientRolesFromJSONTyped,
    ControlClientRolesToJSON,
} from './ControlClientRoles';
import type { ControlPostTestingCreateClient } from './ControlPostTestingCreateClient';
import {
    ControlPostTestingCreateClientFromJSON,
    ControlPostTestingCreateClientFromJSONTyped,
    ControlPostTestingCreateClientToJSON,
} from './ControlPostTestingCreateClient';
import type { ControlPostTestingUser } from './ControlPostTestingUser';
import {
    ControlPostTestingUserFromJSON,
    ControlPostTestingUserFromJSONTyped,
    ControlPostTestingUserToJSON,
} from './ControlPostTestingUser';

/**
 * 
 * @export
 * @interface ControlPostTestingCreateMSPClient
 */
export interface ControlPostTestingCreateMSPClient {
    /**
     * 
     * @type {ControlClientRoles}
     * @memberof ControlPostTestingCreateMSPClient
     */
    client_role: ControlClientRoles;
    /**
     * 
     * @type {Array<ControlPostTestingUser>}
     * @memberof ControlPostTestingCreateMSPClient
     */
    users: Array<ControlPostTestingUser>;
    /**
     * 
     * @type {Array<ControlPostTestingCreateClient>}
     * @memberof ControlPostTestingCreateMSPClient
     */
    monitored: Array<ControlPostTestingCreateClient>;
}

/**
 * Check if a given object implements the ControlPostTestingCreateMSPClient interface.
 */
export function instanceOfControlPostTestingCreateMSPClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_role" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "monitored" in value;

    return isInstance;
}

export function ControlPostTestingCreateMSPClientFromJSON(json: any): ControlPostTestingCreateMSPClient {
    return ControlPostTestingCreateMSPClientFromJSONTyped(json, false);
}

export function ControlPostTestingCreateMSPClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPostTestingCreateMSPClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_role': ControlClientRolesFromJSON(json['client_role']),
        'users': ((json['users'] as Array<any>).map(ControlPostTestingUserFromJSON)),
        'monitored': ((json['monitored'] as Array<any>).map(ControlPostTestingCreateClientFromJSON)),
    };
}

export function ControlPostTestingCreateMSPClientToJSON(value?: ControlPostTestingCreateMSPClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_role': ControlClientRolesToJSON(value.client_role),
        'users': ((value.users as Array<any>).map(ControlPostTestingUserToJSON)),
        'monitored': ((value.monitored as Array<any>).map(ControlPostTestingCreateClientToJSON)),
    };
}

