/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewEnumerationCounter } from './CRSFSecReviewEnumerationCounter';
import {
    CRSFSecReviewEnumerationCounterFromJSON,
    CRSFSecReviewEnumerationCounterFromJSONTyped,
    CRSFSecReviewEnumerationCounterToJSON,
} from './CRSFSecReviewEnumerationCounter';
import type { CRSFSecReviewSourceCount } from './CRSFSecReviewSourceCount';
import {
    CRSFSecReviewSourceCountFromJSON,
    CRSFSecReviewSourceCountFromJSONTyped,
    CRSFSecReviewSourceCountToJSON,
} from './CRSFSecReviewSourceCount';

/**
 * 
 * @export
 * @interface SourceCount1
 */
export interface SourceCount1 {
    /**
     * 
     * @type {CRSFSecReviewEnumerationCounter}
     * @memberof SourceCount1
     */
    account?: CRSFSecReviewEnumerationCounter;
    /**
     * 
     * @type {CRSFSecReviewEnumerationCounter}
     * @memberof SourceCount1
     */
    account_sec?: CRSFSecReviewEnumerationCounter;
    /**
     * 
     * @type {CRSFSecReviewEnumerationCounter}
     * @memberof SourceCount1
     */
    asm?: CRSFSecReviewEnumerationCounter;
    /**
     * 
     * @type {CRSFSecReviewEnumerationCounter}
     * @memberof SourceCount1
     */
    bvd?: CRSFSecReviewEnumerationCounter;
    /**
     * 
     * @type {CRSFSecReviewEnumerationCounter}
     * @memberof SourceCount1
     */
    redirect?: CRSFSecReviewEnumerationCounter;
    /**
     * 
     * @type {CRSFSecReviewEnumerationCounter}
     * @memberof SourceCount1
     */
    whois?: CRSFSecReviewEnumerationCounter;
    /**
     * 
     * @type {CRSFSecReviewEnumerationCounter}
     * @memberof SourceCount1
     */
    zoominfo?: CRSFSecReviewEnumerationCounter;
}

/**
 * Check if a given object implements the SourceCount1 interface.
 */
export function instanceOfSourceCount1(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SourceCount1FromJSON(json: any): SourceCount1 {
    return SourceCount1FromJSONTyped(json, false);
}

export function SourceCount1FromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceCount1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': !exists(json, 'account') ? undefined : CRSFSecReviewEnumerationCounterFromJSON(json['account']),
        'account_sec': !exists(json, 'account-sec') ? undefined : CRSFSecReviewEnumerationCounterFromJSON(json['account-sec']),
        'asm': !exists(json, 'asm') ? undefined : CRSFSecReviewEnumerationCounterFromJSON(json['asm']),
        'bvd': !exists(json, 'bvd') ? undefined : CRSFSecReviewEnumerationCounterFromJSON(json['bvd']),
        'redirect': !exists(json, 'redirect') ? undefined : CRSFSecReviewEnumerationCounterFromJSON(json['redirect']),
        'whois': !exists(json, 'whois') ? undefined : CRSFSecReviewEnumerationCounterFromJSON(json['whois']),
        'zoominfo': !exists(json, 'zoominfo') ? undefined : CRSFSecReviewEnumerationCounterFromJSON(json['zoominfo']),
    };
}

export function SourceCount1ToJSON(value?: SourceCount1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': CRSFSecReviewEnumerationCounterToJSON(value.account),
        'account-sec': CRSFSecReviewEnumerationCounterToJSON(value.account_sec),
        'asm': CRSFSecReviewEnumerationCounterToJSON(value.asm),
        'bvd': CRSFSecReviewEnumerationCounterToJSON(value.bvd),
        'redirect': CRSFSecReviewEnumerationCounterToJSON(value.redirect),
        'whois': CRSFSecReviewEnumerationCounterToJSON(value.whois),
        'zoominfo': CRSFSecReviewEnumerationCounterToJSON(value.zoominfo),
    };
}

