/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitHostingServiceResult } from './MonitHostingServiceResult';
import {
    MonitHostingServiceResultFromJSON,
    MonitHostingServiceResultFromJSONTyped,
    MonitHostingServiceResultToJSON,
} from './MonitHostingServiceResult';

/**
 * 
 * @export
 * @interface MonitHostingRegionResult
 */
export interface MonitHostingRegionResult {
    /**
     * 
     * @type {number}
     * @memberof MonitHostingRegionResult
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitHostingRegionResult
     */
    region?: string;
    /**
     * 
     * @type {Array<MonitHostingServiceResult>}
     * @memberof MonitHostingRegionResult
     */
    services?: Array<MonitHostingServiceResult>;
}

/**
 * Check if a given object implements the MonitHostingRegionResult interface.
 */
export function instanceOfMonitHostingRegionResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitHostingRegionResultFromJSON(json: any): MonitHostingRegionResult {
    return MonitHostingRegionResultFromJSONTyped(json, false);
}

export function MonitHostingRegionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitHostingRegionResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(MonitHostingServiceResultFromJSON)),
    };
}

export function MonitHostingRegionResultToJSON(value?: MonitHostingRegionResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'region': value.region,
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(MonitHostingServiceResultToJSON)),
    };
}

