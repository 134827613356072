/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    other_organization_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    transaction_type_txt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    involved_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg
     */
    method_of_amount_determination_txt?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'other_organization_name': !exists(json, 'other_organization_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['other_organization_name']),
        'transaction_type_txt': !exists(json, 'transaction_type_txt') ? undefined : json['transaction_type_txt'],
        'involved_amt': !exists(json, 'involved_amt') ? undefined : json['involved_amt'],
        'method_of_amount_determination_txt': !exists(json, 'method_of_amount_determination_txt') ? undefined : json['method_of_amount_determination_txt'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'other_organization_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.other_organization_name),
        'transaction_type_txt': value.transaction_type_txt,
        'involved_amt': value.involved_amt,
        'method_of_amount_determination_txt': value.method_of_amount_determination_txt,
    };
}

