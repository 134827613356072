/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    DisregardedEntityName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    USAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    ForeignAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    EIN?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    PrimaryActivitiesTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    LegalDomicileStateCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    LegalDomicileForeignCountryCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    ExemptCodeSectionTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    PublicCharityStatusTxt?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    DirectControllingEntityName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    DirectControllingNACd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg
     */
    ControlledOrganizationInd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DisregardedEntityName': !exists(json, 'DisregardedEntityName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['DisregardedEntityName']),
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': !exists(json, 'ForeignAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'EIN': !exists(json, 'EIN') ? undefined : json['EIN'],
        'PrimaryActivitiesTxt': !exists(json, 'PrimaryActivitiesTxt') ? undefined : json['PrimaryActivitiesTxt'],
        'LegalDomicileStateCd': !exists(json, 'LegalDomicileStateCd') ? undefined : json['LegalDomicileStateCd'],
        'LegalDomicileForeignCountryCd': !exists(json, 'LegalDomicileForeignCountryCd') ? undefined : json['LegalDomicileForeignCountryCd'],
        'ExemptCodeSectionTxt': !exists(json, 'ExemptCodeSectionTxt') ? undefined : json['ExemptCodeSectionTxt'],
        'PublicCharityStatusTxt': !exists(json, 'PublicCharityStatusTxt') ? undefined : json['PublicCharityStatusTxt'],
        'DirectControllingEntityName': !exists(json, 'DirectControllingEntityName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['DirectControllingEntityName']),
        'DirectControllingNACd': !exists(json, 'DirectControllingNACd') ? undefined : json['DirectControllingNACd'],
        'ControlledOrganizationInd': !exists(json, 'ControlledOrganizationInd') ? undefined : json['ControlledOrganizationInd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrgToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedTaxExemptOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DisregardedEntityName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.DisregardedEntityName),
        'USAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'EIN': value.EIN,
        'PrimaryActivitiesTxt': value.PrimaryActivitiesTxt,
        'LegalDomicileStateCd': value.LegalDomicileStateCd,
        'LegalDomicileForeignCountryCd': value.LegalDomicileForeignCountryCd,
        'ExemptCodeSectionTxt': value.ExemptCodeSectionTxt,
        'PublicCharityStatusTxt': value.PublicCharityStatusTxt,
        'DirectControllingEntityName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.DirectControllingEntityName),
        'DirectControllingNACd': value.DirectControllingNACd,
        'ControlledOrganizationInd': value.ControlledOrganizationInd,
    };
}

