import React, { memo, useMemo, Dispatch, SetStateAction } from 'react';
import { useFormik } from 'formik';
import { Grid } from '@explorer/core';
import { Form, FormFieldControl, SubmitButton } from '@explorer/forms';
import {
  FormValues,
  validationSchema,
  initializeFormSchema,
} from './formSchema';
import { useIntl } from 'react-intl';
import useGetClientInfoQuery from './useGetClientInfoQuery';
import { useDecodeEncodeQueryParams } from '@explorer/hooks';
import { ControlClientInfoResponse } from '@explorer/open-api-client';

const formId = 'GetClient';

export const GetClient = memo(
  //
  function GetClient({ setClient }: GetClientProps) {
    const intl = useIntl();
    const { decodedQuery, pushWithEncodedQuery } =
      useDecodeEncodeQueryParams<FormValues>();
    const decodedClientId = decodedQuery?.client_id ?? '';

    const formik = useFormik<FormValues>({
      validationSchema: validationSchema(intl),
      initialValues: {
        client_id: decodedClientId,
      },
      onSubmit: (values) => {
        pushWithEncodedQuery({ client_id: values.client_id });
      },
    });

    const { isInitialLoading } = useGetClientInfoQuery(
      setClient,
      decodedClientId,
      formik,
    );

    const formSchema = useMemo(
      () =>
        initializeFormSchema({
          formId,
          formik,
        }),
      [formik],
    );

    /**
     * Disable the submit button
     */
    const disableSearch = useMemo<boolean>(() => {
      const {
        isSubmitting,
        isValid,
        values: { client_id },
      } = formik;

      if (isSubmitting || !isValid) return true;

      if (client_id === '') return true;

      return false;
    }, [formik]);

    return (
      <Form formId={formId} formik={formik}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={10}>
            <FormFieldControl {...formSchema.client_id} />
          </Grid>
          <Grid item xs={2}>
            <SubmitButton
              formId={formId}
              formik={formik}
              isLoading={isInitialLoading}
              color="primary"
              label={{ id: 'forms-presets.search' }}
              style={{ height: 56 }}
              disabled={disableSearch}
              fullWidth
            />
          </Grid>
        </Grid>
      </Form>
    );
  },
);

export interface GetClientProps {
  readonly setClient?: Dispatch<
    SetStateAction<ControlClientInfoResponse | undefined>
  >;
}
