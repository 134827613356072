import { CheckboxOptions } from '@explorer/forms/base/FieldCheckbox';
import { getLanguageLabel } from '@explorer/helpers/getLanguageLabel/getLanguageLabel';
import { CRSFLocale } from '@explorer/open-api-client';
import { SUPPORTED_CRA_LOCALES } from './formSchema';

export const FORM_ID = 'TriggerCRAScan';

export const DEFAULT_LOCALE = 'en-US';

export const INITIAL_FORM_VALUES = {
  entity_name: '',
  entity_domain: '',
  domains: '',
  ip_addresses: '',
  industry: '',
  number_of_employees: 0,
  number_of_pii_records: 0,
  company_revenue: 0,
  locale: DEFAULT_LOCALE,
  cobranded: null,
  scan_opts: {
    force: false,
  },
};

export const SCAN_OPTS: CheckboxOptions = [
  {
    label: {
      id: 'forms-presets.scan-opts-force',
    },
    name: 'force',
  },
];

export const LOCALE_OPTS: Options = SUPPORTED_CRA_LOCALES.map((locale) => ({
  label: getLanguageLabel({
    displayedLocale: locale,
    currentLocale: 'en-US',
    options: { includeCountry: true },
  }),
  value: locale,
}));

export const COBRANDED_OPTS: Options = [
  {
    label: 'Acrisure',
    value: 'acrisure',
  },
  {
    label: 'CDW',
    value: 'cdw',
  },
];
