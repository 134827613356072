/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFInsuranceEnv } from './CRSFInsuranceEnv';
import {
    CRSFInsuranceEnvFromJSON,
    CRSFInsuranceEnvFromJSONTyped,
    CRSFInsuranceEnvToJSON,
} from './CRSFInsuranceEnv';
import type { CRSFLocale } from './CRSFLocale';
import {
    CRSFLocaleFromJSON,
    CRSFLocaleFromJSONTyped,
    CRSFLocaleToJSON,
} from './CRSFLocale';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * 
 * @export
 * @interface CRSFTriggerNoIssue
 */
export interface CRSFTriggerNoIssue {
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerNoIssue
     */
    cobranded?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFTriggerNoIssue
     */
    domains: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerNoIssue
     */
    entity_name?: string;
    /**
     * 
     * @type {object}
     * @memberof CRSFTriggerNoIssue
     */
    extra?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTriggerNoIssue
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFInsuranceEnv}
     * @memberof CRSFTriggerNoIssue
     */
    insurance_env?: CRSFInsuranceEnv;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFTriggerNoIssue
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTriggerNoIssue
     */
    lite_enum?: boolean;
    /**
     * 
     * @type {CRSFLocale}
     * @memberof CRSFTriggerNoIssue
     */
    locale?: CRSFLocale;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRSFTriggerNoIssue
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerNoIssue
     */
    request_origin?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTriggerNoIssue
     */
    scan_web_sev2_force?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerNoIssue
     */
    user?: string;
}

/**
 * Check if a given object implements the CRSFTriggerNoIssue interface.
 */
export function instanceOfCRSFTriggerNoIssue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;

    return isInstance;
}

export function CRSFTriggerNoIssueFromJSON(json: any): CRSFTriggerNoIssue {
    return CRSFTriggerNoIssueFromJSONTyped(json, false);
}

export function CRSFTriggerNoIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTriggerNoIssue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'domains': json['domains'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFInsuranceEnvFromJSON(json['insurance_env']),
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'lite_enum': !exists(json, 'lite_enum') ? undefined : json['lite_enum'],
        'locale': !exists(json, 'locale') ? undefined : CRSFLocaleFromJSON(json['locale']),
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'request_origin': !exists(json, 'request_origin') ? undefined : json['request_origin'],
        'scan_web_sev2_force': !exists(json, 'scan_web_sev2_force') ? undefined : json['scan_web_sev2_force'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CRSFTriggerNoIssueToJSON(value?: CRSFTriggerNoIssue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cobranded': value.cobranded,
        'domains': value.domains,
        'entity_name': value.entity_name,
        'extra': value.extra,
        'force': value.force,
        'insurance_env': CRSFInsuranceEnvToJSON(value.insurance_env),
        'ip_addresses': value.ip_addresses,
        'lite_enum': value.lite_enum,
        'locale': CRSFLocaleToJSON(value.locale),
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'request_origin': value.request_origin,
        'scan_web_sev2_force': value.scan_web_sev2_force,
        'user': value.user,
    };
}

