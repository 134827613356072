/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlChecklistV2Progress } from './ControlChecklistV2Progress';
import {
    ControlChecklistV2ProgressFromJSON,
    ControlChecklistV2ProgressFromJSONTyped,
    ControlChecklistV2ProgressToJSON,
} from './ControlChecklistV2Progress';

/**
 * 
 * @export
 * @interface ControlManagedClientResponse
 */
export interface ControlManagedClientResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlManagedClientResponse
     */
    is_policyholder: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlManagedClientResponse
     */
    pending_review: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    policy_ends?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlManagedClientResponse
     */
    risk_score?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlManagedClientResponse
     */
    prev_risk_score?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    logo_url?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlManagedClientResponse
     */
    critical_findings?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlManagedClientResponse
     */
    total_findings?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    last_scan?: string;
    /**
     * 
     * @type {ControlChecklistV2Progress}
     * @memberof ControlManagedClientResponse
     */
    security_checklist_v2_progress?: ControlChecklistV2Progress;
    /**
     * 
     * @type {string}
     * @memberof ControlManagedClientResponse
     */
    report?: string;
}

/**
 * Check if a given object implements the ControlManagedClientResponse interface.
 */
export function instanceOfControlManagedClientResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "entity_id" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "is_policyholder" in value;
    isInstance = isInstance && "pending_review" in value;

    return isInstance;
}

export function ControlManagedClientResponseFromJSON(json: any): ControlManagedClientResponse {
    return ControlManagedClientResponseFromJSONTyped(json, false);
}

export function ControlManagedClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlManagedClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'client_id': json['client_id'],
        'entity_id': json['entity_id'],
        'domain': json['domain'],
        'is_policyholder': json['is_policyholder'],
        'pending_review': json['pending_review'],
        'policy_ends': !exists(json, 'policy_ends') ? undefined : json['policy_ends'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'prev_risk_score': !exists(json, 'prev_risk_score') ? undefined : json['prev_risk_score'],
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'critical_findings': !exists(json, 'critical_findings') ? undefined : json['critical_findings'],
        'total_findings': !exists(json, 'total_findings') ? undefined : json['total_findings'],
        'last_scan': !exists(json, 'last_scan') ? undefined : json['last_scan'],
        'security_checklist_v2_progress': !exists(json, 'security_checklist_v2_progress') ? undefined : ControlChecklistV2ProgressFromJSON(json['security_checklist_v2_progress']),
        'report': !exists(json, 'report') ? undefined : json['report'],
    };
}

export function ControlManagedClientResponseToJSON(value?: ControlManagedClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'client_id': value.client_id,
        'entity_id': value.entity_id,
        'domain': value.domain,
        'is_policyholder': value.is_policyholder,
        'pending_review': value.pending_review,
        'policy_ends': value.policy_ends,
        'risk_score': value.risk_score,
        'prev_risk_score': value.prev_risk_score,
        'logo_url': value.logo_url,
        'critical_findings': value.critical_findings,
        'total_findings': value.total_findings,
        'last_scan': value.last_scan,
        'security_checklist_v2_progress': ControlChecklistV2ProgressToJSON(value.security_checklist_v2_progress),
        'report': value.report,
    };
}

