/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetplaidtransactionsinsightsPlaidTransactionInsight } from './JumboGetplaidtransactionsinsightsPlaidTransactionInsight';
import {
    JumboGetplaidtransactionsinsightsPlaidTransactionInsightFromJSON,
    JumboGetplaidtransactionsinsightsPlaidTransactionInsightFromJSONTyped,
    JumboGetplaidtransactionsinsightsPlaidTransactionInsightToJSON,
} from './JumboGetplaidtransactionsinsightsPlaidTransactionInsight';

/**
 * 
 * @export
 * @interface JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult
 */
export interface JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult {
    /**
     * 
     * @type {JumboGetplaidtransactionsinsightsPlaidTransactionInsight}
     * @memberof JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult
     */
    aboveThreshold?: JumboGetplaidtransactionsinsightsPlaidTransactionInsight;
    /**
     * 
     * @type {JumboGetplaidtransactionsinsightsPlaidTransactionInsight}
     * @memberof JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult
     */
    needsReview?: JumboGetplaidtransactionsinsightsPlaidTransactionInsight;
    /**
     * 
     * @type {JumboGetplaidtransactionsinsightsPlaidTransactionInsight}
     * @memberof JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult
     */
    outboundTransactions?: JumboGetplaidtransactionsinsightsPlaidTransactionInsight;
    /**
     * 
     * @type {JumboGetplaidtransactionsinsightsPlaidTransactionInsight}
     * @memberof JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult
     */
    toNewMerchant?: JumboGetplaidtransactionsinsightsPlaidTransactionInsight;
}

/**
 * Check if a given object implements the JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult interface.
 */
export function instanceOfJumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResultFromJSON(json: any): JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult {
    return JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResultFromJSONTyped(json, false);
}

export function JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aboveThreshold': !exists(json, 'aboveThreshold') ? undefined : JumboGetplaidtransactionsinsightsPlaidTransactionInsightFromJSON(json['aboveThreshold']),
        'needsReview': !exists(json, 'needsReview') ? undefined : JumboGetplaidtransactionsinsightsPlaidTransactionInsightFromJSON(json['needsReview']),
        'outboundTransactions': !exists(json, 'outboundTransactions') ? undefined : JumboGetplaidtransactionsinsightsPlaidTransactionInsightFromJSON(json['outboundTransactions']),
        'toNewMerchant': !exists(json, 'toNewMerchant') ? undefined : JumboGetplaidtransactionsinsightsPlaidTransactionInsightFromJSON(json['toNewMerchant']),
    };
}

export function JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResultToJSON(value?: JumboGetplaidtransactionsinsightsGetPlaidTransactionsInsightsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aboveThreshold': JumboGetplaidtransactionsinsightsPlaidTransactionInsightToJSON(value.aboveThreshold),
        'needsReview': JumboGetplaidtransactionsinsightsPlaidTransactionInsightToJSON(value.needsReview),
        'outboundTransactions': JumboGetplaidtransactionsinsightsPlaidTransactionInsightToJSON(value.outboundTransactions),
        'toNewMerchant': JumboGetplaidtransactionsinsightsPlaidTransactionInsightToJSON(value.toNewMerchant),
    };
}

