/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityIds } from './EntityIds';
import {
    EntityIdsFromJSON,
    EntityIdsFromJSONTyped,
    EntityIdsToJSON,
} from './EntityIds';
import type { ExternalIds } from './ExternalIds';
import {
    ExternalIdsFromJSON,
    ExternalIdsFromJSONTyped,
    ExternalIdsToJSON,
} from './ExternalIds';
import type { MonitRelationType } from './MonitRelationType';
import {
    MonitRelationTypeFromJSON,
    MonitRelationTypeFromJSONTyped,
    MonitRelationTypeToJSON,
} from './MonitRelationType';

/**
 * 
 * @export
 * @interface MonitEntitySearchQueries
 */
export interface MonitEntitySearchQueries {
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySearchQueries
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntitySearchQueries
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    search?: string;
    /**
     * 
     * @type {MonitRelationType}
     * @memberof MonitEntitySearchQueries
     */
    relation?: MonitRelationType;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    policy_number?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    policy_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    policyholder_email?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    policyholder_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    account_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    account_line_uuid?: string;
    /**
     * 
     * @type {EntityIds}
     * @memberof MonitEntitySearchQueries
     */
    entity_ids?: EntityIds;
    /**
     * 
     * @type {ExternalIds}
     * @memberof MonitEntitySearchQueries
     */
    external_ids?: ExternalIds;
    /**
     * 
     * @type {string}
     * @memberof MonitEntitySearchQueries
     */
    entity_domain?: string;
}

/**
 * Check if a given object implements the MonitEntitySearchQueries interface.
 */
export function instanceOfMonitEntitySearchQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntitySearchQueriesFromJSON(json: any): MonitEntitySearchQueries {
    return MonitEntitySearchQueriesFromJSONTyped(json, false);
}

export function MonitEntitySearchQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntitySearchQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'relation': !exists(json, 'relation') ? undefined : MonitRelationTypeFromJSON(json['relation']),
        'policy_number': !exists(json, 'policy_number') ? undefined : json['policy_number'],
        'policy_uuid': !exists(json, 'policy_uuid') ? undefined : json['policy_uuid'],
        'policyholder_email': !exists(json, 'policyholder_email') ? undefined : json['policyholder_email'],
        'policyholder_uuid': !exists(json, 'policyholder_uuid') ? undefined : json['policyholder_uuid'],
        'account_uuid': !exists(json, 'account_uuid') ? undefined : json['account_uuid'],
        'account_line_uuid': !exists(json, 'account_line_uuid') ? undefined : json['account_line_uuid'],
        'entity_ids': !exists(json, 'entity_ids') ? undefined : EntityIdsFromJSON(json['entity_ids']),
        'external_ids': !exists(json, 'external_ids') ? undefined : ExternalIdsFromJSON(json['external_ids']),
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
    };
}

export function MonitEntitySearchQueriesToJSON(value?: MonitEntitySearchQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'page_size': value.page_size,
        'search': value.search,
        'relation': MonitRelationTypeToJSON(value.relation),
        'policy_number': value.policy_number,
        'policy_uuid': value.policy_uuid,
        'policyholder_email': value.policyholder_email,
        'policyholder_uuid': value.policyholder_uuid,
        'account_uuid': value.account_uuid,
        'account_line_uuid': value.account_line_uuid,
        'entity_ids': EntityIdsToJSON(value.entity_ids),
        'external_ids': ExternalIdsToJSON(value.external_ids),
        'entity_domain': value.entity_domain,
    };
}

