/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsEDRAlertsResult } from './JumboStructsEDRAlertsResult';
import {
    JumboStructsEDRAlertsResultFromJSON,
    JumboStructsEDRAlertsResultFromJSONTyped,
    JumboStructsEDRAlertsResultToJSON,
} from './JumboStructsEDRAlertsResult';

/**
 * 
 * @export
 * @interface JumboStructsEDRPaginatedAlertsResult
 */
export interface JumboStructsEDRPaginatedAlertsResult {
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRPaginatedAlertsResult
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRPaginatedAlertsResult
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRPaginatedAlertsResult
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<JumboStructsEDRAlertsResult>}
     * @memberof JumboStructsEDRPaginatedAlertsResult
     */
    threats?: Array<JumboStructsEDRAlertsResult>;
}

/**
 * Check if a given object implements the JumboStructsEDRPaginatedAlertsResult interface.
 */
export function instanceOfJumboStructsEDRPaginatedAlertsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRPaginatedAlertsResultFromJSON(json: any): JumboStructsEDRPaginatedAlertsResult {
    return JumboStructsEDRPaginatedAlertsResultFromJSONTyped(json, false);
}

export function JumboStructsEDRPaginatedAlertsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRPaginatedAlertsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'threats': !exists(json, 'threats') ? undefined : ((json['threats'] as Array<any>).map(JumboStructsEDRAlertsResultFromJSON)),
    };
}

export function JumboStructsEDRPaginatedAlertsResultToJSON(value?: JumboStructsEDRPaginatedAlertsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'page': value.page,
        'pageSize': value.pageSize,
        'threats': value.threats === undefined ? undefined : ((value.threats as Array<any>).map(JumboStructsEDRAlertsResultToJSON)),
    };
}

