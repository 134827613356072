import { useV1APIMutation } from '@explorer/query';
import { useQuerySnackbar } from '@explorer/hooks';
import { V1IntegrationsWebAdminSentineloneAccountsIdDeleteRequest } from '@explorer/open-api-client';

export const useMutationDeleteSentinelOneAccount = (params: {
  client_id: string;
}) => {
  const { client_id } = params;
  const { snackError, snackSuccess } = useQuerySnackbar();

  return useV1APIMutation({
    mutationKey: ['v1IntegrationsWebAdminSentineloneAccountsAccountIdDelete'],
    mutationFn(
      args: V1IntegrationsWebAdminSentineloneAccountsIdDeleteRequest,
      client,
    ) {
      return client.v1IntegrationsWebAdminSentineloneAccountsIdDelete({
        id: args.id,
      });
    },
    onSuccess: () => {
      snackSuccess('Account has been successfully deleted.');
    },
    explorerAPIOptions: {
      clientId: client_id,
      onError: (error) => {
        snackError(
          'Failed to delete account. Please try again later.',
          error.error,
        );
      },
    },
  });
};
