/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails } from './JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails';
import {
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsFromJSON,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsFromJSONTyped,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsToJSON,
} from './JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails';
import type { JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals } from './JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals';
import {
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSON,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSONTyped,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsToJSON,
} from './JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals';

/**
 * 
 * @export
 * @interface JumboGetwizercompanyreportGetWizerCompanyReportTrainings
 */
export interface JumboGetwizercompanyreportGetWizerCompanyReportTrainings {
    /**
     * 
     * @type {Array<JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails>}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainings
     */
    details?: Array<JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails>;
    /**
     * 
     * @type {JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainings
     */
    totals?: JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals;
}

/**
 * Check if a given object implements the JumboGetwizercompanyreportGetWizerCompanyReportTrainings interface.
 */
export function instanceOfJumboGetwizercompanyreportGetWizerCompanyReportTrainings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingsFromJSON(json: any): JumboGetwizercompanyreportGetWizerCompanyReportTrainings {
    return JumboGetwizercompanyreportGetWizerCompanyReportTrainingsFromJSONTyped(json, false);
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetwizercompanyreportGetWizerCompanyReportTrainings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsFromJSON)),
        'totals': !exists(json, 'totals') ? undefined : JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSON(json['totals']),
    };
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingsToJSON(value?: JumboGetwizercompanyreportGetWizerCompanyReportTrainings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsToJSON)),
        'totals': JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsToJSON(value.totals),
    };
}

