import { Typography } from '@explorer/core';
import { CurrencyCode, formatMoneyWithCurrencyCode } from '@explorer/helpers';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Grid } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { PrepurchaseInfo } from '@explorer/forms/presets/forms/ModifyClient/types';

export const PrepurchaseDescription = (props: {
  prepurchaseInfo: PrepurchaseInfo;
}) => {
  const { prepurchaseInfo } = props;

  const intl = useIntl();

  const { productSku, currency, price } = prepurchaseInfo;

  const priceAsNumber = parseInt(price, 10);
  const hasPrice = !Number.isNaN(priceAsNumber);

  const convertedFromCents = hasPrice ? priceAsNumber / 100 : null;

  const descriptionStringId = hasPrice
    ? 'forms-presets.module-prepurchase-bundle-description-with-price'
    : 'forms-presets.module-prepurchase-bundle-description';

  return (
    <Grid
      container
      alignItems="center"
      wrap={'nowrap'}
      gap={1}
      sx={{
        backgroundColor: 'paper',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        p: 1,
        px: 2,
      }}
    >
      <AddShoppingCartIcon />
      <Typography variant="bodyS" color="textSecondary">
        {intl.formatMessage(
          {
            id: descriptionStringId,
          },
          {
            sku: productSku ?? 'unknown',
            price: formatMoneyWithCurrencyCode(
              convertedFromCents,
              (currency?.toUpperCase() as CurrencyCode) ?? 'USD',
            ),
          },
        )}
      </Typography>
    </Grid>
  );
};
