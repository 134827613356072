/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsPlaidTransactionPaymentMeta
 */
export interface JumboStructsPlaidTransactionPaymentMeta {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    byOrderOf?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    payee?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    payer?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    paymentProcessor?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    pppId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionPaymentMeta
     */
    referenceNumber?: string;
}

/**
 * Check if a given object implements the JumboStructsPlaidTransactionPaymentMeta interface.
 */
export function instanceOfJumboStructsPlaidTransactionPaymentMeta(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsPlaidTransactionPaymentMetaFromJSON(json: any): JumboStructsPlaidTransactionPaymentMeta {
    return JumboStructsPlaidTransactionPaymentMetaFromJSONTyped(json, false);
}

export function JumboStructsPlaidTransactionPaymentMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsPlaidTransactionPaymentMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'byOrderOf': !exists(json, 'byOrderOf') ? undefined : json['byOrderOf'],
        'payee': !exists(json, 'payee') ? undefined : json['payee'],
        'payer': !exists(json, 'payer') ? undefined : json['payer'],
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : json['paymentMethod'],
        'paymentProcessor': !exists(json, 'paymentProcessor') ? undefined : json['paymentProcessor'],
        'pppId': !exists(json, 'pppId') ? undefined : json['pppId'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'referenceNumber': !exists(json, 'referenceNumber') ? undefined : json['referenceNumber'],
    };
}

export function JumboStructsPlaidTransactionPaymentMetaToJSON(value?: JumboStructsPlaidTransactionPaymentMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'byOrderOf': value.byOrderOf,
        'payee': value.payee,
        'payer': value.payer,
        'paymentMethod': value.paymentMethod,
        'paymentProcessor': value.paymentProcessor,
        'pppId': value.pppId,
        'reason': value.reason,
        'referenceNumber': value.referenceNumber,
    };
}

