/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp
     */
    PTIN?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp
     */
    PhoneNum?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp
     */
    PreparationDt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp
     */
    SelfEmployedInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp
     */
    PreparerPersonNm?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PTIN': !exists(json, 'PTIN') ? undefined : json['PTIN'],
        'PhoneNum': !exists(json, 'PhoneNum') ? undefined : json['PhoneNum'],
        'PreparationDt': !exists(json, 'PreparationDt') ? undefined : json['PreparationDt'],
        'SelfEmployedInd': !exists(json, 'SelfEmployedInd') ? undefined : json['SelfEmployedInd'],
        'PreparerPersonNm': !exists(json, 'PreparerPersonNm') ? undefined : json['PreparerPersonNm'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrpToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderPreparerPersonGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PTIN': value.PTIN,
        'PhoneNum': value.PhoneNum,
        'PreparationDt': value.PreparationDt,
        'SelfEmployedInd': value.SelfEmployedInd,
        'PreparerPersonNm': value.PreparerPersonNm,
    };
}

