/**
 *
 * TODO - this is a helper that can be shared in a package with Control
 *
 *  @param args.displayedLocale - The locale to display the label
 * @param args.currentLocale - The current locale of the user. This determines the language of the label.
 * @param args.options.includeCountry - Whether to include the country in the label eg English (US) vs English
 *
 * @returns string - The label for the locale
 */
export function getLanguageLabel(args: {
  displayedLocale: string;
  currentLocale: string;
  options?: {
    includeCountry?: boolean;
  };
}): string {
  const { displayedLocale, currentLocale, options } = args;
  const { includeCountry = false } = options ?? {};

  const [languageInCurrentLocale] = currentLocale.split('-') ?? [];
  const [languageInTargetLocale] = displayedLocale.split('-') ?? [];

  const currentLocaleLanguageFormatter = new Intl.DisplayNames(
    [currentLocale],
    {
      type: 'language',
      style: 'short',
      languageDisplay: 'standard',
    },
  );

  // excluding the country in the displayed name is as simple as removing the region descriptor.
  const languageInCurrentLocaleLabel = currentLocaleLanguageFormatter.of(
    includeCountry ? displayedLocale : languageInTargetLocale,
  );

  let label = `${languageInCurrentLocaleLabel}`;

  const needsToDisplayTranslation =
    languageInCurrentLocale !== languageInTargetLocale;

  if (!needsToDisplayTranslation) {
    return label;
  }

  const targetLocaleLanguageFormatter = new Intl.DisplayNames(
    [displayedLocale],
    {
      type: 'language',
      style: 'short',
      languageDisplay: 'standard',
    },
  );

  const languageInTargetLocaleLabel = targetLocaleLanguageFormatter.of(
    includeCountry ? displayedLocale : languageInTargetLocale,
  );

  label = `${label} | ${languageInTargetLocaleLabel}`;

  return label;
}
