/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp
     */
    PersonNm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp
     */
    PhoneNum?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp
     */
    SignatureDt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp
     */
    PersonTitleTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp
     */
    DiscussWithPaidPreparerInd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': !exists(json, 'PersonNm') ? undefined : json['PersonNm'],
        'PhoneNum': !exists(json, 'PhoneNum') ? undefined : json['PhoneNum'],
        'SignatureDt': !exists(json, 'SignatureDt') ? undefined : json['SignatureDt'],
        'PersonTitleTxt': !exists(json, 'PersonTitleTxt') ? undefined : json['PersonTitleTxt'],
        'DiscussWithPaidPreparerInd': !exists(json, 'DiscussWithPaidPreparerInd') ? undefined : json['DiscussWithPaidPreparerInd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrpToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsBaseFilingHeaderBusinessOfficerGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'PhoneNum': value.PhoneNum,
        'SignatureDt': value.SignatureDt,
        'PersonTitleTxt': value.PersonTitleTxt,
        'DiscussWithPaidPreparerInd': value.DiscussWithPaidPreparerInd,
    };
}

