/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear
 */
export interface ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear
     */
    current_tax_year_minus_4_years_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear
     */
    current_tax_year_minus_3_years_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear
     */
    current_tax_year_minus_2_years_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear
     */
    current_tax_year_minus_1_year_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear
     */
    current_tax_year_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear
     */
    total_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear {
    return ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_tax_year_minus_4_years_amt': !exists(json, 'current_tax_year_minus_4_years_amt') ? undefined : json['current_tax_year_minus_4_years_amt'],
        'current_tax_year_minus_3_years_amt': !exists(json, 'current_tax_year_minus_3_years_amt') ? undefined : json['current_tax_year_minus_3_years_amt'],
        'current_tax_year_minus_2_years_amt': !exists(json, 'current_tax_year_minus_2_years_amt') ? undefined : json['current_tax_year_minus_2_years_amt'],
        'current_tax_year_minus_1_year_amt': !exists(json, 'current_tax_year_minus_1_year_amt') ? undefined : json['current_tax_year_minus_1_year_amt'],
        'current_tax_year_amt': !exists(json, 'current_tax_year_amt') ? undefined : json['current_tax_year_amt'],
        'total_amt': !exists(json, 'total_amt') ? undefined : json['total_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON(value?: ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_tax_year_minus_4_years_amt': value.current_tax_year_minus_4_years_amt,
        'current_tax_year_minus_3_years_amt': value.current_tax_year_minus_3_years_amt,
        'current_tax_year_minus_2_years_amt': value.current_tax_year_minus_2_years_amt,
        'current_tax_year_minus_1_year_amt': value.current_tax_year_minus_1_year_amt,
        'current_tax_year_amt': value.current_tax_year_amt,
        'total_amt': value.total_amt,
    };
}

