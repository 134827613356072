/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const NotificationsContactType = {
    Retailbroker: 'retailbroker',
    Premium: 'premium',
    Broker: 'broker',
    Freemium: 'freemium',
    Policyholder: 'policyholder',
    Policyholderplus: 'policyholderplus',
    General: 'general',
    RsaAttachment: 'rsa attachment',
    ControlTrialInitial: 'control trial initial',
    ControlTrialReminder: 'control trial reminder',
    ControlTrialConfirmation: 'control trial confirmation',
    ControlTrialOverSoon: 'control trial over soon',
    ControlTrialUpgraded: 'control trial upgraded',
    ControlTrialDowngrade: 'control trial downgrade',
    ControlAssetRemoval: 'control asset removal',
    ControlRescanCompleted: 'control rescan completed',
    ControlResolutionUpdate: 'control resolution update',
    ControlMuteAccepted: 'control mute accepted',
    ControlTrialBrokerInvite: 'control trial broker invite',
    ClientChangeRequest: 'client change request',
    ClientChangeRequestRejected: 'client change request rejected',
    ClientChangeRequestApproved: 'client change request approved',
    ConfirmRegistration: 'confirm registration',
    PasswordReset: 'password reset',
    PasswordResetComplete: 'password reset complete',
    RegistrationComplete: 'registration complete',
    SignupAttempt: 'signup attempt',
    InvitationByPh: 'invitation by ph',
    InvitationByNonPh: 'invitation by non ph',
    InvitationExistingUser: 'invitation existing user',
    BrokerBouncedAlert: 'broker bounced alert',
    FtfSms: 'ftf sms',
    ControlMdrUserAssessment: 'control mdr user assessment',
    SentinelOneSingleThreat: 'sentinel one single threat',
    FtfSuspiciousTransaction: 'ftf suspicious transaction',
    FtfUserSuspiciousTransaction: 'ftf user suspicious transaction',
    SentinelOneGroupThreat: 'sentinel one group threat',
    ControlMdrMonthlyDigest: 'control mdr monthly digest',
    ZeroDayAlert: 'zero day alert',
    StripeTrialEnd: 'stripe trial end',
    RenewalReminder: 'renewal reminder',
    RenewalReminderTechnical: 'renewal reminder (technical)',
    ShareContingentFindings: 'share contingent findings',
    SatTrialStart: 'sat trial start',
    SatTrialTryPhishingSimulation: 'sat trial try phishing simulation',
    SatTrialExpiryReminder: 'sat trial expiry reminder',
    SatTrialExpired: 'sat trial expired'
} as const;
export type NotificationsContactType = typeof NotificationsContactType[keyof typeof NotificationsContactType];


export function NotificationsContactTypeFromJSON(json: any): NotificationsContactType {
    return NotificationsContactTypeFromJSONTyped(json, false);
}

export function NotificationsContactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsContactType {
    return json as NotificationsContactType;
}

export function NotificationsContactTypeToJSON(value?: NotificationsContactType | null): any {
    return value as any;
}

