/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody
 */
export interface JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody {
    /**
     * 
     * @type {string}
     * @memberof JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody
     */
    managementHost: string;
    /**
     * 
     * @type {string}
     * @memberof JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody
     */
    userVerdict: JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyUserVerdictEnum;
}


/**
 * @export
 */
export const JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyUserVerdictEnum = {
    TruePositive: 'truePositive',
    FalsePositive: 'falsePositive'
} as const;
export type JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyUserVerdictEnum = typeof JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyUserVerdictEnum[keyof typeof JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyUserVerdictEnum];


/**
 * Check if a given object implements the JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody interface.
 */
export function instanceOfJumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "managementHost" in value;
    isInstance = isInstance && "userVerdict" in value;

    return isInstance;
}

export function JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyFromJSON(json: any): JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody {
    return JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyFromJSONTyped(json, false);
}

export function JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managementHost': json['managementHost'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'userVerdict': json['userVerdict'],
    };
}

export function JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBodyToJSON(value?: JumboPostsentinelonethreatthreatidassessmentPostSentineloneThreatThreatIDAssessmentRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managementHost': value.managementHost,
        'note': value.note,
        'userVerdict': value.userVerdict,
    };
}

