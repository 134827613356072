/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsSentinelOneUserAssessment
 */
export interface JumboStructsSentinelOneUserAssessment {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsSentinelOneUserAssessment
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsSentinelOneUserAssessment
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsSentinelOneUserAssessment
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsSentinelOneUserAssessment
     */
    verdict?: JumboStructsSentinelOneUserAssessmentVerdictEnum;
}


/**
 * @export
 */
export const JumboStructsSentinelOneUserAssessmentVerdictEnum = {
    TruePositive: 'truePositive',
    FalsePositive: 'falsePositive'
} as const;
export type JumboStructsSentinelOneUserAssessmentVerdictEnum = typeof JumboStructsSentinelOneUserAssessmentVerdictEnum[keyof typeof JumboStructsSentinelOneUserAssessmentVerdictEnum];


/**
 * Check if a given object implements the JumboStructsSentinelOneUserAssessment interface.
 */
export function instanceOfJumboStructsSentinelOneUserAssessment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsSentinelOneUserAssessmentFromJSON(json: any): JumboStructsSentinelOneUserAssessment {
    return JumboStructsSentinelOneUserAssessmentFromJSONTyped(json, false);
}

export function JumboStructsSentinelOneUserAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsSentinelOneUserAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'verdict': !exists(json, 'verdict') ? undefined : json['verdict'],
    };
}

export function JumboStructsSentinelOneUserAssessmentToJSON(value?: JumboStructsSentinelOneUserAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'note': value.note,
        'updatedAt': value.updatedAt,
        'verdict': value.verdict,
    };
}

