/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsPlaidTransactionCounterparties
 */
export interface JumboStructsPlaidTransactionCounterparties {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionCounterparties
     */
    confidenceLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionCounterparties
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionCounterparties
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionCounterparties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionCounterparties
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionCounterparties
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionCounterparties
     */
    website?: string;
}

/**
 * Check if a given object implements the JumboStructsPlaidTransactionCounterparties interface.
 */
export function instanceOfJumboStructsPlaidTransactionCounterparties(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsPlaidTransactionCounterpartiesFromJSON(json: any): JumboStructsPlaidTransactionCounterparties {
    return JumboStructsPlaidTransactionCounterpartiesFromJSONTyped(json, false);
}

export function JumboStructsPlaidTransactionCounterpartiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsPlaidTransactionCounterparties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confidenceLevel': !exists(json, 'confidenceLevel') ? undefined : json['confidenceLevel'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'website': !exists(json, 'website') ? undefined : json['website'],
    };
}

export function JumboStructsPlaidTransactionCounterpartiesToJSON(value?: JumboStructsPlaidTransactionCounterparties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confidenceLevel': value.confidenceLevel,
        'entityId': value.entityId,
        'logoUrl': value.logoUrl,
        'name': value.name,
        'phoneNumber': value.phoneNumber,
        'type': value.type,
        'website': value.website,
    };
}

