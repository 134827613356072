/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsMDRAlertsOverTime } from './JumboStructsMDRAlertsOverTime';
import {
    JumboStructsMDRAlertsOverTimeFromJSON,
    JumboStructsMDRAlertsOverTimeFromJSONTyped,
    JumboStructsMDRAlertsOverTimeToJSON,
} from './JumboStructsMDRAlertsOverTime';
import type { JumboStructsMDRClassificationCountOverTime } from './JumboStructsMDRClassificationCountOverTime';
import {
    JumboStructsMDRClassificationCountOverTimeFromJSON,
    JumboStructsMDRClassificationCountOverTimeFromJSONTyped,
    JumboStructsMDRClassificationCountOverTimeToJSON,
} from './JumboStructsMDRClassificationCountOverTime';

/**
 * 
 * @export
 * @interface JumboStructsMDRAlertsResult
 */
export interface JumboStructsMDRAlertsResult {
    /**
     * 
     * @type {Array<JumboStructsMDRAlertsOverTime>}
     * @memberof JumboStructsMDRAlertsResult
     */
    alertsOverTime?: Array<JumboStructsMDRAlertsOverTime>;
    /**
     * 
     * @type {Array<JumboStructsMDRClassificationCountOverTime>}
     * @memberof JumboStructsMDRAlertsResult
     */
    classificationCountsOverTime?: Array<JumboStructsMDRClassificationCountOverTime>;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDRAlertsResult
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDRAlertsResult
     */
    totalResolved?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDRAlertsResult
     */
    truePositive?: number;
}

/**
 * Check if a given object implements the JumboStructsMDRAlertsResult interface.
 */
export function instanceOfJumboStructsMDRAlertsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsMDRAlertsResultFromJSON(json: any): JumboStructsMDRAlertsResult {
    return JumboStructsMDRAlertsResultFromJSONTyped(json, false);
}

export function JumboStructsMDRAlertsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsMDRAlertsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertsOverTime': !exists(json, 'alertsOverTime') ? undefined : ((json['alertsOverTime'] as Array<any>).map(JumboStructsMDRAlertsOverTimeFromJSON)),
        'classificationCountsOverTime': !exists(json, 'classificationCountsOverTime') ? undefined : ((json['classificationCountsOverTime'] as Array<any>).map(JumboStructsMDRClassificationCountOverTimeFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'totalResolved': !exists(json, 'totalResolved') ? undefined : json['totalResolved'],
        'truePositive': !exists(json, 'truePositive') ? undefined : json['truePositive'],
    };
}

export function JumboStructsMDRAlertsResultToJSON(value?: JumboStructsMDRAlertsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertsOverTime': value.alertsOverTime === undefined ? undefined : ((value.alertsOverTime as Array<any>).map(JumboStructsMDRAlertsOverTimeToJSON)),
        'classificationCountsOverTime': value.classificationCountsOverTime === undefined ? undefined : ((value.classificationCountsOverTime as Array<any>).map(JumboStructsMDRClassificationCountOverTimeToJSON)),
        'totalCount': value.totalCount,
        'totalResolved': value.totalResolved,
        'truePositive': value.truePositive,
    };
}

