/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation
     */
    SupportedOrganizationName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation
     */
    EIN?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation
     */
    OrganizationTypeCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation
     */
    GoverningDocumentListedInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation
     */
    SupportAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation
     */
    OtherSupportAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SupportedOrganizationName': !exists(json, 'SupportedOrganizationName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['SupportedOrganizationName']),
        'EIN': !exists(json, 'EIN') ? undefined : json['EIN'],
        'OrganizationTypeCd': !exists(json, 'OrganizationTypeCd') ? undefined : json['OrganizationTypeCd'],
        'GoverningDocumentListedInd': !exists(json, 'GoverningDocumentListedInd') ? undefined : json['GoverningDocumentListedInd'],
        'SupportAmt': !exists(json, 'SupportAmt') ? undefined : json['SupportAmt'],
        'OtherSupportAmt': !exists(json, 'OtherSupportAmt') ? undefined : json['OtherSupportAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SupportedOrganizationName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.SupportedOrganizationName),
        'EIN': value.EIN,
        'OrganizationTypeCd': value.OrganizationTypeCd,
        'GoverningDocumentListedInd': value.GoverningDocumentListedInd,
        'SupportAmt': value.SupportAmt,
        'OtherSupportAmt': value.OtherSupportAmt,
    };
}

