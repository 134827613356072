/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleA } from './ExecRiskApiAppCoreModelsScansForm990ScheduleA';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleA';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleR } from './ExecRiskApiAppCoreModelsScansForm990ScheduleR';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleR';
import type { ExecRiskIRS990 } from './ExecRiskIRS990';
import {
    ExecRiskIRS990FromJSON,
    ExecRiskIRS990FromJSONTyped,
    ExecRiskIRS990ToJSON,
} from './ExecRiskIRS990';
import type { ExecRiskIRS990EZ } from './ExecRiskIRS990EZ';
import {
    ExecRiskIRS990EZFromJSON,
    ExecRiskIRS990EZFromJSONTyped,
    ExecRiskIRS990EZToJSON,
} from './ExecRiskIRS990EZ';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ReturnData
 */
export interface ExecRiskApiAppCoreModelsScansForm990ReturnData {
    /**
     * 
     * @type {ExecRiskIRS990}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ReturnData
     */
    IRS990?: ExecRiskIRS990;
    /**
     * 
     * @type {ExecRiskIRS990EZ}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ReturnData
     */
    IRS990EZ?: ExecRiskIRS990EZ;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990ScheduleA}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ReturnData
     */
    IRS990ScheduleA?: ExecRiskApiAppCoreModelsScansForm990ScheduleA;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990ScheduleR}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ReturnData
     */
    IRS990ScheduleR?: ExecRiskApiAppCoreModelsScansForm990ScheduleR;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ReturnData interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ReturnData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ReturnDataFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ReturnData {
    return ExecRiskApiAppCoreModelsScansForm990ReturnDataFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ReturnDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ReturnData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IRS990': !exists(json, 'IRS990') ? undefined : ExecRiskIRS990FromJSON(json['IRS990']),
        'IRS990EZ': !exists(json, 'IRS990EZ') ? undefined : ExecRiskIRS990EZFromJSON(json['IRS990EZ']),
        'IRS990ScheduleA': !exists(json, 'IRS990ScheduleA') ? undefined : ExecRiskApiAppCoreModelsScansForm990ScheduleAFromJSON(json['IRS990ScheduleA']),
        'IRS990ScheduleR': !exists(json, 'IRS990ScheduleR') ? undefined : ExecRiskApiAppCoreModelsScansForm990ScheduleRFromJSON(json['IRS990ScheduleR']),
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ReturnDataToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ReturnData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IRS990': ExecRiskIRS990ToJSON(value.IRS990),
        'IRS990EZ': ExecRiskIRS990EZToJSON(value.IRS990EZ),
        'IRS990ScheduleA': ExecRiskApiAppCoreModelsScansForm990ScheduleAToJSON(value.IRS990ScheduleA),
        'IRS990ScheduleR': ExecRiskApiAppCoreModelsScansForm990ScheduleRToJSON(value.IRS990ScheduleR),
    };
}

