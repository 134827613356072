/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990 } from './ExecRiskApiAppCoreModelsScansForm990';
import {
    ExecRiskApiAppCoreModelsScansForm990FromJSON,
    ExecRiskApiAppCoreModelsScansForm990FromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990';

/**
 * 
 * @export
 * @interface ExecRiskPaginatedForm990Response
 */
export interface ExecRiskPaginatedForm990Response {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedForm990Response
     */
    total?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990>}
     * @memberof ExecRiskPaginatedForm990Response
     */
    results: Array<ExecRiskApiAppCoreModelsScansForm990>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskPaginatedForm990Response
     */
    schema_version?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedForm990Response
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskPaginatedForm990Response
     */
    page_size?: number;
}

/**
 * Check if a given object implements the ExecRiskPaginatedForm990Response interface.
 */
export function instanceOfExecRiskPaginatedForm990Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ExecRiskPaginatedForm990ResponseFromJSON(json: any): ExecRiskPaginatedForm990Response {
    return ExecRiskPaginatedForm990ResponseFromJSONTyped(json, false);
}

export function ExecRiskPaginatedForm990ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskPaginatedForm990Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': ((json['results'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990FromJSON)),
        'schema_version': !exists(json, 'schema_version') ? undefined : json['schema_version'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_size': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function ExecRiskPaginatedForm990ResponseToJSON(value?: ExecRiskPaginatedForm990Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ToJSON)),
        'schema_version': value.schema_version,
        'page': value.page,
        'page_size': value.page_size,
    };
}

