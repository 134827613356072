/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsFindingUser } from './JumboStructsFindingUser';
import {
    JumboStructsFindingUserFromJSON,
    JumboStructsFindingUserFromJSONTyped,
    JumboStructsFindingUserToJSON,
} from './JumboStructsFindingUser';

/**
 * 
 * @export
 * @interface JumboStructsUserListFinding
 */
export interface JumboStructsUserListFinding {
    /**
     * 
     * @type {Array<JumboStructsFindingUser>}
     * @memberof JumboStructsUserListFinding
     */
    items?: Array<JumboStructsFindingUser>;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsUserListFinding
     */
    total?: number;
}

/**
 * Check if a given object implements the JumboStructsUserListFinding interface.
 */
export function instanceOfJumboStructsUserListFinding(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsUserListFindingFromJSON(json: any): JumboStructsUserListFinding {
    return JumboStructsUserListFindingFromJSONTyped(json, false);
}

export function JumboStructsUserListFindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsUserListFinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JumboStructsFindingUserFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function JumboStructsUserListFindingToJSON(value?: JumboStructsUserListFinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JumboStructsFindingUserToJSON)),
        'total': value.total,
    };
}

