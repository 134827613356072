/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome {
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    net_st_capital_gain_adj_net_incm_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    recoveries_py_distributions_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    other_gross_income_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    adjusted_gross_income_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    depreciation_depletion_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    production_income_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    other_expenses_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    total_adjusted_net_income_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt>;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'net_st_capital_gain_adj_net_incm_grp': !exists(json, 'net_st_capital_gain_adj_net_incm_grp') ? undefined : ((json['net_st_capital_gain_adj_net_incm_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'recoveries_py_distributions_grp': !exists(json, 'recoveries_py_distributions_grp') ? undefined : ((json['recoveries_py_distributions_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'other_gross_income_grp': !exists(json, 'other_gross_income_grp') ? undefined : ((json['other_gross_income_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'adjusted_gross_income_grp': !exists(json, 'adjusted_gross_income_grp') ? undefined : ((json['adjusted_gross_income_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'depreciation_depletion_grp': !exists(json, 'depreciation_depletion_grp') ? undefined : ((json['depreciation_depletion_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'production_income_grp': !exists(json, 'production_income_grp') ? undefined : ((json['production_income_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'other_expenses_grp': !exists(json, 'other_expenses_grp') ? undefined : ((json['other_expenses_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
        'total_adjusted_net_income_grp': !exists(json, 'total_adjusted_net_income_grp') ? undefined : ((json['total_adjusted_net_income_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON)),
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'net_st_capital_gain_adj_net_incm_grp': value.net_st_capital_gain_adj_net_incm_grp === undefined ? undefined : ((value.net_st_capital_gain_adj_net_incm_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'recoveries_py_distributions_grp': value.recoveries_py_distributions_grp === undefined ? undefined : ((value.recoveries_py_distributions_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'other_gross_income_grp': value.other_gross_income_grp === undefined ? undefined : ((value.other_gross_income_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'adjusted_gross_income_grp': value.adjusted_gross_income_grp === undefined ? undefined : ((value.adjusted_gross_income_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'depreciation_depletion_grp': value.depreciation_depletion_grp === undefined ? undefined : ((value.depreciation_depletion_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'production_income_grp': value.production_income_grp === undefined ? undefined : ((value.production_income_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'other_expenses_grp': value.other_expenses_grp === undefined ? undefined : ((value.other_expenses_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
        'total_adjusted_net_income_grp': value.total_adjusted_net_income_grp === undefined ? undefined : ((value.total_adjusted_net_income_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON)),
    };
}

