/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
     */
    NameOfOrganization?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
     */
    OrganizationTypeDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule
     */
    DescriptionOfRelationship?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'NameOfOrganization': !exists(json, 'NameOfOrganization') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['NameOfOrganization']),
        'OrganizationTypeDesc': !exists(json, 'OrganizationTypeDesc') ? undefined : json['OrganizationTypeDesc'],
        'DescriptionOfRelationship': !exists(json, 'DescriptionOfRelationship') ? undefined : json['DescriptionOfRelationship'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'NameOfOrganization': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.NameOfOrganization),
        'OrganizationTypeDesc': value.OrganizationTypeDesc,
        'DescriptionOfRelationship': value.DescriptionOfRelationship,
    };
}

