/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const JumboStructsSentinelOneAgentOSType = {
    Linux: 'linux',
    Macos: 'macos',
    Windows: 'windows',
    WindowsLegacy: 'windows_legacy'
} as const;
export type JumboStructsSentinelOneAgentOSType = typeof JumboStructsSentinelOneAgentOSType[keyof typeof JumboStructsSentinelOneAgentOSType];


export function JumboStructsSentinelOneAgentOSTypeFromJSON(json: any): JumboStructsSentinelOneAgentOSType {
    return JumboStructsSentinelOneAgentOSTypeFromJSONTyped(json, false);
}

export function JumboStructsSentinelOneAgentOSTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsSentinelOneAgentOSType {
    return json as JumboStructsSentinelOneAgentOSType;
}

export function JumboStructsSentinelOneAgentOSTypeToJSON(value?: JumboStructsSentinelOneAgentOSType | null): any {
    return value as any;
}

