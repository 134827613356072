/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail
     */
    PersonNm?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail
     */
    BusinessName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail
     */
    PhoneNum?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail
     */
    USAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail
     */
    ForeignAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': !exists(json, 'PersonNm') ? undefined : json['PersonNm'],
        'BusinessName': !exists(json, 'BusinessName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
        'PhoneNum': !exists(json, 'PhoneNum') ? undefined : json['PhoneNum'],
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': !exists(json, 'ForeignAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'BusinessName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
        'PhoneNum': value.PhoneNum,
        'USAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
    };
}

