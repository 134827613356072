import {
  ControlAppSchemasUserClientResponse,
  ControlClientUpdateRequest,
  V1Api,
} from '@explorer/open-api-client';
import {
  useExplorerAPIMutationParamType,
  useV1APIMutation,
} from '@explorer/query';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

type MutateArgs = { clientId: string } & ControlClientUpdateRequest;

export const useMutationUpdateClient = (
  args?: Omit<
    useExplorerAPIMutationParamType<V1Api, ControlAppSchemasUserClientResponse>,
    'mutationFn'
  >,
) => {
  const client = useQueryClient();

  const { onSuccess: onSuccessCallback, ...otherArgs } = args ?? {};

  const onSuccess: typeof onSuccessCallback = useCallback(
    (data, mutateArgs: MutateArgs, context) => {
      const { clientId } = mutateArgs;

      client.invalidateQueries([
        'v1ControlWebAdminGetClientInfoClientIdGet',
        clientId,
      ]);

      onSuccessCallback?.(data, mutateArgs, context);
    },
    [client, onSuccessCallback],
  );

  return useV1APIMutation<ControlAppSchemasUserClientResponse, MutateArgs>({
    mutationFn(
      args: { clientId: string } & ControlClientUpdateRequest,
      client,
    ) {
      const { clientId, ...rest } = args;

      return client.v1ControlWebAdminClientUpdateClientIdPost({
        client_id: clientId,
        ControlClientUpdateRequest: rest,
      });
    },
    onSuccess: (...successArgs) => {
      onSuccess(...successArgs);
    },
    ...otherArgs,
  });
};
