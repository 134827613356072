/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsMDREndpoint } from './JumboStructsMDREndpoint';
import {
    JumboStructsMDREndpointFromJSON,
    JumboStructsMDREndpointFromJSONTyped,
    JumboStructsMDREndpointToJSON,
} from './JumboStructsMDREndpoint';

/**
 * 
 * @export
 * @interface JumboStructsMDREndpointsResult
 */
export interface JumboStructsMDREndpointsResult {
    /**
     * 
     * @type {Array<JumboStructsMDREndpoint>}
     * @memberof JumboStructsMDREndpointsResult
     */
    endpoints?: Array<JumboStructsMDREndpoint>;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDREndpointsResult
     */
    totalCount?: number;
}

/**
 * Check if a given object implements the JumboStructsMDREndpointsResult interface.
 */
export function instanceOfJumboStructsMDREndpointsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsMDREndpointsResultFromJSON(json: any): JumboStructsMDREndpointsResult {
    return JumboStructsMDREndpointsResultFromJSONTyped(json, false);
}

export function JumboStructsMDREndpointsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsMDREndpointsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoints': !exists(json, 'endpoints') ? undefined : ((json['endpoints'] as Array<any>).map(JumboStructsMDREndpointFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function JumboStructsMDREndpointsResultToJSON(value?: JumboStructsMDREndpointsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoints': value.endpoints === undefined ? undefined : ((value.endpoints as Array<any>).map(JumboStructsMDREndpointToJSON)),
        'totalCount': value.totalCount,
    };
}

