/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsEDRAccountResult } from './JumboStructsEDRAccountResult';
import {
    JumboStructsEDRAccountResultFromJSON,
    JumboStructsEDRAccountResultFromJSONTyped,
    JumboStructsEDRAccountResultToJSON,
} from './JumboStructsEDRAccountResult';

/**
 * 
 * @export
 * @interface JumboStructsGetEDRAccountResult
 */
export interface JumboStructsGetEDRAccountResult {
    /**
     * 
     * @type {Array<JumboStructsEDRAccountResult>}
     * @memberof JumboStructsGetEDRAccountResult
     */
    accounts?: Array<JumboStructsEDRAccountResult>;
}

/**
 * Check if a given object implements the JumboStructsGetEDRAccountResult interface.
 */
export function instanceOfJumboStructsGetEDRAccountResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsGetEDRAccountResultFromJSON(json: any): JumboStructsGetEDRAccountResult {
    return JumboStructsGetEDRAccountResultFromJSONTyped(json, false);
}

export function JumboStructsGetEDRAccountResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsGetEDRAccountResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accounts': !exists(json, 'accounts') ? undefined : ((json['accounts'] as Array<any>).map(JumboStructsEDRAccountResultFromJSON)),
    };
}

export function JumboStructsGetEDRAccountResultToJSON(value?: JumboStructsGetEDRAccountResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accounts': value.accounts === undefined ? undefined : ((value.accounts as Array<any>).map(JumboStructsEDRAccountResultToJSON)),
    };
}

