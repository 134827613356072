/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVI } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVI';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVIFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVIFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVIToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVI';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    ChurchInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SchoolInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    HospitalInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    MedicalResearchOrganizationInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    HospitalNameAndAddressGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddress>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    CollegeOrganizationInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GovernmentalUnitInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PublicOrganization170Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    CommunityTrustInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    AgriculturalResearchOrgInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    AgriculturalNameAndAddressGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PubliclySupportedOrg509a2Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TestPublicSafetyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportingOrganization509a3Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportingOrgType1Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportingOrgType2Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportingOrgType3FuncIntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportingOrgType3NonFuncInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    IRSWrittenDeterminationInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportedOrganizationsCnt?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportedOrgInformationGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformation>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SupportSumAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    OtherSupportSumAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GiftsGrantsContriRcvd170Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TaxRevLeviedOrgnztnlBnft170Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GovtFurnSrvcFcltsVl170Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TotalCalendarYear170Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SubstantialContributorsTotAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PublicSupportTotal170Amt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GrossInvestmentIncome170Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    UnrelatedBusinessNetIncm170Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    OtherIncome170Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TotalSupportAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GrossReceiptsRltdActivitiesAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    First5Years170Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PublicSupportCY170Pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PublicSupportPY170Pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    ThirtyThrPctSuprtTestsCY170Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    ThirtyThrPctSuprtTestsPY170Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TenPctFactsCrcmstncsTestCYInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TenPctFactsCrcmstncsTestPYInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PrivateFoundation170Ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GiftsGrantsContrisRcvd509Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GrossReceiptsAdmissionsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GrossReceiptsNonUnrltBusGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TaxRevLeviedOrgnztnlBnft509Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GovtFurnSrvcFcltsVl509Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    Total509Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    AmountsRcvdDsqlfyPersonGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SubstantialContributorsAmtGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    SubstAndDsqlfyPrsnsTotGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PublicSupportTotal509Amt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    GrossInvestmentIncome509Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    Post1975UBTIGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    InvestmentIncomeAndUBTIGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    NetIncomeFromOtherUBIGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    OtherIncome509Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TotalSupportCalendarYearGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    First5Years509Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PublicSupportCY509Pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PublicSupportPY509Pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    InvestmentIncomeCYPct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    InvestmentIncomePYPct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    ThirtyThrPctSuprtTestsCY509Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    ThirtyThrPctSuprtTestsPY509Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    PrivateFoundation509Ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    Form990SchASupportingOrgGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrg>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    Form990SchAType1SuprtOrgGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrg>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    MajorityDirTrstSupportedOrgInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    Form990SchAType3SprtOrgAllGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAll>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    Form990SchAType3FuncIntGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TrustIntegralPartTestInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    AdjustedNetIncomeGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    MinimumAssetAmountGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmount>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    DistributableAmountGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    DistributionsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributions>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    DistributionAllocationsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocations>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    FactsAndCircumstancesTestTxt?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVI>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    Form990ScheduleAPartVIGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVI>;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    TransferSchedule?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferSchedule;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA
     */
    RelationshipSchedule?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipSchedule;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ChurchInd': !exists(json, 'ChurchInd') ? undefined : json['ChurchInd'],
        'SchoolInd': !exists(json, 'SchoolInd') ? undefined : json['SchoolInd'],
        'HospitalInd': !exists(json, 'HospitalInd') ? undefined : json['HospitalInd'],
        'MedicalResearchOrganizationInd': !exists(json, 'MedicalResearchOrganizationInd') ? undefined : json['MedicalResearchOrganizationInd'],
        'HospitalNameAndAddressGrp': !exists(json, 'HospitalNameAndAddressGrp') ? undefined : ((json['HospitalNameAndAddressGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressFromJSON)),
        'CollegeOrganizationInd': !exists(json, 'CollegeOrganizationInd') ? undefined : json['CollegeOrganizationInd'],
        'GovernmentalUnitInd': !exists(json, 'GovernmentalUnitInd') ? undefined : json['GovernmentalUnitInd'],
        'PublicOrganization170Ind': !exists(json, 'PublicOrganization170Ind') ? undefined : json['PublicOrganization170Ind'],
        'CommunityTrustInd': !exists(json, 'CommunityTrustInd') ? undefined : json['CommunityTrustInd'],
        'AgriculturalResearchOrgInd': !exists(json, 'AgriculturalResearchOrgInd') ? undefined : json['AgriculturalResearchOrgInd'],
        'AgriculturalNameAndAddressGrp': !exists(json, 'AgriculturalNameAndAddressGrp') ? undefined : ((json['AgriculturalNameAndAddressGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressFromJSON)),
        'PubliclySupportedOrg509a2Ind': !exists(json, 'PubliclySupportedOrg509a2Ind') ? undefined : json['PubliclySupportedOrg509a2Ind'],
        'TestPublicSafetyInd': !exists(json, 'TestPublicSafetyInd') ? undefined : json['TestPublicSafetyInd'],
        'SupportingOrganization509a3Ind': !exists(json, 'SupportingOrganization509a3Ind') ? undefined : json['SupportingOrganization509a3Ind'],
        'SupportingOrgType1Ind': !exists(json, 'SupportingOrgType1Ind') ? undefined : json['SupportingOrgType1Ind'],
        'SupportingOrgType2Ind': !exists(json, 'SupportingOrgType2Ind') ? undefined : json['SupportingOrgType2Ind'],
        'SupportingOrgType3FuncIntInd': !exists(json, 'SupportingOrgType3FuncIntInd') ? undefined : json['SupportingOrgType3FuncIntInd'],
        'SupportingOrgType3NonFuncInd': !exists(json, 'SupportingOrgType3NonFuncInd') ? undefined : json['SupportingOrgType3NonFuncInd'],
        'IRSWrittenDeterminationInd': !exists(json, 'IRSWrittenDeterminationInd') ? undefined : json['IRSWrittenDeterminationInd'],
        'SupportedOrganizationsCnt': !exists(json, 'SupportedOrganizationsCnt') ? undefined : json['SupportedOrganizationsCnt'],
        'SupportedOrgInformationGrp': !exists(json, 'SupportedOrgInformationGrp') ? undefined : ((json['SupportedOrgInformationGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationFromJSON)),
        'SupportSumAmt': !exists(json, 'SupportSumAmt') ? undefined : json['SupportSumAmt'],
        'OtherSupportSumAmt': !exists(json, 'OtherSupportSumAmt') ? undefined : json['OtherSupportSumAmt'],
        'GiftsGrantsContriRcvd170Grp': !exists(json, 'GiftsGrantsContriRcvd170Grp') ? undefined : ((json['GiftsGrantsContriRcvd170Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'TaxRevLeviedOrgnztnlBnft170Grp': !exists(json, 'TaxRevLeviedOrgnztnlBnft170Grp') ? undefined : ((json['TaxRevLeviedOrgnztnlBnft170Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'GovtFurnSrvcFcltsVl170Grp': !exists(json, 'GovtFurnSrvcFcltsVl170Grp') ? undefined : ((json['GovtFurnSrvcFcltsVl170Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'TotalCalendarYear170Grp': !exists(json, 'TotalCalendarYear170Grp') ? undefined : ((json['TotalCalendarYear170Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'SubstantialContributorsTotAmt': !exists(json, 'SubstantialContributorsTotAmt') ? undefined : json['SubstantialContributorsTotAmt'],
        'PublicSupportTotal170Amt': !exists(json, 'PublicSupportTotal170Amt') ? undefined : json['PublicSupportTotal170Amt'],
        'GrossInvestmentIncome170Grp': !exists(json, 'GrossInvestmentIncome170Grp') ? undefined : ((json['GrossInvestmentIncome170Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'UnrelatedBusinessNetIncm170Grp': !exists(json, 'UnrelatedBusinessNetIncm170Grp') ? undefined : ((json['UnrelatedBusinessNetIncm170Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'OtherIncome170Grp': !exists(json, 'OtherIncome170Grp') ? undefined : ((json['OtherIncome170Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'TotalSupportAmt': !exists(json, 'TotalSupportAmt') ? undefined : json['TotalSupportAmt'],
        'GrossReceiptsRltdActivitiesAmt': !exists(json, 'GrossReceiptsRltdActivitiesAmt') ? undefined : json['GrossReceiptsRltdActivitiesAmt'],
        'First5Years170Ind': !exists(json, 'First5Years170Ind') ? undefined : json['First5Years170Ind'],
        'PublicSupportCY170Pct': !exists(json, 'PublicSupportCY170Pct') ? undefined : json['PublicSupportCY170Pct'],
        'PublicSupportPY170Pct': !exists(json, 'PublicSupportPY170Pct') ? undefined : json['PublicSupportPY170Pct'],
        'ThirtyThrPctSuprtTestsCY170Ind': !exists(json, 'ThirtyThrPctSuprtTestsCY170Ind') ? undefined : json['ThirtyThrPctSuprtTestsCY170Ind'],
        'ThirtyThrPctSuprtTestsPY170Ind': !exists(json, 'ThirtyThrPctSuprtTestsPY170Ind') ? undefined : json['ThirtyThrPctSuprtTestsPY170Ind'],
        'TenPctFactsCrcmstncsTestCYInd': !exists(json, 'TenPctFactsCrcmstncsTestCYInd') ? undefined : json['TenPctFactsCrcmstncsTestCYInd'],
        'TenPctFactsCrcmstncsTestPYInd': !exists(json, 'TenPctFactsCrcmstncsTestPYInd') ? undefined : json['TenPctFactsCrcmstncsTestPYInd'],
        'PrivateFoundation170Ind': !exists(json, 'PrivateFoundation170Ind') ? undefined : json['PrivateFoundation170Ind'],
        'GiftsGrantsContrisRcvd509Grp': !exists(json, 'GiftsGrantsContrisRcvd509Grp') ? undefined : ((json['GiftsGrantsContrisRcvd509Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'GrossReceiptsAdmissionsGrp': !exists(json, 'GrossReceiptsAdmissionsGrp') ? undefined : ((json['GrossReceiptsAdmissionsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'GrossReceiptsNonUnrltBusGrp': !exists(json, 'GrossReceiptsNonUnrltBusGrp') ? undefined : ((json['GrossReceiptsNonUnrltBusGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'TaxRevLeviedOrgnztnlBnft509Grp': !exists(json, 'TaxRevLeviedOrgnztnlBnft509Grp') ? undefined : ((json['TaxRevLeviedOrgnztnlBnft509Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'GovtFurnSrvcFcltsVl509Grp': !exists(json, 'GovtFurnSrvcFcltsVl509Grp') ? undefined : ((json['GovtFurnSrvcFcltsVl509Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'Total509Grp': !exists(json, 'Total509Grp') ? undefined : ((json['Total509Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'AmountsRcvdDsqlfyPersonGrp': !exists(json, 'AmountsRcvdDsqlfyPersonGrp') ? undefined : ((json['AmountsRcvdDsqlfyPersonGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'SubstantialContributorsAmtGrp': !exists(json, 'SubstantialContributorsAmtGrp') ? undefined : ((json['SubstantialContributorsAmtGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'SubstAndDsqlfyPrsnsTotGrp': !exists(json, 'SubstAndDsqlfyPrsnsTotGrp') ? undefined : ((json['SubstAndDsqlfyPrsnsTotGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'PublicSupportTotal509Amt': !exists(json, 'PublicSupportTotal509Amt') ? undefined : json['PublicSupportTotal509Amt'],
        'GrossInvestmentIncome509Grp': !exists(json, 'GrossInvestmentIncome509Grp') ? undefined : ((json['GrossInvestmentIncome509Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'Post1975UBTIGrp': !exists(json, 'Post1975UBTIGrp') ? undefined : ((json['Post1975UBTIGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'InvestmentIncomeAndUBTIGrp': !exists(json, 'InvestmentIncomeAndUBTIGrp') ? undefined : ((json['InvestmentIncomeAndUBTIGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'NetIncomeFromOtherUBIGrp': !exists(json, 'NetIncomeFromOtherUBIGrp') ? undefined : ((json['NetIncomeFromOtherUBIGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'OtherIncome509Grp': !exists(json, 'OtherIncome509Grp') ? undefined : ((json['OtherIncome509Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'TotalSupportCalendarYearGrp': !exists(json, 'TotalSupportCalendarYearGrp') ? undefined : ((json['TotalSupportCalendarYearGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearFromJSON)),
        'First5Years509Ind': !exists(json, 'First5Years509Ind') ? undefined : json['First5Years509Ind'],
        'PublicSupportCY509Pct': !exists(json, 'PublicSupportCY509Pct') ? undefined : json['PublicSupportCY509Pct'],
        'PublicSupportPY509Pct': !exists(json, 'PublicSupportPY509Pct') ? undefined : json['PublicSupportPY509Pct'],
        'InvestmentIncomeCYPct': !exists(json, 'InvestmentIncomeCYPct') ? undefined : json['InvestmentIncomeCYPct'],
        'InvestmentIncomePYPct': !exists(json, 'InvestmentIncomePYPct') ? undefined : json['InvestmentIncomePYPct'],
        'ThirtyThrPctSuprtTestsCY509Ind': !exists(json, 'ThirtyThrPctSuprtTestsCY509Ind') ? undefined : json['ThirtyThrPctSuprtTestsCY509Ind'],
        'ThirtyThrPctSuprtTestsPY509Ind': !exists(json, 'ThirtyThrPctSuprtTestsPY509Ind') ? undefined : json['ThirtyThrPctSuprtTestsPY509Ind'],
        'PrivateFoundation509Ind': !exists(json, 'PrivateFoundation509Ind') ? undefined : json['PrivateFoundation509Ind'],
        'Form990SchASupportingOrgGrp': !exists(json, 'Form990SchASupportingOrgGrp') ? undefined : ((json['Form990SchASupportingOrgGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgFromJSON)),
        'Form990SchAType1SuprtOrgGrp': !exists(json, 'Form990SchAType1SuprtOrgGrp') ? undefined : ((json['Form990SchAType1SuprtOrgGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgFromJSON)),
        'MajorityDirTrstSupportedOrgInd': !exists(json, 'MajorityDirTrstSupportedOrgInd') ? undefined : json['MajorityDirTrstSupportedOrgInd'],
        'Form990SchAType3SprtOrgAllGrp': !exists(json, 'Form990SchAType3SprtOrgAllGrp') ? undefined : ((json['Form990SchAType3SprtOrgAllGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllFromJSON)),
        'Form990SchAType3FuncIntGrp': !exists(json, 'Form990SchAType3FuncIntGrp') ? undefined : ((json['Form990SchAType3FuncIntGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntFromJSON)),
        'TrustIntegralPartTestInd': !exists(json, 'TrustIntegralPartTestInd') ? undefined : json['TrustIntegralPartTestInd'],
        'AdjustedNetIncomeGrp': !exists(json, 'AdjustedNetIncomeGrp') ? undefined : ((json['AdjustedNetIncomeGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSON)),
        'MinimumAssetAmountGrp': !exists(json, 'MinimumAssetAmountGrp') ? undefined : ((json['MinimumAssetAmountGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountFromJSON)),
        'DistributableAmountGrp': !exists(json, 'DistributableAmountGrp') ? undefined : ((json['DistributableAmountGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountFromJSON)),
        'DistributionsGrp': !exists(json, 'DistributionsGrp') ? undefined : ((json['DistributionsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsFromJSON)),
        'DistributionAllocationsGrp': !exists(json, 'DistributionAllocationsGrp') ? undefined : ((json['DistributionAllocationsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsFromJSON)),
        'FactsAndCircumstancesTestTxt': !exists(json, 'FactsAndCircumstancesTestTxt') ? undefined : json['FactsAndCircumstancesTestTxt'],
        'Form990ScheduleAPartVIGrp': !exists(json, 'Form990ScheduleAPartVIGrp') ? undefined : ((json['Form990ScheduleAPartVIGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVIFromJSON)),
        'TransferSchedule': !exists(json, 'TransferSchedule') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleFromJSON(json['TransferSchedule']),
        'RelationshipSchedule': !exists(json, 'RelationshipSchedule') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleFromJSON(json['RelationshipSchedule']),
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ChurchInd': value.ChurchInd,
        'SchoolInd': value.SchoolInd,
        'HospitalInd': value.HospitalInd,
        'MedicalResearchOrganizationInd': value.MedicalResearchOrganizationInd,
        'HospitalNameAndAddressGrp': value.HospitalNameAndAddressGrp === undefined ? undefined : ((value.HospitalNameAndAddressGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAHospitalNameAndAddressToJSON)),
        'CollegeOrganizationInd': value.CollegeOrganizationInd,
        'GovernmentalUnitInd': value.GovernmentalUnitInd,
        'PublicOrganization170Ind': value.PublicOrganization170Ind,
        'CommunityTrustInd': value.CommunityTrustInd,
        'AgriculturalResearchOrgInd': value.AgriculturalResearchOrgInd,
        'AgriculturalNameAndAddressGrp': value.AgriculturalNameAndAddressGrp === undefined ? undefined : ((value.AgriculturalNameAndAddressGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressToJSON)),
        'PubliclySupportedOrg509a2Ind': value.PubliclySupportedOrg509a2Ind,
        'TestPublicSafetyInd': value.TestPublicSafetyInd,
        'SupportingOrganization509a3Ind': value.SupportingOrganization509a3Ind,
        'SupportingOrgType1Ind': value.SupportingOrgType1Ind,
        'SupportingOrgType2Ind': value.SupportingOrgType2Ind,
        'SupportingOrgType3FuncIntInd': value.SupportingOrgType3FuncIntInd,
        'SupportingOrgType3NonFuncInd': value.SupportingOrgType3NonFuncInd,
        'IRSWrittenDeterminationInd': value.IRSWrittenDeterminationInd,
        'SupportedOrganizationsCnt': value.SupportedOrganizationsCnt,
        'SupportedOrgInformationGrp': value.SupportedOrgInformationGrp === undefined ? undefined : ((value.SupportedOrgInformationGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleASupportedOrgInformationToJSON)),
        'SupportSumAmt': value.SupportSumAmt,
        'OtherSupportSumAmt': value.OtherSupportSumAmt,
        'GiftsGrantsContriRcvd170Grp': value.GiftsGrantsContriRcvd170Grp === undefined ? undefined : ((value.GiftsGrantsContriRcvd170Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'TaxRevLeviedOrgnztnlBnft170Grp': value.TaxRevLeviedOrgnztnlBnft170Grp === undefined ? undefined : ((value.TaxRevLeviedOrgnztnlBnft170Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'GovtFurnSrvcFcltsVl170Grp': value.GovtFurnSrvcFcltsVl170Grp === undefined ? undefined : ((value.GovtFurnSrvcFcltsVl170Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'TotalCalendarYear170Grp': value.TotalCalendarYear170Grp === undefined ? undefined : ((value.TotalCalendarYear170Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'SubstantialContributorsTotAmt': value.SubstantialContributorsTotAmt,
        'PublicSupportTotal170Amt': value.PublicSupportTotal170Amt,
        'GrossInvestmentIncome170Grp': value.GrossInvestmentIncome170Grp === undefined ? undefined : ((value.GrossInvestmentIncome170Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'UnrelatedBusinessNetIncm170Grp': value.UnrelatedBusinessNetIncm170Grp === undefined ? undefined : ((value.UnrelatedBusinessNetIncm170Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'OtherIncome170Grp': value.OtherIncome170Grp === undefined ? undefined : ((value.OtherIncome170Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'TotalSupportAmt': value.TotalSupportAmt,
        'GrossReceiptsRltdActivitiesAmt': value.GrossReceiptsRltdActivitiesAmt,
        'First5Years170Ind': value.First5Years170Ind,
        'PublicSupportCY170Pct': value.PublicSupportCY170Pct,
        'PublicSupportPY170Pct': value.PublicSupportPY170Pct,
        'ThirtyThrPctSuprtTestsCY170Ind': value.ThirtyThrPctSuprtTestsCY170Ind,
        'ThirtyThrPctSuprtTestsPY170Ind': value.ThirtyThrPctSuprtTestsPY170Ind,
        'TenPctFactsCrcmstncsTestCYInd': value.TenPctFactsCrcmstncsTestCYInd,
        'TenPctFactsCrcmstncsTestPYInd': value.TenPctFactsCrcmstncsTestPYInd,
        'PrivateFoundation170Ind': value.PrivateFoundation170Ind,
        'GiftsGrantsContrisRcvd509Grp': value.GiftsGrantsContrisRcvd509Grp === undefined ? undefined : ((value.GiftsGrantsContrisRcvd509Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'GrossReceiptsAdmissionsGrp': value.GrossReceiptsAdmissionsGrp === undefined ? undefined : ((value.GrossReceiptsAdmissionsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'GrossReceiptsNonUnrltBusGrp': value.GrossReceiptsNonUnrltBusGrp === undefined ? undefined : ((value.GrossReceiptsNonUnrltBusGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'TaxRevLeviedOrgnztnlBnft509Grp': value.TaxRevLeviedOrgnztnlBnft509Grp === undefined ? undefined : ((value.TaxRevLeviedOrgnztnlBnft509Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'GovtFurnSrvcFcltsVl509Grp': value.GovtFurnSrvcFcltsVl509Grp === undefined ? undefined : ((value.GovtFurnSrvcFcltsVl509Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'Total509Grp': value.Total509Grp === undefined ? undefined : ((value.Total509Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'AmountsRcvdDsqlfyPersonGrp': value.AmountsRcvdDsqlfyPersonGrp === undefined ? undefined : ((value.AmountsRcvdDsqlfyPersonGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'SubstantialContributorsAmtGrp': value.SubstantialContributorsAmtGrp === undefined ? undefined : ((value.SubstantialContributorsAmtGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'SubstAndDsqlfyPrsnsTotGrp': value.SubstAndDsqlfyPrsnsTotGrp === undefined ? undefined : ((value.SubstAndDsqlfyPrsnsTotGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'PublicSupportTotal509Amt': value.PublicSupportTotal509Amt,
        'GrossInvestmentIncome509Grp': value.GrossInvestmentIncome509Grp === undefined ? undefined : ((value.GrossInvestmentIncome509Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'Post1975UBTIGrp': value.Post1975UBTIGrp === undefined ? undefined : ((value.Post1975UBTIGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'InvestmentIncomeAndUBTIGrp': value.InvestmentIncomeAndUBTIGrp === undefined ? undefined : ((value.InvestmentIncomeAndUBTIGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'NetIncomeFromOtherUBIGrp': value.NetIncomeFromOtherUBIGrp === undefined ? undefined : ((value.NetIncomeFromOtherUBIGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'OtherIncome509Grp': value.OtherIncome509Grp === undefined ? undefined : ((value.OtherIncome509Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'TotalSupportCalendarYearGrp': value.TotalSupportCalendarYearGrp === undefined ? undefined : ((value.TotalSupportCalendarYearGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesCurrentTaxYearToJSON)),
        'First5Years509Ind': value.First5Years509Ind,
        'PublicSupportCY509Pct': value.PublicSupportCY509Pct,
        'PublicSupportPY509Pct': value.PublicSupportPY509Pct,
        'InvestmentIncomeCYPct': value.InvestmentIncomeCYPct,
        'InvestmentIncomePYPct': value.InvestmentIncomePYPct,
        'ThirtyThrPctSuprtTestsCY509Ind': value.ThirtyThrPctSuprtTestsCY509Ind,
        'ThirtyThrPctSuprtTestsPY509Ind': value.ThirtyThrPctSuprtTestsPY509Ind,
        'PrivateFoundation509Ind': value.PrivateFoundation509Ind,
        'Form990SchASupportingOrgGrp': value.Form990SchASupportingOrgGrp === undefined ? undefined : ((value.Form990SchASupportingOrgGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchASupportingOrgToJSON)),
        'Form990SchAType1SuprtOrgGrp': value.Form990SchAType1SuprtOrgGrp === undefined ? undefined : ((value.Form990SchAType1SuprtOrgGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType1SuprtOrgToJSON)),
        'MajorityDirTrstSupportedOrgInd': value.MajorityDirTrstSupportedOrgInd,
        'Form990SchAType3SprtOrgAllGrp': value.Form990SchAType3SprtOrgAllGrp === undefined ? undefined : ((value.Form990SchAType3SprtOrgAllGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3SprtOrgAllToJSON)),
        'Form990SchAType3FuncIntGrp': value.Form990SchAType3FuncIntGrp === undefined ? undefined : ((value.Form990SchAType3FuncIntGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntToJSON)),
        'TrustIntegralPartTestInd': value.TrustIntegralPartTestInd,
        'AdjustedNetIncomeGrp': value.AdjustedNetIncomeGrp === undefined ? undefined : ((value.AdjustedNetIncomeGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeToJSON)),
        'MinimumAssetAmountGrp': value.MinimumAssetAmountGrp === undefined ? undefined : ((value.MinimumAssetAmountGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountToJSON)),
        'DistributableAmountGrp': value.DistributableAmountGrp === undefined ? undefined : ((value.DistributableAmountGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountToJSON)),
        'DistributionsGrp': value.DistributionsGrp === undefined ? undefined : ((value.DistributionsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionsToJSON)),
        'DistributionAllocationsGrp': value.DistributionAllocationsGrp === undefined ? undefined : ((value.DistributionAllocationsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributionAllocationsToJSON)),
        'FactsAndCircumstancesTestTxt': value.FactsAndCircumstancesTestTxt,
        'Form990ScheduleAPartVIGrp': value.Form990ScheduleAPartVIGrp === undefined ? undefined : ((value.Form990ScheduleAPartVIGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990ScheduleAPartVIToJSON)),
        'TransferSchedule': ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleATransferScheduleToJSON(value.TransferSchedule),
        'RelationshipSchedule': ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleARelationshipScheduleToJSON(value.RelationshipSchedule),
    };
}

