/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsPlaidTransactionHistory
 */
export interface JumboStructsPlaidTransactionHistory {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionHistory
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionHistory
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidTransactionHistory
     */
    userEmail?: string;
}

/**
 * Check if a given object implements the JumboStructsPlaidTransactionHistory interface.
 */
export function instanceOfJumboStructsPlaidTransactionHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsPlaidTransactionHistoryFromJSON(json: any): JumboStructsPlaidTransactionHistory {
    return JumboStructsPlaidTransactionHistoryFromJSONTyped(json, false);
}

export function JumboStructsPlaidTransactionHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsPlaidTransactionHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'userEmail': !exists(json, 'userEmail') ? undefined : json['userEmail'],
    };
}

export function JumboStructsPlaidTransactionHistoryToJSON(value?: JumboStructsPlaidTransactionHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'date': value.date,
        'userEmail': value.userEmail,
    };
}

