import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { endpoints, useQuery } from '@explorer/api';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@explorer/core';
import { Form, FormFieldControl } from '@explorer/forms';
import { useQuerySnackbar } from '@explorer/hooks';
import {
  FormValues,
  initializeFormSchema,
  validationSchema,
} from './formSchema';
import {
  ControlAdminSearchResponseEntity,
  ControlTrial,
} from '@explorer/open-api-client/models';

const formId = 'ManageTrial';
/**
 * ManageTrialDialog
 */
export const ManageTrialDialog = ({
  open,
  onClose,
  item,
  refresh,
}: ManageTrialDialogProps) => {
  const { snackSuccess, snackError } = useQuerySnackbar();
  const intl = useIntl();
  const { palette } = useTheme();

  const queryManageTrial = useQuery({
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1controladmin['/update-client-trial/[p1]']({
      p1: item?.external_id,
    }),
    method: 'PATCH',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  });

  const formik = useFormik<FormValues>({
    validationSchema,
    initialValues: {
      version: 'trial',
      start_date: item.trial?.start_date ?? new Date(),
      end_date: item.trial?.end_date ?? new Date(),
    } as FormValues,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      queryManageTrial
        .submit({
          payload: {
            start_date: values.start_date,
            end_date: values.end_date,
            enabled: item.trial?.enabled ?? false,
          },
        })
        .then(({ error }) => {
          if (error) {
            snackError('Manage Trial: Error', error);
            setSubmitting(false);
          } else {
            snackSuccess('Trial updated successfully');
            resetForm();
            onClose();
            refresh();
          }
        });
    },
  });

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId,
        formik,
        options: {},
      }),
    [formik],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      stopPropagation
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography
              variant="subtitleCapsL"
              color="textSecondary"
              textColor={palette.grey[400]}
            >
              {intl.formatMessage({
                id: 'admin.search-companies.manage-trial',
              })}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box textAlign="right">
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <Form formId={formId} formik={formik} my={2}>
        <DialogContent>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={6}>
              <FormFieldControl {...formSchema.start_date} />
            </Grid>
            <Grid item xs={6}>
              <FormFieldControl {...formSchema.end_date} />
            </Grid>
            <Grid item xs={6}>
              <FormFieldControl {...formSchema.version} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions px={3}>
          <Button
            mx={1}
            variant="text"
            onClick={onClose}
            style={{ color: palette.action.disabled }}
            label={{ id: 'global.cancel' }}
          />
          <Button
            ml={1}
            color="primary"
            label={{ id: 'global.update' }}
            disabled={!formik.isValid}
            onClick={formik.submitForm}
            loading={formik.isSubmitting}
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export interface ManageTrialDialogProps {
  item:
    | ControlAdminSearchResponseEntity
    | { trial: ControlTrial; external_id: string };
  open: boolean;
  onClose: () => void;
  refresh: () => void;
}
