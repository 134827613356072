import { Divider, Grid } from '@explorer/core';
import { Form, FormFieldControl, SubmitButton } from '@explorer/forms';
import { ClientRoles } from '@explorer/forms/types';
import { useQuerySnackbar } from '@explorer/hooks';
import { FormikHelpers, useFormik } from 'formik';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DisplayModules } from './Modules/Modules';
import {
  FormValues,
  getInitialValues,
  getModules,
  initializeFormSchema,
  validationSchema,
} from './formSchema';
import { useMutationUpdateClient } from './queries/useMutationUpdateClient';
import { DeletePrepurchaseDialog } from './DeletePrePurchaseDialog';
import { ControlClientInfoResponse } from '@explorer/open-api-client';
import { useMutationDeletePrePurchase } from './queries/useMutationDeletePrePurchase';
import {
  useMutationUpdateMDRAssociation,
  MDRAssociationAction,
} from './queries/useMutationUpdateMDRAssociation';

export const ModifyClient = memo(function ModifyClient({
  activeClient,
}: ModifyClientProps) {
  const { client_id: clientId } = activeClient?.client ?? {};
  const { snackError, snackSuccess } = useQuerySnackbar();
  const { formatMessage } = useIntl();
  const formId = `ModifyClient-${clientId}`;

  const { mutateAsync: mutateClientAsync } = useMutationUpdateClient();
  const { mutateAsync: deletePrepurchaseAsync } =
    useMutationDeletePrePurchase();
  const { mutateAsync: mutateMDRAssociationAsync } =
    useMutationUpdateMDRAssociation();

  const modules = useMemo(
    () => getModules(activeClient?.client),
    [activeClient?.client],
  );

  const [open, setOpen] = useState(false);

  const onSubmit = useCallback(
    async (
      values: FormValues,
      formikHelpers: FormikHelpers<FormValues>,
      confirmed: boolean,
    ) => {
      const { setSubmitting } = formikHelpers;

      const componentActivated = !!Object.entries(
        values.control_components,
      ).find(([, overridden]) => {
        return !!overridden;
      });

      const hasPrepurchaseComponents = Object.entries(
        modules.prepurchasedModules,
      ).find(([, prepurchaseInfo]) => {
        return !!prepurchaseInfo;
      });

      // If flipped to active, and has prepurchase components, we need to show a confirmation dialog
      if (componentActivated && hasPrepurchaseComponents && !confirmed) {
        setOpen(true);
        return;
      }

      try {
        await mutateClientAsync({
          clientId,
          role: values?.role ?? ClientRoles.PORTAL_FREEMIUM,
          count_max_lite: values?.count_max_lite ?? 0,
          count_max_extended: values?.count_max_extended ?? 0,
          msp: values?.option?.msp ?? false,
          control_components: values?.control_components ?? {},
        });

        if (componentActivated && hasPrepurchaseComponents) {
          try {
            await deletePrepurchaseAsync({
              clientId,
            });
          } catch (e) {
            snackError('Failed to clear prepurchased modules', e);
          }
        }

        const isMDRActive = !!activeClient.client.active_components['MDR'];

        if (isMDRActive != values.control_components.MDR) {
          await mutateMDRAssociationAsync({
            clientId,
            action: values.control_components.MDR
              ? MDRAssociationAction.Associate
              : MDRAssociationAction.Disassociate,
            mspClientId: process.env.MDR_CLIENT_ID,
          });
        }

        snackSuccess(
          formatMessage(
            { id: 'forms-presets.success-upgrade-client' },
            { client: clientId },
          ),
        );

        setSubmitting(false);
      } catch (e) {
        snackError('Modify Client Error', e);
      }
    },
    [
      clientId,
      deletePrepurchaseAsync,
      formatMessage,
      modules.prepurchasedModules,
      mutateClientAsync,
      snackError,
      snackSuccess,
      activeClient.client.active_components,
      mutateMDRAssociationAsync,
    ],
  );

  const formik = useFormik<FormValues>({
    validationSchema,
    initialValues: getInitialValues(activeClient, modules),
    onSubmit: (...args) => onSubmit(...args, false),
  });

  const formSchema = useMemo(
    () =>
      initializeFormSchema({
        formId,
        formik,
      }),
    [formik],
  );

  // If the role is changed to premium, set count_max_extended to 1
  useEffect(() => {
    if (
      (formik?.values?.role === ClientRoles.PORTAL_PREMIUM ||
        formik?.values?.role === ClientRoles.PORTAL_POLICYHOLDER) &&
      formik.values.count_max_extended < 1
    ) {
      formik.setFieldValue('count_max_extended', 1);
    }
    // disabling this b/c we only want to set count_max_extended to 1 when the role is changed to premium
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.role]);

  /**
   * Disable the submit button
   */
  const disableSave = useMemo<boolean>(() => {
    const { isSubmitting, isValid } = formik;

    if (isSubmitting || !isValid) return true;

    return false;
  }, [formik]);

  return (
    <>
      <DeletePrepurchaseDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          onSubmit(formik.values, formik, true);
        }}
      />
      <Form formId={formId} formik={formik}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={6}>
            <FormFieldControl {...formSchema.count_max_lite} />
          </Grid>
          <Grid item xs={6}>
            <FormFieldControl {...formSchema.count_max_extended} />
          </Grid>
          <Grid item xs={12}>
            <FormFieldControl {...formSchema.role} />
          </Grid>
          <Grid item xs={12}>
            <Divider mb={2}>
              {formatMessage({ id: 'forms-presets.modules' })}
            </Divider>
          </Grid>
          <DisplayModules
            clientId={clientId}
            moduleInfo={modules}
            formik={formik}
            formId={formId}
            schema={formSchema}
          />
          <Grid item xs={6}>
            <FormFieldControl {...formSchema.option} />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <SubmitButton
              formId={formId}
              formik={formik}
              color="primary"
              label={{ id: 'forms-presets.save' }}
              disabled={disableSave}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
});

export interface ModifyClientProps {
  readonly activeClient?: ControlClientInfoResponse;
}
