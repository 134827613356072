/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlZeroDayAssociationInvalidityReason = {
    ClientNotFoundInControl: 'client-not-found-in-control',
    ClientOnlyWatchlistingEntity: 'client-only-watchlisting-entity',
    ClientIsFreemiumTier: 'client-is-freemium-tier',
    ClientIsPremiumOrMonitorTierNotManaged: 'client-is-premium-or-monitor-tier-not-managed',
    ClientRoleNotValidForAssociation: 'client-role-not-valid-for-association',
    ClientIsExpectedPolicyholderMissingPolicy: 'client-is-expected-policyholder-missing-policy',
    ClientHasExpiredPolicy: 'client-has-expired-policy',
    EntityHasNoClientsInAsm: 'entity-has-no-clients-in-asm'
} as const;
export type ControlZeroDayAssociationInvalidityReason = typeof ControlZeroDayAssociationInvalidityReason[keyof typeof ControlZeroDayAssociationInvalidityReason];


export function ControlZeroDayAssociationInvalidityReasonFromJSON(json: any): ControlZeroDayAssociationInvalidityReason {
    return ControlZeroDayAssociationInvalidityReasonFromJSONTyped(json, false);
}

export function ControlZeroDayAssociationInvalidityReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZeroDayAssociationInvalidityReason {
    return json as ControlZeroDayAssociationInvalidityReason;
}

export function ControlZeroDayAssociationInvalidityReasonToJSON(value?: ControlZeroDayAssociationInvalidityReason | null): any {
    return value as any;
}

