/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    BusinessName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    USAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    ForeignAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    EIN?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    PrimaryActivitiesTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    LegalDomicileStateCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    LegalDomicileForeignCountryCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    PredominateIncomeDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    AllPartnersC3SInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    ShareOfTotalIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    ShareOfEOYAssetsAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    DisproportionateAllocationsInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    UBICodeVAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    GeneralOrManagingPartnerInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership
     */
    OwnershipPct?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'BusinessName': !exists(json, 'BusinessName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': !exists(json, 'ForeignAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'EIN': !exists(json, 'EIN') ? undefined : json['EIN'],
        'PrimaryActivitiesTxt': !exists(json, 'PrimaryActivitiesTxt') ? undefined : json['PrimaryActivitiesTxt'],
        'LegalDomicileStateCd': !exists(json, 'LegalDomicileStateCd') ? undefined : json['LegalDomicileStateCd'],
        'LegalDomicileForeignCountryCd': !exists(json, 'LegalDomicileForeignCountryCd') ? undefined : json['LegalDomicileForeignCountryCd'],
        'PredominateIncomeDesc': !exists(json, 'PredominateIncomeDesc') ? undefined : json['PredominateIncomeDesc'],
        'AllPartnersC3SInd': !exists(json, 'AllPartnersC3SInd') ? undefined : json['AllPartnersC3SInd'],
        'ShareOfTotalIncomeAmt': !exists(json, 'ShareOfTotalIncomeAmt') ? undefined : json['ShareOfTotalIncomeAmt'],
        'ShareOfEOYAssetsAmt': !exists(json, 'ShareOfEOYAssetsAmt') ? undefined : json['ShareOfEOYAssetsAmt'],
        'DisproportionateAllocationsInd': !exists(json, 'DisproportionateAllocationsInd') ? undefined : json['DisproportionateAllocationsInd'],
        'UBICodeVAmt': !exists(json, 'UBICodeVAmt') ? undefined : json['UBICodeVAmt'],
        'GeneralOrManagingPartnerInd': !exists(json, 'GeneralOrManagingPartnerInd') ? undefined : json['GeneralOrManagingPartnerInd'],
        'OwnershipPct': !exists(json, 'OwnershipPct') ? undefined : json['OwnershipPct'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnershipToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRUnrelatedOrgTxblPartnership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BusinessName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
        'USAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'EIN': value.EIN,
        'PrimaryActivitiesTxt': value.PrimaryActivitiesTxt,
        'LegalDomicileStateCd': value.LegalDomicileStateCd,
        'LegalDomicileForeignCountryCd': value.LegalDomicileForeignCountryCd,
        'PredominateIncomeDesc': value.PredominateIncomeDesc,
        'AllPartnersC3SInd': value.AllPartnersC3SInd,
        'ShareOfTotalIncomeAmt': value.ShareOfTotalIncomeAmt,
        'ShareOfEOYAssetsAmt': value.ShareOfEOYAssetsAmt,
        'DisproportionateAllocationsInd': value.DisproportionateAllocationsInd,
        'UBICodeVAmt': value.UBICodeVAmt,
        'GeneralOrManagingPartnerInd': value.GeneralOrManagingPartnerInd,
        'OwnershipPct': value.OwnershipPct,
    };
}

