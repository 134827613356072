/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsEDRMachine } from './JumboStructsEDRMachine';
import {
    JumboStructsEDRMachineFromJSON,
    JumboStructsEDRMachineFromJSONTyped,
    JumboStructsEDRMachineToJSON,
} from './JumboStructsEDRMachine';

/**
 * 
 * @export
 * @interface JumboStructsEDRPaginatedMachines
 */
export interface JumboStructsEDRPaginatedMachines {
    /**
     * 
     * @type {Array<JumboStructsEDRMachine>}
     * @memberof JumboStructsEDRPaginatedMachines
     */
    agents?: Array<JumboStructsEDRMachine>;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRPaginatedMachines
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRPaginatedMachines
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRPaginatedMachines
     */
    pageSize?: number;
}

/**
 * Check if a given object implements the JumboStructsEDRPaginatedMachines interface.
 */
export function instanceOfJumboStructsEDRPaginatedMachines(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRPaginatedMachinesFromJSON(json: any): JumboStructsEDRPaginatedMachines {
    return JumboStructsEDRPaginatedMachinesFromJSONTyped(json, false);
}

export function JumboStructsEDRPaginatedMachinesFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRPaginatedMachines {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agents': !exists(json, 'agents') ? undefined : ((json['agents'] as Array<any>).map(JumboStructsEDRMachineFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function JumboStructsEDRPaginatedMachinesToJSON(value?: JumboStructsEDRPaginatedMachines | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agents': value.agents === undefined ? undefined : ((value.agents as Array<any>).map(JumboStructsEDRMachineToJSON)),
        'count': value.count,
        'page': value.page,
        'pageSize': value.pageSize,
    };
}

