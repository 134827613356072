import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    Button,
    Typography,
  } from '@explorer/core';
import CloseIcon from '@mui/icons-material/Close';
import { useMutationDeleteSentinelOneAccount } from './useMutationDeleteSentinelOneAccount';

export const SentinelOneDisconnectModal = ({
  clientId,
  accountId,
  open = false,
  handleClose,
}: SentinelOneDisconnectModalProps) => {
  const { palette } = useTheme();

  const { mutate, isLoading, isSuccess } = useMutationDeleteSentinelOneAccount({
    client_id: clientId,
  });

  const handleDisconnect = () => {
    mutate({
      id: accountId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose({ refetch: true });
    }
  }, [isSuccess, handleClose]);

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Typography
                variant="subtitleCapsL"
                color="textSecondary"
                textColor={palette.grey[400]}
                label={{ id: 'mdr.sentinelone.disconnect-sentinelone' }}
                noUserSelect
            />
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => handleClose()}>
                <CloseIcon style={{ color: palette.grey[400] }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ pt: '0 !important' }}>
        <Typography variant="body1" color="textPrimary" label={{ id: 'mdr.sentinelone.disconnect-confirmation' }} />
      </DialogContent>
      <DialogActions>
          <Button
              variant="text"
              label={{ id: 'global.cancel' }}
              onClick={() => handleClose()}
              mx={1}
          />
          <Button
              label={{ id: 'mdr.sentinelone.disconnect' }}
              onClick={handleDisconnect}
              mx={1}
              loading={isLoading}
          />
      </DialogActions>
    </Dialog>
  )
};

export interface SentinelOneDisconnectModalProps {
  clientId: string;
  accountId: string;
  open: boolean;
  handleClose: (options?: { refetch: boolean }) => void;
}
