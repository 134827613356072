/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsPlaidBankAccount } from './JumboStructsPlaidBankAccount';
import {
    JumboStructsPlaidBankAccountFromJSON,
    JumboStructsPlaidBankAccountFromJSONTyped,
    JumboStructsPlaidBankAccountToJSON,
} from './JumboStructsPlaidBankAccount';

/**
 * 
 * @export
 * @interface JumboStructsPlaidItem
 */
export interface JumboStructsPlaidItem {
    /**
     * 
     * @type {Array<JumboStructsPlaidBankAccount>}
     * @memberof JumboStructsPlaidItem
     */
    accounts?: Array<JumboStructsPlaidBankAccount>;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidItem
     */
    institutionName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsPlaidItem
     */
    isConnected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsPlaidItem
     */
    needsLinkUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsPlaidItem
     */
    newAccountsAvailable?: boolean;
}

/**
 * Check if a given object implements the JumboStructsPlaidItem interface.
 */
export function instanceOfJumboStructsPlaidItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsPlaidItemFromJSON(json: any): JumboStructsPlaidItem {
    return JumboStructsPlaidItemFromJSONTyped(json, false);
}

export function JumboStructsPlaidItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsPlaidItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accounts': !exists(json, 'accounts') ? undefined : ((json['accounts'] as Array<any>).map(JumboStructsPlaidBankAccountFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'institutionName': !exists(json, 'institutionName') ? undefined : json['institutionName'],
        'isConnected': !exists(json, 'isConnected') ? undefined : json['isConnected'],
        'needsLinkUpdate': !exists(json, 'needsLinkUpdate') ? undefined : json['needsLinkUpdate'],
        'newAccountsAvailable': !exists(json, 'newAccountsAvailable') ? undefined : json['newAccountsAvailable'],
    };
}

export function JumboStructsPlaidItemToJSON(value?: JumboStructsPlaidItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accounts': value.accounts === undefined ? undefined : ((value.accounts as Array<any>).map(JumboStructsPlaidBankAccountToJSON)),
        'id': value.id,
        'institutionName': value.institutionName,
        'isConnected': value.isConnected,
        'needsLinkUpdate': value.needsLinkUpdate,
        'newAccountsAvailable': value.newAccountsAvailable,
    };
}

