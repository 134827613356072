/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetawsreportAWSReport } from './JumboGetawsreportAWSReport';
import {
    JumboGetawsreportAWSReportFromJSON,
    JumboGetawsreportAWSReportFromJSONTyped,
    JumboGetawsreportAWSReportToJSON,
} from './JumboGetawsreportAWSReport';

/**
 * 
 * @export
 * @interface JumboGetawsreportGetAWSReportResult
 */
export interface JumboGetawsreportGetAWSReportResult {
    /**
     * 
     * @type {{ [key: string]: JumboGetawsreportAWSReport; }}
     * @memberof JumboGetawsreportGetAWSReportResult
     */
    accounts?: { [key: string]: JumboGetawsreportAWSReport; };
}

/**
 * Check if a given object implements the JumboGetawsreportGetAWSReportResult interface.
 */
export function instanceOfJumboGetawsreportGetAWSReportResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetawsreportGetAWSReportResultFromJSON(json: any): JumboGetawsreportGetAWSReportResult {
    return JumboGetawsreportGetAWSReportResultFromJSONTyped(json, false);
}

export function JumboGetawsreportGetAWSReportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetawsreportGetAWSReportResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accounts': !exists(json, 'accounts') ? undefined : (mapValues(json['accounts'], JumboGetawsreportAWSReportFromJSON)),
    };
}

export function JumboGetawsreportGetAWSReportResultToJSON(value?: JumboGetawsreportGetAWSReportResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accounts': value.accounts === undefined ? undefined : (mapValues(value.accounts, JumboGetawsreportAWSReportToJSON)),
    };
}

