/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAddressSchemaORM } from './ExecRiskAddressSchemaORM';
import {
    ExecRiskAddressSchemaORMFromJSON,
    ExecRiskAddressSchemaORMFromJSONTyped,
    ExecRiskAddressSchemaORMToJSON,
} from './ExecRiskAddressSchemaORM';
import type { ExecRiskAutomatedDomains } from './ExecRiskAutomatedDomains';
import {
    ExecRiskAutomatedDomainsFromJSON,
    ExecRiskAutomatedDomainsFromJSONTyped,
    ExecRiskAutomatedDomainsToJSON,
} from './ExecRiskAutomatedDomains';
import type { ExecRiskExperianTradeDBT } from './ExecRiskExperianTradeDBT';
import {
    ExecRiskExperianTradeDBTFromJSON,
    ExecRiskExperianTradeDBTFromJSONTyped,
    ExecRiskExperianTradeDBTToJSON,
} from './ExecRiskExperianTradeDBT';
import type { ExecRiskGlassdoorRating } from './ExecRiskGlassdoorRating';
import {
    ExecRiskGlassdoorRatingFromJSON,
    ExecRiskGlassdoorRatingFromJSONTyped,
    ExecRiskGlassdoorRatingToJSON,
} from './ExecRiskGlassdoorRating';
import type { ExecRiskRiskFlag } from './ExecRiskRiskFlag';
import {
    ExecRiskRiskFlagFromJSON,
    ExecRiskRiskFlagFromJSONTyped,
    ExecRiskRiskFlagToJSON,
} from './ExecRiskRiskFlag';

/**
 * 
 * @export
 * @interface ExecRiskCompanyInfoResponse
 */
export interface ExecRiskCompanyInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoResponse
     */
    company_name: string;
    /**
     * 
     * @type {ExecRiskAddressSchemaORM}
     * @memberof ExecRiskCompanyInfoResponse
     */
    location?: ExecRiskAddressSchemaORM;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoResponse
     */
    scanned?: string;
    /**
     * 
     * @type {ExecRiskAutomatedDomains}
     * @memberof ExecRiskCompanyInfoResponse
     */
    automated_domains?: ExecRiskAutomatedDomains;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskCompanyInfoResponse
     */
    manual_domains?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ExecRiskCompanyInfoResponse
     */
    sic_codes?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ExecRiskCompanyInfoResponse
     */
    naics_codes?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoResponse
     */
    company_type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskCompanyInfoResponse
     */
    domains_list?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoResponse
     */
    incorporated_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCompanyInfoResponse
     */
    fsr_score?: number;
    /**
     * 
     * @type {ExecRiskExperianTradeDBT}
     * @memberof ExecRiskCompanyInfoResponse
     */
    days_beyond_terms?: ExecRiskExperianTradeDBT;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCompanyInfoResponse
     */
    alexa_rank?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoResponse
     */
    employee_range?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoResponse
     */
    revenue_range?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCompanyInfoResponse
     */
    revenue?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof ExecRiskCompanyInfoResponse
     */
    industries?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof ExecRiskCompanyInfoResponse
     */
    primary_industries?: Array<any>;
    /**
     * 
     * @type {ExecRiskGlassdoorRating}
     * @memberof ExecRiskCompanyInfoResponse
     */
    glassdoor_rating?: ExecRiskGlassdoorRating;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCompanyInfoResponse
     */
    lien_count: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCompanyInfoResponse
     */
    ppploan_count: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCompanyInfoResponse
     */
    investor_count: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskCompanyInfoResponse
     */
    violation_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExecRiskCompanyInfoResponse
     */
    is_public?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskCompanyInfoResponse
     */
    ticker?: string;
    /**
     * 
     * @type {Array<ExecRiskRiskFlag>}
     * @memberof ExecRiskCompanyInfoResponse
     */
    risk_flags?: Array<ExecRiskRiskFlag>;
}

/**
 * Check if a given object implements the ExecRiskCompanyInfoResponse interface.
 */
export function instanceOfExecRiskCompanyInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "company_name" in value;
    isInstance = isInstance && "lien_count" in value;
    isInstance = isInstance && "ppploan_count" in value;
    isInstance = isInstance && "investor_count" in value;
    isInstance = isInstance && "violation_count" in value;

    return isInstance;
}

export function ExecRiskCompanyInfoResponseFromJSON(json: any): ExecRiskCompanyInfoResponse {
    return ExecRiskCompanyInfoResponseFromJSONTyped(json, false);
}

export function ExecRiskCompanyInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskCompanyInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company_name': json['company_name'],
        'location': !exists(json, 'location') ? undefined : ExecRiskAddressSchemaORMFromJSON(json['location']),
        'scanned': !exists(json, 'scanned') ? undefined : json['scanned'],
        'automated_domains': !exists(json, 'automated_domains') ? undefined : ExecRiskAutomatedDomainsFromJSON(json['automated_domains']),
        'manual_domains': !exists(json, 'manual_domains') ? undefined : json['manual_domains'],
        'sic_codes': !exists(json, 'sic_codes') ? undefined : json['sic_codes'],
        'naics_codes': !exists(json, 'naics_codes') ? undefined : json['naics_codes'],
        'company_type': !exists(json, 'company_type') ? undefined : json['company_type'],
        'domains_list': !exists(json, 'domains_list') ? undefined : json['domains_list'],
        'incorporated_date': !exists(json, 'incorporated_date') ? undefined : json['incorporated_date'],
        'fsr_score': !exists(json, 'fsr_score') ? undefined : json['fsr_score'],
        'days_beyond_terms': !exists(json, 'days_beyond_terms') ? undefined : ExecRiskExperianTradeDBTFromJSON(json['days_beyond_terms']),
        'alexa_rank': !exists(json, 'alexa_rank') ? undefined : json['alexa_rank'],
        'employee_range': !exists(json, 'employee_range') ? undefined : json['employee_range'],
        'revenue_range': !exists(json, 'revenue_range') ? undefined : json['revenue_range'],
        'revenue': !exists(json, 'revenue') ? undefined : json['revenue'],
        'industries': !exists(json, 'industries') ? undefined : json['industries'],
        'primary_industries': !exists(json, 'primary_industries') ? undefined : json['primary_industries'],
        'glassdoor_rating': !exists(json, 'glassdoor_rating') ? undefined : ExecRiskGlassdoorRatingFromJSON(json['glassdoor_rating']),
        'lien_count': json['lien_count'],
        'ppploan_count': json['ppploan_count'],
        'investor_count': json['investor_count'],
        'violation_count': json['violation_count'],
        'is_public': !exists(json, 'is_public') ? undefined : json['is_public'],
        'ticker': !exists(json, 'ticker') ? undefined : json['ticker'],
        'risk_flags': !exists(json, 'risk_flags') ? undefined : ((json['risk_flags'] as Array<any>).map(ExecRiskRiskFlagFromJSON)),
    };
}

export function ExecRiskCompanyInfoResponseToJSON(value?: ExecRiskCompanyInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_name': value.company_name,
        'location': ExecRiskAddressSchemaORMToJSON(value.location),
        'scanned': value.scanned,
        'automated_domains': ExecRiskAutomatedDomainsToJSON(value.automated_domains),
        'manual_domains': value.manual_domains,
        'sic_codes': value.sic_codes,
        'naics_codes': value.naics_codes,
        'company_type': value.company_type,
        'domains_list': value.domains_list,
        'incorporated_date': value.incorporated_date,
        'fsr_score': value.fsr_score,
        'days_beyond_terms': ExecRiskExperianTradeDBTToJSON(value.days_beyond_terms),
        'alexa_rank': value.alexa_rank,
        'employee_range': value.employee_range,
        'revenue_range': value.revenue_range,
        'revenue': value.revenue,
        'industries': value.industries,
        'primary_industries': value.primary_industries,
        'glassdoor_rating': ExecRiskGlassdoorRatingToJSON(value.glassdoor_rating),
        'lien_count': value.lien_count,
        'ppploan_count': value.ppploan_count,
        'investor_count': value.investor_count,
        'violation_count': value.violation_count,
        'is_public': value.is_public,
        'ticker': value.ticker,
        'risk_flags': value.risk_flags === undefined ? undefined : ((value.risk_flags as Array<any>).map(ExecRiskRiskFlagToJSON)),
    };
}

