import { ControlClientInfoResponse, V1Api } from '@explorer/open-api-client';
import {
  useExplorerAPIMutationParamType,
  useV1APIMutation,
} from '@explorer/query';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

type MutateArgs = { clientId: string };

export const useMutationDeletePrePurchase = (
  args?: Omit<useExplorerAPIMutationParamType<V1Api>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();

  const { onSuccess: onSuccessCallback, ...otherArgs } = args ?? {};

  const onSuccess: typeof onSuccessCallback = useCallback(
    (data, mutateArgs: MutateArgs, context) => {
      const { clientId } = mutateArgs;

      queryClient.setQueryData<ControlClientInfoResponse>(
        ['v1ControlWebAdminGetClientInfoClientIdGet', clientId],
        (currentData) => {
          if (!currentData) return currentData;

          return {
            ...currentData,
            client: {
              ...currentData.client,
              pre_purchase_components: {},
            },
          };
        },
      );

      onSuccessCallback?.(data, mutateArgs, context);
    },
    [queryClient, onSuccessCallback],
  );

  return useV1APIMutation<void, MutateArgs>({
    mutationFn(args: { clientId: string }, client) {
      const { clientId, ...rest } = args;

      /* The backend requires a policyId to delete the prepurchase components.
      
      We only have one policyId per prepurchase component at this point, so we don't need to expose the policy Id requirement outside of this hook. Both Control and Explorer don't even support a multi-quote prepurchase use case at this point, so we don't expect multiple policyIds.
      
      */
      const clientInfo = queryClient.getQueryData<ControlClientInfoResponse>([
        'v1ControlWebAdminGetClientInfoClientIdGet',
        clientId,
      ]);

      const prepurchaseComponents = Object.values(
        clientInfo?.client?.pre_purchase_components ?? {},
      ).flat();

      const { component_json } = prepurchaseComponents[0] ?? {};

      let policyId: string;
      try {
        const prepurchaseInfo = JSON.parse(component_json ?? null);
        policyId = prepurchaseInfo?.policy_id;
      } catch (e) {}

      // If no policyId is found, we can't delete the prepurchase components
      if (!policyId) {
        throw new Error('No policyId found in prepurchase components');
      }

      return client.v1ControlWebAdminClientClientIdPoliciesPolicyIdPrePurchaseComponentsDelete(
        {
          client_id: clientId,
          policy_id: policyId,
        },
      );
    },
    onSuccess: (...successArgs) => {
      onSuccess(...successArgs);
    },
    ...otherArgs,
  });
};
