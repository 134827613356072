/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
 */
export interface ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    business_cd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    total_revenue_column_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    related_or_exempt_func_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    unrelated_business_revenue_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    exclusion_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    activity_or_mission_desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue
     */
    mission_desc?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenueFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue {
    return ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenueFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'business_cd': !exists(json, 'business_cd') ? undefined : json['business_cd'],
        'total_revenue_column_amt': !exists(json, 'total_revenue_column_amt') ? undefined : json['total_revenue_column_amt'],
        'related_or_exempt_func_income_amt': !exists(json, 'related_or_exempt_func_income_amt') ? undefined : json['related_or_exempt_func_income_amt'],
        'unrelated_business_revenue_amt': !exists(json, 'unrelated_business_revenue_amt') ? undefined : json['unrelated_business_revenue_amt'],
        'exclusion_amt': !exists(json, 'exclusion_amt') ? undefined : json['exclusion_amt'],
        'activity_or_mission_desc': !exists(json, 'activity_or_mission_desc') ? undefined : json['activity_or_mission_desc'],
        'mission_desc': !exists(json, 'mission_desc') ? undefined : json['mission_desc'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenueToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990ProgramServiceRevenue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'desc': value.desc,
        'business_cd': value.business_cd,
        'total_revenue_column_amt': value.total_revenue_column_amt,
        'related_or_exempt_func_income_amt': value.related_or_exempt_func_income_amt,
        'unrelated_business_revenue_amt': value.unrelated_business_revenue_amt,
        'exclusion_amt': value.exclusion_amt,
        'activity_or_mission_desc': value.activity_or_mission_desc,
        'mission_desc': value.mission_desc,
    };
}

