/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlControlDefinition } from './ControlControlDefinition';
import {
    ControlControlDefinitionFromJSON,
    ControlControlDefinitionFromJSONTyped,
    ControlControlDefinitionToJSON,
} from './ControlControlDefinition';
import type { ControlControlState } from './ControlControlState';
import {
    ControlControlStateFromJSON,
    ControlControlStateFromJSONTyped,
    ControlControlStateToJSON,
} from './ControlControlState';
import type { ControlFrameworkDefinition } from './ControlFrameworkDefinition';
import {
    ControlFrameworkDefinitionFromJSON,
    ControlFrameworkDefinitionFromJSONTyped,
    ControlFrameworkDefinitionToJSON,
} from './ControlFrameworkDefinition';
import type { ControlScope } from './ControlScope';
import {
    ControlScopeFromJSON,
    ControlScopeFromJSONTyped,
    ControlScopeToJSON,
} from './ControlScope';

/**
 * 
 * @export
 * @interface ControlGetFrameworkResponse
 */
export interface ControlGetFrameworkResponse {
    /**
     * 
     * @type {ControlFrameworkDefinition}
     * @memberof ControlGetFrameworkResponse
     */
    framework_definition: ControlFrameworkDefinition;
    /**
     * 
     * @type {Array<ControlControlDefinition>}
     * @memberof ControlGetFrameworkResponse
     */
    control_definitions: Array<ControlControlDefinition>;
    /**
     * 
     * @type {ControlScope}
     * @memberof ControlGetFrameworkResponse
     */
    scope: ControlScope;
    /**
     * 
     * @type {Array<ControlControlState>}
     * @memberof ControlGetFrameworkResponse
     */
    control_states: Array<ControlControlState>;
}

/**
 * Check if a given object implements the ControlGetFrameworkResponse interface.
 */
export function instanceOfControlGetFrameworkResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "framework_definition" in value;
    isInstance = isInstance && "control_definitions" in value;
    isInstance = isInstance && "scope" in value;
    isInstance = isInstance && "control_states" in value;

    return isInstance;
}

export function ControlGetFrameworkResponseFromJSON(json: any): ControlGetFrameworkResponse {
    return ControlGetFrameworkResponseFromJSONTyped(json, false);
}

export function ControlGetFrameworkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlGetFrameworkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'framework_definition': ControlFrameworkDefinitionFromJSON(json['framework_definition']),
        'control_definitions': ((json['control_definitions'] as Array<any>).map(ControlControlDefinitionFromJSON)),
        'scope': ControlScopeFromJSON(json['scope']),
        'control_states': ((json['control_states'] as Array<any>).map(ControlControlStateFromJSON)),
    };
}

export function ControlGetFrameworkResponseToJSON(value?: ControlGetFrameworkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'framework_definition': ControlFrameworkDefinitionToJSON(value.framework_definition),
        'control_definitions': ((value.control_definitions as Array<any>).map(ControlControlDefinitionToJSON)),
        'scope': ControlScopeToJSON(value.scope),
        'control_states': ((value.control_states as Array<any>).map(ControlControlStateToJSON)),
    };
}

