/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityFindingsAggAsset } from './MonitEntityFindingsAggAsset';
import {
    MonitEntityFindingsAggAssetFromJSON,
    MonitEntityFindingsAggAssetFromJSONTyped,
    MonitEntityFindingsAggAssetToJSON,
} from './MonitEntityFindingsAggAsset';

/**
 * 
 * @export
 * @interface MonitEntityFindingsAggResult
 */
export interface MonitEntityFindingsAggResult {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    datapoint?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityFindingsAggResult
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    score_category?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsAggResult
     */
    recommendation?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsAggResult
     */
    references?: Array<string>;
    /**
     * 
     * @type {Array<MonitEntityFindingsAggAsset>}
     * @memberof MonitEntityFindingsAggResult
     */
    assets?: Array<MonitEntityFindingsAggAsset>;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityFindingsAggResult
     */
    first_seen?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityFindingsAggResult
     */
    last_seen?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitEntityFindingsAggResult
     */
    risk_score?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    muted_status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    scan_status?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityFindingsAggResult
     */
    resolution_status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityFindingsAggResult
     */
    resolution_types?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityFindingsAggResult
     */
    is_contingency?: boolean;
}

/**
 * Check if a given object implements the MonitEntityFindingsAggResult interface.
 */
export function instanceOfMonitEntityFindingsAggResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityFindingsAggResultFromJSON(json: any): MonitEntityFindingsAggResult {
    return MonitEntityFindingsAggResultFromJSONTyped(json, false);
}

export function MonitEntityFindingsAggResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityFindingsAggResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoint': !exists(json, 'datapoint') ? undefined : json['datapoint'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'score_category': !exists(json, 'score_category') ? undefined : json['score_category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'assets': !exists(json, 'assets') ? undefined : ((json['assets'] as Array<any>).map(MonitEntityFindingsAggAssetFromJSON)),
        'first_seen': !exists(json, 'first_seen') ? undefined : json['first_seen'],
        'last_seen': !exists(json, 'last_seen') ? undefined : json['last_seen'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'muted_status': !exists(json, 'muted_status') ? undefined : json['muted_status'],
        'scan_status': !exists(json, 'scan_status') ? undefined : json['scan_status'],
        'resolution_status': !exists(json, 'resolution_status') ? undefined : json['resolution_status'],
        'resolution_types': !exists(json, 'resolution_types') ? undefined : json['resolution_types'],
        'is_contingency': !exists(json, 'is_contingency') ? undefined : json['is_contingency'],
    };
}

export function MonitEntityFindingsAggResultToJSON(value?: MonitEntityFindingsAggResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoint': value.datapoint,
        'name': value.name,
        'count': value.count,
        'level': value.level,
        'category': value.category,
        'score_category': value.score_category,
        'description': value.description,
        'recommendation': value.recommendation,
        'references': value.references,
        'assets': value.assets === undefined ? undefined : ((value.assets as Array<any>).map(MonitEntityFindingsAggAssetToJSON)),
        'first_seen': value.first_seen,
        'last_seen': value.last_seen,
        'risk_score': value.risk_score,
        'muted_status': value.muted_status,
        'scan_status': value.scan_status,
        'resolution_status': value.resolution_status,
        'resolution_types': value.resolution_types,
        'is_contingency': value.is_contingency,
    };
}

