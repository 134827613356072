/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsTriggerScanStatus } from './ExecRiskApiAppCoreModelsTriggerScanStatus';
import {
    ExecRiskApiAppCoreModelsTriggerScanStatusFromJSON,
    ExecRiskApiAppCoreModelsTriggerScanStatusFromJSONTyped,
    ExecRiskApiAppCoreModelsTriggerScanStatusToJSON,
} from './ExecRiskApiAppCoreModelsTriggerScanStatus';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsTriggerProcessStatusResponse
 */
export interface ExecRiskApiAppCoreModelsTriggerProcessStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsTriggerProcessStatusResponse
     */
    process_id: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsTriggerProcessStatusResponse
     */
    status?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsTriggerScanStatus}
     * @memberof ExecRiskApiAppCoreModelsTriggerProcessStatusResponse
     */
    scan_status?: ExecRiskApiAppCoreModelsTriggerScanStatus;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsTriggerProcessStatusResponse
     */
    started: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsTriggerProcessStatusResponse
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsTriggerProcessStatusResponse
     */
    finished?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsTriggerProcessStatusResponse interface.
 */
export function instanceOfExecRiskApiAppCoreModelsTriggerProcessStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "process_id" in value;
    isInstance = isInstance && "started" in value;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsTriggerProcessStatusResponseFromJSON(json: any): ExecRiskApiAppCoreModelsTriggerProcessStatusResponse {
    return ExecRiskApiAppCoreModelsTriggerProcessStatusResponseFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsTriggerProcessStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsTriggerProcessStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'process_id': json['process_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'scan_status': !exists(json, 'scan_status') ? undefined : ExecRiskApiAppCoreModelsTriggerScanStatusFromJSON(json['scan_status']),
        'started': json['started'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'finished': !exists(json, 'finished') ? undefined : json['finished'],
    };
}

export function ExecRiskApiAppCoreModelsTriggerProcessStatusResponseToJSON(value?: ExecRiskApiAppCoreModelsTriggerProcessStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_id': value.process_id,
        'status': value.status,
        'scan_status': ExecRiskApiAppCoreModelsTriggerScanStatusToJSON(value.scan_status),
        'started': value.started,
        'updated': value.updated,
        'finished': value.finished,
    };
}

