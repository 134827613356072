/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt
 */
export interface ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt
     */
    personal_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt
     */
    real_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmtFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt {
    return ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personal_amt': !exists(json, 'personal_amt') ? undefined : json['personal_amt'],
        'real_amt': !exists(json, 'real_amt') ? undefined : json['real_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmtToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990PersonalRealAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personal_amt': value.personal_amt,
        'real_amt': value.real_amt,
    };
}

