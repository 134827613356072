/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt
     */
    BOYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt
     */
    EOYAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'BOYAmt': !exists(json, 'BOYAmt') ? undefined : json['BOYAmt'],
        'EOYAmt': !exists(json, 'EOYAmt') ? undefined : json['EOYAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BOYAmt': value.BOYAmt,
        'EOYAmt': value.EOYAmt,
    };
}

