/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt
 */
export interface ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt
     */
    boy_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt
     */
    eoy_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmtFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt {
    return ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boy_amt': !exists(json, 'boy_amt') ? undefined : json['boy_amt'],
        'eoy_amt': !exists(json, 'eoy_amt') ? undefined : json['eoy_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmtToJSON(value?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBOYEOYAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boy_amt': value.boy_amt,
        'eoy_amt': value.eoy_amt,
    };
}

