import { JumboStructsGetEDRAccountResult } from '@explorer/open-api-client';

// Taken from be-frontend-control
// https://github.com/binaryedge/be-frontend-control/blob/main/src/components/ManageIntegrations/Integrations/SentinelOne/helpers/getConnectedAccount.ts
export const getOrderedConnectedAccounts = (
  accountData?: JumboStructsGetEDRAccountResult,
) => {
  if (!accountData?.accounts || accountData.accounts.length === 0) {
    return { orderedValidAccounts: [], allAccountsInvalid: false };
  }
  // Currently assuming that only one account can be connected at a time.
  const orderedValidAccounts = accountData.accounts
    .filter((account) => {
      // We only care about accounts that are connected and have a createdAt date.
      return account.isConnected && account.createdAt;
    })
    .sort((a, b) => {
      return (
        new Date(b.createdAt as string).getTime() -
        new Date(a.createdAt as string).getTime()
      );
    });

  const allAccountsInvalid = accountData.accounts.every(
    (account) => account.invalidatedAt,
  );

  return { orderedValidAccounts, allAccountsInvalid };
};
