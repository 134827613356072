/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsFinding } from './JumboStructsFinding';
import {
    JumboStructsFindingFromJSON,
    JumboStructsFindingFromJSONTyped,
    JumboStructsFindingToJSON,
} from './JumboStructsFinding';

/**
 * 
 * @export
 * @interface JumboGetfindingsdetailsGetFindingsDetailsResult
 */
export interface JumboGetfindingsdetailsGetFindingsDetailsResult {
    /**
     * 
     * @type {JumboStructsFinding}
     * @memberof JumboGetfindingsdetailsGetFindingsDetailsResult
     */
    item?: JumboStructsFinding;
}

/**
 * Check if a given object implements the JumboGetfindingsdetailsGetFindingsDetailsResult interface.
 */
export function instanceOfJumboGetfindingsdetailsGetFindingsDetailsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetfindingsdetailsGetFindingsDetailsResultFromJSON(json: any): JumboGetfindingsdetailsGetFindingsDetailsResult {
    return JumboGetfindingsdetailsGetFindingsDetailsResultFromJSONTyped(json, false);
}

export function JumboGetfindingsdetailsGetFindingsDetailsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetfindingsdetailsGetFindingsDetailsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': !exists(json, 'item') ? undefined : JumboStructsFindingFromJSON(json['item']),
    };
}

export function JumboGetfindingsdetailsGetFindingsDetailsResultToJSON(value?: JumboGetfindingsdetailsGetFindingsDetailsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': JumboStructsFindingToJSON(value.item),
    };
}

