/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Triggers } from './Triggers';
import {
    TriggersFromJSON,
    TriggersFromJSONTyped,
    TriggersToJSON,
} from './Triggers';

/**
 * 
 * @export
 * @interface ExecRiskBingNewsDetailsEntry
 */
export interface ExecRiskBingNewsDetailsEntry {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    snippet?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    overall_sentiment?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    positive?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    neutral?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    negative?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    canonical_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    article_body?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    date_published?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    date_modified?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    authors?: Array<string>;
    /**
     * 
     * @type {Triggers}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    triggers: Triggers;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskBingNewsDetailsEntry
     */
    triggers_count?: number;
}

/**
 * Check if a given object implements the ExecRiskBingNewsDetailsEntry interface.
 */
export function instanceOfExecRiskBingNewsDetailsEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "triggers" in value;

    return isInstance;
}

export function ExecRiskBingNewsDetailsEntryFromJSON(json: any): ExecRiskBingNewsDetailsEntry {
    return ExecRiskBingNewsDetailsEntryFromJSONTyped(json, false);
}

export function ExecRiskBingNewsDetailsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskBingNewsDetailsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'snippet': !exists(json, 'snippet') ? undefined : json['snippet'],
        'overall_sentiment': !exists(json, 'overall_sentiment') ? undefined : json['overall_sentiment'],
        'positive': !exists(json, 'positive') ? undefined : json['positive'],
        'neutral': !exists(json, 'neutral') ? undefined : json['neutral'],
        'negative': !exists(json, 'negative') ? undefined : json['negative'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'canonical_url': !exists(json, 'canonical_url') ? undefined : json['canonical_url'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'article_body': !exists(json, 'article_body') ? undefined : json['article_body'],
        'date_published': !exists(json, 'date_published') ? undefined : json['date_published'],
        'date_modified': !exists(json, 'date_modified') ? undefined : json['date_modified'],
        'authors': !exists(json, 'authors') ? undefined : json['authors'],
        'triggers': TriggersFromJSON(json['triggers']),
        'triggers_count': !exists(json, 'triggers_count') ? undefined : json['triggers_count'],
    };
}

export function ExecRiskBingNewsDetailsEntryToJSON(value?: ExecRiskBingNewsDetailsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'snippet': value.snippet,
        'overall_sentiment': value.overall_sentiment,
        'positive': value.positive,
        'neutral': value.neutral,
        'negative': value.negative,
        'url': value.url,
        'canonical_url': value.canonical_url,
        'author': value.author,
        'language': value.language,
        'article_body': value.article_body,
        'date_published': value.date_published,
        'date_modified': value.date_modified,
        'authors': value.authors,
        'triggers': TriggersToJSON(value.triggers),
        'triggers_count': value.triggers_count,
    };
}

