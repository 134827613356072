/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleR } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleR';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleR';
import type { ExecRiskIRS990 } from './ExecRiskIRS990';
import {
    ExecRiskIRS990FromJSON,
    ExecRiskIRS990FromJSONTyped,
    ExecRiskIRS990ToJSON,
} from './ExecRiskIRS990';
import type { ExecRiskIRS990EZ } from './ExecRiskIRS990EZ';
import {
    ExecRiskIRS990EZFromJSON,
    ExecRiskIRS990EZFromJSONTyped,
    ExecRiskIRS990EZToJSON,
} from './ExecRiskIRS990EZ';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData {
    /**
     * 
     * @type {ExecRiskIRS990}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990?: ExecRiskIRS990;
    /**
     * 
     * @type {ExecRiskIRS990EZ}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990EZ?: ExecRiskIRS990EZ;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990ScheduleA?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleA;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleR}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990ScheduleR?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleR;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IRS990': !exists(json, 'IRS990') ? undefined : ExecRiskIRS990FromJSON(json['IRS990']),
        'IRS990EZ': !exists(json, 'IRS990EZ') ? undefined : ExecRiskIRS990EZFromJSON(json['IRS990EZ']),
        'IRS990ScheduleA': !exists(json, 'IRS990ScheduleA') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSON(json['IRS990ScheduleA']),
        'IRS990ScheduleR': !exists(json, 'IRS990ScheduleR') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSON(json['IRS990ScheduleR']),
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IRS990': ExecRiskIRS990ToJSON(value.IRS990),
        'IRS990EZ': ExecRiskIRS990EZToJSON(value.IRS990EZ),
        'IRS990ScheduleA': ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAToJSON(value.IRS990ScheduleA),
        'IRS990ScheduleR': ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRToJSON(value.IRS990ScheduleR),
    };
}

