/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsMDRAlertsResult } from './JumboStructsMDRAlertsResult';
import {
    JumboStructsMDRAlertsResultFromJSON,
    JumboStructsMDRAlertsResultFromJSONTyped,
    JumboStructsMDRAlertsResultToJSON,
} from './JumboStructsMDRAlertsResult';
import type { JumboStructsMDREndpointsResult } from './JumboStructsMDREndpointsResult';
import {
    JumboStructsMDREndpointsResultFromJSON,
    JumboStructsMDREndpointsResultFromJSONTyped,
    JumboStructsMDREndpointsResultToJSON,
} from './JumboStructsMDREndpointsResult';
import type { JumboStructsMDRMostRecentAlert } from './JumboStructsMDRMostRecentAlert';
import {
    JumboStructsMDRMostRecentAlertFromJSON,
    JumboStructsMDRMostRecentAlertFromJSONTyped,
    JumboStructsMDRMostRecentAlertToJSON,
} from './JumboStructsMDRMostRecentAlert';

/**
 * 
 * @export
 * @interface JumboStructsMDRDashboardResult
 */
export interface JumboStructsMDRDashboardResult {
    /**
     * 
     * @type {JumboStructsMDRAlertsResult}
     * @memberof JumboStructsMDRDashboardResult
     */
    alertsResult?: JumboStructsMDRAlertsResult;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDRDashboardResult
     */
    customDetectionRulesCount?: number;
    /**
     * 
     * @type {JumboStructsMDREndpointsResult}
     * @memberof JumboStructsMDRDashboardResult
     */
    endpointsResult?: JumboStructsMDREndpointsResult;
    /**
     * 
     * @type {JumboStructsMDRMostRecentAlert}
     * @memberof JumboStructsMDRDashboardResult
     */
    mostRecentAlert?: JumboStructsMDRMostRecentAlert;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDRDashboardResult
     */
    needsAction?: number;
    /**
     * 
     * @type {JumboStructsMDREndpointsResult}
     * @memberof JumboStructsMDRDashboardResult
     */
    serversResult?: JumboStructsMDREndpointsResult;
}

/**
 * Check if a given object implements the JumboStructsMDRDashboardResult interface.
 */
export function instanceOfJumboStructsMDRDashboardResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsMDRDashboardResultFromJSON(json: any): JumboStructsMDRDashboardResult {
    return JumboStructsMDRDashboardResultFromJSONTyped(json, false);
}

export function JumboStructsMDRDashboardResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsMDRDashboardResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertsResult': !exists(json, 'alertsResult') ? undefined : JumboStructsMDRAlertsResultFromJSON(json['alertsResult']),
        'customDetectionRulesCount': !exists(json, 'customDetectionRulesCount') ? undefined : json['customDetectionRulesCount'],
        'endpointsResult': !exists(json, 'endpointsResult') ? undefined : JumboStructsMDREndpointsResultFromJSON(json['endpointsResult']),
        'mostRecentAlert': !exists(json, 'mostRecentAlert') ? undefined : JumboStructsMDRMostRecentAlertFromJSON(json['mostRecentAlert']),
        'needsAction': !exists(json, 'needsAction') ? undefined : json['needsAction'],
        'serversResult': !exists(json, 'serversResult') ? undefined : JumboStructsMDREndpointsResultFromJSON(json['serversResult']),
    };
}

export function JumboStructsMDRDashboardResultToJSON(value?: JumboStructsMDRDashboardResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertsResult': JumboStructsMDRAlertsResultToJSON(value.alertsResult),
        'customDetectionRulesCount': value.customDetectionRulesCount,
        'endpointsResult': JumboStructsMDREndpointsResultToJSON(value.endpointsResult),
        'mostRecentAlert': JumboStructsMDRMostRecentAlertToJSON(value.mostRecentAlert),
        'needsAction': value.needsAction,
        'serversResult': JumboStructsMDREndpointsResultToJSON(value.serversResult),
    };
}

