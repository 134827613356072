/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    PersonNm?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    BusinessName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    TitleTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    AverageHoursPerWeekRt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    AverageHoursPerWeekRltdOrgRt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    IndividualTrusteeOrDirectorInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    InstitutionalTrusteeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    OfficerInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    KeyEmployeeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    HighestCompensatedEmployeeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    FormerOfcrDirectorTrusteeInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    ReportableCompFromOrgAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    ReportableCompFromRltdOrgAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    OtherCompensationAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': !exists(json, 'PersonNm') ? undefined : json['PersonNm'],
        'BusinessName': !exists(json, 'BusinessName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
        'TitleTxt': !exists(json, 'TitleTxt') ? undefined : json['TitleTxt'],
        'AverageHoursPerWeekRt': !exists(json, 'AverageHoursPerWeekRt') ? undefined : json['AverageHoursPerWeekRt'],
        'AverageHoursPerWeekRltdOrgRt': !exists(json, 'AverageHoursPerWeekRltdOrgRt') ? undefined : json['AverageHoursPerWeekRltdOrgRt'],
        'IndividualTrusteeOrDirectorInd': !exists(json, 'IndividualTrusteeOrDirectorInd') ? undefined : json['IndividualTrusteeOrDirectorInd'],
        'InstitutionalTrusteeInd': !exists(json, 'InstitutionalTrusteeInd') ? undefined : json['InstitutionalTrusteeInd'],
        'OfficerInd': !exists(json, 'OfficerInd') ? undefined : json['OfficerInd'],
        'KeyEmployeeInd': !exists(json, 'KeyEmployeeInd') ? undefined : json['KeyEmployeeInd'],
        'HighestCompensatedEmployeeInd': !exists(json, 'HighestCompensatedEmployeeInd') ? undefined : json['HighestCompensatedEmployeeInd'],
        'FormerOfcrDirectorTrusteeInd': !exists(json, 'FormerOfcrDirectorTrusteeInd') ? undefined : json['FormerOfcrDirectorTrusteeInd'],
        'ReportableCompFromOrgAmt': !exists(json, 'ReportableCompFromOrgAmt') ? undefined : json['ReportableCompFromOrgAmt'],
        'ReportableCompFromRltdOrgAmt': !exists(json, 'ReportableCompFromRltdOrgAmt') ? undefined : json['ReportableCompFromRltdOrgAmt'],
        'OtherCompensationAmt': !exists(json, 'OtherCompensationAmt') ? undefined : json['OtherCompensationAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'BusinessName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
        'TitleTxt': value.TitleTxt,
        'AverageHoursPerWeekRt': value.AverageHoursPerWeekRt,
        'AverageHoursPerWeekRltdOrgRt': value.AverageHoursPerWeekRltdOrgRt,
        'IndividualTrusteeOrDirectorInd': value.IndividualTrusteeOrDirectorInd,
        'InstitutionalTrusteeInd': value.InstitutionalTrusteeInd,
        'OfficerInd': value.OfficerInd,
        'KeyEmployeeInd': value.KeyEmployeeInd,
        'HighestCompensatedEmployeeInd': value.HighestCompensatedEmployeeInd,
        'FormerOfcrDirectorTrusteeInd': value.FormerOfcrDirectorTrusteeInd,
        'ReportableCompFromOrgAmt': value.ReportableCompFromOrgAmt,
        'ReportableCompFromRltdOrgAmt': value.ReportableCompFromRltdOrgAmt,
        'OtherCompensationAmt': value.OtherCompensationAmt,
    };
}

