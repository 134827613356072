/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsAWSS3BucketReport
 */
export interface JumboStructsAWSS3BucketReport {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsAWSS3BucketReport
     */
    arn?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsAWSS3BucketReport
     */
    displayName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsAWSS3BucketReport
     */
    isPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsAWSS3BucketReport
     */
    publicSource?: string;
}

/**
 * Check if a given object implements the JumboStructsAWSS3BucketReport interface.
 */
export function instanceOfJumboStructsAWSS3BucketReport(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsAWSS3BucketReportFromJSON(json: any): JumboStructsAWSS3BucketReport {
    return JumboStructsAWSS3BucketReportFromJSONTyped(json, false);
}

export function JumboStructsAWSS3BucketReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsAWSS3BucketReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'arn': !exists(json, 'arn') ? undefined : json['arn'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'isPublic': !exists(json, 'isPublic') ? undefined : json['isPublic'],
        'publicSource': !exists(json, 'publicSource') ? undefined : json['publicSource'],
    };
}

export function JumboStructsAWSS3BucketReportToJSON(value?: JumboStructsAWSS3BucketReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'arn': value.arn,
        'displayName': value.displayName,
        'isPublic': value.isPublic,
        'publicSource': value.publicSource,
    };
}

