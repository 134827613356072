/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsFindingUser
 */
export interface JumboStructsFindingUser {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFindingUser
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFindingUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFindingUser
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFindingUser
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFindingUser
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFindingUser
     */
    uniqueUserID?: string;
}

/**
 * Check if a given object implements the JumboStructsFindingUser interface.
 */
export function instanceOfJumboStructsFindingUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsFindingUserFromJSON(json: any): JumboStructsFindingUser {
    return JumboStructsFindingUserFromJSONTyped(json, false);
}

export function JumboStructsFindingUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsFindingUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'uniqueUserID': !exists(json, 'uniqueUserID') ? undefined : json['uniqueUserID'],
    };
}

export function JumboStructsFindingUserToJSON(value?: JumboStructsFindingUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'email': value.email,
        'givenName': value.givenName,
        'id': value.id,
        'surname': value.surname,
        'uniqueUserID': value.uniqueUserID,
    };
}

