/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityTriggerQueries
 */
export interface MonitEntityTriggerQueries {
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityTriggerQueries
     */
    nocache?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityTriggerQueries
     */
    trial?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityTriggerQueries
     */
    quote_uuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityTriggerQueries
     */
    lite_enum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityTriggerQueries
     */
    lite_template?: boolean;
}

/**
 * Check if a given object implements the MonitEntityTriggerQueries interface.
 */
export function instanceOfMonitEntityTriggerQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityTriggerQueriesFromJSON(json: any): MonitEntityTriggerQueries {
    return MonitEntityTriggerQueriesFromJSONTyped(json, false);
}

export function MonitEntityTriggerQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityTriggerQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nocache': !exists(json, 'nocache') ? undefined : json['nocache'],
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
        'quote_uuid': !exists(json, 'quote_uuid') ? undefined : json['quote_uuid'],
        'lite_enum': !exists(json, 'lite_enum') ? undefined : json['lite_enum'],
        'lite_template': !exists(json, 'lite_template') ? undefined : json['lite_template'],
    };
}

export function MonitEntityTriggerQueriesToJSON(value?: MonitEntityTriggerQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nocache': value.nocache,
        'trial': value.trial,
        'quote_uuid': value.quote_uuid,
        'lite_enum': value.lite_enum,
        'lite_template': value.lite_template,
    };
}

