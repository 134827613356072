/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
     */
    CYAdjNetIncomeDistributableAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
     */
    CYPct85AdjustedNetIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
     */
    CYTotalMinAstDistributableAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
     */
    CYGreaterAdjustedMinimumAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
     */
    CYIncomeTaxImposedPYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
     */
    CYDistributableAsAdjustedAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount
     */
    FirstYearType3NonFuncInd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CYAdjNetIncomeDistributableAmt': !exists(json, 'CYAdjNetIncomeDistributableAmt') ? undefined : json['CYAdjNetIncomeDistributableAmt'],
        'CYPct85AdjustedNetIncomeAmt': !exists(json, 'CYPct85AdjustedNetIncomeAmt') ? undefined : json['CYPct85AdjustedNetIncomeAmt'],
        'CYTotalMinAstDistributableAmt': !exists(json, 'CYTotalMinAstDistributableAmt') ? undefined : json['CYTotalMinAstDistributableAmt'],
        'CYGreaterAdjustedMinimumAmt': !exists(json, 'CYGreaterAdjustedMinimumAmt') ? undefined : json['CYGreaterAdjustedMinimumAmt'],
        'CYIncomeTaxImposedPYAmt': !exists(json, 'CYIncomeTaxImposedPYAmt') ? undefined : json['CYIncomeTaxImposedPYAmt'],
        'CYDistributableAsAdjustedAmt': !exists(json, 'CYDistributableAsAdjustedAmt') ? undefined : json['CYDistributableAsAdjustedAmt'],
        'FirstYearType3NonFuncInd': !exists(json, 'FirstYearType3NonFuncInd') ? undefined : json['FirstYearType3NonFuncInd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmountToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleADistributableAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CYAdjNetIncomeDistributableAmt': value.CYAdjNetIncomeDistributableAmt,
        'CYPct85AdjustedNetIncomeAmt': value.CYPct85AdjustedNetIncomeAmt,
        'CYTotalMinAstDistributableAmt': value.CYTotalMinAstDistributableAmt,
        'CYGreaterAdjustedMinimumAmt': value.CYGreaterAdjustedMinimumAmt,
        'CYIncomeTaxImposedPYAmt': value.CYIncomeTaxImposedPYAmt,
        'CYDistributableAsAdjustedAmt': value.CYDistributableAsAdjustedAmt,
        'FirstYearType3NonFuncInd': value.FirstYearType3NonFuncInd,
    };
}

