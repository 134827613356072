/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse
 */
export interface JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse
     */
    isLegacyAuthEnabled?: boolean;
}

/**
 * Check if a given object implements the JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse interface.
 */
export function instanceOfJumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponseFromJSON(json: any): JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse {
    return JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponseFromJSONTyped(json, false);
}

export function JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isLegacyAuthEnabled': !exists(json, 'isLegacyAuthEnabled') ? undefined : json['isLegacyAuthEnabled'],
    };
}

export function JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponseToJSON(value?: JumboGetorglegacyauthstatusGetOrgLegacyAuthStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isLegacyAuthEnabled': value.isLegacyAuthEnabled,
    };
}

