/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI
 */
export interface ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI
     */
    ack_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI
     */
    ack_id_uuid?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI
     */
    row_order?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI
     */
    small_plan_transfer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI
     */
    small_plan_transfer_ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI
     */
    small_plan_transfer_pn?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIFromJSON(json: any): ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI {
    return ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ack_id': !exists(json, 'ack_id') ? undefined : json['ack_id'],
        'ack_id_uuid': !exists(json, 'ack_id_uuid') ? undefined : json['ack_id_uuid'],
        'row_order': !exists(json, 'row_order') ? undefined : json['row_order'],
        'small_plan_transfer_name': !exists(json, 'small_plan_transfer_name') ? undefined : json['small_plan_transfer_name'],
        'small_plan_transfer_ein': !exists(json, 'small_plan_transfer_ein') ? undefined : json['small_plan_transfer_ein'],
        'small_plan_transfer_pn': !exists(json, 'small_plan_transfer_pn') ? undefined : json['small_plan_transfer_pn'],
    };
}

export function ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIToJSON(value?: ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ack_id': value.ack_id,
        'ack_id_uuid': value.ack_id_uuid,
        'row_order': value.row_order,
        'small_plan_transfer_name': value.small_plan_transfer_name,
        'small_plan_transfer_ein': value.small_plan_transfer_ein,
        'small_plan_transfer_pn': value.small_plan_transfer_pn,
    };
}

