import { appConfig } from './appConfig';
import { Routes } from './appRoutes';

const { NAME } = appConfig;

const commonDescription =
  'Coalition Explorer is the go to internal tool to interface with the Customer Security data. With a myriad of ever growing modules that allows users to control and explore our business book from a perspective of Customer Security, Risk Assessment, Security Review, or Executive Risks.';

export const appMeta: AppMeta<Routes> = {
  '/': {
    pageUrl: '/',
    metaTitle: `Dashboard | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/404': {
    pageUrl: '/404',
    metaTitle: `Page Not Found | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/error': {
    pageUrl: '/error',
    metaTitle: `Error | ${NAME}`,
    metaDescription: commonDescription,
  },
  /**
   * Control Admin Panel
   */
  '/admin-control/companies': {
    pageUrl: '/admin-control/companies',
    metaTitle: `Search Companies | Control Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-control/invite': {
    pageUrl: '/admin-control/invite',
    metaTitle: `Invite Users | Control Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-control/new-control-user': {
    pageUrl: '/admin-control/new-control-user',
    metaTitle: `New Control User | Control Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-control/users': {
    pageUrl: '/admin-control/users',
    metaTitle: `Search Users | Control Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-control/manage-access-requests': {
    pageUrl: '/admin-control/manage-access-requests',
    metaTitle: `Manage Access Requests | Control Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-control/modify-client': {
    pageUrl: '/admin-control/modify-client',
    metaTitle: `Modify Client | Control Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-control/associate-email-to-client': {
    pageUrl: '/admin-control/associate-email-to-client',
    metaTitle: `Associate Email to Client | Control Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  /**
   * Explorer Admin Panel
   */
  '/admin-explorer/client': {
    pageUrl: '/admin-explorer/client',
    metaTitle: `Add Client ID to ASM | Explorer Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-explorer/companies': {
    pageUrl: '/admin-explorer/companies',
    metaTitle: `Search Companies | Explorer Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-explorer/user': {
    pageUrl: '/admin-explorer/user',
    metaTitle: `Add a new Platform User | Explorer Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-explorer/email': {
    pageUrl: '/admin-explorer/email',
    metaTitle: `Add new email to Client ID | Explorer Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/admin-explorer/token': {
    pageUrl: '/admin-explorer/token',
    metaTitle: `Manage Tokens | Explorer Admin Panel | ${NAME}`,
    metaDescription: commonDescription,
  },
  /**
   * Attack Surface Monitoring (ASM)
   */
  '/asm': {
    pageUrl: '/asm',
    metaTitle: `Book Dashboard | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/asm/notifications': {
    pageUrl: '/asm/notifications',
    metaTitle: `All Notifications | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/asm/vulnerabilities': {
    pageUrl: '/asm/vulnerabilities',
    metaTitle: `All Vulnerabilities | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/asm/technologies': {
    pageUrl: '/asm/technologies',
    metaTitle: `All Technologies | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/asm/companies': {
    pageUrl: '/asm/companies',
    metaTitle: `All Companies | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/asm/companies/assets': {
    pageUrl: '/asm/companies/assets',
    metaTitle: `Assets | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) => `Assets | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/dashboard': {
    pageUrl: '/asm/companies/dashboard',
    metaTitle: `Dashboard | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Dashboard | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/data-breaches': {
    pageUrl: '/asm/companies/data-breaches',
    metaTitle: `Data Breaches | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Data Breaches | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/honeypots': {
    pageUrl: '/asm/companies/honeypots',
    metaTitle: `Honeypots | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Honeypots | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/keywords': {
    pageUrl: '/asm/companies/keywords',
    metaTitle: `Keywords | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Keywords | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/lookalikes': {
    pageUrl: '/asm/companies/lookalikes',
    metaTitle: `Lookalikes | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Lookalikes | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/notifications': {
    pageUrl: '/asm/companies/notifications',
    metaTitle: `Notifications | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Notifications | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/technologies': {
    pageUrl: '/asm/companies/technologies',
    metaTitle: `Technologies | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Technologies | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/torrents': {
    pageUrl: '/asm/companies/torrents',
    metaTitle: `Torrents | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Torrents | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/vulnerabilities': {
    pageUrl: '/asm/companies/vulnerabilities',
    metaTitle: `Vulnerabilities | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Vulnerabilities | ${title} | Monitoring | ${NAME}`,
  },
  '/asm/companies/event-log': {
    pageUrl: '/asm/companies/event-log',
    metaTitle: `Event Log | Monitoring | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Event Log  | ${title} | Monitoring | ${NAME}`,
  },
  /**
   * Attack Surface Monitoring - Assets (ASM)
   */
  '/asm/assets': {
    pageUrl: '/asm/assets',
    metaTitle: `All Assets | Attack Surface Monitoring | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/asm/assets/dns': {
    pageUrl: '/asm/assets/dns',
    metaTitle: `DNS | Asset | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) => `DNS | ${title} | Asset | ${NAME}`,
  },
  '/asm/assets/screenshots': {
    pageUrl: '/asm/assets/screenshots',
    metaTitle: `Screenshots | Asset | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) => `Screenshots | ${title} | Asset | ${NAME}`,
  },
  '/asm/assets/vulnerabilities': {
    pageUrl: '/asm/assets/vulnerabilities',
    metaTitle: `Vulnerabilities | Asset | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Vulnerabilities | ${title} | Asset | ${NAME}`,
  },
  '/asm/assets/technologies': {
    pageUrl: '/asm/assets/technologies',
    metaTitle: `Technologies | Asset | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Technologies | ${title} | Asset | ${NAME}`,
  },
  /**
   * Risk Assessment
   */
  '/risk-assessment': {
    pageUrl: '/risk-assessment',
    metaTitle: `Risk Assessment | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/risk-assessment/assets': {
    pageUrl: '/risk-assessment/assets',
    metaTitle: `Assets | Risk Assessment | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Assets | ${title} | Risk Assessment | ${NAME}`,
  },
  '/risk-assessment/data-breaches': {
    pageUrl: '/risk-assessment/data-breaches',
    metaTitle: `Data Breaches | Risk Assessment | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Data Breaches | ${title} | Risk Assessment | ${NAME}`,
  },
  '/risk-assessment/findings': {
    pageUrl: '/risk-assessment/findings',
    metaTitle: `Findings | Risk Assessment | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Findings | ${title} | Risk Assessment | ${NAME}`,
  },
  '/risk-assessment/honeypots': {
    pageUrl: '/risk-assessment/honeypots',
    metaTitle: `Honeypots | Risk Assessment | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Honeypots | ${title} | Risk Assessment | ${NAME}`,
  },
  '/risk-assessment/technologies': {
    pageUrl: '/risk-assessment/technologies',
    metaTitle: `Technologies | Risk Assessment | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Technologies | ${title} | Risk Assessment | ${NAME}`,
  },
  '/risk-assessment/torrents': {
    pageUrl: '/risk-assessment/torrents',
    metaTitle: `Torrents | Risk Assessment | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Torrents | ${title} | Risk Assessment | ${NAME}`,
  },
  /**
   * Security Review
   */
  '/sec-review': {
    pageUrl: '/sec-review',
    metaTitle: `Security Review | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/sec-review/assets': {
    pageUrl: '/sec-review/assets',
    metaTitle: `Assets | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Assets | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/data-breaches': {
    pageUrl: '/sec-review/data-breaches',
    metaTitle: `Data Breaches | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Data Breaches | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/documents/csv': {
    pageUrl: '/sec-review/documents/csv',
    metaTitle: `Documents | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Documents | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/documents/html': {
    pageUrl: '/sec-review/documents/html',
    metaTitle: `Documents | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Documents | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/documents/txt': {
    pageUrl: '/sec-review/documents/txt',
    metaTitle: `Documents | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Documents | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/findings': {
    pageUrl: '/sec-review/findings',
    metaTitle: `Findings | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Findings | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/honeypots': {
    pageUrl: '/sec-review/honeypots',
    metaTitle: `Honeypots | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Honeypots | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/asset-removals': {
    pageUrl: '/sec-review/asset-removals',
    metaTitle: `Asset Removals | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Asset Removals | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/technologies': {
    pageUrl: '/sec-review/technologies',
    metaTitle: `Technologies | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Technologies | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/torrents': {
    pageUrl: '/sec-review/torrents',
    metaTitle: `Torrents | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Torrents | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/vulnerability-resolutions': {
    pageUrl: '/sec-review/vulnerability-resolutions',
    metaTitle: `Vulnerability Resolution | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Vulnerability Resolution | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/vulnerability-resolutions/resolution-request': {
    pageUrl: '/sec-review/vulnerability-resolutions/resolution-request',
    metaTitle: `Vulnerability Resolution | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Vulnerability Resolution | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/vulnerability-resolutions/resolution-request/assets': {
    pageUrl: '/sec-review/vulnerability-resolutions/resolution-request/assets',
    metaTitle: `Vulnerability Resolution | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Vulnerability Resolution | ${title} | Security Review | ${NAME}`,
  },
  '/sec-review/vulnerability-resolutions/resolution-request/details': {
    pageUrl: '/sec-review/vulnerability-resolutions/resolution-request',
    metaTitle: `Vulnerability Resolution | Security Review | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ title }) =>
      `Vulnerability Resolution | ${title} | Security Review | ${NAME}`,
  },
  /**
   * Executive Risks
   */
  '/exec-risks': {
    pageUrl: '/exec-risks',
    metaTitle: `Executive Risks | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/exec-risks/employee-sentiment': {
    pageUrl: '/exec-risks/employee-sentiment',
    metaTitle: `Employee Sentiment | Executive Risks | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/exec-risks/financial-stability': {
    pageUrl: '/exec-risks/financial-stability',
    metaTitle: `Financial Stability | Executive Risks | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/exec-risks/investors': {
    pageUrl: '/exec-risks/investors',
    metaTitle: `Investors | Executive Risks | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/exec-risks/litigation': {
    pageUrl: '/exec-risks/litigation',
    metaTitle: `Litigation | Executive Risks | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/exec-risks/news': {
    pageUrl: '/exec-risks/news',
    metaTitle: `News | Executive Risks | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/exec-risks/regulatory-issues': {
    pageUrl: '/exec-risks/regulatory-issues',
    metaTitle: `Regulatory Issues | Executive Risks | ${NAME}`,
    metaDescription: commonDescription,
  },
  /**
   * Supplementals
   */
  '/supplementals': {
    pageUrl: '/supplementals',
    metaTitle: `Supplementals | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/supplementals/account': {
    pageUrl: '/supplementals/account',
    metaTitle: `Account | Supplementals | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ id }) => `Account | ${id} | Supplementals | ${NAME}`,
  },
  '/supplementals/recipient': {
    pageUrl: '/supplementals/recipient',
    metaTitle: `Recipient | Supplementals | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ email }) =>
      `Recipient | ${email} | Supplementals | ${NAME}`,
  },
  '/supplementals/recipient/logs': {
    pageUrl: '/supplementals/recipient/logs',
    metaTitle: `Recipient | Supplementals | ${NAME}`,
    metaDescription: commonDescription,
    metaTitleDynamic: ({ email }) =>
      `Recipient | ${email} | Supplementals | ${NAME}`,
  },
  '/users': {
    pageUrl: '/users',
    metaTitle: `Users | User Profile | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/users/notifications': {
    pageUrl: '/users/notifications',
    metaTitle: `Users | User Profile | Notifications | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/users/filters': {
    pageUrl: '/users/filters',
    metaTitle: `Users | User Profile | Filters | ${NAME}`,
    metaDescription: commonDescription,
  },
  /**
   * Zero Day Alert
   */

  '/zero-day-alert': {
    pageUrl: '/zero-day-alert',
    metaTitle: `Zero Day Alert | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/zero-day-alert/details': {
    pageUrl: '/zero-day-alert/details',
    metaTitle: `Zero Day Alert | Alert Information | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/zero-day-alert/companies': {
    pageUrl: '/zero-day-alert/details',
    metaTitle: `Zero Day Alert | Impacted Companies | ${NAME}`,
    metaDescription: commonDescription,
  },
  '/zero-day-alert/history': {
    pageUrl: '/zero-day-alert/details',
    metaTitle: `Zero Day Alert | Alert History | ${NAME}`,
    metaDescription: commonDescription,
  },
};
