/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlFrameworkDefinition } from './ControlFrameworkDefinition';
import {
    ControlFrameworkDefinitionFromJSON,
    ControlFrameworkDefinitionFromJSONTyped,
    ControlFrameworkDefinitionToJSON,
} from './ControlFrameworkDefinition';
import type { ControlFrameworkProgress } from './ControlFrameworkProgress';
import {
    ControlFrameworkProgressFromJSON,
    ControlFrameworkProgressFromJSONTyped,
    ControlFrameworkProgressToJSON,
} from './ControlFrameworkProgress';

/**
 * 
 * @export
 * @interface ControlFrameworkSummary
 */
export interface ControlFrameworkSummary {
    /**
     * 
     * @type {ControlFrameworkDefinition}
     * @memberof ControlFrameworkSummary
     */
    definition: ControlFrameworkDefinition;
    /**
     * 
     * @type {ControlFrameworkProgress}
     * @memberof ControlFrameworkSummary
     */
    progress: ControlFrameworkProgress;
}

/**
 * Check if a given object implements the ControlFrameworkSummary interface.
 */
export function instanceOfControlFrameworkSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "definition" in value;
    isInstance = isInstance && "progress" in value;

    return isInstance;
}

export function ControlFrameworkSummaryFromJSON(json: any): ControlFrameworkSummary {
    return ControlFrameworkSummaryFromJSONTyped(json, false);
}

export function ControlFrameworkSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFrameworkSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definition': ControlFrameworkDefinitionFromJSON(json['definition']),
        'progress': ControlFrameworkProgressFromJSON(json['progress']),
    };
}

export function ControlFrameworkSummaryToJSON(value?: ControlFrameworkSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition': ControlFrameworkDefinitionToJSON(value.definition),
        'progress': ControlFrameworkProgressToJSON(value.progress),
    };
}

