/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp
 */
export interface ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp
     */
    person_nm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp
     */
    phone_num?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp
     */
    signature_dt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp
     */
    person_title_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp
     */
    discuss_with_paid_preparer_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpFromJSON(json: any): ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp {
    return ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_nm': !exists(json, 'person_nm') ? undefined : json['person_nm'],
        'phone_num': !exists(json, 'phone_num') ? undefined : json['phone_num'],
        'signature_dt': !exists(json, 'signature_dt') ? undefined : json['signature_dt'],
        'person_title_txt': !exists(json, 'person_title_txt') ? undefined : json['person_title_txt'],
        'discuss_with_paid_preparer_ind': !exists(json, 'discuss_with_paid_preparer_ind') ? undefined : json['discuss_with_paid_preparer_ind'],
    };
}

export function ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrpToJSON(value?: ExecRiskApiAppCoreModelsScansBaseFilingHeaderBusinessOfficerGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_nm': value.person_nm,
        'phone_num': value.phone_num,
        'signature_dt': value.signature_dt,
        'person_title_txt': value.person_title_txt,
        'discuss_with_paid_preparer_ind': value.discuss_with_paid_preparer_ind,
    };
}

