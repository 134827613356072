/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsClassificationsCount } from './JumboStructsClassificationsCount';
import {
    JumboStructsClassificationsCountFromJSON,
    JumboStructsClassificationsCountFromJSONTyped,
    JumboStructsClassificationsCountToJSON,
} from './JumboStructsClassificationsCount';

/**
 * 
 * @export
 * @interface JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult
 */
export interface JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult {
    /**
     * 
     * @type {JumboStructsClassificationsCount}
     * @memberof JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult
     */
    classificationCounts?: JumboStructsClassificationsCount;
    /**
     * 
     * @type {number}
     * @memberof JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult
     */
    resolvedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult
     */
    truePositiveCount?: number;
}

/**
 * Check if a given object implements the JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult interface.
 */
export function instanceOfJumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResultFromJSON(json: any): JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult {
    return JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResultFromJSONTyped(json, false);
}

export function JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classificationCounts': !exists(json, 'classificationCounts') ? undefined : JumboStructsClassificationsCountFromJSON(json['classificationCounts']),
        'resolvedCount': !exists(json, 'resolvedCount') ? undefined : json['resolvedCount'],
        'truePositiveCount': !exists(json, 'truePositiveCount') ? undefined : json['truePositiveCount'],
    };
}

export function JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResultToJSON(value?: JumboGetsentinelonethreatmetricsresolvedResolvedThreatMetricsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classificationCounts': JumboStructsClassificationsCountToJSON(value.classificationCounts),
        'resolvedCount': value.resolvedCount,
        'truePositiveCount': value.truePositiveCount,
    };
}

