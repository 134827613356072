/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlManagedClientRequestCategories } from './ControlManagedClientRequestCategories';
import {
    ControlManagedClientRequestCategoriesFromJSON,
    ControlManagedClientRequestCategoriesFromJSONTyped,
    ControlManagedClientRequestCategoriesToJSON,
} from './ControlManagedClientRequestCategories';

/**
 * 
 * @export
 * @interface ControlAddManagedClientRequest
 */
export interface ControlAddManagedClientRequest {
    /**
     * 
     * @type {ControlManagedClientRequestCategories}
     * @memberof ControlAddManagedClientRequest
     */
    category: ControlManagedClientRequestCategories;
    /**
     * 
     * @type {string}
     * @memberof ControlAddManagedClientRequest
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddManagedClientRequest
     */
    policy_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddManagedClientRequest
     */
    industry: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddManagedClientRequest
     */
    logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddManagedClientRequest
     */
    main_domain: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlAddManagedClientRequest
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlAddManagedClientRequest
     */
    number_of_employees?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAddManagedClientRequest
     */
    company_revenue?: string;
}

/**
 * Check if a given object implements the ControlAddManagedClientRequest interface.
 */
export function instanceOfControlAddManagedClientRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "company_name" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "main_domain" in value;

    return isInstance;
}

export function ControlAddManagedClientRequestFromJSON(json: any): ControlAddManagedClientRequest {
    return ControlAddManagedClientRequestFromJSONTyped(json, false);
}

export function ControlAddManagedClientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAddManagedClientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': ControlManagedClientRequestCategoriesFromJSON(json['category']),
        'company_name': json['company_name'],
        'policy_number': !exists(json, 'policy_number') ? undefined : json['policy_number'],
        'industry': json['industry'],
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'main_domain': json['main_domain'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'number_of_employees': !exists(json, 'number_of_employees') ? undefined : json['number_of_employees'],
        'company_revenue': !exists(json, 'company_revenue') ? undefined : json['company_revenue'],
    };
}

export function ControlAddManagedClientRequestToJSON(value?: ControlAddManagedClientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': ControlManagedClientRequestCategoriesToJSON(value.category),
        'company_name': value.company_name,
        'policy_number': value.policy_number,
        'industry': value.industry,
        'logo_url': value.logo_url,
        'main_domain': value.main_domain,
        'ip_addresses': value.ip_addresses,
        'number_of_employees': value.number_of_employees,
        'company_revenue': value.company_revenue,
    };
}

