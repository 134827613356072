/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmount } from './ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmount';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmountFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmountFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmountToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmount';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocations } from './ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocations';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocationsFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocationsFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocationsToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocations';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleADistributions } from './ExecRiskApiAppCoreModelsScansForm990ScheduleADistributions';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionsFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionsFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionsToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleADistributions';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAll } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAll';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAllFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAllFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAllToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAll';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome } from './ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule } from './ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation } from './ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule } from './ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleA
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleA {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    church_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    school_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    hospital_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    medical_research_organization_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    hospital_name_and_address_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    college_organization_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    governmental_unit_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    public_organization_170_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    community_trust_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    agricultural_research_org_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    agricultural_name_and_address_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddress>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    publicly_supported_org_509_a_2_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    test_public_safety_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supporting_organization_509_a_3_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supporting_org_type_1_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supporting_org_type_2_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supporting_org_type_3_func_int_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supporting_org_type_3_non_func_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    irs_written_determination_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supported_organizations_cnt?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supported_org_information_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformation>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    supported_organizations_total_cnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    support_sum_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    other_support_sum_amt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    gifts_grants_contri_rcvd_170_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    tax_rev_levied_orgnztnl_bnft_170_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    govt_furn_srvc_fclts_vl_170_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    total_calendar_year_170_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    substantial_contributors_tot_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    public_support_total_170_amt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    gross_investment_income_170_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    unrelated_business_net_incm_170_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    other_income_170_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    total_support_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    gross_receipts_rltd_activities_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    first_5_years_170_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    public_support_cy_170_pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    public_support_py_170_pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    thirty_thr_pct_suprt_tests_cy_170_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    thirty_thr_pct_suprt_tests_py_170_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    ten_pct_facts_crcmstncs_test_cy_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    ten_pct_facts_crcmstncs_test_py_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    private_foundation_170_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    gifts_grants_contris_rcvd_509_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    gross_receipts_admissions_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    gross_receipts_non_unrlt_bus_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    tax_rev_levied_orgnztnl_bnft_509_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    govt_furn_srvc_fclts_vl_509_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    total_509_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    amounts_rcvd_dsqlfy_person_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    substantial_contributors_amt_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    subst_and_dsqlfy_prsns_tot_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    public_support_total_509_amt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    gross_investment_income_509_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    post_1975_ubti_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    investment_income_and_ubti_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    net_income_from_other_ubi_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    other_income_509_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    total_support_calendar_year_grp?: Array<ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYear>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    first_5_years_509_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    public_support_cy_509_pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    public_support_py_509_pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    investment_income_cy_pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    investment_income_py_pct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    thirty_thr_pct_suprt_tests_cy_509_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    thirty_thr_pct_suprt_tests_py_509_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    private_foundation_509_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    form_990_sch_a_supporting_org_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrg>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    form_990_sch_a_type_1_suprt_org_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    majority_dir_trst_supported_org_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAll>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    form_990_sch_a_type_3_sprt_org_all_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAll>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    form_990_sch_a_type_3_func_int_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncInt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    trust_integral_part_test_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    adjusted_net_income_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncome>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    minimum_asset_amount_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmount>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmount>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    distributable_amount_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmount>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleADistributions>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    distributions_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleADistributions>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocations>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    distribution_allocations_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocations>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    facts_and_circumstances_test_txt?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    form_990_schedule_a_part_vi_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVI>;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    transfer_schedule?: ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleA
     */
    relationship_schedule?: ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleA interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleA(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleA {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleAFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleA {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'church_ind': !exists(json, 'church_ind') ? undefined : json['church_ind'],
        'school_ind': !exists(json, 'school_ind') ? undefined : json['school_ind'],
        'hospital_ind': !exists(json, 'hospital_ind') ? undefined : json['hospital_ind'],
        'medical_research_organization_ind': !exists(json, 'medical_research_organization_ind') ? undefined : json['medical_research_organization_ind'],
        'hospital_name_and_address_grp': !exists(json, 'hospital_name_and_address_grp') ? undefined : ((json['hospital_name_and_address_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressFromJSON)),
        'college_organization_ind': !exists(json, 'college_organization_ind') ? undefined : json['college_organization_ind'],
        'governmental_unit_ind': !exists(json, 'governmental_unit_ind') ? undefined : json['governmental_unit_ind'],
        'public_organization_170_ind': !exists(json, 'public_organization_170_ind') ? undefined : json['public_organization_170_ind'],
        'community_trust_ind': !exists(json, 'community_trust_ind') ? undefined : json['community_trust_ind'],
        'agricultural_research_org_ind': !exists(json, 'agricultural_research_org_ind') ? undefined : json['agricultural_research_org_ind'],
        'agricultural_name_and_address_grp': !exists(json, 'agricultural_name_and_address_grp') ? undefined : ((json['agricultural_name_and_address_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressFromJSON)),
        'publicly_supported_org_509_a_2_ind': !exists(json, 'publicly_supported_org_509_a_2_ind') ? undefined : json['publicly_supported_org_509_a_2_ind'],
        'test_public_safety_ind': !exists(json, 'test_public_safety_ind') ? undefined : json['test_public_safety_ind'],
        'supporting_organization_509_a_3_ind': !exists(json, 'supporting_organization_509_a_3_ind') ? undefined : json['supporting_organization_509_a_3_ind'],
        'supporting_org_type_1_ind': !exists(json, 'supporting_org_type_1_ind') ? undefined : json['supporting_org_type_1_ind'],
        'supporting_org_type_2_ind': !exists(json, 'supporting_org_type_2_ind') ? undefined : json['supporting_org_type_2_ind'],
        'supporting_org_type_3_func_int_ind': !exists(json, 'supporting_org_type_3_func_int_ind') ? undefined : json['supporting_org_type_3_func_int_ind'],
        'supporting_org_type_3_non_func_ind': !exists(json, 'supporting_org_type_3_non_func_ind') ? undefined : json['supporting_org_type_3_non_func_ind'],
        'irs_written_determination_ind': !exists(json, 'irs_written_determination_ind') ? undefined : json['irs_written_determination_ind'],
        'supported_organizations_cnt': !exists(json, 'supported_organizations_cnt') ? undefined : json['supported_organizations_cnt'],
        'supported_org_information_grp': !exists(json, 'supported_org_information_grp') ? undefined : ((json['supported_org_information_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationFromJSON)),
        'supported_organizations_total_cnt': !exists(json, 'supported_organizations_total_cnt') ? undefined : json['supported_organizations_total_cnt'],
        'support_sum_amt': !exists(json, 'support_sum_amt') ? undefined : json['support_sum_amt'],
        'other_support_sum_amt': !exists(json, 'other_support_sum_amt') ? undefined : json['other_support_sum_amt'],
        'gifts_grants_contri_rcvd_170_grp': !exists(json, 'gifts_grants_contri_rcvd_170_grp') ? undefined : ((json['gifts_grants_contri_rcvd_170_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'tax_rev_levied_orgnztnl_bnft_170_grp': !exists(json, 'tax_rev_levied_orgnztnl_bnft_170_grp') ? undefined : ((json['tax_rev_levied_orgnztnl_bnft_170_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'govt_furn_srvc_fclts_vl_170_grp': !exists(json, 'govt_furn_srvc_fclts_vl_170_grp') ? undefined : ((json['govt_furn_srvc_fclts_vl_170_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'total_calendar_year_170_grp': !exists(json, 'total_calendar_year_170_grp') ? undefined : ((json['total_calendar_year_170_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'substantial_contributors_tot_amt': !exists(json, 'substantial_contributors_tot_amt') ? undefined : json['substantial_contributors_tot_amt'],
        'public_support_total_170_amt': !exists(json, 'public_support_total_170_amt') ? undefined : json['public_support_total_170_amt'],
        'gross_investment_income_170_grp': !exists(json, 'gross_investment_income_170_grp') ? undefined : ((json['gross_investment_income_170_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'unrelated_business_net_incm_170_grp': !exists(json, 'unrelated_business_net_incm_170_grp') ? undefined : ((json['unrelated_business_net_incm_170_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'other_income_170_grp': !exists(json, 'other_income_170_grp') ? undefined : ((json['other_income_170_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'total_support_amt': !exists(json, 'total_support_amt') ? undefined : json['total_support_amt'],
        'gross_receipts_rltd_activities_amt': !exists(json, 'gross_receipts_rltd_activities_amt') ? undefined : json['gross_receipts_rltd_activities_amt'],
        'first_5_years_170_ind': !exists(json, 'first_5_years_170_ind') ? undefined : json['first_5_years_170_ind'],
        'public_support_cy_170_pct': !exists(json, 'public_support_cy_170_pct') ? undefined : json['public_support_cy_170_pct'],
        'public_support_py_170_pct': !exists(json, 'public_support_py_170_pct') ? undefined : json['public_support_py_170_pct'],
        'thirty_thr_pct_suprt_tests_cy_170_ind': !exists(json, 'thirty_thr_pct_suprt_tests_cy_170_ind') ? undefined : json['thirty_thr_pct_suprt_tests_cy_170_ind'],
        'thirty_thr_pct_suprt_tests_py_170_ind': !exists(json, 'thirty_thr_pct_suprt_tests_py_170_ind') ? undefined : json['thirty_thr_pct_suprt_tests_py_170_ind'],
        'ten_pct_facts_crcmstncs_test_cy_ind': !exists(json, 'ten_pct_facts_crcmstncs_test_cy_ind') ? undefined : json['ten_pct_facts_crcmstncs_test_cy_ind'],
        'ten_pct_facts_crcmstncs_test_py_ind': !exists(json, 'ten_pct_facts_crcmstncs_test_py_ind') ? undefined : json['ten_pct_facts_crcmstncs_test_py_ind'],
        'private_foundation_170_ind': !exists(json, 'private_foundation_170_ind') ? undefined : json['private_foundation_170_ind'],
        'gifts_grants_contris_rcvd_509_grp': !exists(json, 'gifts_grants_contris_rcvd_509_grp') ? undefined : ((json['gifts_grants_contris_rcvd_509_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'gross_receipts_admissions_grp': !exists(json, 'gross_receipts_admissions_grp') ? undefined : ((json['gross_receipts_admissions_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'gross_receipts_non_unrlt_bus_grp': !exists(json, 'gross_receipts_non_unrlt_bus_grp') ? undefined : ((json['gross_receipts_non_unrlt_bus_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'tax_rev_levied_orgnztnl_bnft_509_grp': !exists(json, 'tax_rev_levied_orgnztnl_bnft_509_grp') ? undefined : ((json['tax_rev_levied_orgnztnl_bnft_509_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'govt_furn_srvc_fclts_vl_509_grp': !exists(json, 'govt_furn_srvc_fclts_vl_509_grp') ? undefined : ((json['govt_furn_srvc_fclts_vl_509_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'total_509_grp': !exists(json, 'total_509_grp') ? undefined : ((json['total_509_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'amounts_rcvd_dsqlfy_person_grp': !exists(json, 'amounts_rcvd_dsqlfy_person_grp') ? undefined : ((json['amounts_rcvd_dsqlfy_person_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'substantial_contributors_amt_grp': !exists(json, 'substantial_contributors_amt_grp') ? undefined : ((json['substantial_contributors_amt_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'subst_and_dsqlfy_prsns_tot_grp': !exists(json, 'subst_and_dsqlfy_prsns_tot_grp') ? undefined : ((json['subst_and_dsqlfy_prsns_tot_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'public_support_total_509_amt': !exists(json, 'public_support_total_509_amt') ? undefined : json['public_support_total_509_amt'],
        'gross_investment_income_509_grp': !exists(json, 'gross_investment_income_509_grp') ? undefined : ((json['gross_investment_income_509_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'post_1975_ubti_grp': !exists(json, 'post_1975_ubti_grp') ? undefined : ((json['post_1975_ubti_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'investment_income_and_ubti_grp': !exists(json, 'investment_income_and_ubti_grp') ? undefined : ((json['investment_income_and_ubti_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'net_income_from_other_ubi_grp': !exists(json, 'net_income_from_other_ubi_grp') ? undefined : ((json['net_income_from_other_ubi_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'other_income_509_grp': !exists(json, 'other_income_509_grp') ? undefined : ((json['other_income_509_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'total_support_calendar_year_grp': !exists(json, 'total_support_calendar_year_grp') ? undefined : ((json['total_support_calendar_year_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearFromJSON)),
        'first_5_years_509_ind': !exists(json, 'first_5_years_509_ind') ? undefined : json['first_5_years_509_ind'],
        'public_support_cy_509_pct': !exists(json, 'public_support_cy_509_pct') ? undefined : json['public_support_cy_509_pct'],
        'public_support_py_509_pct': !exists(json, 'public_support_py_509_pct') ? undefined : json['public_support_py_509_pct'],
        'investment_income_cy_pct': !exists(json, 'investment_income_cy_pct') ? undefined : json['investment_income_cy_pct'],
        'investment_income_py_pct': !exists(json, 'investment_income_py_pct') ? undefined : json['investment_income_py_pct'],
        'thirty_thr_pct_suprt_tests_cy_509_ind': !exists(json, 'thirty_thr_pct_suprt_tests_cy_509_ind') ? undefined : json['thirty_thr_pct_suprt_tests_cy_509_ind'],
        'thirty_thr_pct_suprt_tests_py_509_ind': !exists(json, 'thirty_thr_pct_suprt_tests_py_509_ind') ? undefined : json['thirty_thr_pct_suprt_tests_py_509_ind'],
        'private_foundation_509_ind': !exists(json, 'private_foundation_509_ind') ? undefined : json['private_foundation_509_ind'],
        'form_990_sch_a_supporting_org_grp': !exists(json, 'form_990_sch_a_supporting_org_grp') ? undefined : ((json['form_990_sch_a_supporting_org_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgFromJSON)),
        'form_990_sch_a_type_1_suprt_org_grp': !exists(json, 'form_990_sch_a_type_1_suprt_org_grp') ? undefined : ((json['form_990_sch_a_type_1_suprt_org_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgFromJSON)),
        'majority_dir_trst_supported_org_ind': !exists(json, 'majority_dir_trst_supported_org_ind') ? undefined : json['majority_dir_trst_supported_org_ind'],
        'form_990_sch_a_type_3_sprt_org_all_grp': !exists(json, 'form_990_sch_a_type_3_sprt_org_all_grp') ? undefined : ((json['form_990_sch_a_type_3_sprt_org_all_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAllFromJSON)),
        'form_990_sch_a_type_3_func_int_grp': !exists(json, 'form_990_sch_a_type_3_func_int_grp') ? undefined : ((json['form_990_sch_a_type_3_func_int_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntFromJSON)),
        'trust_integral_part_test_ind': !exists(json, 'trust_integral_part_test_ind') ? undefined : json['trust_integral_part_test_ind'],
        'adjusted_net_income_grp': !exists(json, 'adjusted_net_income_grp') ? undefined : ((json['adjusted_net_income_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSON)),
        'minimum_asset_amount_grp': !exists(json, 'minimum_asset_amount_grp') ? undefined : ((json['minimum_asset_amount_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountFromJSON)),
        'distributable_amount_grp': !exists(json, 'distributable_amount_grp') ? undefined : ((json['distributable_amount_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmountFromJSON)),
        'distributions_grp': !exists(json, 'distributions_grp') ? undefined : ((json['distributions_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionsFromJSON)),
        'distribution_allocations_grp': !exists(json, 'distribution_allocations_grp') ? undefined : ((json['distribution_allocations_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocationsFromJSON)),
        'facts_and_circumstances_test_txt': !exists(json, 'facts_and_circumstances_test_txt') ? undefined : json['facts_and_circumstances_test_txt'],
        'form_990_schedule_a_part_vi_grp': !exists(json, 'form_990_schedule_a_part_vi_grp') ? undefined : ((json['form_990_schedule_a_part_vi_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIFromJSON)),
        'transfer_schedule': !exists(json, 'transfer_schedule') ? undefined : ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleFromJSON(json['transfer_schedule']),
        'relationship_schedule': !exists(json, 'relationship_schedule') ? undefined : ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSON(json['relationship_schedule']),
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleA | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'church_ind': value.church_ind,
        'school_ind': value.school_ind,
        'hospital_ind': value.hospital_ind,
        'medical_research_organization_ind': value.medical_research_organization_ind,
        'hospital_name_and_address_grp': value.hospital_name_and_address_grp === undefined ? undefined : ((value.hospital_name_and_address_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressToJSON)),
        'college_organization_ind': value.college_organization_ind,
        'governmental_unit_ind': value.governmental_unit_ind,
        'public_organization_170_ind': value.public_organization_170_ind,
        'community_trust_ind': value.community_trust_ind,
        'agricultural_research_org_ind': value.agricultural_research_org_ind,
        'agricultural_name_and_address_grp': value.agricultural_name_and_address_grp === undefined ? undefined : ((value.agricultural_name_and_address_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAAgriculturalNameAndAddressToJSON)),
        'publicly_supported_org_509_a_2_ind': value.publicly_supported_org_509_a_2_ind,
        'test_public_safety_ind': value.test_public_safety_ind,
        'supporting_organization_509_a_3_ind': value.supporting_organization_509_a_3_ind,
        'supporting_org_type_1_ind': value.supporting_org_type_1_ind,
        'supporting_org_type_2_ind': value.supporting_org_type_2_ind,
        'supporting_org_type_3_func_int_ind': value.supporting_org_type_3_func_int_ind,
        'supporting_org_type_3_non_func_ind': value.supporting_org_type_3_non_func_ind,
        'irs_written_determination_ind': value.irs_written_determination_ind,
        'supported_organizations_cnt': value.supported_organizations_cnt,
        'supported_org_information_grp': value.supported_org_information_grp === undefined ? undefined : ((value.supported_org_information_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleASupportedOrgInformationToJSON)),
        'supported_organizations_total_cnt': value.supported_organizations_total_cnt,
        'support_sum_amt': value.support_sum_amt,
        'other_support_sum_amt': value.other_support_sum_amt,
        'gifts_grants_contri_rcvd_170_grp': value.gifts_grants_contri_rcvd_170_grp === undefined ? undefined : ((value.gifts_grants_contri_rcvd_170_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'tax_rev_levied_orgnztnl_bnft_170_grp': value.tax_rev_levied_orgnztnl_bnft_170_grp === undefined ? undefined : ((value.tax_rev_levied_orgnztnl_bnft_170_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'govt_furn_srvc_fclts_vl_170_grp': value.govt_furn_srvc_fclts_vl_170_grp === undefined ? undefined : ((value.govt_furn_srvc_fclts_vl_170_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'total_calendar_year_170_grp': value.total_calendar_year_170_grp === undefined ? undefined : ((value.total_calendar_year_170_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'substantial_contributors_tot_amt': value.substantial_contributors_tot_amt,
        'public_support_total_170_amt': value.public_support_total_170_amt,
        'gross_investment_income_170_grp': value.gross_investment_income_170_grp === undefined ? undefined : ((value.gross_investment_income_170_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'unrelated_business_net_incm_170_grp': value.unrelated_business_net_incm_170_grp === undefined ? undefined : ((value.unrelated_business_net_incm_170_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'other_income_170_grp': value.other_income_170_grp === undefined ? undefined : ((value.other_income_170_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'total_support_amt': value.total_support_amt,
        'gross_receipts_rltd_activities_amt': value.gross_receipts_rltd_activities_amt,
        'first_5_years_170_ind': value.first_5_years_170_ind,
        'public_support_cy_170_pct': value.public_support_cy_170_pct,
        'public_support_py_170_pct': value.public_support_py_170_pct,
        'thirty_thr_pct_suprt_tests_cy_170_ind': value.thirty_thr_pct_suprt_tests_cy_170_ind,
        'thirty_thr_pct_suprt_tests_py_170_ind': value.thirty_thr_pct_suprt_tests_py_170_ind,
        'ten_pct_facts_crcmstncs_test_cy_ind': value.ten_pct_facts_crcmstncs_test_cy_ind,
        'ten_pct_facts_crcmstncs_test_py_ind': value.ten_pct_facts_crcmstncs_test_py_ind,
        'private_foundation_170_ind': value.private_foundation_170_ind,
        'gifts_grants_contris_rcvd_509_grp': value.gifts_grants_contris_rcvd_509_grp === undefined ? undefined : ((value.gifts_grants_contris_rcvd_509_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'gross_receipts_admissions_grp': value.gross_receipts_admissions_grp === undefined ? undefined : ((value.gross_receipts_admissions_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'gross_receipts_non_unrlt_bus_grp': value.gross_receipts_non_unrlt_bus_grp === undefined ? undefined : ((value.gross_receipts_non_unrlt_bus_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'tax_rev_levied_orgnztnl_bnft_509_grp': value.tax_rev_levied_orgnztnl_bnft_509_grp === undefined ? undefined : ((value.tax_rev_levied_orgnztnl_bnft_509_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'govt_furn_srvc_fclts_vl_509_grp': value.govt_furn_srvc_fclts_vl_509_grp === undefined ? undefined : ((value.govt_furn_srvc_fclts_vl_509_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'total_509_grp': value.total_509_grp === undefined ? undefined : ((value.total_509_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'amounts_rcvd_dsqlfy_person_grp': value.amounts_rcvd_dsqlfy_person_grp === undefined ? undefined : ((value.amounts_rcvd_dsqlfy_person_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'substantial_contributors_amt_grp': value.substantial_contributors_amt_grp === undefined ? undefined : ((value.substantial_contributors_amt_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'subst_and_dsqlfy_prsns_tot_grp': value.subst_and_dsqlfy_prsns_tot_grp === undefined ? undefined : ((value.subst_and_dsqlfy_prsns_tot_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'public_support_total_509_amt': value.public_support_total_509_amt,
        'gross_investment_income_509_grp': value.gross_investment_income_509_grp === undefined ? undefined : ((value.gross_investment_income_509_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'post_1975_ubti_grp': value.post_1975_ubti_grp === undefined ? undefined : ((value.post_1975_ubti_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'investment_income_and_ubti_grp': value.investment_income_and_ubti_grp === undefined ? undefined : ((value.investment_income_and_ubti_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'net_income_from_other_ubi_grp': value.net_income_from_other_ubi_grp === undefined ? undefined : ((value.net_income_from_other_ubi_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'other_income_509_grp': value.other_income_509_grp === undefined ? undefined : ((value.other_income_509_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'total_support_calendar_year_grp': value.total_support_calendar_year_grp === undefined ? undefined : ((value.total_support_calendar_year_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990CommonTypesCurrentTaxYearToJSON)),
        'first_5_years_509_ind': value.first_5_years_509_ind,
        'public_support_cy_509_pct': value.public_support_cy_509_pct,
        'public_support_py_509_pct': value.public_support_py_509_pct,
        'investment_income_cy_pct': value.investment_income_cy_pct,
        'investment_income_py_pct': value.investment_income_py_pct,
        'thirty_thr_pct_suprt_tests_cy_509_ind': value.thirty_thr_pct_suprt_tests_cy_509_ind,
        'thirty_thr_pct_suprt_tests_py_509_ind': value.thirty_thr_pct_suprt_tests_py_509_ind,
        'private_foundation_509_ind': value.private_foundation_509_ind,
        'form_990_sch_a_supporting_org_grp': value.form_990_sch_a_supporting_org_grp === undefined ? undefined : ((value.form_990_sch_a_supporting_org_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchASupportingOrgToJSON)),
        'form_990_sch_a_type_1_suprt_org_grp': value.form_990_sch_a_type_1_suprt_org_grp === undefined ? undefined : ((value.form_990_sch_a_type_1_suprt_org_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgToJSON)),
        'majority_dir_trst_supported_org_ind': value.majority_dir_trst_supported_org_ind,
        'form_990_sch_a_type_3_sprt_org_all_grp': value.form_990_sch_a_type_3_sprt_org_all_grp === undefined ? undefined : ((value.form_990_sch_a_type_3_sprt_org_all_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3SprtOrgAllToJSON)),
        'form_990_sch_a_type_3_func_int_grp': value.form_990_sch_a_type_3_func_int_grp === undefined ? undefined : ((value.form_990_sch_a_type_3_func_int_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType3FuncIntToJSON)),
        'trust_integral_part_test_ind': value.trust_integral_part_test_ind,
        'adjusted_net_income_grp': value.adjusted_net_income_grp === undefined ? undefined : ((value.adjusted_net_income_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeToJSON)),
        'minimum_asset_amount_grp': value.minimum_asset_amount_grp === undefined ? undefined : ((value.minimum_asset_amount_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAMinimumAssetAmountToJSON)),
        'distributable_amount_grp': value.distributable_amount_grp === undefined ? undefined : ((value.distributable_amount_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleADistributableAmountToJSON)),
        'distributions_grp': value.distributions_grp === undefined ? undefined : ((value.distributions_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionsToJSON)),
        'distribution_allocations_grp': value.distribution_allocations_grp === undefined ? undefined : ((value.distribution_allocations_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleADistributionAllocationsToJSON)),
        'facts_and_circumstances_test_txt': value.facts_and_circumstances_test_txt,
        'form_990_schedule_a_part_vi_grp': value.form_990_schedule_a_part_vi_grp === undefined ? undefined : ((value.form_990_schedule_a_part_vi_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990ScheduleAPartVIToJSON)),
        'transfer_schedule': ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleToJSON(value.transfer_schedule),
        'relationship_schedule': ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleToJSON(value.relationship_schedule),
    };
}

