/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitISPProviderResult
 */
export interface MonitISPProviderResult {
    /**
     * 
     * @type {number}
     * @memberof MonitISPProviderResult
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitISPProviderResult
     */
    provider?: string;
}

/**
 * Check if a given object implements the MonitISPProviderResult interface.
 */
export function instanceOfMonitISPProviderResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitISPProviderResultFromJSON(json: any): MonitISPProviderResult {
    return MonitISPProviderResultFromJSONTyped(json, false);
}

export function MonitISPProviderResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitISPProviderResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
    };
}

export function MonitISPProviderResultToJSON(value?: MonitISPProviderResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'provider': value.provider,
    };
}

