/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlZeroDayAssociationEntryResult } from './ControlZeroDayAssociationEntryResult';
import {
    ControlZeroDayAssociationEntryResultFromJSON,
    ControlZeroDayAssociationEntryResultFromJSONTyped,
    ControlZeroDayAssociationEntryResultToJSON,
} from './ControlZeroDayAssociationEntryResult';

/**
 * 
 * @export
 * @interface ControlAssociateClientsWithZeroDayResponse
 */
export interface ControlAssociateClientsWithZeroDayResponse {
    /**
     * 
     * @type {Array<ControlZeroDayAssociationEntryResult>}
     * @memberof ControlAssociateClientsWithZeroDayResponse
     */
    results?: Array<ControlZeroDayAssociationEntryResult>;
    /**
     * 
     * @type {number}
     * @memberof ControlAssociateClientsWithZeroDayResponse
     */
    uploaded_clients?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlAssociateClientsWithZeroDayResponse
     */
    invalid_entities?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlAssociateClientsWithZeroDayResponse
     */
    total_entities?: number;
}

/**
 * Check if a given object implements the ControlAssociateClientsWithZeroDayResponse interface.
 */
export function instanceOfControlAssociateClientsWithZeroDayResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlAssociateClientsWithZeroDayResponseFromJSON(json: any): ControlAssociateClientsWithZeroDayResponse {
    return ControlAssociateClientsWithZeroDayResponseFromJSONTyped(json, false);
}

export function ControlAssociateClientsWithZeroDayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAssociateClientsWithZeroDayResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlZeroDayAssociationEntryResultFromJSON)),
        'uploaded_clients': !exists(json, 'uploaded_clients') ? undefined : json['uploaded_clients'],
        'invalid_entities': !exists(json, 'invalid_entities') ? undefined : json['invalid_entities'],
        'total_entities': !exists(json, 'total_entities') ? undefined : json['total_entities'],
    };
}

export function ControlAssociateClientsWithZeroDayResponseToJSON(value?: ControlAssociateClientsWithZeroDayResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlZeroDayAssociationEntryResultToJSON)),
        'uploaded_clients': value.uploaded_clients,
        'invalid_entities': value.invalid_entities,
        'total_entities': value.total_entities,
    };
}

