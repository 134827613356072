/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntities } from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntities';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntitiesFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntitiesFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntitiesToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntities';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr } from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership } from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg } from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetail } from './ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetail';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetailFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetailFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetailToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetail';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg } from './ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg';
import type { ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership } from './ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership';
import {
    ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSON,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleR
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleR {
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntities>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    id_disregarded_entities_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntities>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    id_related_tax_exempt_org_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrg>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    id_related_org_txbl_partnership_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    id_related_org_txbl_corp_tr_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTr>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    receipt_of_int_annts_rnts_rylts_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    gift_grnt_or_cap_contri_to_oth_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    gift_grnt_cap_contri_from_oth_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    loans_or_guarantees_to_other_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    loans_or_guarantees_from_oth_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    div_related_organization_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    asset_sale_to_other_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    asset_purchase_from_other_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    asset_exchange_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    rental_of_facilities_to_oth_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    rental_of_fclts_from_oth_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    perform_of_services_for_oth_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    perform_of_services_by_other_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    sharing_of_facilities_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    paid_employees_sharing_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    reimbursement_paid_to_other_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    reimbursement_paid_by_other_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    transfer_to_other_org_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    transfer_from_other_org_ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    transactions_related_org_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrg>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    unrelated_org_txbl_partnership_grp?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnership>;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetail>}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleR
     */
    supplemental_information_detail?: Array<ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetail>;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleR interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleR(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleR {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleRFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleR {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id_disregarded_entities_grp': !exists(json, 'id_disregarded_entities_grp') ? undefined : ((json['id_disregarded_entities_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntitiesFromJSON)),
        'id_related_tax_exempt_org_grp': !exists(json, 'id_related_tax_exempt_org_grp') ? undefined : ((json['id_related_tax_exempt_org_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgFromJSON)),
        'id_related_org_txbl_partnership_grp': !exists(json, 'id_related_org_txbl_partnership_grp') ? undefined : ((json['id_related_org_txbl_partnership_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSON)),
        'id_related_org_txbl_corp_tr_grp': !exists(json, 'id_related_org_txbl_corp_tr_grp') ? undefined : ((json['id_related_org_txbl_corp_tr_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSON)),
        'receipt_of_int_annts_rnts_rylts_ind': !exists(json, 'receipt_of_int_annts_rnts_rylts_ind') ? undefined : json['receipt_of_int_annts_rnts_rylts_ind'],
        'gift_grnt_or_cap_contri_to_oth_org_ind': !exists(json, 'gift_grnt_or_cap_contri_to_oth_org_ind') ? undefined : json['gift_grnt_or_cap_contri_to_oth_org_ind'],
        'gift_grnt_cap_contri_from_oth_org_ind': !exists(json, 'gift_grnt_cap_contri_from_oth_org_ind') ? undefined : json['gift_grnt_cap_contri_from_oth_org_ind'],
        'loans_or_guarantees_to_other_org_ind': !exists(json, 'loans_or_guarantees_to_other_org_ind') ? undefined : json['loans_or_guarantees_to_other_org_ind'],
        'loans_or_guarantees_from_oth_org_ind': !exists(json, 'loans_or_guarantees_from_oth_org_ind') ? undefined : json['loans_or_guarantees_from_oth_org_ind'],
        'div_related_organization_ind': !exists(json, 'div_related_organization_ind') ? undefined : json['div_related_organization_ind'],
        'asset_sale_to_other_org_ind': !exists(json, 'asset_sale_to_other_org_ind') ? undefined : json['asset_sale_to_other_org_ind'],
        'asset_purchase_from_other_org_ind': !exists(json, 'asset_purchase_from_other_org_ind') ? undefined : json['asset_purchase_from_other_org_ind'],
        'asset_exchange_ind': !exists(json, 'asset_exchange_ind') ? undefined : json['asset_exchange_ind'],
        'rental_of_facilities_to_oth_org_ind': !exists(json, 'rental_of_facilities_to_oth_org_ind') ? undefined : json['rental_of_facilities_to_oth_org_ind'],
        'rental_of_fclts_from_oth_org_ind': !exists(json, 'rental_of_fclts_from_oth_org_ind') ? undefined : json['rental_of_fclts_from_oth_org_ind'],
        'perform_of_services_for_oth_org_ind': !exists(json, 'perform_of_services_for_oth_org_ind') ? undefined : json['perform_of_services_for_oth_org_ind'],
        'perform_of_services_by_other_org_ind': !exists(json, 'perform_of_services_by_other_org_ind') ? undefined : json['perform_of_services_by_other_org_ind'],
        'sharing_of_facilities_ind': !exists(json, 'sharing_of_facilities_ind') ? undefined : json['sharing_of_facilities_ind'],
        'paid_employees_sharing_ind': !exists(json, 'paid_employees_sharing_ind') ? undefined : json['paid_employees_sharing_ind'],
        'reimbursement_paid_to_other_org_ind': !exists(json, 'reimbursement_paid_to_other_org_ind') ? undefined : json['reimbursement_paid_to_other_org_ind'],
        'reimbursement_paid_by_other_org_ind': !exists(json, 'reimbursement_paid_by_other_org_ind') ? undefined : json['reimbursement_paid_by_other_org_ind'],
        'transfer_to_other_org_ind': !exists(json, 'transfer_to_other_org_ind') ? undefined : json['transfer_to_other_org_ind'],
        'transfer_from_other_org_ind': !exists(json, 'transfer_from_other_org_ind') ? undefined : json['transfer_from_other_org_ind'],
        'transactions_related_org_grp': !exists(json, 'transactions_related_org_grp') ? undefined : ((json['transactions_related_org_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgFromJSON)),
        'unrelated_org_txbl_partnership_grp': !exists(json, 'unrelated_org_txbl_partnership_grp') ? undefined : ((json['unrelated_org_txbl_partnership_grp'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipFromJSON)),
        'supplemental_information_detail': !exists(json, 'supplemental_information_detail') ? undefined : ((json['supplemental_information_detail'] as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetailFromJSON)),
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleR | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id_disregarded_entities_grp': value.id_disregarded_entities_grp === undefined ? undefined : ((value.id_disregarded_entities_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdDisregardedEntitiesToJSON)),
        'id_related_tax_exempt_org_grp': value.id_related_tax_exempt_org_grp === undefined ? undefined : ((value.id_related_tax_exempt_org_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedTaxExemptOrgToJSON)),
        'id_related_org_txbl_partnership_grp': value.id_related_org_txbl_partnership_grp === undefined ? undefined : ((value.id_related_org_txbl_partnership_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipToJSON)),
        'id_related_org_txbl_corp_tr_grp': value.id_related_org_txbl_corp_tr_grp === undefined ? undefined : ((value.id_related_org_txbl_corp_tr_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblCorpTrToJSON)),
        'receipt_of_int_annts_rnts_rylts_ind': value.receipt_of_int_annts_rnts_rylts_ind,
        'gift_grnt_or_cap_contri_to_oth_org_ind': value.gift_grnt_or_cap_contri_to_oth_org_ind,
        'gift_grnt_cap_contri_from_oth_org_ind': value.gift_grnt_cap_contri_from_oth_org_ind,
        'loans_or_guarantees_to_other_org_ind': value.loans_or_guarantees_to_other_org_ind,
        'loans_or_guarantees_from_oth_org_ind': value.loans_or_guarantees_from_oth_org_ind,
        'div_related_organization_ind': value.div_related_organization_ind,
        'asset_sale_to_other_org_ind': value.asset_sale_to_other_org_ind,
        'asset_purchase_from_other_org_ind': value.asset_purchase_from_other_org_ind,
        'asset_exchange_ind': value.asset_exchange_ind,
        'rental_of_facilities_to_oth_org_ind': value.rental_of_facilities_to_oth_org_ind,
        'rental_of_fclts_from_oth_org_ind': value.rental_of_fclts_from_oth_org_ind,
        'perform_of_services_for_oth_org_ind': value.perform_of_services_for_oth_org_ind,
        'perform_of_services_by_other_org_ind': value.perform_of_services_by_other_org_ind,
        'sharing_of_facilities_ind': value.sharing_of_facilities_ind,
        'paid_employees_sharing_ind': value.paid_employees_sharing_ind,
        'reimbursement_paid_to_other_org_ind': value.reimbursement_paid_to_other_org_ind,
        'reimbursement_paid_by_other_org_ind': value.reimbursement_paid_by_other_org_ind,
        'transfer_to_other_org_ind': value.transfer_to_other_org_ind,
        'transfer_from_other_org_ind': value.transfer_from_other_org_ind,
        'transactions_related_org_grp': value.transactions_related_org_grp === undefined ? undefined : ((value.transactions_related_org_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRTransactionsRelatedOrgToJSON)),
        'unrelated_org_txbl_partnership_grp': value.unrelated_org_txbl_partnership_grp === undefined ? undefined : ((value.unrelated_org_txbl_partnership_grp as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRUnrelatedOrgTxblPartnershipToJSON)),
        'supplemental_information_detail': value.supplemental_information_detail === undefined ? undefined : ((value.supplemental_information_detail as Array<any>).map(ExecRiskApiAppCoreModelsScansForm990ScheduleRSupplementalInformationDetailToJSON)),
    };
}

