/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFSecReviewInsuranceEnv } from './CRSFSecReviewInsuranceEnv';
import {
    CRSFSecReviewInsuranceEnvFromJSON,
    CRSFSecReviewInsuranceEnvFromJSONTyped,
    CRSFSecReviewInsuranceEnvToJSON,
} from './CRSFSecReviewInsuranceEnv';
import type { CRSFSecReviewLocale } from './CRSFSecReviewLocale';
import {
    CRSFSecReviewLocaleFromJSON,
    CRSFSecReviewLocaleFromJSONTyped,
    CRSFSecReviewLocaleToJSON,
} from './CRSFSecReviewLocale';

/**
 * Model contains only data to trigger underwriting (quick_scan) process with domains.
 * Clienst should not trigger svcan for policies with no domains or use this API for renewals (fetching ASM data)
 * @export
 * @interface CRSFSecReviewTriggerUWRequest
 */
export interface CRSFSecReviewTriggerUWRequest {
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    account_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    domains: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    extended_aggr_timeout?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    external_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFSecReviewInsuranceEnv}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    insurance_env?: CRSFSecReviewInsuranceEnv;
    /**
     * 
     * @type {CRSFSecReviewLocale}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    locale?: CRSFSecReviewLocale;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    quote_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSecReviewTriggerUWRequest
     */
    retained_from_uuid?: string;
}

/**
 * Check if a given object implements the CRSFSecReviewTriggerUWRequest interface.
 */
export function instanceOfCRSFSecReviewTriggerUWRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;

    return isInstance;
}

export function CRSFSecReviewTriggerUWRequestFromJSON(json: any): CRSFSecReviewTriggerUWRequest {
    return CRSFSecReviewTriggerUWRequestFromJSONTyped(json, false);
}

export function CRSFSecReviewTriggerUWRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSecReviewTriggerUWRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'domains': json['domains'],
        'extended_aggr_timeout': !exists(json, 'extended_aggr_timeout') ? undefined : json['extended_aggr_timeout'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFSecReviewInsuranceEnvFromJSON(json['insurance_env']),
        'locale': !exists(json, 'locale') ? undefined : CRSFSecReviewLocaleFromJSON(json['locale']),
        'quote_id': !exists(json, 'quote_id') ? undefined : json['quote_id'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
    };
}

export function CRSFSecReviewTriggerUWRequestToJSON(value?: CRSFSecReviewTriggerUWRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'domains': value.domains,
        'extended_aggr_timeout': value.extended_aggr_timeout,
        'external_id': value.external_id,
        'force': value.force,
        'insurance_env': CRSFSecReviewInsuranceEnvToJSON(value.insurance_env),
        'locale': CRSFSecReviewLocaleToJSON(value.locale),
        'quote_id': value.quote_id,
        'renewal_of_uuid': value.renewal_of_uuid,
        'retained_from_uuid': value.retained_from_uuid,
    };
}

