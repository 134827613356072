/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    ContractorName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractor;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    ContractorAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    ServicesDesc?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation
     */
    CompensationAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ContractorName': !exists(json, 'ContractorName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorFromJSON(json['ContractorName']),
        'ContractorAddress': !exists(json, 'ContractorAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressFromJSON(json['ContractorAddress']),
        'ServicesDesc': !exists(json, 'ServicesDesc') ? undefined : json['ServicesDesc'],
        'CompensationAmt': !exists(json, 'CompensationAmt') ? undefined : json['CompensationAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ContractorName': ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorToJSON(value.ContractorName),
        'ContractorAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationContractorAddressToJSON(value.ContractorAddress),
        'ServicesDesc': value.ServicesDesc,
        'CompensationAmt': value.CompensationAmt,
    };
}

