/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlPrePurchaseProductSpecification } from './ControlPrePurchaseProductSpecification';
import {
    ControlPrePurchaseProductSpecificationFromJSON,
    ControlPrePurchaseProductSpecificationFromJSONTyped,
    ControlPrePurchaseProductSpecificationToJSON,
} from './ControlPrePurchaseProductSpecification';
import type { ControlPrePurchaseProducts } from './ControlPrePurchaseProducts';
import {
    ControlPrePurchaseProductsFromJSON,
    ControlPrePurchaseProductsFromJSONTyped,
    ControlPrePurchaseProductsToJSON,
} from './ControlPrePurchaseProducts';

/**
 * 
 * @export
 * @interface ControlPrePurchaseInformationResponse
 */
export interface ControlPrePurchaseInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof ControlPrePurchaseInformationResponse
     */
    policy_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPrePurchaseInformationResponse
     */
    insurance_security_sku: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPrePurchaseInformationResponse
     */
    broker_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPrePurchaseInformationResponse
     */
    agency_id: string;
    /**
     * 
     * @type {Array<ControlPrePurchaseProductSpecification>}
     * @memberof ControlPrePurchaseInformationResponse
     */
    stripe_product_specification: Array<ControlPrePurchaseProductSpecification>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlPrePurchaseInformationResponse
     */
    direct_to_sales: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlPrePurchaseInformationResponse
     */
    price_target_currency: ControlPrePurchaseInformationResponsePriceTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlPrePurchaseInformationResponse
     */
    presented_price?: string;
    /**
     * 
     * @type {Array<ControlPrePurchaseProducts>}
     * @memberof ControlPrePurchaseInformationResponse
     */
    pre_purchase_products: Array<ControlPrePurchaseProducts>;
    /**
     * 
     * @type {number}
     * @memberof ControlPrePurchaseInformationResponse
     */
    pre_tax_total_price: number;
}


/**
 * @export
 */
export const ControlPrePurchaseInformationResponsePriceTargetCurrencyEnum = {
    Usd: 'usd',
    Cad: 'cad'
} as const;
export type ControlPrePurchaseInformationResponsePriceTargetCurrencyEnum = typeof ControlPrePurchaseInformationResponsePriceTargetCurrencyEnum[keyof typeof ControlPrePurchaseInformationResponsePriceTargetCurrencyEnum];


/**
 * Check if a given object implements the ControlPrePurchaseInformationResponse interface.
 */
export function instanceOfControlPrePurchaseInformationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "policy_id" in value;
    isInstance = isInstance && "insurance_security_sku" in value;
    isInstance = isInstance && "broker_id" in value;
    isInstance = isInstance && "agency_id" in value;
    isInstance = isInstance && "stripe_product_specification" in value;
    isInstance = isInstance && "direct_to_sales" in value;
    isInstance = isInstance && "price_target_currency" in value;
    isInstance = isInstance && "pre_purchase_products" in value;
    isInstance = isInstance && "pre_tax_total_price" in value;

    return isInstance;
}

export function ControlPrePurchaseInformationResponseFromJSON(json: any): ControlPrePurchaseInformationResponse {
    return ControlPrePurchaseInformationResponseFromJSONTyped(json, false);
}

export function ControlPrePurchaseInformationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPrePurchaseInformationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy_id': json['policy_id'],
        'insurance_security_sku': json['insurance_security_sku'],
        'broker_id': json['broker_id'],
        'agency_id': json['agency_id'],
        'stripe_product_specification': ((json['stripe_product_specification'] as Array<any>).map(ControlPrePurchaseProductSpecificationFromJSON)),
        'direct_to_sales': json['direct_to_sales'],
        'price_target_currency': json['price_target_currency'],
        'presented_price': !exists(json, 'presented_price') ? undefined : json['presented_price'],
        'pre_purchase_products': ((json['pre_purchase_products'] as Array<any>).map(ControlPrePurchaseProductsFromJSON)),
        'pre_tax_total_price': json['pre_tax_total_price'],
    };
}

export function ControlPrePurchaseInformationResponseToJSON(value?: ControlPrePurchaseInformationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy_id': value.policy_id,
        'insurance_security_sku': value.insurance_security_sku,
        'broker_id': value.broker_id,
        'agency_id': value.agency_id,
        'stripe_product_specification': ((value.stripe_product_specification as Array<any>).map(ControlPrePurchaseProductSpecificationToJSON)),
        'direct_to_sales': value.direct_to_sales,
        'price_target_currency': value.price_target_currency,
        'presented_price': value.presented_price,
        'pre_purchase_products': ((value.pre_purchase_products as Array<any>).map(ControlPrePurchaseProductsToJSON)),
        'pre_tax_total_price': value.pre_tax_total_price,
    };
}

