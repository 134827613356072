/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    ActivityCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    ExpenseAmt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    GrantAmt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    RevenueAmt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    Desc?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ActivityCd': !exists(json, 'ActivityCd') ? undefined : json['ActivityCd'],
        'ExpenseAmt': !exists(json, 'ExpenseAmt') ? undefined : json['ExpenseAmt'],
        'GrantAmt': !exists(json, 'GrantAmt') ? undefined : json['GrantAmt'],
        'RevenueAmt': !exists(json, 'RevenueAmt') ? undefined : json['RevenueAmt'],
        'Desc': !exists(json, 'Desc') ? undefined : json['Desc'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ActivityCd': value.ActivityCd,
        'ExpenseAmt': value.ExpenseAmt,
        'GrantAmt': value.GrantAmt,
        'RevenueAmt': value.RevenueAmt,
        'Desc': value.Desc,
    };
}

