/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboGetadminmfaenforcedPagination
 */
export interface JumboGetadminmfaenforcedPagination {
    /**
     * 
     * @type {boolean}
     * @memberof JumboGetadminmfaenforcedPagination
     */
    hasMore?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboGetadminmfaenforcedPagination
     */
    nextCursor?: string;
}

/**
 * Check if a given object implements the JumboGetadminmfaenforcedPagination interface.
 */
export function instanceOfJumboGetadminmfaenforcedPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetadminmfaenforcedPaginationFromJSON(json: any): JumboGetadminmfaenforcedPagination {
    return JumboGetadminmfaenforcedPaginationFromJSONTyped(json, false);
}

export function JumboGetadminmfaenforcedPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetadminmfaenforcedPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
        'nextCursor': !exists(json, 'nextCursor') ? undefined : json['nextCursor'],
    };
}

export function JumboGetadminmfaenforcedPaginationToJSON(value?: JumboGetadminmfaenforcedPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasMore': value.hasMore,
        'nextCursor': value.nextCursor,
    };
}

