/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFDataleak
 */
export interface CRSFDataleak {
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    domain: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFDataleak
     */
    features: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    infected_machine_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    infected_path?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFDataleak
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleak
     */
    severity?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    target_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    target_subdomain?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    target_url?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleak
     */
    updated: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    user_browser?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    user_hostname?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleak
     */
    user_os?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFDataleak
     */
    year?: number;
}

/**
 * Check if a given object implements the CRSFDataleak interface.
 */
export function instanceOfCRSFDataleak(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "updated" in value;

    return isInstance;
}

export function CRSFDataleakFromJSON(json: any): CRSFDataleak {
    return CRSFDataleakFromJSONTyped(json, false);
}

export function CRSFDataleakFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDataleak {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'domain': json['domain'],
        'features': json['features'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'infected_machine_id': !exists(json, 'infected_machine_id') ? undefined : json['infected_machine_id'],
        'infected_path': !exists(json, 'infected_path') ? undefined : json['infected_path'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'name': json['name'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'target_domain': !exists(json, 'target_domain') ? undefined : json['target_domain'],
        'target_subdomain': !exists(json, 'target_subdomain') ? undefined : json['target_subdomain'],
        'target_url': !exists(json, 'target_url') ? undefined : json['target_url'],
        'updated': json['updated'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'user_browser': !exists(json, 'user_browser') ? undefined : json['user_browser'],
        'user_hostname': !exists(json, 'user_hostname') ? undefined : json['user_hostname'],
        'user_os': !exists(json, 'user_os') ? undefined : json['user_os'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function CRSFDataleakToJSON(value?: CRSFDataleak | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'domain': value.domain,
        'features': value.features,
        'fullname': value.fullname,
        'infected_machine_id': value.infected_machine_id,
        'infected_path': value.infected_path,
        'ip_addresses': value.ip_addresses,
        'name': value.name,
        'severity': value.severity,
        'target_domain': value.target_domain,
        'target_subdomain': value.target_subdomain,
        'target_url': value.target_url,
        'updated': value.updated,
        'user': value.user,
        'user_browser': value.user_browser,
        'user_hostname': value.user_hostname,
        'user_os': value.user_os,
        'year': value.year,
    };
}

