/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsEDRAlertNote
 */
export interface JumboStructsEDRAlertNote {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertNote
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertNote
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertNote
     */
    note?: string;
}

/**
 * Check if a given object implements the JumboStructsEDRAlertNote interface.
 */
export function instanceOfJumboStructsEDRAlertNote(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRAlertNoteFromJSON(json: any): JumboStructsEDRAlertNote {
    return JumboStructsEDRAlertNoteFromJSONTyped(json, false);
}

export function JumboStructsEDRAlertNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRAlertNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function JumboStructsEDRAlertNoteToJSON(value?: JumboStructsEDRAlertNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'name': value.name,
        'note': value.note,
    };
}

