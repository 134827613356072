/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboGetawsaccountsAWSAccount
 */
export interface JumboGetawsaccountsAWSAccount {
    /**
     * 
     * @type {string}
     * @memberof JumboGetawsaccountsAWSAccount
     */
    createStackURL?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetawsaccountsAWSAccount
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboGetawsaccountsAWSAccount
     */
    isConnected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JumboGetawsaccountsAWSAccount
     */
    isSetupComplete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboGetawsaccountsAWSAccount
     */
    setupCompletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetawsaccountsAWSAccount
     */
    userAccountID?: string;
}

/**
 * Check if a given object implements the JumboGetawsaccountsAWSAccount interface.
 */
export function instanceOfJumboGetawsaccountsAWSAccount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetawsaccountsAWSAccountFromJSON(json: any): JumboGetawsaccountsAWSAccount {
    return JumboGetawsaccountsAWSAccountFromJSONTyped(json, false);
}

export function JumboGetawsaccountsAWSAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetawsaccountsAWSAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createStackURL': !exists(json, 'createStackURL') ? undefined : json['createStackURL'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'isConnected': !exists(json, 'isConnected') ? undefined : json['isConnected'],
        'isSetupComplete': !exists(json, 'isSetupComplete') ? undefined : json['isSetupComplete'],
        'setupCompletedAt': !exists(json, 'setupCompletedAt') ? undefined : json['setupCompletedAt'],
        'userAccountID': !exists(json, 'userAccountID') ? undefined : json['userAccountID'],
    };
}

export function JumboGetawsaccountsAWSAccountToJSON(value?: JumboGetawsaccountsAWSAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createStackURL': value.createStackURL,
        'createdAt': value.createdAt,
        'isConnected': value.isConnected,
        'isSetupComplete': value.isSetupComplete,
        'setupCompletedAt': value.setupCompletedAt,
        'userAccountID': value.userAccountID,
    };
}

