/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI } from './ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI';
import {
    ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIFromJSON,
    ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIFromJSONTyped,
    ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIToJSON,
} from './ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI';
import type { ExecRiskScheduleIBase } from './ExecRiskScheduleIBase';
import {
    ExecRiskScheduleIBaseFromJSON,
    ExecRiskScheduleIBaseFromJSONTyped,
    ExecRiskScheduleIBaseToJSON,
} from './ExecRiskScheduleIBase';

/**
 * 
 * @export
 * @interface ExecRiskScheduleIDef
 */
export interface ExecRiskScheduleIDef {
    /**
     * 
     * @type {ExecRiskScheduleIBase}
     * @memberof ExecRiskScheduleIDef
     */
    base_form?: ExecRiskScheduleIBase;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI>}
     * @memberof ExecRiskScheduleIDef
     */
    part1_q5?: Array<ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartI>;
}

/**
 * Check if a given object implements the ExecRiskScheduleIDef interface.
 */
export function instanceOfExecRiskScheduleIDef(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleIDefFromJSON(json: any): ExecRiskScheduleIDef {
    return ExecRiskScheduleIDefFromJSONTyped(json, false);
}

export function ExecRiskScheduleIDefFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleIDef {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_form': !exists(json, 'base_form') ? undefined : ExecRiskScheduleIBaseFromJSON(json['base_form']),
        'part1_q5': !exists(json, 'part1_q5') ? undefined : ((json['part1_q5'] as Array<any>).map(ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIFromJSON)),
    };
}

export function ExecRiskScheduleIDefToJSON(value?: ExecRiskScheduleIDef | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_form': ExecRiskScheduleIBaseToJSON(value.base_form),
        'part1_q5': value.part1_q5 === undefined ? undefined : ((value.part1_q5 as Array<any>).map(ExecRiskApiAppCoreModelsScansLongForm5500ScheduleIDefPartIToJSON)),
    };
}

