/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetwizercompanyreportGetWizerCompanyReportTrainings } from './JumboGetwizercompanyreportGetWizerCompanyReportTrainings';
import {
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsFromJSON,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsFromJSONTyped,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsToJSON,
} from './JumboGetwizercompanyreportGetWizerCompanyReportTrainings';
import type { JumboGetwizercompanyreportGetWizerCompanyReportUsers } from './JumboGetwizercompanyreportGetWizerCompanyReportUsers';
import {
    JumboGetwizercompanyreportGetWizerCompanyReportUsersFromJSON,
    JumboGetwizercompanyreportGetWizerCompanyReportUsersFromJSONTyped,
    JumboGetwizercompanyreportGetWizerCompanyReportUsersToJSON,
} from './JumboGetwizercompanyreportGetWizerCompanyReportUsers';

/**
 * 
 * @export
 * @interface JumboGetwizercompanyreportGetWizerCompanyReportResult
 */
export interface JumboGetwizercompanyreportGetWizerCompanyReportResult {
    /**
     * 
     * @type {JumboGetwizercompanyreportGetWizerCompanyReportTrainings}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportResult
     */
    trainings?: JumboGetwizercompanyreportGetWizerCompanyReportTrainings;
    /**
     * 
     * @type {JumboGetwizercompanyreportGetWizerCompanyReportUsers}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportResult
     */
    users?: JumboGetwizercompanyreportGetWizerCompanyReportUsers;
}

/**
 * Check if a given object implements the JumboGetwizercompanyreportGetWizerCompanyReportResult interface.
 */
export function instanceOfJumboGetwizercompanyreportGetWizerCompanyReportResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetwizercompanyreportGetWizerCompanyReportResultFromJSON(json: any): JumboGetwizercompanyreportGetWizerCompanyReportResult {
    return JumboGetwizercompanyreportGetWizerCompanyReportResultFromJSONTyped(json, false);
}

export function JumboGetwizercompanyreportGetWizerCompanyReportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetwizercompanyreportGetWizerCompanyReportResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trainings': !exists(json, 'trainings') ? undefined : JumboGetwizercompanyreportGetWizerCompanyReportTrainingsFromJSON(json['trainings']),
        'users': !exists(json, 'users') ? undefined : JumboGetwizercompanyreportGetWizerCompanyReportUsersFromJSON(json['users']),
    };
}

export function JumboGetwizercompanyreportGetWizerCompanyReportResultToJSON(value?: JumboGetwizercompanyreportGetWizerCompanyReportResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trainings': JumboGetwizercompanyreportGetWizerCompanyReportTrainingsToJSON(value.trainings),
        'users': JumboGetwizercompanyreportGetWizerCompanyReportUsersToJSON(value.users),
    };
}

