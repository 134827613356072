/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg
     */
    power_appoint_majority_dir_trst_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg
     */
    operate_benefit_non_suprt_org_ind?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'power_appoint_majority_dir_trst_ind': !exists(json, 'power_appoint_majority_dir_trst_ind') ? undefined : json['power_appoint_majority_dir_trst_ind'],
        'operate_benefit_non_suprt_org_ind': !exists(json, 'operate_benefit_non_suprt_org_ind') ? undefined : json['operate_benefit_non_suprt_org_ind'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrgToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleAForm990SchAType1SuprtOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'power_appoint_majority_dir_trst_ind': value.power_appoint_majority_dir_trst_ind,
        'operate_benefit_non_suprt_org_ind': value.operate_benefit_non_suprt_org_ind,
    };
}

