/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule
     */
    line_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule
     */
    amount_involved?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule
     */
    name_of_noncharitable_eo?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule
     */
    description_of_transfers_etc?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line_number': !exists(json, 'line_number') ? undefined : json['line_number'],
        'amount_involved': !exists(json, 'amount_involved') ? undefined : json['amount_involved'],
        'name_of_noncharitable_eo': !exists(json, 'name_of_noncharitable_eo') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['name_of_noncharitable_eo']),
        'description_of_transfers_etc': !exists(json, 'description_of_transfers_etc') ? undefined : json['description_of_transfers_etc'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleATransferScheduleToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleATransferSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line_number': value.line_number,
        'amount_involved': value.amount_involved,
        'name_of_noncharitable_eo': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.name_of_noncharitable_eo),
        'description_of_transfers_etc': value.description_of_transfers_etc,
    };
}

