/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFInsuranceEnv } from './CRSFInsuranceEnv';
import {
    CRSFInsuranceEnvFromJSON,
    CRSFInsuranceEnvFromJSONTyped,
    CRSFInsuranceEnvToJSON,
} from './CRSFInsuranceEnv';
import type { CRSFLocale } from './CRSFLocale';
import {
    CRSFLocaleFromJSON,
    CRSFLocaleFromJSONTyped,
    CRSFLocaleToJSON,
} from './CRSFLocale';
import type { Metrics } from './Metrics';
import {
    MetricsFromJSON,
    MetricsFromJSONTyped,
    MetricsToJSON,
} from './Metrics';

/**
 * Request payload for CRA scans.
 * @export
 * @interface CRSFSrcHttpdlibCoreModelsCraTrigger
 */
export interface CRSFSrcHttpdlibCoreModelsCraTrigger {
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    account_line_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    agency_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    cobranded?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    desired_policy_uuid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    domains: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    external_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFInsuranceEnv}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    insurance_env?: CRSFInsuranceEnv;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {CRSFLocale}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    locale?: CRSFLocale;
    /**
     * 
     * @type {{ [key: string]: Metrics; }}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    metrics?: { [key: string]: Metrics; };
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    renewal_of_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFSrcHttpdlibCoreModelsCraTrigger
     */
    retained_from_uuid?: string;
}

/**
 * Check if a given object implements the CRSFSrcHttpdlibCoreModelsCraTrigger interface.
 */
export function instanceOfCRSFSrcHttpdlibCoreModelsCraTrigger(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;

    return isInstance;
}

export function CRSFSrcHttpdlibCoreModelsCraTriggerFromJSON(json: any): CRSFSrcHttpdlibCoreModelsCraTrigger {
    return CRSFSrcHttpdlibCoreModelsCraTriggerFromJSONTyped(json, false);
}

export function CRSFSrcHttpdlibCoreModelsCraTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSrcHttpdlibCoreModelsCraTrigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_line_uuid': !exists(json, 'account_line_uuid') ? undefined : json['account_line_uuid'],
        'agency_name': !exists(json, 'agency_name') ? undefined : json['agency_name'],
        'cobranded': !exists(json, 'cobranded') ? undefined : json['cobranded'],
        'desired_policy_uuid': !exists(json, 'desired_policy_uuid') ? undefined : json['desired_policy_uuid'],
        'domains': json['domains'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFInsuranceEnvFromJSON(json['insurance_env']),
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'locale': !exists(json, 'locale') ? undefined : CRSFLocaleFromJSON(json['locale']),
        'metrics': !exists(json, 'metrics') ? undefined : (mapValues(json['metrics'], MetricsFromJSON)),
        'renewal_of_id': !exists(json, 'renewal_of_id') ? undefined : json['renewal_of_id'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
    };
}

export function CRSFSrcHttpdlibCoreModelsCraTriggerToJSON(value?: CRSFSrcHttpdlibCoreModelsCraTrigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_line_uuid': value.account_line_uuid,
        'agency_name': value.agency_name,
        'cobranded': value.cobranded,
        'desired_policy_uuid': value.desired_policy_uuid,
        'domains': value.domains,
        'entity_name': value.entity_name,
        'external_id': value.external_id,
        'force': value.force,
        'insurance_env': CRSFInsuranceEnvToJSON(value.insurance_env),
        'ip_addresses': value.ip_addresses,
        'locale': CRSFLocaleToJSON(value.locale),
        'metrics': value.metrics === undefined ? undefined : (mapValues(value.metrics, MetricsToJSON)),
        'renewal_of_id': value.renewal_of_id,
        'retained_from_uuid': value.retained_from_uuid,
    };
}

