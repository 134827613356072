/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFEnumerationCounter } from './CRSFEnumerationCounter';
import {
    CRSFEnumerationCounterFromJSON,
    CRSFEnumerationCounterFromJSONTyped,
    CRSFEnumerationCounterToJSON,
} from './CRSFEnumerationCounter';
import type { CRSFSourceCount } from './CRSFSourceCount';
import {
    CRSFSourceCountFromJSON,
    CRSFSourceCountFromJSONTyped,
    CRSFSourceCountToJSON,
} from './CRSFSourceCount';

/**
 * 
 * @export
 * @interface SourceCount
 */
export interface SourceCount {
    /**
     * 
     * @type {CRSFEnumerationCounter}
     * @memberof SourceCount
     */
    account?: CRSFEnumerationCounter;
    /**
     * 
     * @type {CRSFEnumerationCounter}
     * @memberof SourceCount
     */
    account_sec?: CRSFEnumerationCounter;
    /**
     * 
     * @type {CRSFEnumerationCounter}
     * @memberof SourceCount
     */
    asm?: CRSFEnumerationCounter;
    /**
     * 
     * @type {CRSFEnumerationCounter}
     * @memberof SourceCount
     */
    bvd?: CRSFEnumerationCounter;
    /**
     * 
     * @type {CRSFEnumerationCounter}
     * @memberof SourceCount
     */
    redirect?: CRSFEnumerationCounter;
    /**
     * 
     * @type {CRSFEnumerationCounter}
     * @memberof SourceCount
     */
    whois?: CRSFEnumerationCounter;
    /**
     * 
     * @type {CRSFEnumerationCounter}
     * @memberof SourceCount
     */
    zoominfo?: CRSFEnumerationCounter;
}

/**
 * Check if a given object implements the SourceCount interface.
 */
export function instanceOfSourceCount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SourceCountFromJSON(json: any): SourceCount {
    return SourceCountFromJSONTyped(json, false);
}

export function SourceCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': !exists(json, 'account') ? undefined : CRSFEnumerationCounterFromJSON(json['account']),
        'account_sec': !exists(json, 'account-sec') ? undefined : CRSFEnumerationCounterFromJSON(json['account-sec']),
        'asm': !exists(json, 'asm') ? undefined : CRSFEnumerationCounterFromJSON(json['asm']),
        'bvd': !exists(json, 'bvd') ? undefined : CRSFEnumerationCounterFromJSON(json['bvd']),
        'redirect': !exists(json, 'redirect') ? undefined : CRSFEnumerationCounterFromJSON(json['redirect']),
        'whois': !exists(json, 'whois') ? undefined : CRSFEnumerationCounterFromJSON(json['whois']),
        'zoominfo': !exists(json, 'zoominfo') ? undefined : CRSFEnumerationCounterFromJSON(json['zoominfo']),
    };
}

export function SourceCountToJSON(value?: SourceCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': CRSFEnumerationCounterToJSON(value.account),
        'account-sec': CRSFEnumerationCounterToJSON(value.account_sec),
        'asm': CRSFEnumerationCounterToJSON(value.asm),
        'bvd': CRSFEnumerationCounterToJSON(value.bvd),
        'redirect': CRSFEnumerationCounterToJSON(value.redirect),
        'whois': CRSFEnumerationCounterToJSON(value.whois),
        'zoominfo': CRSFEnumerationCounterToJSON(value.zoominfo),
    };
}

