/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner
 */
export interface JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner {
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner
     */
    appliesTo?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner
     */
    provisioningStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner
     */
    servicePlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner
     */
    servicePlanName?: string;
}

/**
 * Check if a given object implements the JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner interface.
 */
export function instanceOfJumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerFromJSON(json: any): JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner {
    return JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerFromJSONTyped(json, false);
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appliesTo': !exists(json, 'appliesTo') ? undefined : json['appliesTo'],
        'provisioningStatus': !exists(json, 'provisioningStatus') ? undefined : json['provisioningStatus'],
        'servicePlanId': !exists(json, 'servicePlanId') ? undefined : json['servicePlanId'],
        'servicePlanName': !exists(json, 'servicePlanName') ? undefined : json['servicePlanName'],
    };
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerToJSON(value?: JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appliesTo': value.appliesTo,
        'provisioningStatus': value.provisioningStatus,
        'servicePlanId': value.servicePlanId,
        'servicePlanName': value.servicePlanName,
    };
}

