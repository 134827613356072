import { Table } from '@crucible-risk/security-applications';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@explorer/core';
import {
  FieldCheckbox,
  FormFieldControl,
  FormSchemaReturn,
} from '@explorer/forms';
import {
  Modules,
  SUPPORTS_OVERRIDES,
  SupportedSystems,
} from '@explorer/forms/presets/forms/ModifyClient/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormValues } from '../formSchema';
import { SentinelOneConnection } from './MDR/SentinelOneConnection';
import { useGetColumns } from './useGetColumns';
import { PrepurchaseDescription } from './PrepurchaseDescription';

interface DisplayModulesProps {
  clientId: string;
  moduleInfo: Modules;
  formik: FormikProps<FormValues>;
  formId: string;
  schema: FormSchemaReturn<FormValues>;
}

interface OverrideModulePRops {
  moduleKey: string;
  formik: FormikProps<FormValues>;
  formId: string;
  fieldName: string;
}

export function OverrideModules({
  formik,
  formId,
  moduleKey,
  fieldName,
}: OverrideModulePRops) {
  const renderField = (props) => (
    <FieldCheckbox
      {...props}
      options={[
        {
          label: { id: `forms-presets.${moduleKey}` },
          name: moduleKey,
        },
      ]}
    />
  );
  return (
    <FormFieldControl
      key={fieldName}
      formId={formId}
      formik={formik}
      name={fieldName}
      renderField={renderField}
    />
  );
}

export function DisplayModules({
  clientId,
  moduleInfo,
  formik,
  formId,
  schema,
}: DisplayModulesProps) {
  const { formatMessage } = useIntl();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { columns } = useGetColumns(formatMessage);

  const renderModules = () => {
    const { modules } = moduleInfo;

    return Object.entries(modules).map(([key, supportedModules]) => {
      const rows = supportedModules
        .map((item, idx) => {
          // TODO: For phase 2 when other source systems are added, add them here.
          // This will allow for specific components to be shown for each source system.
          switch (item.sourceSystems) {
            case SupportedSystems.STRIPE: {
              const { isTrial, isExpired, endDate } = item.featureSet;
              return {
                id: idx,
                name: formatMessage({
                  id: `forms-presets.${item.sourceSystems}`,
                }),
                type: isTrial
                  ? formatMessage({ id: 'forms-presets.trial' })
                  : formatMessage({ id: 'forms-presets.subscription' }),
                endDate: endDate ? `${new Date(endDate).toDateString()}` : '',
                expired: isExpired
                  ? formatMessage({ id: 'forms-presets.expired' })
                  : '',
              };
            }
            case SupportedSystems.HUBSPOT:
            case SupportedSystems.EXPLORER:
              return null;
            default:
              return null;
          }
        })
        .filter(Boolean);

      const prepurchaseInfo = moduleInfo.prepurchasedModules?.[key];

      const overrideSupported = SUPPORTS_OVERRIDES[key];
      return (
        <Grid item xs={12} key={key} mb={1}>
          <Accordion sx={{ border: '1px solid #CAD3DB', borderRadius: '8px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${key}-content`}
              id={`${key}-header`}
            >
              {formatMessage({ id: `forms-presets.title-${key}` })}
            </AccordionSummary>
            <AccordionDetails>
              {overrideSupported ? (
                <>
                  {!!prepurchaseInfo && (
                    <PrepurchaseDescription prepurchaseInfo={prepurchaseInfo} />
                  )}
                  <OverrideModules
                    formik={formik}
                    formId={formId}
                    moduleKey={key}
                    fieldName={schema.control_components.name}
                  />
                  {key === 'MDR' ? (
                    <SentinelOneConnection clientId={clientId} />
                  ) : null}
                </>
              ) : null}
              {rows?.length > 0 && (
                <>
                  <Typography variant="bodyS">
                    {formatMessage({ id: 'forms-presets.source-systems' })}
                  </Typography>
                  <Table
                    rows={rows}
                    paginationMode="client"
                    page={page - 1}
                    setPage={(newPage) => setPage(newPage + 1)}
                    pageSize={pageSize}
                    rowCount={rows?.length ?? 0}
                    setPageSize={(newPageSize) => setPageSize(newPageSize)}
                    hasPagination
                    loading={false}
                    error={false}
                    columns={columns}
                    renderEmptyText={''}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  };

  return (
    <Grid item xs={12} mb={2}>
      {renderModules()}
    </Grid>
  );
}
