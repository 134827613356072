import React from 'react';
import * as yup from 'yup';
import {
  FieldAutocomplete,
  FieldCheckbox,
  FieldNumber,
  FieldText,
  FieldTextWithInfo,
  FormSchemaOptions,
  FormSchemaReturn,
} from '@explorer/forms';
import {
  isIPAddress,
  isValidDomain,
  parseCommaSeparatedList,
} from '@explorer/helpers';

export const SUPPORTED_CRA_LOCALES = [
  'en-US',
  'en-GB',
  'en-AU',
  'en-DK',
  'en-SE',
  'en-CA',
  'de-DE',
  'fr-CA',
];

/**
 * Form Validation Schema
 */
export const validationSchema = (intl) =>
  yup
    .object({
      entity_name: yup
        .string()
        .required(
          intl.formatMessage({ id: 'forms-errors.required-entity-name' }),
        ),
      domains: yup
        .string()
        .required(
          intl.formatMessage({ id: 'forms-errors.required-entity-domain' }),
        )
        .ensure()
        .trim()
        .test(
          'is-domains-list',
          intl.formatMessage({ id: 'forms-errors.invalid-domains-list' }),
          (value) => {
            if (!value) return true;
            const entries = Array.from(parseCommaSeparatedList(value));
            return entries.every((entry) => isValidDomain(entry));
          },
        ),
      ip_addresses: yup
        .string()
        .ensure()
        .trim()
        .test(
          'is-ip-address-list',
          intl.formatMessage({ id: 'forms-errors.invalid-ip-address-list' }),
          (value) => {
            if (!value) return true;

            const entries = Array.from(parseCommaSeparatedList(value));
            return entries.every((entry) => isIPAddress(entry));
          },
        ),
      industry: yup
        .string()
        .nullable()
        .required(intl.formatMessage({ id: 'errors.requiredIndustry' })),
      number_of_employees: yup
        .number()
        .nullable()
        .required(
          intl.formatMessage({ id: 'errors.requiredNumberOfEmployees' }),
        ),
      number_of_pii_records: yup
        .number()
        .nullable()
        .required(intl.formatMessage({ id: 'errors.requiredNumberOfRecord' })),
      company_revenue: yup
        .number()
        .nullable()
        .required(intl.formatMessage({ id: 'errors.requiredCompanyRevenue' })),
      scan_opts: yup.object({
        force: yup.boolean(),
      }),
      locale: yup
        .string()
        .oneOf([
          'en-US',
          'en-GB',
          'en-AU',
          'en-DK',
          'en-SE',
          'en-CA',
          'de-DE',
          'fr-CA',
        ])
        .required(intl.formatMessage({ id: 'forms-errors.required-locale' })),
      cobranded: yup
        .string()
        .nullable()
        .oneOf(
          [null, 'acrisure', 'cdw'],
          intl.formatMessage({ id: 'forms-errors.invalid-cobranded' }),
        ),
    })
    .defined();

/**
 * Form Values
 */
export type FormValues = yup.InferType<ReturnType<typeof validationSchema>>;

/**
 * Form Schema
 */
export const initializeFormSchema = ({
  formId,
  formik,
  options = { industries: [], scanOpts: [] },
}: FormSchemaOptions): FormSchemaReturn<FormValues> => ({
  entity_name: {
    formId,
    formik,
    name: 'entity_name',
    renderField: (props) => (
      <FieldText {...props} label={{ id: 'forms-presets.entity-name' }} />
    ),
  },
  domains: {
    formId,
    formik,
    name: 'domains',
    renderField: (props) => (
      <FieldTextWithInfo
        {...props}
        label={{ id: 'forms-presets.domains' }}
        minRows={1}
        tooltipText={{ id: 'forms-presets.domains-tooltip' }}
      />
    ),
  },
  ip_addresses: {
    formId,
    formik,
    name: 'ip_addresses',
    renderField: (props) => (
      <FieldTextWithInfo
        {...props}
        label={{ id: 'forms-presets.ip-addresses' }}
        minRows={1}
        tooltipText={{ id: 'forms-presets.ip-addresses-tooltip' }}
      />
    ),
  },
  industry: {
    formId,
    formik,
    name: 'industry',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'forms-presets.industry' }}
        options={options.industries}
      />
    ),
  },
  number_of_employees: {
    formId,
    formik,
    name: 'number_of_employees',
    renderField: (props) => (
      <FieldNumber {...props} label={{ id: 'forms-presets.employees' }} />
    ),
  },
  number_of_pii_records: {
    formId,
    formik,
    name: 'number_of_pii_records',
    renderField: (props) => (
      <FieldNumber {...props} label={{ id: 'forms-presets.records' }} />
    ),
  },
  company_revenue: {
    formId,
    formik,
    name: 'company_revenue',
    renderField: (props) => (
      <FieldNumber {...props} label={{ id: 'forms-presets.revenue' }} />
    ),
  },
  locale: {
    formId,
    formik,
    name: 'locale',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'forms-presets.locale' }}
        options={options.locale_opts}
        disableClearable
      />
    ),
  },
  cobranded: {
    formId,
    formik,
    name: 'cobranded',
    renderField: (props) => (
      <FieldAutocomplete
        {...props}
        label={{ id: 'forms-presets.cobranded' }}
        options={options.cobranded_opts}
      />
    ),
  },
  scan_opts: {
    formId,
    formik,
    name: 'scan_opts',
    renderField: (props) => (
      <FieldCheckbox
        {...props}
        label={{ id: 'forms-presets.options' }}
        options={options.scan_opts}
      />
    ),
  },
});
