/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetawsreportAWSMFAReport } from './JumboGetawsreportAWSMFAReport';
import {
    JumboGetawsreportAWSMFAReportFromJSON,
    JumboGetawsreportAWSMFAReportFromJSONTyped,
    JumboGetawsreportAWSMFAReportToJSON,
} from './JumboGetawsreportAWSMFAReport';
import type { JumboStructsAWSS3BucketReport } from './JumboStructsAWSS3BucketReport';
import {
    JumboStructsAWSS3BucketReportFromJSON,
    JumboStructsAWSS3BucketReportFromJSONTyped,
    JumboStructsAWSS3BucketReportToJSON,
} from './JumboStructsAWSS3BucketReport';

/**
 * 
 * @export
 * @interface JumboGetawsreportAWSReport
 */
export interface JumboGetawsreportAWSReport {
    /**
     * 
     * @type {JumboGetawsreportAWSMFAReport}
     * @memberof JumboGetawsreportAWSReport
     */
    mfa?: JumboGetawsreportAWSMFAReport;
    /**
     * 
     * @type {Array<JumboStructsAWSS3BucketReport>}
     * @memberof JumboGetawsreportAWSReport
     */
    s3Buckets?: Array<JumboStructsAWSS3BucketReport>;
}

/**
 * Check if a given object implements the JumboGetawsreportAWSReport interface.
 */
export function instanceOfJumboGetawsreportAWSReport(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetawsreportAWSReportFromJSON(json: any): JumboGetawsreportAWSReport {
    return JumboGetawsreportAWSReportFromJSONTyped(json, false);
}

export function JumboGetawsreportAWSReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetawsreportAWSReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mfa': !exists(json, 'mfa') ? undefined : JumboGetawsreportAWSMFAReportFromJSON(json['mfa']),
        's3Buckets': !exists(json, 's3Buckets') ? undefined : ((json['s3Buckets'] as Array<any>).map(JumboStructsAWSS3BucketReportFromJSON)),
    };
}

export function JumboGetawsreportAWSReportToJSON(value?: JumboGetawsreportAWSReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mfa': JumboGetawsreportAWSMFAReportToJSON(value.mfa),
        's3Buckets': value.s3Buckets === undefined ? undefined : ((value.s3Buckets as Array<any>).map(JumboStructsAWSS3BucketReportToJSON)),
    };
}

