/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ControlTag = {
    Free: 'free',
    Recommended: 'recommended',
    Included: 'included'
} as const;
export type ControlTag = typeof ControlTag[keyof typeof ControlTag];


export function ControlTagFromJSON(json: any): ControlTag {
    return ControlTagFromJSONTyped(json, false);
}

export function ControlTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTag {
    return json as ControlTag;
}

export function ControlTagToJSON(value?: ControlTag | null): any {
    return value as any;
}

