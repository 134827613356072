/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
     */
    ActivitiesTestInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
     */
    ParentSupportedOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
     */
    GovernmentalEntityInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
     */
    ActivitiesFurtherExemptPrpsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
     */
    ActivitiesEngagedOrgInvlmntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
     */
    AppointElectMajorityOfficerInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt
     */
    ExerciseDirectionPoliciesInd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ActivitiesTestInd': !exists(json, 'ActivitiesTestInd') ? undefined : json['ActivitiesTestInd'],
        'ParentSupportedOrgInd': !exists(json, 'ParentSupportedOrgInd') ? undefined : json['ParentSupportedOrgInd'],
        'GovernmentalEntityInd': !exists(json, 'GovernmentalEntityInd') ? undefined : json['GovernmentalEntityInd'],
        'ActivitiesFurtherExemptPrpsInd': !exists(json, 'ActivitiesFurtherExemptPrpsInd') ? undefined : json['ActivitiesFurtherExemptPrpsInd'],
        'ActivitiesEngagedOrgInvlmntInd': !exists(json, 'ActivitiesEngagedOrgInvlmntInd') ? undefined : json['ActivitiesEngagedOrgInvlmntInd'],
        'AppointElectMajorityOfficerInd': !exists(json, 'AppointElectMajorityOfficerInd') ? undefined : json['AppointElectMajorityOfficerInd'],
        'ExerciseDirectionPoliciesInd': !exists(json, 'ExerciseDirectionPoliciesInd') ? undefined : json['ExerciseDirectionPoliciesInd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncIntToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAForm990SchAType3FuncInt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ActivitiesTestInd': value.ActivitiesTestInd,
        'ParentSupportedOrgInd': value.ParentSupportedOrgInd,
        'GovernmentalEntityInd': value.GovernmentalEntityInd,
        'ActivitiesFurtherExemptPrpsInd': value.ActivitiesFurtherExemptPrpsInd,
        'ActivitiesEngagedOrgInvlmntInd': value.ActivitiesEngagedOrgInvlmntInd,
        'AppointElectMajorityOfficerInd': value.AppointElectMajorityOfficerInd,
        'ExerciseDirectionPoliciesInd': value.ExerciseDirectionPoliciesInd,
    };
}

