/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp
 */
export interface ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp
     */
    person_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp
     */
    title_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp
     */
    average_hours_per_week_rt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp
     */
    compensation_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp
     */
    employee_benefits_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp
     */
    expense_account_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrpFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp {
    return ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrpFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_name': !exists(json, 'person_name') ? undefined : json['person_name'],
        'title_txt': !exists(json, 'title_txt') ? undefined : json['title_txt'],
        'average_hours_per_week_rt': !exists(json, 'average_hours_per_week_rt') ? undefined : json['average_hours_per_week_rt'],
        'compensation_amt': !exists(json, 'compensation_amt') ? undefined : json['compensation_amt'],
        'employee_benefits_amt': !exists(json, 'employee_benefits_amt') ? undefined : json['employee_benefits_amt'],
        'expense_account_amt': !exists(json, 'expense_account_amt') ? undefined : json['expense_account_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrpToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990EZCompensationHighestPaidEmplGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_name': value.person_name,
        'title_txt': value.title_txt,
        'average_hours_per_week_rt': value.average_hours_per_week_rt,
        'compensation_amt': value.compensation_amt,
        'employee_benefits_amt': value.employee_benefits_amt,
        'expense_account_amt': value.expense_account_amt,
    };
}

