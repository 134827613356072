/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsClassificationsCount } from './JumboStructsClassificationsCount';
import {
    JumboStructsClassificationsCountFromJSON,
    JumboStructsClassificationsCountFromJSONTyped,
    JumboStructsClassificationsCountToJSON,
} from './JumboStructsClassificationsCount';

/**
 * 
 * @export
 * @interface JumboStructsMDRClassificationCountOverTime
 */
export interface JumboStructsMDRClassificationCountOverTime {
    /**
     * 
     * @type {JumboStructsClassificationsCount}
     * @memberof JumboStructsMDRClassificationCountOverTime
     */
    classificationCounts?: JumboStructsClassificationsCount;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDRClassificationCountOverTime
     */
    timestamp?: number;
}

/**
 * Check if a given object implements the JumboStructsMDRClassificationCountOverTime interface.
 */
export function instanceOfJumboStructsMDRClassificationCountOverTime(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsMDRClassificationCountOverTimeFromJSON(json: any): JumboStructsMDRClassificationCountOverTime {
    return JumboStructsMDRClassificationCountOverTimeFromJSONTyped(json, false);
}

export function JumboStructsMDRClassificationCountOverTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsMDRClassificationCountOverTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classificationCounts': !exists(json, 'classificationCounts') ? undefined : JumboStructsClassificationsCountFromJSON(json['classificationCounts']),
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function JumboStructsMDRClassificationCountOverTimeToJSON(value?: JumboStructsMDRClassificationCountOverTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classificationCounts': JumboStructsClassificationsCountToJSON(value.classificationCounts),
        'timestamp': value.timestamp,
    };
}

