/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt
 */
export interface ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt
     */
    desc?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt
     */
    total_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt
     */
    program_services_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt
     */
    management_and_general_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt
     */
    fundraising_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmtFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt {
    return ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'total_amt': !exists(json, 'total_amt') ? undefined : json['total_amt'],
        'program_services_amt': !exists(json, 'program_services_amt') ? undefined : json['program_services_amt'],
        'management_and_general_amt': !exists(json, 'management_and_general_amt') ? undefined : json['management_and_general_amt'],
        'fundraising_amt': !exists(json, 'fundraising_amt') ? undefined : json['fundraising_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmtToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990TotalProgramServicesAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'desc': value.desc,
        'total_amt': value.total_amt,
        'program_services_amt': value.program_services_amt,
        'management_and_general_amt': value.management_and_general_amt,
        'fundraising_amt': value.fundraising_amt,
    };
}

