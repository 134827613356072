/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsPlaidItem } from './JumboStructsPlaidItem';
import {
    JumboStructsPlaidItemFromJSON,
    JumboStructsPlaidItemFromJSONTyped,
    JumboStructsPlaidItemToJSON,
} from './JumboStructsPlaidItem';

/**
 * 
 * @export
 * @interface JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse
 */
export interface JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse {
    /**
     * 
     * @type {JumboStructsPlaidItem}
     * @memberof JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse
     */
    item?: JumboStructsPlaidItem;
}

/**
 * Check if a given object implements the JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse interface.
 */
export function instanceOfJumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponseFromJSON(json: any): JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse {
    return JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponseFromJSONTyped(json, false);
}

export function JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': !exists(json, 'item') ? undefined : JumboStructsPlaidItemFromJSON(json['item']),
    };
}

export function JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponseToJSON(value?: JumboPostplaiditempublictokenexchangePostPlaidItemPublicTokenExchangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': JumboStructsPlaidItemToJSON(value.item),
    };
}

