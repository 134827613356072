/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFInsuranceEnv } from './CRSFInsuranceEnv';
import {
    CRSFInsuranceEnvFromJSON,
    CRSFInsuranceEnvFromJSONTyped,
    CRSFInsuranceEnvToJSON,
} from './CRSFInsuranceEnv';
import type { CRSFLocale } from './CRSFLocale';
import {
    CRSFLocaleFromJSON,
    CRSFLocaleFromJSONTyped,
    CRSFLocaleToJSON,
} from './CRSFLocale';

/**
 * Model contains only data to trigger underwriting (quick_scan) process with domains.
 * Clienst should not trigger svcan for policies with no domains or use this API for renewals (fetching ASM data)
 * @export
 * @interface CRSFTriggerUWRequest
 */
export interface CRSFTriggerUWRequest {
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerUWRequest
     */
    account_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFTriggerUWRequest
     */
    domains: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTriggerUWRequest
     */
    extended_aggr_timeout?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerUWRequest
     */
    external_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFTriggerUWRequest
     */
    force?: boolean;
    /**
     * 
     * @type {CRSFInsuranceEnv}
     * @memberof CRSFTriggerUWRequest
     */
    insurance_env?: CRSFInsuranceEnv;
    /**
     * 
     * @type {CRSFLocale}
     * @memberof CRSFTriggerUWRequest
     */
    locale?: CRSFLocale;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerUWRequest
     */
    quote_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerUWRequest
     */
    renewal_of_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFTriggerUWRequest
     */
    retained_from_uuid?: string;
}

/**
 * Check if a given object implements the CRSFTriggerUWRequest interface.
 */
export function instanceOfCRSFTriggerUWRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domains" in value;

    return isInstance;
}

export function CRSFTriggerUWRequestFromJSON(json: any): CRSFTriggerUWRequest {
    return CRSFTriggerUWRequestFromJSONTyped(json, false);
}

export function CRSFTriggerUWRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFTriggerUWRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'domains': json['domains'],
        'extended_aggr_timeout': !exists(json, 'extended_aggr_timeout') ? undefined : json['extended_aggr_timeout'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'force': !exists(json, 'force') ? undefined : json['force'],
        'insurance_env': !exists(json, 'insurance_env') ? undefined : CRSFInsuranceEnvFromJSON(json['insurance_env']),
        'locale': !exists(json, 'locale') ? undefined : CRSFLocaleFromJSON(json['locale']),
        'quote_id': !exists(json, 'quote_id') ? undefined : json['quote_id'],
        'renewal_of_uuid': !exists(json, 'renewal_of_uuid') ? undefined : json['renewal_of_uuid'],
        'retained_from_uuid': !exists(json, 'retained_from_uuid') ? undefined : json['retained_from_uuid'],
    };
}

export function CRSFTriggerUWRequestToJSON(value?: CRSFTriggerUWRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'domains': value.domains,
        'extended_aggr_timeout': value.extended_aggr_timeout,
        'external_id': value.external_id,
        'force': value.force,
        'insurance_env': CRSFInsuranceEnvToJSON(value.insurance_env),
        'locale': CRSFLocaleToJSON(value.locale),
        'quote_id': value.quote_id,
        'renewal_of_uuid': value.renewal_of_uuid,
        'retained_from_uuid': value.retained_from_uuid,
    };
}

