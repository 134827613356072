import { V1Api } from '@explorer/open-api-client';
import {
  useExplorerAPIMutationParamType,
  useV1APIMutation,
} from '@explorer/query';

export enum MDRAssociationAction {
  Associate = 'associate',
  Disassociate = 'dissociate',
}

type MutateArgs = {
  clientId: string;
  action: MDRAssociationAction;
  mspClientId: string;
};

export const useMutationUpdateMDRAssociation = (
  args?: Omit<useExplorerAPIMutationParamType<V1Api, void>, 'mutationFn'>,
) => {
  const { onSuccess: onSuccessCallback, ...otherArgs } = args ?? {};

  return useV1APIMutation<void, MutateArgs>({
    mutationFn(args: MutateArgs, client) {
      const { clientId, ...rest } = args;
      return client.v1ControlWebAdminClientControlClientIdUpdateMspAssociationPost(
        {
          control_client_id: clientId,
          ControlUpdateMSPClientAssociationBody: {
            msp_client_id: rest.mspClientId,
            action: rest.action,
          },
        },
      );
    },
    onSuccess: onSuccessCallback,
    ...otherArgs,
  });
};
