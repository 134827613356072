/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityScanQueries
 */
export interface MonitEntityScanQueries {
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityScanQueries
     */
    nocache?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityScanQueries
     */
    lite_enum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitEntityScanQueries
     */
    lite_template?: boolean;
}

/**
 * Check if a given object implements the MonitEntityScanQueries interface.
 */
export function instanceOfMonitEntityScanQueries(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityScanQueriesFromJSON(json: any): MonitEntityScanQueries {
    return MonitEntityScanQueriesFromJSONTyped(json, false);
}

export function MonitEntityScanQueriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityScanQueries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nocache': !exists(json, 'nocache') ? undefined : json['nocache'],
        'lite_enum': !exists(json, 'lite_enum') ? undefined : json['lite_enum'],
        'lite_template': !exists(json, 'lite_template') ? undefined : json['lite_template'],
    };
}

export function MonitEntityScanQueriesToJSON(value?: MonitEntityScanQueries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nocache': value.nocache,
        'lite_enum': value.lite_enum,
        'lite_template': value.lite_template,
    };
}

