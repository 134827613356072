/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlZeroDayAssociationInvalidityReason } from './ControlZeroDayAssociationInvalidityReason';
import {
    ControlZeroDayAssociationInvalidityReasonFromJSON,
    ControlZeroDayAssociationInvalidityReasonFromJSONTyped,
    ControlZeroDayAssociationInvalidityReasonToJSON,
} from './ControlZeroDayAssociationInvalidityReason';

/**
 * 
 * @export
 * @interface ControlZeroDayAssociationEntryResult
 */
export interface ControlZeroDayAssociationEntryResult {
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAssociationEntryResult
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAssociationEntryResult
     */
    entity_id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlZeroDayAssociationEntryResult
     */
    affected_assets: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlZeroDayAssociationEntryResult
     */
    uploaded_successfully: boolean;
    /**
     * 
     * @type {ControlZeroDayAssociationInvalidityReason}
     * @memberof ControlZeroDayAssociationEntryResult
     */
    invalidity_reason?: ControlZeroDayAssociationInvalidityReason;
    /**
     * 
     * @type {string}
     * @memberof ControlZeroDayAssociationEntryResult
     */
    policy_number?: string;
}

/**
 * Check if a given object implements the ControlZeroDayAssociationEntryResult interface.
 */
export function instanceOfControlZeroDayAssociationEntryResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity_id" in value;
    isInstance = isInstance && "affected_assets" in value;
    isInstance = isInstance && "uploaded_successfully" in value;

    return isInstance;
}

export function ControlZeroDayAssociationEntryResultFromJSON(json: any): ControlZeroDayAssociationEntryResult {
    return ControlZeroDayAssociationEntryResultFromJSONTyped(json, false);
}

export function ControlZeroDayAssociationEntryResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlZeroDayAssociationEntryResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': !exists(json, 'client_id') ? undefined : json['client_id'],
        'entity_id': json['entity_id'],
        'affected_assets': json['affected_assets'],
        'uploaded_successfully': json['uploaded_successfully'],
        'invalidity_reason': !exists(json, 'invalidity_reason') ? undefined : ControlZeroDayAssociationInvalidityReasonFromJSON(json['invalidity_reason']),
        'policy_number': !exists(json, 'policy_number') ? undefined : json['policy_number'],
    };
}

export function ControlZeroDayAssociationEntryResultToJSON(value?: ControlZeroDayAssociationEntryResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'entity_id': value.entity_id,
        'affected_assets': value.affected_assets,
        'uploaded_successfully': value.uploaded_successfully,
        'invalidity_reason': ControlZeroDayAssociationInvalidityReasonToJSON(value.invalidity_reason),
        'policy_number': value.policy_number,
    };
}

