/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits } from './JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits';
import {
    JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsFromJSON,
    JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsFromJSONTyped,
    JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsToJSON,
} from './JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits';
import type { JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner } from './JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner';
import {
    JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerFromJSON,
    JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerFromJSONTyped,
    JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerToJSON,
} from './JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner';

/**
 * 
 * @export
 * @interface JumboMicrosoftMicrosoftOrganizationSubscription
 */
export interface JumboMicrosoftMicrosoftOrganizationSubscription {
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    accountId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    appliesTo?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    capabilityStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    consumedUnits?: number;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    id?: string;
    /**
     * 
     * @type {JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    prepaidUnits?: JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    productDisplayName?: string;
    /**
     * 
     * @type {Array<JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner>}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    servicePlans?: Array<JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInner>;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    skuId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    skuPartNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscription
     */
    subscriptionIds?: Array<string>;
}

/**
 * Check if a given object implements the JumboMicrosoftMicrosoftOrganizationSubscription interface.
 */
export function instanceOfJumboMicrosoftMicrosoftOrganizationSubscription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionFromJSON(json: any): JumboMicrosoftMicrosoftOrganizationSubscription {
    return JumboMicrosoftMicrosoftOrganizationSubscriptionFromJSONTyped(json, false);
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboMicrosoftMicrosoftOrganizationSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountId': !exists(json, 'accountId') ? undefined : json['accountId'],
        'accountName': !exists(json, 'accountName') ? undefined : json['accountName'],
        'appliesTo': !exists(json, 'appliesTo') ? undefined : json['appliesTo'],
        'capabilityStatus': !exists(json, 'capabilityStatus') ? undefined : json['capabilityStatus'],
        'consumedUnits': !exists(json, 'consumedUnits') ? undefined : json['consumedUnits'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'prepaidUnits': !exists(json, 'prepaidUnits') ? undefined : JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsFromJSON(json['prepaidUnits']),
        'productDisplayName': !exists(json, 'productDisplayName') ? undefined : json['productDisplayName'],
        'servicePlans': !exists(json, 'servicePlans') ? undefined : ((json['servicePlans'] as Array<any>).map(JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerFromJSON)),
        'skuId': !exists(json, 'skuId') ? undefined : json['skuId'],
        'skuPartNumber': !exists(json, 'skuPartNumber') ? undefined : json['skuPartNumber'],
        'subscriptionIds': !exists(json, 'subscriptionIds') ? undefined : json['subscriptionIds'],
    };
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionToJSON(value?: JumboMicrosoftMicrosoftOrganizationSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountId': value.accountId,
        'accountName': value.accountName,
        'appliesTo': value.appliesTo,
        'capabilityStatus': value.capabilityStatus,
        'consumedUnits': value.consumedUnits,
        'id': value.id,
        'prepaidUnits': JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsToJSON(value.prepaidUnits),
        'productDisplayName': value.productDisplayName,
        'servicePlans': value.servicePlans === undefined ? undefined : ((value.servicePlans as Array<any>).map(JumboMicrosoftMicrosoftOrganizationSubscriptionServicePlansInnerToJSON)),
        'skuId': value.skuId,
        'skuPartNumber': value.skuPartNumber,
        'subscriptionIds': value.subscriptionIds,
    };
}

