/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt
 */
export interface ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt
     */
    other_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt
     */
    securities_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmtFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt {
    return ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'other_amt': !exists(json, 'other_amt') ? undefined : json['other_amt'],
        'securities_amt': !exists(json, 'securities_amt') ? undefined : json['securities_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmtToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990OtherSecuritiesAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'other_amt': value.other_amt,
        'securities_amt': value.securities_amt,
    };
}

