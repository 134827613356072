/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt
 */
export interface ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt
     */
    prior_year_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt
     */
    current_year_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt {
    return ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prior_year_amt': !exists(json, 'prior_year_amt') ? undefined : json['prior_year_amt'],
        'current_year_amt': !exists(json, 'current_year_amt') ? undefined : json['current_year_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmtToJSON(value?: ExecRiskApiAppCoreModelsScansForm990CommonTypesPriorCurrentYearAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prior_year_amt': value.prior_year_amt,
        'current_year_amt': value.current_year_amt,
    };
}

