/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExecRiskProcessStatusType = {
    Requested: 'requested',
    Started: 'started',
    Skipped: 'skipped',
    Finished: 'finished',
    Errored: 'errored',
    Revoked: 'revoked'
} as const;
export type ExecRiskProcessStatusType = typeof ExecRiskProcessStatusType[keyof typeof ExecRiskProcessStatusType];


export function ExecRiskProcessStatusTypeFromJSON(json: any): ExecRiskProcessStatusType {
    return ExecRiskProcessStatusTypeFromJSONTyped(json, false);
}

export function ExecRiskProcessStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskProcessStatusType {
    return json as ExecRiskProcessStatusType;
}

export function ExecRiskProcessStatusTypeToJSON(value?: ExecRiskProcessStatusType | null): any {
    return value as any;
}

