export enum SupportedModules {
  MDR = 'MDR',
  SAT = 'SAT',
}

// Modules that support overrides go here. This will show the default override behavior in explorer.
export const SUPPORTS_OVERRIDES: Record<SupportedModules, boolean> = {
  MDR: true,
  SAT: true,
};

// Supported Modules, all others will be ignored when parsing the data from the API.
export const SUPPORTED_MODULES: SupportedModules[] = [
  SupportedModules.MDR,
  SupportedModules.SAT,
];

export enum SupportedSystems {
  EXPLORER = 'explorer',
  STRIPE = 'stripe',
  HUBSPOT = 'hubspot',
}

export type SourceSystem =
  | {
      sourceSystems: SupportedSystems.EXPLORER;
      featureSet: {
        isEnabled: boolean;
      };
    }
  | {
      sourceSystems: SupportedSystems.STRIPE | SupportedSystems.HUBSPOT;
      featureSet: {
        isTrial: boolean;
        isExpired: boolean;
        endDate?: string;
      };
    };

export type PrepurchaseInfo = {
  productSku?: string;
  currency?: string;
  price?: string;
};

export type Modules = {
  prepurchasedModules?: Record<SupportedModules, PrepurchaseInfo>;
  overrides: Record<SupportedModules, boolean>;
  modules: Record<SupportedModules, SourceSystem[]>;
};
