/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
     */
    SeparateBasisFinclStmtInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
     */
    ConsolidatedBasisFinclStmtInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
     */
    ConsolAndSepBasisFinclStmtInd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SeparateBasisFinclStmtInd': !exists(json, 'SeparateBasisFinclStmtInd') ? undefined : json['SeparateBasisFinclStmtInd'],
        'ConsolidatedBasisFinclStmtInd': !exists(json, 'ConsolidatedBasisFinclStmtInd') ? undefined : json['ConsolidatedBasisFinclStmtInd'],
        'ConsolAndSepBasisFinclStmtInd': !exists(json, 'ConsolAndSepBasisFinclStmtInd') ? undefined : json['ConsolAndSepBasisFinclStmtInd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SeparateBasisFinclStmtInd': value.SeparateBasisFinclStmtInd,
        'ConsolidatedBasisFinclStmtInd': value.ConsolidatedBasisFinclStmtInd,
        'ConsolAndSepBasisFinclStmtInd': value.ConsolAndSepBasisFinclStmtInd,
    };
}

