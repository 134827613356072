/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlRequirementDefinition } from './ControlRequirementDefinition';
import {
    ControlRequirementDefinitionFromJSON,
    ControlRequirementDefinitionFromJSONTyped,
    ControlRequirementDefinitionToJSON,
} from './ControlRequirementDefinition';

/**
 * 
 * @export
 * @interface ControlAppSchemasComplianceFrameworkCategoryDefinition
 */
export interface ControlAppSchemasComplianceFrameworkCategoryDefinition {
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasComplianceFrameworkCategoryDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlAppSchemasComplianceFrameworkCategoryDefinition
     */
    name: string;
    /**
     * 
     * @type {Array<ControlRequirementDefinition>}
     * @memberof ControlAppSchemasComplianceFrameworkCategoryDefinition
     */
    requirements: Array<ControlRequirementDefinition>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAppSchemasComplianceFrameworkCategoryDefinition
     */
    enable_by_default?: boolean;
}

/**
 * Check if a given object implements the ControlAppSchemasComplianceFrameworkCategoryDefinition interface.
 */
export function instanceOfControlAppSchemasComplianceFrameworkCategoryDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "requirements" in value;

    return isInstance;
}

export function ControlAppSchemasComplianceFrameworkCategoryDefinitionFromJSON(json: any): ControlAppSchemasComplianceFrameworkCategoryDefinition {
    return ControlAppSchemasComplianceFrameworkCategoryDefinitionFromJSONTyped(json, false);
}

export function ControlAppSchemasComplianceFrameworkCategoryDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAppSchemasComplianceFrameworkCategoryDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'requirements': ((json['requirements'] as Array<any>).map(ControlRequirementDefinitionFromJSON)),
        'enable_by_default': !exists(json, 'enable_by_default') ? undefined : json['enable_by_default'],
    };
}

export function ControlAppSchemasComplianceFrameworkCategoryDefinitionToJSON(value?: ControlAppSchemasComplianceFrameworkCategoryDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'requirements': ((value.requirements as Array<any>).map(ControlRequirementDefinitionToJSON)),
        'enable_by_default': value.enable_by_default,
    };
}

