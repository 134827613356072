/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsSentinelOneUserAssessment } from './JumboStructsSentinelOneUserAssessment';
import {
    JumboStructsSentinelOneUserAssessmentFromJSON,
    JumboStructsSentinelOneUserAssessmentFromJSONTyped,
    JumboStructsSentinelOneUserAssessmentToJSON,
} from './JumboStructsSentinelOneUserAssessment';

/**
 * 
 * @export
 * @interface JumboStructsEDRAlertsResult
 */
export interface JumboStructsEDRAlertsResult {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    agentId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    analystVerdict?: JumboStructsEDRAlertsResultAnalystVerdictEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    classification?: JumboStructsEDRAlertsResultClassificationEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    computerName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    identifiedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    mitigationStatusDescription?: JumboStructsEDRAlertsResultMitigationStatusDescriptionEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    status?: JumboStructsEDRAlertsResultStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAlertsResult
     */
    threatName?: string;
    /**
     * 
     * @type {JumboStructsSentinelOneUserAssessment}
     * @memberof JumboStructsEDRAlertsResult
     */
    userAssessment?: JumboStructsSentinelOneUserAssessment;
}


/**
 * @export
 */
export const JumboStructsEDRAlertsResultAnalystVerdictEnum = {
    TruePositive: 'true_positive',
    FalsePositive: 'false_positive',
    Suspicious: 'suspicious',
    Undefined: 'undefined'
} as const;
export type JumboStructsEDRAlertsResultAnalystVerdictEnum = typeof JumboStructsEDRAlertsResultAnalystVerdictEnum[keyof typeof JumboStructsEDRAlertsResultAnalystVerdictEnum];

/**
 * @export
 */
export const JumboStructsEDRAlertsResultClassificationEnum = {
    Malware: 'Malware',
    Pua: 'PUA',
    Manual: 'Manual',
    Ransomware: 'Ransomware'
} as const;
export type JumboStructsEDRAlertsResultClassificationEnum = typeof JumboStructsEDRAlertsResultClassificationEnum[keyof typeof JumboStructsEDRAlertsResultClassificationEnum];

/**
 * @export
 */
export const JumboStructsEDRAlertsResultMitigationStatusDescriptionEnum = {
    Mitigated: 'Mitigated',
    NotMitigated: 'Not Mitigated',
    MarkedAsBenign: 'Marked As Benign'
} as const;
export type JumboStructsEDRAlertsResultMitigationStatusDescriptionEnum = typeof JumboStructsEDRAlertsResultMitigationStatusDescriptionEnum[keyof typeof JumboStructsEDRAlertsResultMitigationStatusDescriptionEnum];

/**
 * @export
 */
export const JumboStructsEDRAlertsResultStatusEnum = {
    Resolved: 'resolved',
    Unresolved: 'unresolved',
    InProgress: 'in_progress'
} as const;
export type JumboStructsEDRAlertsResultStatusEnum = typeof JumboStructsEDRAlertsResultStatusEnum[keyof typeof JumboStructsEDRAlertsResultStatusEnum];


/**
 * Check if a given object implements the JumboStructsEDRAlertsResult interface.
 */
export function instanceOfJumboStructsEDRAlertsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRAlertsResultFromJSON(json: any): JumboStructsEDRAlertsResult {
    return JumboStructsEDRAlertsResultFromJSONTyped(json, false);
}

export function JumboStructsEDRAlertsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRAlertsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agentId': !exists(json, 'agentId') ? undefined : json['agentId'],
        'analystVerdict': !exists(json, 'analystVerdict') ? undefined : json['analystVerdict'],
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'computerName': !exists(json, 'computerName') ? undefined : json['computerName'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'identifiedAt': !exists(json, 'identifiedAt') ? undefined : json['identifiedAt'],
        'mitigationStatusDescription': !exists(json, 'mitigationStatusDescription') ? undefined : json['mitigationStatusDescription'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'threatName': !exists(json, 'threatName') ? undefined : json['threatName'],
        'userAssessment': !exists(json, 'userAssessment') ? undefined : JumboStructsSentinelOneUserAssessmentFromJSON(json['userAssessment']),
    };
}

export function JumboStructsEDRAlertsResultToJSON(value?: JumboStructsEDRAlertsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agentId': value.agentId,
        'analystVerdict': value.analystVerdict,
        'classification': value.classification,
        'computerName': value.computerName,
        'externalId': value.externalId,
        'id': value.id,
        'identifiedAt': value.identifiedAt,
        'mitigationStatusDescription': value.mitigationStatusDescription,
        'status': value.status,
        'threatName': value.threatName,
        'userAssessment': JumboStructsSentinelOneUserAssessmentToJSON(value.userAssessment),
    };
}

