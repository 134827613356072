/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksStatusModelsStatus } from './ExecRiskApiAppApiV1EagerTasksStatusModelsStatus';
import {
    ExecRiskApiAppApiV1EagerTasksStatusModelsStatusFromJSON,
    ExecRiskApiAppApiV1EagerTasksStatusModelsStatusFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksStatusModelsStatusToJSON,
} from './ExecRiskApiAppApiV1EagerTasksStatusModelsStatus';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus
 */
export interface ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksStatusModelsStatus}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus
     */
    form990?: ExecRiskApiAppApiV1EagerTasksStatusModelsStatus;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksStatusModelsStatus}
     * @memberof ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus
     */
    form5500?: ExecRiskApiAppApiV1EagerTasksStatusModelsStatus;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus {
    return ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form990': !exists(json, 'form990') ? undefined : ExecRiskApiAppApiV1EagerTasksStatusModelsStatusFromJSON(json['form990']),
        'form5500': !exists(json, 'form5500') ? undefined : ExecRiskApiAppApiV1EagerTasksStatusModelsStatusFromJSON(json['form5500']),
    };
}

export function ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatusToJSON(value?: ExecRiskApiAppApiV1EagerTasksStatusModelsScanStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form990': ExecRiskApiAppApiV1EagerTasksStatusModelsStatusToJSON(value.form990),
        'form5500': ExecRiskApiAppApiV1EagerTasksStatusModelsStatusToJSON(value.form5500),
    };
}

