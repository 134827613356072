/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsEDRMachineMetricsResult
 */
export interface JumboStructsEDRMachineMetricsResult {
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRMachineMetricsResult
     */
    endpointIntegrationCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRMachineMetricsResult
     */
    firewallDisabledEndpointCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRMachineMetricsResult
     */
    infectedEndpointCount?: number;
}

/**
 * Check if a given object implements the JumboStructsEDRMachineMetricsResult interface.
 */
export function instanceOfJumboStructsEDRMachineMetricsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRMachineMetricsResultFromJSON(json: any): JumboStructsEDRMachineMetricsResult {
    return JumboStructsEDRMachineMetricsResultFromJSONTyped(json, false);
}

export function JumboStructsEDRMachineMetricsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRMachineMetricsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpointIntegrationCount': !exists(json, 'endpointIntegrationCount') ? undefined : json['endpointIntegrationCount'],
        'firewallDisabledEndpointCount': !exists(json, 'firewallDisabledEndpointCount') ? undefined : json['firewallDisabledEndpointCount'],
        'infectedEndpointCount': !exists(json, 'infectedEndpointCount') ? undefined : json['infectedEndpointCount'],
    };
}

export function JumboStructsEDRMachineMetricsResultToJSON(value?: JumboStructsEDRMachineMetricsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpointIntegrationCount': value.endpointIntegrationCount,
        'firewallDisabledEndpointCount': value.firewallDisabledEndpointCount,
        'infectedEndpointCount': value.infectedEndpointCount,
    };
}

