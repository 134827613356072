import {
  Box,
  Button,
  Stack,
  Typography,
} from '@crucible-risk/security-applications';
import Image from 'next/image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useQuerySentinelOneAccounts } from './useQuerySentinelOneAccounts';
import { useDialog } from '@explorer/hooks';
import { useIntl } from 'react-intl';
import { SentinelOneConnectModal } from './SentinelOneConnectModal';
import { SentinelOneDisconnectModal } from './SentinelOneDisconnectModal';
import { getOrderedConnectedAccounts } from './helpers';
interface SentinelOneConnectionProps {
  clientId: string;
}

export function SentinelOneConnection({
  clientId,
}: SentinelOneConnectionProps) {
  const intl = useIntl();
  const { data, refetch } = useQuerySentinelOneAccounts({
    client_id: clientId,
  });

  const { orderedValidAccounts } = getOrderedConnectedAccounts(data);

  const {
    open: openConnect,
    openDialog: openConnectDialog,
    closeDialog: closeConnectDialog,
  } = useDialog();

  const {
    open: openDisconnect,
    openDialog: openDisconnectDialog,
    closeDialog: closeDisconnectDialog,
  } = useDialog();

  const handleConnectClose = (e: { refetch: boolean }) => {
    closeConnectDialog();

    if (e?.refetch) {
      refetch();
    }
  };

  const handleDisconnectClose = (e: { refetch: boolean }) => {
    closeDisconnectDialog();
    
    if (e?.refetch) {
      refetch();
    }
  };

  return (
    <Stack
      direction="row"
      sx={{ p: 2, backgroundColor: 'grey.900' }}
      alignItems="center"
    >
      <Stack flex={1} direction="row" gap={1} alignItems="center">
        <Box
          sx={{
            p: 1,
            pb: 0,
            bgcolor: 'white',
            borderRadius: 3,
            borderColor: 'grey.700',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
        >
          <Image
            src="/img/integrations/SentinelOne.svg"
            alt="SentinelOne"
            width={32}
            height={32}
            unoptimized
          />
        </Box>
        <Stack>
          <Typography>
            {intl.formatMessage({
              id: 'mdr.sentinelone.name',
            })}
          </Typography>
          {(orderedValidAccounts.length > 0) && <Typography variant="caption" color={'grey.300'}>
            {orderedValidAccounts[0].data.managementHost}
          </Typography>}
        </Stack>
      </Stack>
      <Stack>
        {(orderedValidAccounts.length === 0) && <Button variant="outlined" onClick={openConnectDialog}>
          {intl.formatMessage({
            id: 'mdr.sentinelone.connect',
          })}
        </Button>}
        {(orderedValidAccounts.length > 0) && 
          <Stack direction="row" gap={2} alignItems="center">
            <Stack direction="row" gap={1}>
              <CheckCircleIcon color="success" />
              <Typography variant="body1">
                {intl.formatMessage({
                  id: 'mdr.sentinelone.connected',
                })}
              </Typography>
            </Stack>
            <Button variant="outlined" onClick={openDisconnectDialog}>
              {intl.formatMessage({
                id: 'mdr.sentinelone.disconnect',
              })}
            </Button>
          </Stack>}
      </Stack>
      {openConnect && (
        <SentinelOneConnectModal
          clientId={clientId}
          open={openConnect}
          handleClose={handleConnectClose}
        />
      )}
      {openDisconnect && (
        <SentinelOneDisconnectModal
          clientId={clientId}
          accountId={orderedValidAccounts[0].id}
          open={openDisconnect}
          handleClose={handleDisconnectClose}
        />
      )}
    </Stack>
  );
}
