/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
 */
export interface ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    person_name?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    business_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    title_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    average_hours_per_week_rt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    average_hours_per_week_rltd_org_rt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    individual_trustee_or_director_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    institutional_trustee_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    officer_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    key_employee_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    highest_compensated_employee_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    former_ofcr_director_trustee_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    reportable_comp_from_org_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    reportable_comp_from_rltd_org_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA
     */
    other_compensation_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionAFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA {
    return ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionAFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionAFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_name': !exists(json, 'person_name') ? undefined : json['person_name'],
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
        'title_txt': !exists(json, 'title_txt') ? undefined : json['title_txt'],
        'average_hours_per_week_rt': !exists(json, 'average_hours_per_week_rt') ? undefined : json['average_hours_per_week_rt'],
        'average_hours_per_week_rltd_org_rt': !exists(json, 'average_hours_per_week_rltd_org_rt') ? undefined : json['average_hours_per_week_rltd_org_rt'],
        'individual_trustee_or_director_ind': !exists(json, 'individual_trustee_or_director_ind') ? undefined : json['individual_trustee_or_director_ind'],
        'institutional_trustee_ind': !exists(json, 'institutional_trustee_ind') ? undefined : json['institutional_trustee_ind'],
        'officer_ind': !exists(json, 'officer_ind') ? undefined : json['officer_ind'],
        'key_employee_ind': !exists(json, 'key_employee_ind') ? undefined : json['key_employee_ind'],
        'highest_compensated_employee_ind': !exists(json, 'highest_compensated_employee_ind') ? undefined : json['highest_compensated_employee_ind'],
        'former_ofcr_director_trustee_ind': !exists(json, 'former_ofcr_director_trustee_ind') ? undefined : json['former_ofcr_director_trustee_ind'],
        'reportable_comp_from_org_amt': !exists(json, 'reportable_comp_from_org_amt') ? undefined : json['reportable_comp_from_org_amt'],
        'reportable_comp_from_rltd_org_amt': !exists(json, 'reportable_comp_from_rltd_org_amt') ? undefined : json['reportable_comp_from_rltd_org_amt'],
        'other_compensation_amt': !exists(json, 'other_compensation_amt') ? undefined : json['other_compensation_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionAToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990Form990PartVIISectionA | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_name': value.person_name,
        'business_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
        'title_txt': value.title_txt,
        'average_hours_per_week_rt': value.average_hours_per_week_rt,
        'average_hours_per_week_rltd_org_rt': value.average_hours_per_week_rltd_org_rt,
        'individual_trustee_or_director_ind': value.individual_trustee_or_director_ind,
        'institutional_trustee_ind': value.institutional_trustee_ind,
        'officer_ind': value.officer_ind,
        'key_employee_ind': value.key_employee_ind,
        'highest_compensated_employee_ind': value.highest_compensated_employee_ind,
        'former_ofcr_director_trustee_ind': value.former_ofcr_director_trustee_ind,
        'reportable_comp_from_org_amt': value.reportable_comp_from_org_amt,
        'reportable_comp_from_rltd_org_amt': value.reportable_comp_from_rltd_org_amt,
        'other_compensation_amt': value.other_compensation_amt,
    };
}

