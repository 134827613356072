/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg
     */
    OtherOrganizationName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg
     */
    TransactionTypeTxt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg
     */
    InvolvedAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg
     */
    MethodOfAmountDeterminationTxt?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'OtherOrganizationName': !exists(json, 'OtherOrganizationName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['OtherOrganizationName']),
        'TransactionTypeTxt': !exists(json, 'TransactionTypeTxt') ? undefined : json['TransactionTypeTxt'],
        'InvolvedAmt': !exists(json, 'InvolvedAmt') ? undefined : json['InvolvedAmt'],
        'MethodOfAmountDeterminationTxt': !exists(json, 'MethodOfAmountDeterminationTxt') ? undefined : json['MethodOfAmountDeterminationTxt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrgToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRTransactionsRelatedOrg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OtherOrganizationName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.OtherOrganizationName),
        'TransactionTypeTxt': value.TransactionTypeTxt,
        'InvolvedAmt': value.InvolvedAmt,
        'MethodOfAmountDeterminationTxt': value.MethodOfAmountDeterminationTxt,
    };
}

