import React, { memo, useMemo, Dispatch, SetStateAction } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { Grid } from '@explorer/core';
import { Form, FormFieldControl, SubmitButton } from '@explorer/forms';
import {
  FormValues,
  validationSchema,
  initializeFormSchema,
} from './formSchema';
import { useQuerySnackbar } from '@explorer/hooks';
import { useV1APIMutation } from '@explorer/query';
import {
  ControlAssociateUsersResponse,
  ControlClientInfoClient,
  ControlClientInfoResponse,
  ControlClientInfoUser,
  V1ControlWebAdminAssociateUsersClientIdPostRequest,
} from '@explorer/open-api-client';

const formId = 'AssociateUserToClient';

export const AssociateUserToClient = memo(
  //
  function AssociateUserToClient({
    client,
    setClient,
  }: AssociateUserToClientProps) {
    const { client_id } = client?.client ?? {};
    const { snackError, snackSuccess } = useQuerySnackbar();
    const { formatMessage } = useIntl();

    const formik = useFormik<FormValues>({
      validationSchema: validationSchema(formatMessage),
      initialValues: {
        email: [],
      },
      onSubmit: (values) => {
        mutate(values);
      },
    });

    const { mutate, isLoading } = useV1APIMutation<
      ControlAssociateUsersResponse,
      FormValues
    >({
      mutationKey: ['v1ControlWebInviteUsers'],
      mutationFn(args, client) {
        const params: V1ControlWebAdminAssociateUsersClientIdPostRequest = {
          client_id: client_id,
          ControlAssociateUsersRequest: {
            emails: args.email?.length ? args.email : [],
          },
        };

        return client.v1ControlWebAdminAssociateUsersClientIdPost(params);
      },
      explorerAPIOptions: {
        onSuccess: ({ data }) => {
          formik.resetForm();
          formik.setSubmitting(false);

          /**
           * NOTE
           *
           * The `user` object structure returned by
           * this endpoint, is different from the one
           * in `associated_users`.
           */
          const newUsers: ControlClientInfoUser[] = data?.results?.map(
            (item) => ({
              ...item,
              user_id: item?.user_id,
              created_at: item?.created,
              updated_at: item?.updated,
              is_active: item?.active,
              auth0_id_exists: true,
              auth0_roles: [{ name: item?.client?.role, description: '' }],
            }),
          );

          if (setClient) {
            setClient((prevState) => {
              const updatedClientWithUsers = {
                ...prevState,
                associated_users: [
                  ...(prevState?.associated_users ?? []),
                  ...(newUsers ?? []),
                ],
              };

              return updatedClientWithUsers as ControlClientInfoResponse;
            });
          }
          snackSuccess(
            formatMessage(
              { id: 'forms-presets.success-associate-email-to-client' },
              { client: client_id },
            ),
          );
        },
        onError: (error) => {
          formik.resetForm();
          formik.setSubmitting(false);
          snackError('Associate User Error', error?.error);
        },
      },
    });

    const formSchema = useMemo(
      () =>
        initializeFormSchema({
          formId,
          formik,
        }),
      [formik],
    );

    /**
     * Disable the submit button
     */
    const disableSearch = useMemo<boolean>(() => {
      const { isSubmitting, isValid } = formik;

      if (isSubmitting || !isValid || isLoading) return true;

      return false;
    }, [formik, isLoading]);

    return (
      <Form formId={formId} formik={formik}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <FormFieldControl {...formSchema.email} />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <SubmitButton
              formId={formId}
              formik={formik}
              color="primary"
              label={{ id: 'forms-presets.save' }}
              disabled={disableSearch}
            />
          </Grid>
        </Grid>
      </Form>
    );
  },
);

export interface AssociateUserToClientProps {
  readonly client?: ControlClientInfoResponse;
  readonly setClient?: Dispatch<any>;
}
