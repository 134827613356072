/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboPostplaidalertrecipientPostPlaidAlertRecipientResult } from './JumboPostplaidalertrecipientPostPlaidAlertRecipientResult';
import {
    JumboPostplaidalertrecipientPostPlaidAlertRecipientResultFromJSON,
    JumboPostplaidalertrecipientPostPlaidAlertRecipientResultFromJSONTyped,
    JumboPostplaidalertrecipientPostPlaidAlertRecipientResultToJSON,
} from './JumboPostplaidalertrecipientPostPlaidAlertRecipientResult';

/**
 * 
 * @export
 * @interface JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult
 */
export interface JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult {
    /**
     * 
     * @type {Array<JumboPostplaidalertrecipientPostPlaidAlertRecipientResult>}
     * @memberof JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult
     */
    alertRecipients?: Array<JumboPostplaidalertrecipientPostPlaidAlertRecipientResult>;
}

/**
 * Check if a given object implements the JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult interface.
 */
export function instanceOfJumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResultFromJSON(json: any): JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult {
    return JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResultFromJSONTyped(json, false);
}

export function JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertRecipients': !exists(json, 'alertRecipients') ? undefined : ((json['alertRecipients'] as Array<any>).map(JumboPostplaidalertrecipientPostPlaidAlertRecipientResultFromJSON)),
    };
}

export function JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResultToJSON(value?: JumboGetplaidalertrecipientsGetPlaidAlertRecipientsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertRecipients': value.alertRecipients === undefined ? undefined : ((value.alertRecipients as Array<any>).map(JumboPostplaidalertrecipientPostPlaidAlertRecipientResultToJSON)),
    };
}

