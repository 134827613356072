/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress
 */
export interface ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress
     */
    address_line_1_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress
     */
    address_line_2_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress
     */
    city_nm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress
     */
    province_or_state_nm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress
     */
    country_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress
     */
    foreign_postal_cd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress {
    return ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address_line_1_txt': !exists(json, 'address_line_1_txt') ? undefined : json['address_line_1_txt'],
        'address_line_2_txt': !exists(json, 'address_line_2_txt') ? undefined : json['address_line_2_txt'],
        'city_nm': !exists(json, 'city_nm') ? undefined : json['city_nm'],
        'province_or_state_nm': !exists(json, 'province_or_state_nm') ? undefined : json['province_or_state_nm'],
        'country_cd': !exists(json, 'country_cd') ? undefined : json['country_cd'],
        'foreign_postal_cd': !exists(json, 'foreign_postal_cd') ? undefined : json['foreign_postal_cd'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value?: ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address_line_1_txt': value.address_line_1_txt,
        'address_line_2_txt': value.address_line_2_txt,
        'city_nm': value.city_nm,
        'province_or_state_nm': value.province_or_state_nm,
        'country_cd': value.country_cd,
        'foreign_postal_cd': value.foreign_postal_cd,
    };
}

