/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractor } from './ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractor';
import {
    ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorFromJSON,
    ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorFromJSONTyped,
    ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorToJSON,
} from './ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractor';
import type { ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddress } from './ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddress';
import {
    ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSON,
    ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation
 */
export interface ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractor}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation
     */
    contractor_name?: ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractor;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddress}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation
     */
    contractor_address?: ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation
     */
    services_desc?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation
     */
    compensation_amount?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990ContractorCompensation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation {
    return ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contractor_name': !exists(json, 'contractor_name') ? undefined : ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorFromJSON(json['contractor_name']),
        'contractor_address': !exists(json, 'contractor_address') ? undefined : ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddressFromJSON(json['contractor_address']),
        'services_desc': !exists(json, 'services_desc') ? undefined : json['services_desc'],
        'compensation_amount': !exists(json, 'compensation_amount') ? undefined : json['compensation_amount'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990ContractorCompensation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contractor_name': ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorToJSON(value.contractor_name),
        'contractor_address': ExecRiskApiAppCoreModelsScansIRS990ContractorCompensationContractorAddressToJSON(value.contractor_address),
        'services_desc': value.services_desc,
        'compensation_amount': value.compensation_amount,
    };
}

