/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    RelatedOrganizationName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    USAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    ForeignAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    EIN?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    PrimaryActivitiesTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    LegalDomicileStateCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    LegalDomicileForeignCountryCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    DirectControllingNACd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    EntityTypeTxt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    ShareOfTotalIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    ShareOfEOYAssetsAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    OwnershipPct?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr
     */
    ControlledOrganizationInd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'RelatedOrganizationName': !exists(json, 'RelatedOrganizationName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['RelatedOrganizationName']),
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': !exists(json, 'ForeignAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'EIN': !exists(json, 'EIN') ? undefined : json['EIN'],
        'PrimaryActivitiesTxt': !exists(json, 'PrimaryActivitiesTxt') ? undefined : json['PrimaryActivitiesTxt'],
        'LegalDomicileStateCd': !exists(json, 'LegalDomicileStateCd') ? undefined : json['LegalDomicileStateCd'],
        'LegalDomicileForeignCountryCd': !exists(json, 'LegalDomicileForeignCountryCd') ? undefined : json['LegalDomicileForeignCountryCd'],
        'DirectControllingNACd': !exists(json, 'DirectControllingNACd') ? undefined : json['DirectControllingNACd'],
        'EntityTypeTxt': !exists(json, 'EntityTypeTxt') ? undefined : json['EntityTypeTxt'],
        'ShareOfTotalIncomeAmt': !exists(json, 'ShareOfTotalIncomeAmt') ? undefined : json['ShareOfTotalIncomeAmt'],
        'ShareOfEOYAssetsAmt': !exists(json, 'ShareOfEOYAssetsAmt') ? undefined : json['ShareOfEOYAssetsAmt'],
        'OwnershipPct': !exists(json, 'OwnershipPct') ? undefined : json['OwnershipPct'],
        'ControlledOrganizationInd': !exists(json, 'ControlledOrganizationInd') ? undefined : json['ControlledOrganizationInd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTrToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleRIdRelatedOrgTxblCorpTr | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RelatedOrganizationName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.RelatedOrganizationName),
        'USAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'EIN': value.EIN,
        'PrimaryActivitiesTxt': value.PrimaryActivitiesTxt,
        'LegalDomicileStateCd': value.LegalDomicileStateCd,
        'LegalDomicileForeignCountryCd': value.LegalDomicileForeignCountryCd,
        'DirectControllingNACd': value.DirectControllingNACd,
        'EntityTypeTxt': value.EntityTypeTxt,
        'ShareOfTotalIncomeAmt': value.ShareOfTotalIncomeAmt,
        'ShareOfEOYAssetsAmt': value.ShareOfEOYAssetsAmt,
        'OwnershipPct': value.OwnershipPct,
        'ControlledOrganizationInd': value.ControlledOrganizationInd,
    };
}

