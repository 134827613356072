/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetadminmfaenforcedGetAdminMFAEnforcedItems } from './JumboGetadminmfaenforcedGetAdminMFAEnforcedItems';
import {
    JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsFromJSON,
    JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsFromJSONTyped,
    JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsToJSON,
} from './JumboGetadminmfaenforcedGetAdminMFAEnforcedItems';

/**
 * 
 * @export
 * @interface JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult
 */
export interface JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult {
    /**
     * 
     * @type {string}
     * @memberof JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult
     */
    clientID?: string;
    /**
     * 
     * @type {JumboGetadminmfaenforcedGetAdminMFAEnforcedItems}
     * @memberof JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult
     */
    mfaEnforced?: JumboGetadminmfaenforcedGetAdminMFAEnforcedItems;
}

/**
 * Check if a given object implements the JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult interface.
 */
export function instanceOfJumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultFromJSON(json: any): JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult {
    return JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultFromJSONTyped(json, false);
}

export function JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientID': !exists(json, 'clientID') ? undefined : json['clientID'],
        'mfaEnforced': !exists(json, 'mfaEnforced') ? undefined : JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsFromJSON(json['mfaEnforced']),
    };
}

export function JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResultToJSON(value?: JumboGetadminmfaenforcedGetAdminMFAEnforcedOrganizationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientID': value.clientID,
        'mfaEnforced': JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsToJSON(value.mfaEnforced),
    };
}

