/**
 * Message Syntax: https://formatjs.io/docs/core-concepts/icu-syntax
 */
// prettier-ignore
export const en: IntlMessages = {
  /**
   * Global
   */
  'forms-presets.action': 'Action',
  'forms-presets.active-quote': 'Active Quote',
  'forms-presets.additional-domains-optional': 'Additional Domains (Optional)',
  'forms-presets.approve': 'Approve',
  'forms-presets.cancel': 'Cancel',
  'forms-presets.close': 'Close',
  'forms-presets.clear': 'Clear',
  'forms-presets.client-id': 'Client ID',
  'forms-presets.trigger-scan': 'Trigger Scan',
  'forms-presets.trigger-rescan': 'Trigger Rescan',
  'forms-presets.cobranded': 'Cobranded',
  'forms-presets.company-domain': 'Company Domain',
  'forms-presets.company-name': 'Company Name',
  'forms-presets.custom-domain': 'Custom Domain',
  'forms-presets.domains': 'Domains',
  'forms-presets.domains-tooltip': 'Comma-separated list of domains',
  'forms-presets.discovered-domains': 'Discovered Domains',
  'forms-presets.discovered-domains-count': 'Discovered Domains ({count})',
  'forms-presets.discover-domains': 'Discover domains',
  'forms-presets.email': 'Email',
  'forms-presets.emails': 'Emails',
  'forms-presets.roles': 'Role',
  'forms-presets.evidence-image': 'Evidence View',
  'forms-presets.employees': 'Number of Employees',
  'forms-presets.entity-domain': 'Company Domain',
  'forms-presets.entity-logo': 'Company Logo URL',
  'forms-presets.entity-name': 'Company Name',
  'forms-presets.entity-primary-domain': 'Primary Domain',
  'forms-presets.jira-issue': 'Jira Issue',
  'forms-presets.fetch-jira': 'Fetch Jira',
  'forms-presets.fetch-jira-fields-missing-fields': 'Couldn\'t fetch all fields from Jira.',
  'forms-presets.free': 'Free',
  'forms-presets.fill-last-entry': 'Fill with last entry',
  'forms-presets.form-type': 'Form Type',
  'forms-presets.industry': 'Industry',
  'forms-presets.locale': 'Locale',
  'forms-presets.ip-addresses': 'IP Addresses',
  'forms-presets.ip-addresses-tooltip': 'Comma-separated list of IP addresses',
  'forms-presets.legal-disclaimer-asset-removal': 'By clicking "remove", I acknowledge and agree that this is not my firm\'s asset and that I have requested removal of this asset from any monitoring and coverage under my firm\'s cyber policy.',
  'forms-presets.legal-disclaimer-mute-notifications': 'By clicking ‘Mute’, I acknowledge and agree that I would not like to be alerted on this vulnerability in the future. I understand that I may be asked to provide evidence of my reason and that this may impact my firm\'s cyber policy coverage',
  'forms-presets.legal-disclaimer-asset-removal-internal': 'By clicking "remove", I acknowledge and agree that this is not the firm\'s asset and that I will be removing this asset from any monitoring and coverage under this firm\'s cyber policy. This action cannot be undone.',
  'forms-presets.legal-disclaimer-vulnerability-resolve': 'By clicking ‘Resolve’, I acknowledge and agree that I would like to resolve this vulnerability and accept the risk that comes with it. I understand that I may be asked to provide evidence of my reason and that this may impact my firm\'s cyber policy coverage.',
  'forms-presets.locality': 'Locality',
  'forms-presets.monitType': 'Monitoring Type',
  'forms-presets.monit-type-extended': 'Extended',
  'forms-presets.monit-type-lite': 'Lite',
  'forms-presets.modules': 'Modules',
  'forms-presets.module-prepurchase-bundle-description': 'This module was included with the policy as a bundle ({sku})',
  'forms-presets.module-prepurchase-bundle-description-with-price': 'This module was included with the policy as a bundle ({sku}) for {price}',
  'forms-presets.module-prepurchase-bundle-type-no-package': "N/A",
  'forms-presets.module-prepurchase-deletion-title': 'Confirm Purchase',
  'forms-presets.module-prepurchase-deletion-confirmation': '<p>This policyholder was sold bundled Control features when quoted. By activating these modules, you are confirming that the customer has completed the purchase. </p><p> Once confirmed, the customer\'s "checkout cart" will be cleared. <b>This action cannot be undone.</b></p>',
  'forms-presets.msp': 'Enable MSP features',
  'forms-presets.MDR': 'Enable MDR features',
  'forms-presets.SAT': 'Enable Security Awareness Training features',
  'forms-presets.title-MDR': 'Managed Detection and Response (MDR)',
  'forms-presets.title-SAT': 'Security Awareness Training (SAT)',
  'forms-presets.trial': 'Trial',
  'forms-presets.subscription': 'Subscription',
  'forms-presets.expired': 'Expired',
  'forms-presets.stripe': 'Stripe',
  'forms-presets.hubspot': 'Hubspot',
  'forms-presets.explorer': 'Explorer',
  'forms-presets.source-systems': 'Source Systems',
  'forms-presets.full-name': 'Full Name',
  'forms-presets.first-name': 'First Name',
  'forms-presets.last-name': 'Last Name',
  'forms-presets.name': 'Name',
  'forms-presets.options': 'Options',
  'forms-presets.premium': 'Premium',
  'forms-presets.postal-code': 'Postal Code',
  'forms-presets.records': 'PII, PHI, or PCI Records',
  'forms-presets.region': 'Region',
  'forms-presets.reject': 'Reject',
  'forms-presets.resolution-notes': 'Resolution notes',
  'forms-presets.revenue': 'Revenue',
  'forms-presets.reason': 'Reason',
  'forms-presets.search': 'Search',
  'forms-presets.scan-opts-force': 'Force a new scan',
  'forms-presets.scan-opts-force-tooltip': 'Trigger a new scan even if a completed scan with the same targets exists (possible only after <b>six</b> hours from the last scan)',
  'forms-presets.verification-instructions': 'Verification Instructions',
  'forms-presets.verify-instructions-dns': 'Go to your DNS provider\'s management page and sign in to your account. Add a TXT record with a value equal to the verification code shown in the Manage Domains table after the domain has been added successfully. For specific steps, consult your DNS provider\'s documentation.',
  'forms-presets.verify-instructions-web': 'Upload a .txt file to the root web directory of your website. The file should be called "verification.txt" and should contain the verification code shown in the Manage Domains table after the domain has been added successfully.',
  'forms-presets.verify-method': 'Verification Method',
  'forms-presets.verify-dns': 'DNS',
  'forms-presets.verify-web': 'Web',
  'forms-presets.report-asset-reason': 'Reason',
  'forms-presets.mute-notifications-reason': 'Reason to request a mute',
  'forms-presets.save': 'Save',
  'forms-presets.max-lite': 'Max Lite',
  'forms-presets.max-extended': 'Max Extended',
  'forms-presets.quote-number-optional': 'Coalition Quote Number (Optional)',
  'forms-presets.quote-number-optional.tooltip': 'The Coalition Quote Number is meant for active/contingent quotes that have not yet been bound. Please DO NOT include policy numbers that have already been bound.',
  'forms-presets.primary-monitoring': 'Primary Company Monitoring',
  'forms-presets.resolve': 'Resolve',
  'forms-presets.role': 'Role',
  'forms-presets.role-freemium': 'Freemium / Starter pack (Assess)',
  'forms-presets.role-monitor': 'Essential pack (Monitor)',
  'forms-presets.role-policyholder': 'Policyholder / Advanced pack (Insure)',
  'forms-presets.role-premium': 'Premium / Enterprise pack (Customize)',
  'forms-presets.role-internal': 'Internal',
  'forms-presets.role-superuser': 'Super User',
  'forms-presets.enter-domain': 'Enter a domain',
  'forms-presets.org-has-no-domain': 'My organization does not have a company domain',
  'forms-presets.send-activation-link': 'Send Activation Link',
  'forms-presets.source': 'Source',
  'forms-presets.submit-evidence': 'Upload Evidence',
  'forms-presets.submit-evidence-text': 'If you have further evidence to support that this vulnerability has been remediated, please upload an image file for Coalition to review.',
  'forms-presets.upload-evidence': 'Upload Evidence',
  'forms-presets.version': 'Version',
  'forms-presets.trial-start': 'Trial Start',
  'forms-presets.trial-end': 'Trial End',
  'forms-presets.pls-upload-evidence': 'Please Upload Evidence',
  'forms-presets.helper-text.additional-domains': 'Press Enter, use a comma or space to add multiple domains',
  'forms-presets.helper-text.emails': 'Press Enter, use a comma or space to add multiple emails',
  /**
   * Errors
   */
  'forms-errors.invalid-jira': 'The Jira ticket needs to be an integer number',
  'forms-errors.invalid-email': 'The provided email is not valid.',
  'forms-errors.invalid-cobranded': 'The provided cobranded option is either invalid or not available.',
  'forms-errors.invalid-employees': 'The provided a number of employees.',
  'forms-errors.invalid-industry': 'The provided an industry.',
  'forms-errors.invalid-records': 'The provided the number of records.',
  'forms-errors.invalid-revenue': 'The provided a revenue for your company.',
  'forms-errors.invalid-url': 'The provided URL is not valid.',
  'forms-errors.invalid-domains-list': 'The provided list of domains contains an invalid entry.',
  'forms-errors.invalid-ip-address-list': 'The provided list of IP addresses contains an invalid entry.',
  'forms-errors.invalid-entity-domain': 'The provided domain is not valid.',
  'forms-errors.required-email': 'Please provide an email.',
  'forms-errors.required-name': 'Please provide a name.',
  'forms-errors.required-client-id': 'Please provide a client ID.',
  'forms-errors.required-confirm-read': 'Please confirm you have read the disclaimer before proceeding.',
  'forms-errors.required-entity-domain': 'Please provide a company domain.',
  'forms-errors.required-entity-name': 'Please provide a company name.',
  'forms-errors.required-entity-domains': 'Please provide a company domain.',
  'forms-errors.required-ip-addresses': 'Please provide at least one IP address.',
  'forms-errors.required-locality': 'Please provide a locality.',
  'forms-errors.required-postal-code': 'Please provide a postal code.',
  'forms-errors.required-region': 'Please provide a region.',
  'forms-errors.required-region-format': 'Must use region code e.g. CA or US-CA',
  'forms-errors.required-report-asset-reason': 'A reason is required.',
  'forms-errors.required-report-asset-action': 'A valid action type is required.',
  'forms-errors.required-locale': 'Please provide a locale.',
  'forms-errors.required-date-to-from': 'Please fill out both dates.',
  'forms-errors.required-monit-type': 'Please select a monitoring type. If you can\'t select, please consider adding more licenses.',
  'forms-errors.secreview-error-message-reviewedby': '<i>Reported</i> Asset Removal Requests do not yet have a reviewer. Please select an additional <b>Status</b> to search by this field.',
  'forms-presets.fail-invite-user': 'Invite User Failed',
  /**
   * Form Titles
   */
  'forms-presets.addcompany-title': 'Add a new company',
  'forms-presets.adddomains-title': 'Add domains',
  'forms-presets.add-custom-domains-description': 'To add custom domains, you will need to verify your domain ownership in one of two ways. See instructions below.',
  'forms-presets.adddomains-description': 'Add any alternative domains for your organization to ensure your entire attack surface is being monitored for vulnerabilities.',
  'forms-presets.editcompany-title': 'Edit company',
  'forms-presets.editcompanyinsurancedetails-title': 'Update your company information',
  'forms-presets.invitemyteam-description': 'You can invite internal and external collaborators such as colleagues, consultants, or analysts to Coalition Control. Note that anyone who accepts your invitation will be able to  view your Automated Scanning & Monitoring and Watchlist information.',
  'forms-presets.invitemyteam-description-policyholder': 'Invite internal or external security and IT users to get access to your organization\'s ongoing security monitoring including:',
  'forms-presets.invitemyteam-heading': 'Cybersecurity is a team sport.',
  'forms-presets.invitemyteam-heading-policyholder': 'Invite Security & IT Users.',
  'forms-presets.invitemyteam-dont-show-startup': 'Don\'t show again at the startup.',
  'forms-presets.invitemyteam-submit': 'Invite Now',
  'forms-presets.invitemyteam-title': 'Invite Collaborators',
  'forms-presets.invitemyteam-title-policyholder': 'Get Alerts',
  'forms-presets.invitemyteam-topic-1': '• Critical security alerts via email',
  'forms-presets.invitemyteam-topic-2': '• Vulnerability assessments & detailed recommendations',
  'forms-presets.invitemyteam-topic-3': '• Additional 3rd party risk monitoring',
  'forms-presets.invitemyteam-add-more': 'Add More',
  'forms-presets.invitemyteam-invited-users': 'Invited Users',
  'forms-presets.invitemyteam-no-invites-sent': '• No users have been invited.',
  'forms-presets.manage-secreview-request-select': 'Manage Requests ({size} selected)',
  'forms-presets.manage-secreview-subtitle': 'Please review before submission as the Action and resolution notes will apply to all selected requests. This action is permanent!',
  'forms-presets.trigger-cra-scan-title': 'Trigger new CRA Report',
  'forms-presets.trigger-secreview-scan-title': 'Trigger a new scan',
  'forms-presets.trigger-secreview-scan-subtitle': 'Full scan that includes a mix of historical and live data. Used for initial scans and new domains scans.',
  'forms-presets.trigger-secreview-rescan-title': 'Trigger a new rescan',
  'forms-presets.trigger-secreview-rescan-subtitle': 'Partial scan that uses live data only and is used to validate if identified contingencies were fixed.',
  'forms-presets.trigger-secreview-rescan-success': 'Rescan Triggered Successfully',
  'forms-presets.trigger-secreview-rescan-fail': 'Rescan Trigger Failed',
  'forms-presets.report-asset-title': 'Remove this asset?',
  'forms-presets.add-ip-addresses-title': 'Add IP Addresses',
  'forms-presets.add-ip-addresses-text-1': 'If your organization has an IP address that is not already included in your list of assets, please add one or more and click the checkbox below.',
  'forms-presets.add-ip-addresses-text-2': 'Note that newly-added IP addresses will only be viewable in Coalition Control after your next scan.',
  'forms-presets.add-ip-addresses-legal': 'By clicking this box, I am requesting that Coalition add the referenced IP address(es) to the list of assets for this organization. I confirm that I am authorized by the organization to take this action, and understand that this additional information will be used by Coalition when conducting a risk assessment which may lead to the identification of cyber risks and/or vulnerabilities that could impact the organization\'s risk score.',
  'forms-presets.mute-notifications-title': 'Request to mute notifications on this vulnerability?',
  'forms-presets.mute-notifications-sub-title': 'Note: Muting notifications does not remove the vulnerability or change risk score',
  /**
   * Success Messages
   */
  'forms-presets.success-associate-email-to-client': 'Successfully associated emails with to "{client}"',
  'forms-presets.success-upgrade-client': 'You\'ve successfully upgraded "{client}".',
  'forms-presets.success-creating-user': 'You\'ve successfully created a new user.',
  'forms-presets.success-invite-user': 'Invite User Success (200)',
  'forms-presets.success-update-user-role': "Successfully updated user role",
  /**
   * Error Messages
   */
  'forms-presets.error-update-user-role': 'Failed to update user role',
  /**
   * Upload Field Form
   */
  'forms-presets.upload.accepted-formats': '({formats} files accepted)',
  'forms-presets.upload.drag-and-drop': 'Drag and drop or browse',
  'forms-presets.upload.max-image': 'Max image size: {size}',
  'forms-presets.upload.error-image-size': 'Please upload image smaller than {number}',
  'forms-presets.upload.error-image-format': 'Please upload a file with one of the valid file types: {number}',
  'forms-presets.upload.error-file-limit': 'Too many files added. Only {number} allowed',
    /**
   * Zero day alert
   */
  'forms-presets.zero-day-name': 'Alert Name (Internal Use only)',
  'forms-presets.zero-day-title': 'Alert Title (Displayed on all alerts and email content)',
  'forms-presets.zero-day-short-risk-description': 'Short description (Displayed on all alerts and email content)',
  'forms-presets.zero-day-long-risk-description': 'Full description (Displayed on the alert details page in Control)',
  'forms-presets.zero-day-how-to-resolve': 'How to resolve',
  'forms-presets.error.required': 'This field is required',
  'forms-presets.zero-day-reference-title': 'Reference title',
  'forms-presets.zero-day-reference-link': 'Reference link',
  'forms-presets.error.zero-day-url': 'Please enter a valid URL (e.g. https://www.example.com)',
};
