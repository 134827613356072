/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsSentinelOneAgentOSType } from './JumboStructsSentinelOneAgentOSType';
import {
    JumboStructsSentinelOneAgentOSTypeFromJSON,
    JumboStructsSentinelOneAgentOSTypeFromJSONTyped,
    JumboStructsSentinelOneAgentOSTypeToJSON,
} from './JumboStructsSentinelOneAgentOSType';

/**
 * 
 * @export
 * @interface JumboStructsEDRMachine
 */
export interface JumboStructsEDRMachine {
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRMachine
     */
    allowRemoteShell?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    computerName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    domain?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRMachine
     */
    encryptedApplications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    externalIP?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRMachine
     */
    firewallEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    fullDiskScanLastUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRMachine
     */
    infected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRMachine
     */
    isDecommissioned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    lastActiveDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    lastIPToManagement?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    lastLoggedInUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    machineType?: JumboStructsEDRMachineMachineTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    managementHost?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    networkStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    osName?: string;
    /**
     * 
     * @type {JumboStructsSentinelOneAgentOSType}
     * @memberof JumboStructsEDRMachine
     */
    osType?: JumboStructsSentinelOneAgentOSType;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    registeredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRMachine
     */
    siteID?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRMachine
     */
    threatRebootRequired?: boolean;
}


/**
 * @export
 */
export const JumboStructsEDRMachineMachineTypeEnum = {
    Desktop: 'desktop',
    EcsTask: 'ecs_task',
    KubernetesNode: 'kubernetes_node',
    KubernetesPod: 'kubernetes_pod',
    Laptop: 'laptop',
    Server: 'server',
    Storage: 'storage',
    Unknown: 'unknown'
} as const;
export type JumboStructsEDRMachineMachineTypeEnum = typeof JumboStructsEDRMachineMachineTypeEnum[keyof typeof JumboStructsEDRMachineMachineTypeEnum];


/**
 * Check if a given object implements the JumboStructsEDRMachine interface.
 */
export function instanceOfJumboStructsEDRMachine(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRMachineFromJSON(json: any): JumboStructsEDRMachine {
    return JumboStructsEDRMachineFromJSONTyped(json, false);
}

export function JumboStructsEDRMachineFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRMachine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowRemoteShell': !exists(json, 'allowRemoteShell') ? undefined : json['allowRemoteShell'],
        'computerName': !exists(json, 'computerName') ? undefined : json['computerName'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'encryptedApplications': !exists(json, 'encryptedApplications') ? undefined : json['encryptedApplications'],
        'externalIP': !exists(json, 'externalIP') ? undefined : json['externalIP'],
        'firewallEnabled': !exists(json, 'firewallEnabled') ? undefined : json['firewallEnabled'],
        'fullDiskScanLastUpdatedAt': !exists(json, 'fullDiskScanLastUpdatedAt') ? undefined : json['fullDiskScanLastUpdatedAt'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'infected': !exists(json, 'infected') ? undefined : json['infected'],
        'isDecommissioned': !exists(json, 'isDecommissioned') ? undefined : json['isDecommissioned'],
        'lastActiveDate': !exists(json, 'lastActiveDate') ? undefined : json['lastActiveDate'],
        'lastIPToManagement': !exists(json, 'lastIPToManagement') ? undefined : json['lastIPToManagement'],
        'lastLoggedInUserName': !exists(json, 'lastLoggedInUserName') ? undefined : json['lastLoggedInUserName'],
        'machineType': !exists(json, 'machineType') ? undefined : json['machineType'],
        'managementHost': !exists(json, 'managementHost') ? undefined : json['managementHost'],
        'networkStatus': !exists(json, 'networkStatus') ? undefined : json['networkStatus'],
        'osName': !exists(json, 'osName') ? undefined : json['osName'],
        'osType': !exists(json, 'osType') ? undefined : JumboStructsSentinelOneAgentOSTypeFromJSON(json['osType']),
        'registeredAt': !exists(json, 'registeredAt') ? undefined : json['registeredAt'],
        'siteID': !exists(json, 'siteID') ? undefined : json['siteID'],
        'threatRebootRequired': !exists(json, 'threatRebootRequired') ? undefined : json['threatRebootRequired'],
    };
}

export function JumboStructsEDRMachineToJSON(value?: JumboStructsEDRMachine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowRemoteShell': value.allowRemoteShell,
        'computerName': value.computerName,
        'domain': value.domain,
        'encryptedApplications': value.encryptedApplications,
        'externalIP': value.externalIP,
        'firewallEnabled': value.firewallEnabled,
        'fullDiskScanLastUpdatedAt': value.fullDiskScanLastUpdatedAt,
        'id': value.id,
        'infected': value.infected,
        'isDecommissioned': value.isDecommissioned,
        'lastActiveDate': value.lastActiveDate,
        'lastIPToManagement': value.lastIPToManagement,
        'lastLoggedInUserName': value.lastLoggedInUserName,
        'machineType': value.machineType,
        'managementHost': value.managementHost,
        'networkStatus': value.networkStatus,
        'osName': value.osName,
        'osType': JumboStructsSentinelOneAgentOSTypeToJSON(value.osType),
        'registeredAt': value.registeredAt,
        'siteID': value.siteID,
        'threatRebootRequired': value.threatRebootRequired,
    };
}

