/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsEDRAlertNote } from './JumboStructsEDRAlertNote';
import {
    JumboStructsEDRAlertNoteFromJSON,
    JumboStructsEDRAlertNoteFromJSONTyped,
    JumboStructsEDRAlertNoteToJSON,
} from './JumboStructsEDRAlertNote';
import type { JumboStructsSentinelOneUserAssessment } from './JumboStructsSentinelOneUserAssessment';
import {
    JumboStructsSentinelOneUserAssessmentFromJSON,
    JumboStructsSentinelOneUserAssessmentFromJSONTyped,
    JumboStructsSentinelOneUserAssessmentToJSON,
} from './JumboStructsSentinelOneUserAssessment';

/**
 * 
 * @export
 * @interface JumboStructsEDRThreatDetailResult
 */
export interface JumboStructsEDRThreatDetailResult {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    agentId?: string;
    /**
     * 
     * @type {Array<JumboStructsEDRAlertNote>}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    analystNotes?: Array<JumboStructsEDRAlertNote>;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    analystVerdict?: JumboStructsEDRThreatDetailResultAnalystVerdictEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    classification?: JumboStructsEDRThreatDetailResultClassificationEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    computerName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    confidenceLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    endpointId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    filePath?: string;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    fileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    identifiedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    initiatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    ipAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    isKilled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    isQuarantined?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    isRemediated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    managementHost?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    md5?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    mitigationStatusDescription?: JumboStructsEDRThreatDetailResultMitigationStatusDescriptionEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    organizationID?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    originatingProcess?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    osType?: JumboStructsEDRThreatDetailResultOsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    processUser?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    publisherName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    sha1?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    sha256?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    siteID?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    status?: JumboStructsEDRThreatDetailResultStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    threatName?: string;
    /**
     * 
     * @type {JumboStructsSentinelOneUserAssessment}
     * @memberof JumboStructsEDRThreatDetailResult
     */
    userAssessment?: JumboStructsSentinelOneUserAssessment;
}


/**
 * @export
 */
export const JumboStructsEDRThreatDetailResultAnalystVerdictEnum = {
    TruePositive: 'true_positive',
    FalsePositive: 'false_positive',
    Suspicious: 'suspicious',
    Undefined: 'undefined'
} as const;
export type JumboStructsEDRThreatDetailResultAnalystVerdictEnum = typeof JumboStructsEDRThreatDetailResultAnalystVerdictEnum[keyof typeof JumboStructsEDRThreatDetailResultAnalystVerdictEnum];

/**
 * @export
 */
export const JumboStructsEDRThreatDetailResultClassificationEnum = {
    Malware: 'Malware',
    Pua: 'PUA',
    Manual: 'Manual',
    Ransomware: 'Ransomware'
} as const;
export type JumboStructsEDRThreatDetailResultClassificationEnum = typeof JumboStructsEDRThreatDetailResultClassificationEnum[keyof typeof JumboStructsEDRThreatDetailResultClassificationEnum];

/**
 * @export
 */
export const JumboStructsEDRThreatDetailResultMitigationStatusDescriptionEnum = {
    Mitigated: 'Mitigated',
    NotMitigated: 'Not Mitigated',
    MarkedAsBenign: 'Marked As Benign'
} as const;
export type JumboStructsEDRThreatDetailResultMitigationStatusDescriptionEnum = typeof JumboStructsEDRThreatDetailResultMitigationStatusDescriptionEnum[keyof typeof JumboStructsEDRThreatDetailResultMitigationStatusDescriptionEnum];

/**
 * @export
 */
export const JumboStructsEDRThreatDetailResultOsTypeEnum = {
    Linux: 'linux',
    Macos: 'macos',
    Windows: 'windows',
    WindowsLegacy: 'windows_legacy'
} as const;
export type JumboStructsEDRThreatDetailResultOsTypeEnum = typeof JumboStructsEDRThreatDetailResultOsTypeEnum[keyof typeof JumboStructsEDRThreatDetailResultOsTypeEnum];

/**
 * @export
 */
export const JumboStructsEDRThreatDetailResultStatusEnum = {
    Resolved: 'resolved',
    Unresolved: 'unresolved',
    InProgress: 'in_progress'
} as const;
export type JumboStructsEDRThreatDetailResultStatusEnum = typeof JumboStructsEDRThreatDetailResultStatusEnum[keyof typeof JumboStructsEDRThreatDetailResultStatusEnum];


/**
 * Check if a given object implements the JumboStructsEDRThreatDetailResult interface.
 */
export function instanceOfJumboStructsEDRThreatDetailResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRThreatDetailResultFromJSON(json: any): JumboStructsEDRThreatDetailResult {
    return JumboStructsEDRThreatDetailResultFromJSONTyped(json, false);
}

export function JumboStructsEDRThreatDetailResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRThreatDetailResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agentId': !exists(json, 'agentId') ? undefined : json['agentId'],
        'analystNotes': !exists(json, 'analystNotes') ? undefined : ((json['analystNotes'] as Array<any>).map(JumboStructsEDRAlertNoteFromJSON)),
        'analystVerdict': !exists(json, 'analystVerdict') ? undefined : json['analystVerdict'],
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'computerName': !exists(json, 'computerName') ? undefined : json['computerName'],
        'confidenceLevel': !exists(json, 'confidenceLevel') ? undefined : json['confidenceLevel'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'endpointId': !exists(json, 'endpointId') ? undefined : json['endpointId'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'filePath': !exists(json, 'filePath') ? undefined : json['filePath'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'identifiedAt': !exists(json, 'identifiedAt') ? undefined : json['identifiedAt'],
        'initiatedBy': !exists(json, 'initiatedBy') ? undefined : json['initiatedBy'],
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'isKilled': !exists(json, 'isKilled') ? undefined : json['isKilled'],
        'isQuarantined': !exists(json, 'isQuarantined') ? undefined : json['isQuarantined'],
        'isRemediated': !exists(json, 'isRemediated') ? undefined : json['isRemediated'],
        'managementHost': !exists(json, 'managementHost') ? undefined : json['managementHost'],
        'md5': !exists(json, 'md5') ? undefined : json['md5'],
        'mitigationStatusDescription': !exists(json, 'mitigationStatusDescription') ? undefined : json['mitigationStatusDescription'],
        'organizationID': !exists(json, 'organizationID') ? undefined : json['organizationID'],
        'originatingProcess': !exists(json, 'originatingProcess') ? undefined : json['originatingProcess'],
        'osType': !exists(json, 'osType') ? undefined : json['osType'],
        'processUser': !exists(json, 'processUser') ? undefined : json['processUser'],
        'publisherName': !exists(json, 'publisherName') ? undefined : json['publisherName'],
        'sha1': !exists(json, 'sha1') ? undefined : json['sha1'],
        'sha256': !exists(json, 'sha256') ? undefined : json['sha256'],
        'siteID': !exists(json, 'siteID') ? undefined : json['siteID'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'threatName': !exists(json, 'threatName') ? undefined : json['threatName'],
        'userAssessment': !exists(json, 'userAssessment') ? undefined : JumboStructsSentinelOneUserAssessmentFromJSON(json['userAssessment']),
    };
}

export function JumboStructsEDRThreatDetailResultToJSON(value?: JumboStructsEDRThreatDetailResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agentId': value.agentId,
        'analystNotes': value.analystNotes === undefined ? undefined : ((value.analystNotes as Array<any>).map(JumboStructsEDRAlertNoteToJSON)),
        'analystVerdict': value.analystVerdict,
        'classification': value.classification,
        'computerName': value.computerName,
        'confidenceLevel': value.confidenceLevel,
        'createdAt': value.createdAt,
        'endpointId': value.endpointId,
        'externalId': value.externalId,
        'fileName': value.fileName,
        'filePath': value.filePath,
        'fileSize': value.fileSize,
        'id': value.id,
        'identifiedAt': value.identifiedAt,
        'initiatedBy': value.initiatedBy,
        'ipAddress': value.ipAddress,
        'isKilled': value.isKilled,
        'isQuarantined': value.isQuarantined,
        'isRemediated': value.isRemediated,
        'managementHost': value.managementHost,
        'md5': value.md5,
        'mitigationStatusDescription': value.mitigationStatusDescription,
        'organizationID': value.organizationID,
        'originatingProcess': value.originatingProcess,
        'osType': value.osType,
        'processUser': value.processUser,
        'publisherName': value.publisherName,
        'sha1': value.sha1,
        'sha256': value.sha256,
        'siteID': value.siteID,
        'status': value.status,
        'threatName': value.threatName,
        'userAssessment': JumboStructsSentinelOneUserAssessmentToJSON(value.userAssessment),
    };
}

