/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitHostingAggResult } from './MonitHostingAggResult';
import {
    MonitHostingAggResultFromJSON,
    MonitHostingAggResultFromJSONTyped,
    MonitHostingAggResultToJSON,
} from './MonitHostingAggResult';
import type { MonitISPAggResult } from './MonitISPAggResult';
import {
    MonitISPAggResultFromJSON,
    MonitISPAggResultFromJSONTyped,
    MonitISPAggResultToJSON,
} from './MonitISPAggResult';

/**
 * 
 * @export
 * @interface MonitEntityHostingAggEntry
 */
export interface MonitEntityHostingAggEntry {
    /**
     * 
     * @type {MonitISPAggResult}
     * @memberof MonitEntityHostingAggEntry
     */
    isp?: MonitISPAggResult;
    /**
     * 
     * @type {MonitHostingAggResult}
     * @memberof MonitEntityHostingAggEntry
     */
    hosting?: MonitHostingAggResult;
}

/**
 * Check if a given object implements the MonitEntityHostingAggEntry interface.
 */
export function instanceOfMonitEntityHostingAggEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityHostingAggEntryFromJSON(json: any): MonitEntityHostingAggEntry {
    return MonitEntityHostingAggEntryFromJSONTyped(json, false);
}

export function MonitEntityHostingAggEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityHostingAggEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isp': !exists(json, 'isp') ? undefined : MonitISPAggResultFromJSON(json['isp']),
        'hosting': !exists(json, 'hosting') ? undefined : MonitHostingAggResultFromJSON(json['hosting']),
    };
}

export function MonitEntityHostingAggEntryToJSON(value?: MonitEntityHostingAggEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isp': MonitISPAggResultToJSON(value.isp),
        'hosting': MonitHostingAggResultToJSON(value.hosting),
    };
}

