/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress
     */
    supported_organization_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress
     */
    city_nm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress
     */
    state_abbreviation_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress
     */
    country_cd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supported_organization_name': !exists(json, 'supported_organization_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['supported_organization_name']),
        'city_nm': !exists(json, 'city_nm') ? undefined : json['city_nm'],
        'state_abbreviation_cd': !exists(json, 'state_abbreviation_cd') ? undefined : json['state_abbreviation_cd'],
        'country_cd': !exists(json, 'country_cd') ? undefined : json['country_cd'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddressToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleAHospitalNameAndAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supported_organization_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.supported_organization_name),
        'city_nm': value.city_nm,
        'state_abbreviation_cd': value.state_abbreviation_cd,
        'country_cd': value.country_cd,
    };
}

