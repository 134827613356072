/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody
 */
export interface JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody {
    /**
     * 
     * @type {string}
     * @memberof JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody
     */
    reviewStatus: JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyReviewStatusEnum;
}


/**
 * @export
 */
export const JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyReviewStatusEnum = {
    Approved: 'approved'
} as const;
export type JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyReviewStatusEnum = typeof JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyReviewStatusEnum[keyof typeof JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyReviewStatusEnum];


/**
 * Check if a given object implements the JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody interface.
 */
export function instanceOfJumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reviewStatus" in value;

    return isInstance;
}

export function JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyFromJSON(json: any): JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody {
    return JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyFromJSONTyped(json, false);
}

export function JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviewStatus': json['reviewStatus'],
    };
}

export function JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBodyToJSON(value?: JumboPostplaidtransactionsidreviewPostPlaidTransactionsIDReviewRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviewStatus': value.reviewStatus,
    };
}

