/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule
     */
    name_of_organization?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule
     */
    organization_type_desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule
     */
    description_of_relationship?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name_of_organization': !exists(json, 'name_of_organization') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['name_of_organization']),
        'organization_type_desc': !exists(json, 'organization_type_desc') ? undefined : json['organization_type_desc'],
        'description_of_relationship': !exists(json, 'description_of_relationship') ? undefined : json['description_of_relationship'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipScheduleToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleARelationshipSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name_of_organization': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.name_of_organization),
        'organization_type_desc': value.organization_type_desc,
        'description_of_relationship': value.description_of_relationship,
    };
}

