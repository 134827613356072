/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
 */
export interface ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership {
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    related_organization_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    us_address?: ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    foreign_address?: ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    primary_activities_txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    legal_domicile_state_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    legal_domicile_foreign_country_cd?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    direct_controlling_entity_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    direct_controlling_na_cd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    predominant_income_type_txt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    share_of_total_income_amt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    share_of_eoy_assets_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    disproportionate_allocations_ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    ubi_code_v_amt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    general_or_managing_partner_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership
     */
    ownership_pct?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership {
    return ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'related_organization_name': !exists(json, 'related_organization_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['related_organization_name']),
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
        'ein': !exists(json, 'ein') ? undefined : json['ein'],
        'primary_activities_txt': !exists(json, 'primary_activities_txt') ? undefined : json['primary_activities_txt'],
        'legal_domicile_state_cd': !exists(json, 'legal_domicile_state_cd') ? undefined : json['legal_domicile_state_cd'],
        'legal_domicile_foreign_country_cd': !exists(json, 'legal_domicile_foreign_country_cd') ? undefined : json['legal_domicile_foreign_country_cd'],
        'direct_controlling_entity_name': !exists(json, 'direct_controlling_entity_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['direct_controlling_entity_name']),
        'direct_controlling_na_cd': !exists(json, 'direct_controlling_na_cd') ? undefined : json['direct_controlling_na_cd'],
        'predominant_income_type_txt': !exists(json, 'predominant_income_type_txt') ? undefined : json['predominant_income_type_txt'],
        'share_of_total_income_amt': !exists(json, 'share_of_total_income_amt') ? undefined : json['share_of_total_income_amt'],
        'share_of_eoy_assets_amt': !exists(json, 'share_of_eoy_assets_amt') ? undefined : json['share_of_eoy_assets_amt'],
        'disproportionate_allocations_ind': !exists(json, 'disproportionate_allocations_ind') ? undefined : json['disproportionate_allocations_ind'],
        'ubi_code_v_amt': !exists(json, 'ubi_code_v_amt') ? undefined : json['ubi_code_v_amt'],
        'general_or_managing_partner_ind': !exists(json, 'general_or_managing_partner_ind') ? undefined : json['general_or_managing_partner_ind'],
        'ownership_pct': !exists(json, 'ownership_pct') ? undefined : json['ownership_pct'],
    };
}

export function ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnershipToJSON(value?: ExecRiskApiAppCoreModelsScansForm990ScheduleRIdRelatedOrgTxblPartnership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'related_organization_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.related_organization_name),
        'us_address': ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
        'ein': value.ein,
        'primary_activities_txt': value.primary_activities_txt,
        'legal_domicile_state_cd': value.legal_domicile_state_cd,
        'legal_domicile_foreign_country_cd': value.legal_domicile_foreign_country_cd,
        'direct_controlling_entity_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.direct_controlling_entity_name),
        'direct_controlling_na_cd': value.direct_controlling_na_cd,
        'predominant_income_type_txt': value.predominant_income_type_txt,
        'share_of_total_income_amt': value.share_of_total_income_amt,
        'share_of_eoy_assets_amt': value.share_of_eoy_assets_amt,
        'disproportionate_allocations_ind': value.disproportionate_allocations_ind,
        'ubi_code_v_amt': value.ubi_code_v_amt,
        'general_or_managing_partner_ind': value.general_or_managing_partner_ind,
        'ownership_pct': value.ownership_pct,
    };
}

