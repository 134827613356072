/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult
 */
export interface JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult {
    /**
     * 
     * @type {number}
     * @memberof JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult
     */
    allVersionsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult
     */
    endpointsWithVulnerableVersions?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult
     */
    installedApplicationsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult
     */
    vulnerableVersions?: number;
}

/**
 * Check if a given object implements the JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult interface.
 */
export function instanceOfJumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResultFromJSON(json: any): JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult {
    return JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResultFromJSONTyped(json, false);
}

export function JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allVersionsCount': !exists(json, 'allVersionsCount') ? undefined : json['allVersionsCount'],
        'endpointsWithVulnerableVersions': !exists(json, 'endpointsWithVulnerableVersions') ? undefined : json['endpointsWithVulnerableVersions'],
        'installedApplicationsCount': !exists(json, 'installedApplicationsCount') ? undefined : json['installedApplicationsCount'],
        'vulnerableVersions': !exists(json, 'vulnerableVersions') ? undefined : json['vulnerableVersions'],
    };
}

export function JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResultToJSON(value?: JumboGetsentineloneapplicationsmetricsGetSentineloneApplicationsMetricsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allVersionsCount': value.allVersionsCount,
        'endpointsWithVulnerableVersions': value.endpointsWithVulnerableVersions,
        'installedApplicationsCount': value.installedApplicationsCount,
        'vulnerableVersions': value.vulnerableVersions,
    };
}

