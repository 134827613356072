/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals
 */
export interface JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals {
    /**
     * 
     * @type {number}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals
     */
    assigned?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals
     */
    completed?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals
     */
    inProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals
     */
    notStarted?: number;
}

/**
 * Check if a given object implements the JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals interface.
 */
export function instanceOfJumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSON(json: any): JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals {
    return JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSONTyped(json, false);
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assigned': !exists(json, 'assigned') ? undefined : json['assigned'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'inProgress': !exists(json, 'inProgress') ? undefined : json['inProgress'],
        'notStarted': !exists(json, 'notStarted') ? undefined : json['notStarted'],
    };
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsToJSON(value?: JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assigned': value.assigned,
        'completed': value.completed,
        'inProgress': value.inProgress,
        'notStarted': value.notStarted,
    };
}

