/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetadminmfaenforcedGetAdminMFAEnforcedItem } from './JumboGetadminmfaenforcedGetAdminMFAEnforcedItem';
import {
    JumboGetadminmfaenforcedGetAdminMFAEnforcedItemFromJSON,
    JumboGetadminmfaenforcedGetAdminMFAEnforcedItemFromJSONTyped,
    JumboGetadminmfaenforcedGetAdminMFAEnforcedItemToJSON,
} from './JumboGetadminmfaenforcedGetAdminMFAEnforcedItem';

/**
 * 
 * @export
 * @interface JumboGetadminmfaenforcedGetAdminMFAEnforcedItems
 */
export interface JumboGetadminmfaenforcedGetAdminMFAEnforcedItems {
    /**
     * 
     * @type {JumboGetadminmfaenforcedGetAdminMFAEnforcedItem}
     * @memberof JumboGetadminmfaenforcedGetAdminMFAEnforcedItems
     */
    aws?: JumboGetadminmfaenforcedGetAdminMFAEnforcedItem;
    /**
     * 
     * @type {JumboGetadminmfaenforcedGetAdminMFAEnforcedItem}
     * @memberof JumboGetadminmfaenforcedGetAdminMFAEnforcedItems
     */
    googleWorkspace?: JumboGetadminmfaenforcedGetAdminMFAEnforcedItem;
    /**
     * 
     * @type {JumboGetadminmfaenforcedGetAdminMFAEnforcedItem}
     * @memberof JumboGetadminmfaenforcedGetAdminMFAEnforcedItems
     */
    microsoft365?: JumboGetadminmfaenforcedGetAdminMFAEnforcedItem;
}

/**
 * Check if a given object implements the JumboGetadminmfaenforcedGetAdminMFAEnforcedItems interface.
 */
export function instanceOfJumboGetadminmfaenforcedGetAdminMFAEnforcedItems(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsFromJSON(json: any): JumboGetadminmfaenforcedGetAdminMFAEnforcedItems {
    return JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsFromJSONTyped(json, false);
}

export function JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetadminmfaenforcedGetAdminMFAEnforcedItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aws': !exists(json, 'aws') ? undefined : JumboGetadminmfaenforcedGetAdminMFAEnforcedItemFromJSON(json['aws']),
        'googleWorkspace': !exists(json, 'googleWorkspace') ? undefined : JumboGetadminmfaenforcedGetAdminMFAEnforcedItemFromJSON(json['googleWorkspace']),
        'microsoft365': !exists(json, 'microsoft365') ? undefined : JumboGetadminmfaenforcedGetAdminMFAEnforcedItemFromJSON(json['microsoft365']),
    };
}

export function JumboGetadminmfaenforcedGetAdminMFAEnforcedItemsToJSON(value?: JumboGetadminmfaenforcedGetAdminMFAEnforcedItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aws': JumboGetadminmfaenforcedGetAdminMFAEnforcedItemToJSON(value.aws),
        'googleWorkspace': JumboGetadminmfaenforcedGetAdminMFAEnforcedItemToJSON(value.googleWorkspace),
        'microsoft365': JumboGetadminmfaenforcedGetAdminMFAEnforcedItemToJSON(value.microsoft365),
    };
}

