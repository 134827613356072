/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsPlaidTransactionCounterparties } from './JumboStructsPlaidTransactionCounterparties';
import {
    JumboStructsPlaidTransactionCounterpartiesFromJSON,
    JumboStructsPlaidTransactionCounterpartiesFromJSONTyped,
    JumboStructsPlaidTransactionCounterpartiesToJSON,
} from './JumboStructsPlaidTransactionCounterparties';
import type { JumboStructsPlaidTransactionHistory } from './JumboStructsPlaidTransactionHistory';
import {
    JumboStructsPlaidTransactionHistoryFromJSON,
    JumboStructsPlaidTransactionHistoryFromJSONTyped,
    JumboStructsPlaidTransactionHistoryToJSON,
} from './JumboStructsPlaidTransactionHistory';
import type { JumboStructsPlaidTransactionLabel } from './JumboStructsPlaidTransactionLabel';
import {
    JumboStructsPlaidTransactionLabelFromJSON,
    JumboStructsPlaidTransactionLabelFromJSONTyped,
    JumboStructsPlaidTransactionLabelToJSON,
} from './JumboStructsPlaidTransactionLabel';
import type { JumboStructsPlaidTransactionPaymentMeta } from './JumboStructsPlaidTransactionPaymentMeta';
import {
    JumboStructsPlaidTransactionPaymentMetaFromJSON,
    JumboStructsPlaidTransactionPaymentMetaFromJSONTyped,
    JumboStructsPlaidTransactionPaymentMetaToJSON,
} from './JumboStructsPlaidTransactionPaymentMeta';
import type { JumboStructsPlaidTransactionStatus } from './JumboStructsPlaidTransactionStatus';
import {
    JumboStructsPlaidTransactionStatusFromJSON,
    JumboStructsPlaidTransactionStatusFromJSONTyped,
    JumboStructsPlaidTransactionStatusToJSON,
} from './JumboStructsPlaidTransactionStatus';

/**
 * 
 * @export
 * @interface JumboGetplaidtransactionGetPlaidTransactionResult
 */
export interface JumboGetplaidtransactionGetPlaidTransactionResult {
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    accountID?: string;
    /**
     * 
     * @type {number}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    amountCents?: number;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    authorizedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    authorizedDatetime?: string;
    /**
     * 
     * @type {Array<JumboStructsPlaidTransactionCounterparties>}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    counterparties?: Array<JumboStructsPlaidTransactionCounterparties>;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    datetime?: string;
    /**
     * 
     * @type {Array<JumboStructsPlaidTransactionHistory>}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    history?: Array<JumboStructsPlaidTransactionHistory>;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    isoCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    itemID?: string;
    /**
     * 
     * @type {Array<JumboStructsPlaidTransactionLabel>}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    labels?: Array<JumboStructsPlaidTransactionLabel>;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    merchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    originalDescription?: string;
    /**
     * 
     * @type {JumboStructsPlaidTransactionPaymentMeta}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    paymentMeta?: JumboStructsPlaidTransactionPaymentMeta;
    /**
     * 
     * @type {boolean}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    pending?: boolean;
    /**
     * 
     * @type {Array<JumboStructsPlaidTransactionStatus>}
     * @memberof JumboGetplaidtransactionGetPlaidTransactionResult
     */
    statuses?: Array<JumboStructsPlaidTransactionStatus>;
}

/**
 * Check if a given object implements the JumboGetplaidtransactionGetPlaidTransactionResult interface.
 */
export function instanceOfJumboGetplaidtransactionGetPlaidTransactionResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetplaidtransactionGetPlaidTransactionResultFromJSON(json: any): JumboGetplaidtransactionGetPlaidTransactionResult {
    return JumboGetplaidtransactionGetPlaidTransactionResultFromJSONTyped(json, false);
}

export function JumboGetplaidtransactionGetPlaidTransactionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetplaidtransactionGetPlaidTransactionResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountID': !exists(json, 'accountID') ? undefined : json['accountID'],
        'amountCents': !exists(json, 'amountCents') ? undefined : json['amountCents'],
        'authorizedDate': !exists(json, 'authorizedDate') ? undefined : json['authorizedDate'],
        'authorizedDatetime': !exists(json, 'authorizedDatetime') ? undefined : json['authorizedDatetime'],
        'counterparties': !exists(json, 'counterparties') ? undefined : ((json['counterparties'] as Array<any>).map(JumboStructsPlaidTransactionCounterpartiesFromJSON)),
        'date': !exists(json, 'date') ? undefined : json['date'],
        'datetime': !exists(json, 'datetime') ? undefined : json['datetime'],
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(JumboStructsPlaidTransactionHistoryFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isoCurrencyCode': !exists(json, 'isoCurrencyCode') ? undefined : json['isoCurrencyCode'],
        'itemID': !exists(json, 'itemID') ? undefined : json['itemID'],
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(JumboStructsPlaidTransactionLabelFromJSON)),
        'merchantName': !exists(json, 'merchantName') ? undefined : json['merchantName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'originalDescription': !exists(json, 'originalDescription') ? undefined : json['originalDescription'],
        'paymentMeta': !exists(json, 'paymentMeta') ? undefined : JumboStructsPlaidTransactionPaymentMetaFromJSON(json['paymentMeta']),
        'pending': !exists(json, 'pending') ? undefined : json['pending'],
        'statuses': !exists(json, 'statuses') ? undefined : ((json['statuses'] as Array<any>).map(JumboStructsPlaidTransactionStatusFromJSON)),
    };
}

export function JumboGetplaidtransactionGetPlaidTransactionResultToJSON(value?: JumboGetplaidtransactionGetPlaidTransactionResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountID': value.accountID,
        'amountCents': value.amountCents,
        'authorizedDate': value.authorizedDate,
        'authorizedDatetime': value.authorizedDatetime,
        'counterparties': value.counterparties === undefined ? undefined : ((value.counterparties as Array<any>).map(JumboStructsPlaidTransactionCounterpartiesToJSON)),
        'date': value.date,
        'datetime': value.datetime,
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(JumboStructsPlaidTransactionHistoryToJSON)),
        'id': value.id,
        'isoCurrencyCode': value.isoCurrencyCode,
        'itemID': value.itemID,
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(JumboStructsPlaidTransactionLabelToJSON)),
        'merchantName': value.merchantName,
        'name': value.name,
        'originalDescription': value.originalDescription,
        'paymentMeta': JumboStructsPlaidTransactionPaymentMetaToJSON(value.paymentMeta),
        'pending': value.pending,
        'statuses': value.statuses === undefined ? undefined : ((value.statuses as Array<any>).map(JumboStructsPlaidTransactionStatusToJSON)),
    };
}

