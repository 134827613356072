/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName
     */
    BusinessNameLine1Txt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName
     */
    BusinessNameLine2Txt?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'BusinessNameLine1Txt': !exists(json, 'BusinessNameLine1Txt') ? undefined : json['BusinessNameLine1Txt'],
        'BusinessNameLine2Txt': !exists(json, 'BusinessNameLine2Txt') ? undefined : json['BusinessNameLine2Txt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BusinessNameLine1Txt': value.BusinessNameLine1Txt,
        'BusinessNameLine2Txt': value.BusinessNameLine2Txt,
    };
}

