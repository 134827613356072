/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartI } from './ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartI';
import {
    ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartIFromJSON,
    ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartIFromJSONTyped,
    ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartIToJSON,
} from './ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartI';
import type { ExecRiskScheduleHBase } from './ExecRiskScheduleHBase';
import {
    ExecRiskScheduleHBaseFromJSON,
    ExecRiskScheduleHBaseFromJSONTyped,
    ExecRiskScheduleHBaseToJSON,
} from './ExecRiskScheduleHBase';

/**
 * 
 * @export
 * @interface ExecRiskScheduleHDef
 */
export interface ExecRiskScheduleHDef {
    /**
     * 
     * @type {ExecRiskScheduleHBase}
     * @memberof ExecRiskScheduleHDef
     */
    base_form?: ExecRiskScheduleHBase;
    /**
     * 
     * @type {Array<ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartI>}
     * @memberof ExecRiskScheduleHDef
     */
    part1_q5?: Array<ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartI>;
}

/**
 * Check if a given object implements the ExecRiskScheduleHDef interface.
 */
export function instanceOfExecRiskScheduleHDef(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskScheduleHDefFromJSON(json: any): ExecRiskScheduleHDef {
    return ExecRiskScheduleHDefFromJSONTyped(json, false);
}

export function ExecRiskScheduleHDefFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskScheduleHDef {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_form': !exists(json, 'base_form') ? undefined : ExecRiskScheduleHBaseFromJSON(json['base_form']),
        'part1_q5': !exists(json, 'part1_q5') ? undefined : ((json['part1_q5'] as Array<any>).map(ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartIFromJSON)),
    };
}

export function ExecRiskScheduleHDefToJSON(value?: ExecRiskScheduleHDef | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_form': ExecRiskScheduleHBaseToJSON(value.base_form),
        'part1_q5': value.part1_q5 === undefined ? undefined : ((value.part1_q5 as Array<any>).map(ExecRiskApiAppCoreModelsScansLongForm5500ScheduleHDefPartIToJSON)),
    };
}

