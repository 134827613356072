/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData';
import type { ExecRiskReturnHeader } from './ExecRiskReturnHeader';
import {
    ExecRiskReturnHeaderFromJSON,
    ExecRiskReturnHeaderFromJSONTyped,
    ExecRiskReturnHeaderToJSON,
} from './ExecRiskReturnHeader';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990 {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990
     */
    irs_object_id: string;
    /**
     * 
     * @type {ExecRiskReturnHeader}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990
     */
    ReturnHeader: ExecRiskReturnHeader;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990
     */
    ReturnData: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnData;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990 interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "irs_object_id" in value;
    isInstance = isInstance && "ReturnHeader" in value;
    isInstance = isInstance && "ReturnData" in value;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990FromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990 {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990FromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'irs_object_id': json['irs_object_id'],
        'ReturnHeader': ExecRiskReturnHeaderFromJSON(json['ReturnHeader']),
        'ReturnData': ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSON(json['ReturnData']),
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'irs_object_id': value.irs_object_id,
        'ReturnHeader': ExecRiskReturnHeaderToJSON(value.ReturnHeader),
        'ReturnData': ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ReturnDataToJSON(value.ReturnData),
    };
}

