/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsMDREndpoint
 */
export interface JumboStructsMDREndpoint {
    /**
     * 
     * @type {number}
     * @memberof JumboStructsMDREndpoint
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsMDREndpoint
     */
    osName?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsMDREndpoint
     */
    osType?: JumboStructsMDREndpointOsTypeEnum;
}


/**
 * @export
 */
export const JumboStructsMDREndpointOsTypeEnum = {
    Linux: 'linux',
    Macos: 'macos',
    Windows: 'windows',
    WindowsLegacy: 'windows_legacy'
} as const;
export type JumboStructsMDREndpointOsTypeEnum = typeof JumboStructsMDREndpointOsTypeEnum[keyof typeof JumboStructsMDREndpointOsTypeEnum];


/**
 * Check if a given object implements the JumboStructsMDREndpoint interface.
 */
export function instanceOfJumboStructsMDREndpoint(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsMDREndpointFromJSON(json: any): JumboStructsMDREndpoint {
    return JumboStructsMDREndpointFromJSONTyped(json, false);
}

export function JumboStructsMDREndpointFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsMDREndpoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'osName': !exists(json, 'osName') ? undefined : json['osName'],
        'osType': !exists(json, 'osType') ? undefined : json['osType'],
    };
}

export function JumboStructsMDREndpointToJSON(value?: JumboStructsMDREndpoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'osName': value.osName,
        'osType': value.osType,
    };
}

