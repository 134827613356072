import { useV1APIQuery } from '@explorer/query';
import { useQuerySnackbar } from '@explorer/hooks';
import { JumboStructsGetEDRAccountResult } from '@explorer/open-api-client';

export const useQuerySentinelOneAccounts = (params: { client_id: string }) => {
  const { client_id } = params;
  const { snackError } = useQuerySnackbar();

  return useV1APIQuery<JumboStructsGetEDRAccountResult>({
    queryKey: ['v1IntegrationsWebAdminSentineloneAccountsGet'],
    queryFn: (client, { signal }) => {
      return client.v1IntegrationsWebAdminSentineloneAccountsGet({ signal });
    },
    explorerAPIOptions: {
      clientId: client_id,
      onError: (error) => {
        snackError('Failed to get SentinelOne Accounts.', error.error);
      },
    },
    retry: false,
    enabled: !!client_id,
    refetchOnWindowFocus: false,
  });
};
