/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals } from './JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals';
import {
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSON,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSONTyped,
    JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsToJSON,
} from './JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals';

/**
 * 
 * @export
 * @interface JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
 */
export interface JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails {
    /**
     * 
     * @type {number}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    daysToComplete?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    estimatedTimeInMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    imageURL?: string;
    /**
     * 
     * @type {number}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    numberOfAssets?: number;
    /**
     * 
     * @type {string}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    title?: string;
    /**
     * 
     * @type {JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    totals?: JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotals;
    /**
     * 
     * @type {string}
     * @memberof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails
     */
    typeOfAssets?: JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsTypeOfAssetsEnum;
}


/**
 * @export
 */
export const JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsTypeOfAssetsEnum = {
    Videos: 'videos',
    Questions: 'questions'
} as const;
export type JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsTypeOfAssetsEnum = typeof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsTypeOfAssetsEnum[keyof typeof JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsTypeOfAssetsEnum];


/**
 * Check if a given object implements the JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails interface.
 */
export function instanceOfJumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsFromJSON(json: any): JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails {
    return JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsFromJSONTyped(json, false);
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daysToComplete': !exists(json, 'daysToComplete') ? undefined : json['daysToComplete'],
        'estimatedTimeInMinutes': !exists(json, 'estimatedTimeInMinutes') ? undefined : json['estimatedTimeInMinutes'],
        'imageURL': !exists(json, 'imageURL') ? undefined : json['imageURL'],
        'numberOfAssets': !exists(json, 'numberOfAssets') ? undefined : json['numberOfAssets'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'totals': !exists(json, 'totals') ? undefined : JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsFromJSON(json['totals']),
        'typeOfAssets': !exists(json, 'typeOfAssets') ? undefined : json['typeOfAssets'],
    };
}

export function JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetailsToJSON(value?: JumboGetwizercompanyreportGetWizerCompanyReportTrainingDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daysToComplete': value.daysToComplete,
        'estimatedTimeInMinutes': value.estimatedTimeInMinutes,
        'imageURL': value.imageURL,
        'numberOfAssets': value.numberOfAssets,
        'startDate': value.startDate,
        'title': value.title,
        'totals': JumboGetwizercompanyreportGetWizerCompanyReportTrainingsTotalsToJSON(value.totals),
        'typeOfAssets': value.typeOfAssets,
    };
}

