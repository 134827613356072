import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { alpha, Theme, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Divider, Grid, Typography, TypographyDate } from '@explorer/core';
import { useDialog } from '@explorer/hooks';
import { ActiveUserIcon } from '@explorer/icons';
import { AssociatedUserModal } from './AssociatedUserModal';
import { appConfig } from '@explorer/src';

const useStyles = () =>
  makeStyles(
    (theme: Theme) =>
      createStyles({
        scroll: {
          height: '395px',
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey[500],
            borderRadius: 2,
          },
        },
      }),
    { index: 1 },
  )();

export const AssociatedUsersList = memo(
  //
  function AssociatedUsersList({ associatedUsers }: AssociatedUsersListProps) {
    const { palette } = useTheme();
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { open, openDialog, closeDialog } = useDialog();
    const [userKey, setUserKey] = useState<string>('');

    const handleClickOpenModal = (key: string) => {
      setUserKey(key);
      openDialog();
    };

    return (
      <Box
        className={classes.scroll}
        sx={{
          border: `1px solid ${alpha(palette.text.primary, 0.23)}`,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="bodyS"
          fontWeight="bold"
          color="textPrimary"
          sx={{
            position: 'absolute',
            mt: -1,
            ml: 1,
            zIndex: 2,
            bgcolor: palette.background.paper,
            px: 1,
          }}
        >
          {formatMessage({ id: 'admin.users-found' })} ({associatedUsers.length}
          ):
        </Typography>
        <Box px={1} py={2}>
          {associatedUsers?.map((user: APIExplorerClientAssociatedUser) => (
            <React.Fragment key={user.user_id}>
              <Grid
                container
                alignItems="center"
                onClick={() => handleClickOpenModal(user.user_id!)}
                style={{ cursor: 'pointer' }}
                m={1}
              >
                <Grid container item alignItems="baseline">
                  <Grid item mr={1}>
                    <ActiveUserIcon active={user.is_active} />
                  </Grid>
                  <Grid item>
                    <Typography variant="bodyS" color="textPrimary">
                      {user.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item ml={2} mr={2}>
                    <Typography variant="bodyXS" textColor={palette.grey[400]}>
                      {formatMessage({ id: 'global.created-at' })}:{' '}
                      {user.created_at ? (
                        <TypographyDate
                          variant="bodyXS"
                          textColor={palette.grey[400]}
                          date={user.created_at}
                          format={appConfig.FORMATS.DATE}
                        />
                      ) : (
                        formatMessage({ id: 'global.unknown' })
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="bodyXS" textColor={palette.grey[400]}>
                      {formatMessage({ id: 'global.last-login' })}:{' '}
                      {user.last_login ? (
                        <TypographyDate
                          variant="bodyXS"
                          textColor={palette.grey[400]}
                          date={user.last_login}
                          format={appConfig.FORMATS.DATE}
                        />
                      ) : (
                        formatMessage({ id: 'global.unknown' })
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {user?.user_id === userKey && (
                <AssociatedUserModal
                  user={user}
                  onClose={closeDialog}
                  open={open}
                  classes={classes}
                />
              )}
              <Divider sx={{ mx: 3 }} />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    );
  },
);

export interface AssociatedUsersListProps {
  readonly associatedUsers: APIExplorerClientAssociatedUser[];
}
