import React, { useMemo } from 'react';
import { Button, Grid } from '@explorer/core';
import { FormFieldControl } from '@explorer/forms';
import { FormDialog, FormDialogProps } from '@explorer/forms/base/FormDialog';
import { CommonFormProps } from '@explorer/forms/types';
import { FormValues, initializeFormSchema } from './formSchema';
import { FORM_ID, SCAN_OPTS, LOCALE_OPTS, COBRANDED_OPTS } from './constant';
import { FormikProps } from 'formik';
import { useFeatureFlag } from '@explorer/hooks';

export const TriggerCRAScanForm = ({
  handleClose,
  industries,
  maxWidth = 'md',
  open = false,
  formik,
  handleFetchData,
}: TriggerCRAScanFormProps) => {
  const { value: cdwCraEnabled, isLoading } = useFeatureFlag(
    'explorer.enable-cdw-cobranded-cra',
  );
  const cobrandedOpts =
    !isLoading && cdwCraEnabled
      ? COBRANDED_OPTS
      : COBRANDED_OPTS.filter((option) => option.value !== 'cdw');

  const formSchema = useMemo(() => {
    return initializeFormSchema({
      formId: FORM_ID,
      formik,
      options: {
        industries,
        scan_opts: SCAN_OPTS,
        locale_opts: LOCALE_OPTS,
        cobranded_opts: cobrandedOpts,
      },
    });
  }, [cobrandedOpts, formik, industries]);

  return (
    <FormDialog
      title={{ id: 'forms-presets.trigger-cra-scan-title' }}
      formId={FORM_ID}
      formik={formik}
      open={open}
      handleClose={handleClose}
      maxWidth={maxWidth}
      submitButton
      resetButton
      loading={formik.isSubmitting}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Button
            color="secondary"
            label={{ id: 'forms-presets.fill-last-entry' }}
            onClick={handleFetchData}
            mb={2}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.entity_name} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.industry} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.number_of_employees} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.number_of_pii_records} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.company_revenue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.domains} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.ip_addresses} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.locale} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.cobranded} />
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.scan_opts} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export interface TriggerCRAScanFormProps
  extends Pick<FormDialogProps, 'open' | 'handleClose' | 'maxWidth'>,
    Omit<CommonFormProps<FormValues>, 'endpoint'> {
  industries: Options;
  querySearch?: string;
  formik: FormikProps<FormValues>;
  handleFetchData: () => void;
}
