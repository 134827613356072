/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsFinding
 */
export interface JumboStructsFinding {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFinding
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFinding
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFinding
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JumboStructsFinding
     */
    recommendations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFinding
     */
    severity?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFinding
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFinding
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsFinding
     */
    type?: string;
    /**
     * 
     * @type {any}
     * @memberof JumboStructsFinding
     */
    value?: any | null;
}

/**
 * Check if a given object implements the JumboStructsFinding interface.
 */
export function instanceOfJumboStructsFinding(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsFindingFromJSON(json: any): JumboStructsFinding {
    return JumboStructsFindingFromJSONTyped(json, false);
}

export function JumboStructsFindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsFinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recommendations': !exists(json, 'recommendations') ? undefined : json['recommendations'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function JumboStructsFindingToJSON(value?: JumboStructsFinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'description': value.description,
        'id': value.id,
        'recommendations': value.recommendations,
        'severity': value.severity,
        'source': value.source,
        'title': value.title,
        'type': value.type,
        'value': value.value,
    };
}

