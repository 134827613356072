/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue';

/**
 * 
 * @export
 * @interface ExecRiskIRS990
 */
export interface ExecRiskIRS990 {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990
     */
    SpecialConditionDesc?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    accountingPeriodChangeCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    accountingPeriodChangeApprvCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AddressChangeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NameChangeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InitialReturnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FinalReturnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AmendedReturnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ApplicationPendingInd?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskIRS990
     */
    DoingBusinessAsName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrossReceiptsAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PrincipalOfficerNm?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskIRS990
     */
    PrincipalOfcrBusinessName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskIRS990
     */
    USAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskIRS990
     */
    ForeignAddress?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Organization501c3Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    organization_501_c_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    organization_501_c_type_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Organization4947a1NotPFInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Organization527Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    WebsiteAddressTxt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GroupReturnForAffiliatesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AllAffiliatesIncludedInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GroupExemptionNum?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationCorpInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationTrustInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationAssocInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationOtherInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OtherOrganizationDsc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FormationYr?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LegalDomicileStateCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LegalDomicileCountryCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ActivityOrMissionDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ContractTerminationInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    VotingMembersGoverningBodyCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    VotingMembersIndependentCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TotalEmployeeCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TotalVolunteersCnt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalGrossUBIAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetUnrelatedBusTxblIncmAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYContributionsGrantsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYContributionsGrantsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYProgramServiceRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYProgramServiceRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYInvestmentIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYInvestmentIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYOtherRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYTotalRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYGrantsAndSimilarPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYGrantsAndSimilarPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYBenefitsPaidToMembersAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYBenefitsPaidToMembersAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYSalariesCompEmpBnftPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYSalariesCompEmpBnftPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYTotalProfFndrsngExpnsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalProfFndrsngExpnsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalFundraisingExpenseAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYOtherExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYOtherExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYTotalExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYRevenuesLessExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYRevenuesLessExpensesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalAssetsBOYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalAssetsEOYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetAssetsOrFundBalancesBOYAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetAssetsOrFundBalancesEOYAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartIIIInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MissionDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SignificantNewProgramSrvcInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SignificantChangeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ActivityCd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ExpenseAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrantAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    RevenueAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Desc?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>}
     * @memberof ExecRiskIRS990
     */
    ProgSrvcAccomActy2Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>}
     * @memberof ExecRiskIRS990
     */
    ProgSrvcAccomActy3Grp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>}
     * @memberof ExecRiskIRS990
     */
    ProgSrvcAccomActyOtherGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherProgSrvcExpenseAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherProgSrvcGrantAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherProgSrvcRevenueAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalProgramServiceExpensesAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DescribedInSection501c3Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ScheduleBRequiredInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PoliticalCampaignActyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LobbyingActivitiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SubjectToProxyTaxInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DonorAdvisedFundInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ConservationEasementsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CollectionsOfArtInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CreditCounselingInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DonorRstrOrQuasiEndowmentsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportLandBuildingEquipmentInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportInvestmentsOtherSecInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportProgramRelatedInvstInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportOtherAssetsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportOtherLiabilitiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IncludeFIN48FootnoteInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndependentAuditFinclStmtInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ConsolidatedAuditFinclStmtInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SchoolOperatingInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ForeignOfficeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ForeignActivitiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MoreThan5000KToOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MoreThan5000KToIndividualsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ProfessionalFundraisingInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FundraisingActivitiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GamingActivitiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OperateHospitalInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AuditedFinancialStmtAttInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GrantsToOrganizationsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GrantsToIndividualsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ScheduleJRequiredInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TaxExemptBondsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InvestTaxExemptBondsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EscrowAccountInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OnBehalfOfIssuerInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EngagedInExcessBenefitTransInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PYExcessBenefitTransInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LoanOutstandingInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GrantToRelatedPersonInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BusinessRlnWithOrgMemInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BusinessRlnWithFamMemInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BusinessRlnWith35CtrlEntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DeductibleNonCashContriInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DeductibleArtContributionInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TerminateOperationsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PartialLiquidationInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DisregardedEntityInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RelatedEntityInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RelatedOrganizationCtrlEntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TransactionWithControlEntInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TrnsfrExmptNonChrtblRltdOrgInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ActivitiesConductedPrtshpInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IRPDocumentCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IRPDocumentW2GCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BackupWthldComplianceInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EmployeeCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EmploymentTaxReturnsFiledInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    UnrelatedBusIncmOverLimitInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form990TFiledInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ForeignFinancialAccountInd?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990
     */
    ForeignCountryCd?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ProhibitedTaxShelterTransInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TaxablePartyNotificationInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8886TFiledInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NondeductibleContributionsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NondeductibleContriDisclInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    QuidProQuoContributionsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    QuidProQuoContriDisclInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8282PropertyDisposedOfInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8282FiledCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RcvFndsToPayPrsnlBnftCntrctInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PayPremiumsPrsnlBnftCntrctInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8899Filedind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form1098CFiledInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DAFExcessBusinessHoldingsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TaxableDistributionsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DistributionToDonorInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    InitiationFeesAndCapContriAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrossReceiptsForPublicUseAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    MembersAndShrGrossIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    OtherSourcesGrossIncomeAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OrgFiledInLieuOfForm1041Ind?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TaxExemptInterestAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LicensedMoreThanOneStateInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    StateRequiredReservesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ReservesMaintainedAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndoorTanningServicesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form720FiledInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SubjToTaxRmnrtnExPrchtPymtInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SubjectToExcsTaxNetInvstIncInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ExcsTaxSect4951Or4952Or4953Ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVIInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GoverningBodyVotingMembersCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndependentVotingMemberCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FamilyOrBusinessRlnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DelegationOfMgmtDutiesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ChangeToOrgDocumentsInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MaterialDiversionOrMisuseInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MembersOrStockholdersInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ElectionOfBoardMembersInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DecisionsSubjectToApprovaInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MinutesOfGoverningBodyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MinutesOfCommitteesInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OfficerMailingAddressInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LocalChaptersInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PoliciesReferenceChaptersInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form990ProvidedToGvrnBodyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ConflictOfInterestPolicyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AnnualDisclosureCoveredPrsnInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RegularMonitoringEnfrcInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    WhistleblowerPolicyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DocumentRetentionPolicyInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CompensationProcessCEOInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CompensationProcessOtherInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InvestmentInJointVentureInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    WrittenPolicyOrProcedureInd?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990
     */
    StatesWhereCopyOfReturnIsFldCd?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OwnWebsiteInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OtherWebsiteInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    UponRequestInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OtherInd?: string;
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail}
     * @memberof ExecRiskIRS990
     */
    BooksInCareOfDetail?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVIIInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NoListedPersonsCompensatedInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA>}
     * @memberof ExecRiskIRS990
     */
    Form990PartVIISectionAGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalReportableCompFromOrgAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotReportableCompRltdOrgAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherCompensationAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndivRcvdGreaterThan100KCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FormerOfcrEmployeesListedInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CompensationFromOtherSrcsInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation>}
     * @memberof ExecRiskIRS990
     */
    ContractorCompensationGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CntrctRcvdGreaterThan100KCnt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVIIIInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FederatedCampaignsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    MembershipDuesAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FundraisingAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    RelatedOrganizationsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GovernmentGrantsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    AllOtherContributionsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NoncashContributionsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalContributionsAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue>}
     * @memberof ExecRiskIRS990
     */
    ProgramServiceRevenueGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    TotalOthProgramServiceRevGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalProgramServiceRevenueAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    InvestmentIncomeGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    IncmFromInvestBondProceedsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    RoyaltiesRevenueGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>}
     * @memberof ExecRiskIRS990
     */
    GrossRentsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>}
     * @memberof ExecRiskIRS990
     */
    LessRentalExpensesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>}
     * @memberof ExecRiskIRS990
     */
    RentalIncomeOrLossGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetRentalIncomeOrLossGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>}
     * @memberof ExecRiskIRS990
     */
    GrossAmountSalesAssetsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>}
     * @memberof ExecRiskIRS990
     */
    LessCostOthBasisSalesExpnssGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>}
     * @memberof ExecRiskIRS990
     */
    GainOrLossGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetGainOrLossInvestmentsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ContriRptFundraisingEventAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FundraisingGrossIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FundraisingDirectExpensesAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetIncmFromFundraisingEvtGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GamingGrossIncomeAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GamingDirectExpensesAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetIncomeFromGamingGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrossSalesOfInventoryAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CostOfGoodsSoldAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetIncomeOrLossGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc>}
     * @memberof ExecRiskIRS990
     */
    OtherRevenueMiscGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    MiscellaneousRevenueGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    OtherRevenueTotalAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    TotalRevenueGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartIXInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    GrantsToDomesticOrgsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    GrantsToDomesticIndividualsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    ForeignGrantsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    BenefitsToMembersGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    CompCurrentOfcrDirectorsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    CompDisqualPersonsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherSalariesAndWagesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PensionPlanContributionsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherEmployeeBenefitsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PayrollTaxesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesManagementGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesLegalGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesAccountingGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesLobbyingGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesProfFundraising?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForSrvcInvstMgmntFeesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesOtherGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    AdvertisingGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OfficeExpensesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    InformationTechnologyGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    RoyaltiesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OccupancyGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    TravelGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PymtTravelEntrtnmntPubOfclGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    ConferencesMeetingsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    InterestGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PaymentsToAffiliatesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    DepreciationDepletionGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    InsuranceGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherExpensesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    AllOtherExpensesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalFunctionalExpensesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    JointCostsInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalJointCostsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartXInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    CashNonInterestBearingGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    SavingsAndTempCashInvstGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    PledgesAndGrantsReceivableGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    AccountsReceivableGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    ReceivablesFromOfficersEtcGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    RcvblFromDisqualifiedPrsnGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    OthNotesLoansReceivableNetGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InventoriesForSaleOrUseGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    PrepaidExpensesDefrdChargesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    LandBldgEquipCostOrOtherBssAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    LandBldgEquipAccumDeprecAmt?: number;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    LandBldgEquipBasisNetGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InvestmentsPubTradedSecGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InvestmentsOtherSecuritiesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InvestmentsProgramRelatedGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    IntangibleAssetsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherAssetsTotalGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalAssetsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    AccountsPayableAccrExpnssGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    GrantsPayableGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    DeferredRevenueGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TaxExemptBondLiabilitiesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    EscrowAccountLiabilityGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    LoansFromOfficersDirectorsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    MortgNotesPyblScrdInvstPropGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    UnsecuredNotesLoansPayableGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherLiabilitiesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalLiabilitiesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OrganizationFollowsFASB117Ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    NoDonorRestrictionNetAssetsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    DonorRestrictionNetAssetsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OrgDoesNotFollowFASB117Ind?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    CapStkTrPrinCurrentFundsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    PdInCapSrplsLandBldgEqpFundGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    RtnEarnEndowmentIncmOthFndsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalNetAssetsFundBalanceGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotLiabNetAssetsFundBalanceGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartXIInd?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ReconcilationRevenueExpnssAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetUnrlzdGainsLossesInvstAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    DonatedServicesAndUseFcltsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    InvestmentExpenseAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PriorPeriodAdjustmentsAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    OtherChangesInNetAssetsAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartXIIInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MethodOfAccountingCashInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MethodOfAccountingAccrualInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MethodOfAccountingOtherInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AccountantCompileOrReviewInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>}
     * @memberof ExecRiskIRS990
     */
    AcctCompileOrReviewBasisGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FSAuditedInd?: string;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>}
     * @memberof ExecRiskIRS990
     */
    FSAuditedBasisGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AuditCommitteeInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FederalGrantAuditRequiredInd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FederalGrantAuditPerformedInd?: string;
}

/**
 * Check if a given object implements the ExecRiskIRS990 interface.
 */
export function instanceOfExecRiskIRS990(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskIRS990FromJSON(json: any): ExecRiskIRS990 {
    return ExecRiskIRS990FromJSONTyped(json, false);
}

export function ExecRiskIRS990FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskIRS990 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SpecialConditionDesc': !exists(json, 'SpecialConditionDesc') ? undefined : json['SpecialConditionDesc'],
        'accountingPeriodChangeCd': !exists(json, 'accountingPeriodChangeCd') ? undefined : json['accountingPeriodChangeCd'],
        'accountingPeriodChangeApprvCd': !exists(json, 'accountingPeriodChangeApprvCd') ? undefined : json['accountingPeriodChangeApprvCd'],
        'AddressChangeInd': !exists(json, 'AddressChangeInd') ? undefined : json['AddressChangeInd'],
        'NameChangeInd': !exists(json, 'NameChangeInd') ? undefined : json['NameChangeInd'],
        'InitialReturnInd': !exists(json, 'InitialReturnInd') ? undefined : json['InitialReturnInd'],
        'FinalReturnInd': !exists(json, 'FinalReturnInd') ? undefined : json['FinalReturnInd'],
        'AmendedReturnInd': !exists(json, 'AmendedReturnInd') ? undefined : json['AmendedReturnInd'],
        'ApplicationPendingInd': !exists(json, 'ApplicationPendingInd') ? undefined : json['ApplicationPendingInd'],
        'DoingBusinessAsName': !exists(json, 'DoingBusinessAsName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['DoingBusinessAsName']),
        'GrossReceiptsAmt': !exists(json, 'GrossReceiptsAmt') ? undefined : json['GrossReceiptsAmt'],
        'PrincipalOfficerNm': !exists(json, 'PrincipalOfficerNm') ? undefined : json['PrincipalOfficerNm'],
        'PrincipalOfcrBusinessName': !exists(json, 'PrincipalOfcrBusinessName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['PrincipalOfcrBusinessName']),
        'USAddress': !exists(json, 'USAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': !exists(json, 'ForeignAddress') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'Organization501c3Ind': !exists(json, 'Organization501c3Ind') ? undefined : json['Organization501c3Ind'],
        'organization_501_c_ind': !exists(json, 'organization_501_c_ind') ? undefined : json['organization_501_c_ind'],
        'organization_501_c_type_text': !exists(json, 'organization_501_c_type_text') ? undefined : json['organization_501_c_type_text'],
        'Organization4947a1NotPFInd': !exists(json, 'Organization4947a1NotPFInd') ? undefined : json['Organization4947a1NotPFInd'],
        'Organization527Ind': !exists(json, 'Organization527Ind') ? undefined : json['Organization527Ind'],
        'WebsiteAddressTxt': !exists(json, 'WebsiteAddressTxt') ? undefined : json['WebsiteAddressTxt'],
        'GroupReturnForAffiliatesInd': !exists(json, 'GroupReturnForAffiliatesInd') ? undefined : json['GroupReturnForAffiliatesInd'],
        'AllAffiliatesIncludedInd': !exists(json, 'AllAffiliatesIncludedInd') ? undefined : json['AllAffiliatesIncludedInd'],
        'GroupExemptionNum': !exists(json, 'GroupExemptionNum') ? undefined : json['GroupExemptionNum'],
        'TypeOfOrganizationCorpInd': !exists(json, 'TypeOfOrganizationCorpInd') ? undefined : json['TypeOfOrganizationCorpInd'],
        'TypeOfOrganizationTrustInd': !exists(json, 'TypeOfOrganizationTrustInd') ? undefined : json['TypeOfOrganizationTrustInd'],
        'TypeOfOrganizationAssocInd': !exists(json, 'TypeOfOrganizationAssocInd') ? undefined : json['TypeOfOrganizationAssocInd'],
        'TypeOfOrganizationOtherInd': !exists(json, 'TypeOfOrganizationOtherInd') ? undefined : json['TypeOfOrganizationOtherInd'],
        'OtherOrganizationDsc': !exists(json, 'OtherOrganizationDsc') ? undefined : json['OtherOrganizationDsc'],
        'FormationYr': !exists(json, 'FormationYr') ? undefined : json['FormationYr'],
        'LegalDomicileStateCd': !exists(json, 'LegalDomicileStateCd') ? undefined : json['LegalDomicileStateCd'],
        'LegalDomicileCountryCd': !exists(json, 'LegalDomicileCountryCd') ? undefined : json['LegalDomicileCountryCd'],
        'ActivityOrMissionDesc': !exists(json, 'ActivityOrMissionDesc') ? undefined : json['ActivityOrMissionDesc'],
        'ContractTerminationInd': !exists(json, 'ContractTerminationInd') ? undefined : json['ContractTerminationInd'],
        'VotingMembersGoverningBodyCnt': !exists(json, 'VotingMembersGoverningBodyCnt') ? undefined : json['VotingMembersGoverningBodyCnt'],
        'VotingMembersIndependentCnt': !exists(json, 'VotingMembersIndependentCnt') ? undefined : json['VotingMembersIndependentCnt'],
        'TotalEmployeeCnt': !exists(json, 'TotalEmployeeCnt') ? undefined : json['TotalEmployeeCnt'],
        'TotalVolunteersCnt': !exists(json, 'TotalVolunteersCnt') ? undefined : json['TotalVolunteersCnt'],
        'TotalGrossUBIAmt': !exists(json, 'TotalGrossUBIAmt') ? undefined : json['TotalGrossUBIAmt'],
        'NetUnrelatedBusTxblIncmAmt': !exists(json, 'NetUnrelatedBusTxblIncmAmt') ? undefined : json['NetUnrelatedBusTxblIncmAmt'],
        'PYContributionsGrantsAmt': !exists(json, 'PYContributionsGrantsAmt') ? undefined : json['PYContributionsGrantsAmt'],
        'CYContributionsGrantsAmt': !exists(json, 'CYContributionsGrantsAmt') ? undefined : json['CYContributionsGrantsAmt'],
        'PYProgramServiceRevenueAmt': !exists(json, 'PYProgramServiceRevenueAmt') ? undefined : json['PYProgramServiceRevenueAmt'],
        'CYProgramServiceRevenueAmt': !exists(json, 'CYProgramServiceRevenueAmt') ? undefined : json['CYProgramServiceRevenueAmt'],
        'PYInvestmentIncomeAmt': !exists(json, 'PYInvestmentIncomeAmt') ? undefined : json['PYInvestmentIncomeAmt'],
        'CYInvestmentIncomeAmt': !exists(json, 'CYInvestmentIncomeAmt') ? undefined : json['CYInvestmentIncomeAmt'],
        'PYOtherRevenueAmt': !exists(json, 'PYOtherRevenueAmt') ? undefined : json['PYOtherRevenueAmt'],
        'PYTotalRevenueAmt': !exists(json, 'PYTotalRevenueAmt') ? undefined : json['PYTotalRevenueAmt'],
        'CYTotalRevenueAmt': !exists(json, 'CYTotalRevenueAmt') ? undefined : json['CYTotalRevenueAmt'],
        'PYGrantsAndSimilarPaidAmt': !exists(json, 'PYGrantsAndSimilarPaidAmt') ? undefined : json['PYGrantsAndSimilarPaidAmt'],
        'CYGrantsAndSimilarPaidAmt': !exists(json, 'CYGrantsAndSimilarPaidAmt') ? undefined : json['CYGrantsAndSimilarPaidAmt'],
        'PYBenefitsPaidToMembersAmt': !exists(json, 'PYBenefitsPaidToMembersAmt') ? undefined : json['PYBenefitsPaidToMembersAmt'],
        'CYBenefitsPaidToMembersAmt': !exists(json, 'CYBenefitsPaidToMembersAmt') ? undefined : json['CYBenefitsPaidToMembersAmt'],
        'PYSalariesCompEmpBnftPaidAmt': !exists(json, 'PYSalariesCompEmpBnftPaidAmt') ? undefined : json['PYSalariesCompEmpBnftPaidAmt'],
        'CYSalariesCompEmpBnftPaidAmt': !exists(json, 'CYSalariesCompEmpBnftPaidAmt') ? undefined : json['CYSalariesCompEmpBnftPaidAmt'],
        'PYTotalProfFndrsngExpnsAmt': !exists(json, 'PYTotalProfFndrsngExpnsAmt') ? undefined : json['PYTotalProfFndrsngExpnsAmt'],
        'CYTotalProfFndrsngExpnsAmt': !exists(json, 'CYTotalProfFndrsngExpnsAmt') ? undefined : json['CYTotalProfFndrsngExpnsAmt'],
        'CYTotalFundraisingExpenseAmt': !exists(json, 'CYTotalFundraisingExpenseAmt') ? undefined : json['CYTotalFundraisingExpenseAmt'],
        'PYOtherExpensesAmt': !exists(json, 'PYOtherExpensesAmt') ? undefined : json['PYOtherExpensesAmt'],
        'CYOtherExpensesAmt': !exists(json, 'CYOtherExpensesAmt') ? undefined : json['CYOtherExpensesAmt'],
        'PYTotalExpensesAmt': !exists(json, 'PYTotalExpensesAmt') ? undefined : json['PYTotalExpensesAmt'],
        'CYTotalExpensesAmt': !exists(json, 'CYTotalExpensesAmt') ? undefined : json['CYTotalExpensesAmt'],
        'PYRevenuesLessExpensesAmt': !exists(json, 'PYRevenuesLessExpensesAmt') ? undefined : json['PYRevenuesLessExpensesAmt'],
        'CYRevenuesLessExpensesAmt': !exists(json, 'CYRevenuesLessExpensesAmt') ? undefined : json['CYRevenuesLessExpensesAmt'],
        'TotalAssetsBOYAmt': !exists(json, 'TotalAssetsBOYAmt') ? undefined : json['TotalAssetsBOYAmt'],
        'TotalAssetsEOYAmt': !exists(json, 'TotalAssetsEOYAmt') ? undefined : json['TotalAssetsEOYAmt'],
        'NetAssetsOrFundBalancesBOYAmt': !exists(json, 'NetAssetsOrFundBalancesBOYAmt') ? undefined : json['NetAssetsOrFundBalancesBOYAmt'],
        'NetAssetsOrFundBalancesEOYAmt': !exists(json, 'NetAssetsOrFundBalancesEOYAmt') ? undefined : json['NetAssetsOrFundBalancesEOYAmt'],
        'InfoInScheduleOPartIIIInd': !exists(json, 'InfoInScheduleOPartIIIInd') ? undefined : json['InfoInScheduleOPartIIIInd'],
        'MissionDesc': !exists(json, 'MissionDesc') ? undefined : json['MissionDesc'],
        'SignificantNewProgramSrvcInd': !exists(json, 'SignificantNewProgramSrvcInd') ? undefined : json['SignificantNewProgramSrvcInd'],
        'SignificantChangeInd': !exists(json, 'SignificantChangeInd') ? undefined : json['SignificantChangeInd'],
        'ActivityCd': !exists(json, 'ActivityCd') ? undefined : json['ActivityCd'],
        'ExpenseAmt': !exists(json, 'ExpenseAmt') ? undefined : json['ExpenseAmt'],
        'GrantAmt': !exists(json, 'GrantAmt') ? undefined : json['GrantAmt'],
        'RevenueAmt': !exists(json, 'RevenueAmt') ? undefined : json['RevenueAmt'],
        'Desc': !exists(json, 'Desc') ? undefined : json['Desc'],
        'ProgSrvcAccomActy2Grp': !exists(json, 'ProgSrvcAccomActy2Grp') ? undefined : ((json['ProgSrvcAccomActy2Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON)),
        'ProgSrvcAccomActy3Grp': !exists(json, 'ProgSrvcAccomActy3Grp') ? undefined : ((json['ProgSrvcAccomActy3Grp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON)),
        'ProgSrvcAccomActyOtherGrp': !exists(json, 'ProgSrvcAccomActyOtherGrp') ? undefined : ((json['ProgSrvcAccomActyOtherGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON)),
        'TotalOtherProgSrvcExpenseAmt': !exists(json, 'TotalOtherProgSrvcExpenseAmt') ? undefined : json['TotalOtherProgSrvcExpenseAmt'],
        'TotalOtherProgSrvcGrantAmt': !exists(json, 'TotalOtherProgSrvcGrantAmt') ? undefined : json['TotalOtherProgSrvcGrantAmt'],
        'TotalOtherProgSrvcRevenueAmt': !exists(json, 'TotalOtherProgSrvcRevenueAmt') ? undefined : json['TotalOtherProgSrvcRevenueAmt'],
        'TotalProgramServiceExpensesAmt': !exists(json, 'TotalProgramServiceExpensesAmt') ? undefined : json['TotalProgramServiceExpensesAmt'],
        'DescribedInSection501c3Ind': !exists(json, 'DescribedInSection501c3Ind') ? undefined : json['DescribedInSection501c3Ind'],
        'ScheduleBRequiredInd': !exists(json, 'ScheduleBRequiredInd') ? undefined : json['ScheduleBRequiredInd'],
        'PoliticalCampaignActyInd': !exists(json, 'PoliticalCampaignActyInd') ? undefined : json['PoliticalCampaignActyInd'],
        'LobbyingActivitiesInd': !exists(json, 'LobbyingActivitiesInd') ? undefined : json['LobbyingActivitiesInd'],
        'SubjectToProxyTaxInd': !exists(json, 'SubjectToProxyTaxInd') ? undefined : json['SubjectToProxyTaxInd'],
        'DonorAdvisedFundInd': !exists(json, 'DonorAdvisedFundInd') ? undefined : json['DonorAdvisedFundInd'],
        'ConservationEasementsInd': !exists(json, 'ConservationEasementsInd') ? undefined : json['ConservationEasementsInd'],
        'CollectionsOfArtInd': !exists(json, 'CollectionsOfArtInd') ? undefined : json['CollectionsOfArtInd'],
        'CreditCounselingInd': !exists(json, 'CreditCounselingInd') ? undefined : json['CreditCounselingInd'],
        'DonorRstrOrQuasiEndowmentsInd': !exists(json, 'DonorRstrOrQuasiEndowmentsInd') ? undefined : json['DonorRstrOrQuasiEndowmentsInd'],
        'ReportLandBuildingEquipmentInd': !exists(json, 'ReportLandBuildingEquipmentInd') ? undefined : json['ReportLandBuildingEquipmentInd'],
        'ReportInvestmentsOtherSecInd': !exists(json, 'ReportInvestmentsOtherSecInd') ? undefined : json['ReportInvestmentsOtherSecInd'],
        'ReportProgramRelatedInvstInd': !exists(json, 'ReportProgramRelatedInvstInd') ? undefined : json['ReportProgramRelatedInvstInd'],
        'ReportOtherAssetsInd': !exists(json, 'ReportOtherAssetsInd') ? undefined : json['ReportOtherAssetsInd'],
        'ReportOtherLiabilitiesInd': !exists(json, 'ReportOtherLiabilitiesInd') ? undefined : json['ReportOtherLiabilitiesInd'],
        'IncludeFIN48FootnoteInd': !exists(json, 'IncludeFIN48FootnoteInd') ? undefined : json['IncludeFIN48FootnoteInd'],
        'IndependentAuditFinclStmtInd': !exists(json, 'IndependentAuditFinclStmtInd') ? undefined : json['IndependentAuditFinclStmtInd'],
        'ConsolidatedAuditFinclStmtInd': !exists(json, 'ConsolidatedAuditFinclStmtInd') ? undefined : json['ConsolidatedAuditFinclStmtInd'],
        'SchoolOperatingInd': !exists(json, 'SchoolOperatingInd') ? undefined : json['SchoolOperatingInd'],
        'ForeignOfficeInd': !exists(json, 'ForeignOfficeInd') ? undefined : json['ForeignOfficeInd'],
        'ForeignActivitiesInd': !exists(json, 'ForeignActivitiesInd') ? undefined : json['ForeignActivitiesInd'],
        'MoreThan5000KToOrgInd': !exists(json, 'MoreThan5000KToOrgInd') ? undefined : json['MoreThan5000KToOrgInd'],
        'MoreThan5000KToIndividualsInd': !exists(json, 'MoreThan5000KToIndividualsInd') ? undefined : json['MoreThan5000KToIndividualsInd'],
        'ProfessionalFundraisingInd': !exists(json, 'ProfessionalFundraisingInd') ? undefined : json['ProfessionalFundraisingInd'],
        'FundraisingActivitiesInd': !exists(json, 'FundraisingActivitiesInd') ? undefined : json['FundraisingActivitiesInd'],
        'GamingActivitiesInd': !exists(json, 'GamingActivitiesInd') ? undefined : json['GamingActivitiesInd'],
        'OperateHospitalInd': !exists(json, 'OperateHospitalInd') ? undefined : json['OperateHospitalInd'],
        'AuditedFinancialStmtAttInd': !exists(json, 'AuditedFinancialStmtAttInd') ? undefined : json['AuditedFinancialStmtAttInd'],
        'GrantsToOrganizationsInd': !exists(json, 'GrantsToOrganizationsInd') ? undefined : json['GrantsToOrganizationsInd'],
        'GrantsToIndividualsInd': !exists(json, 'GrantsToIndividualsInd') ? undefined : json['GrantsToIndividualsInd'],
        'ScheduleJRequiredInd': !exists(json, 'ScheduleJRequiredInd') ? undefined : json['ScheduleJRequiredInd'],
        'TaxExemptBondsInd': !exists(json, 'TaxExemptBondsInd') ? undefined : json['TaxExemptBondsInd'],
        'InvestTaxExemptBondsInd': !exists(json, 'InvestTaxExemptBondsInd') ? undefined : json['InvestTaxExemptBondsInd'],
        'EscrowAccountInd': !exists(json, 'EscrowAccountInd') ? undefined : json['EscrowAccountInd'],
        'OnBehalfOfIssuerInd': !exists(json, 'OnBehalfOfIssuerInd') ? undefined : json['OnBehalfOfIssuerInd'],
        'EngagedInExcessBenefitTransInd': !exists(json, 'EngagedInExcessBenefitTransInd') ? undefined : json['EngagedInExcessBenefitTransInd'],
        'PYExcessBenefitTransInd': !exists(json, 'PYExcessBenefitTransInd') ? undefined : json['PYExcessBenefitTransInd'],
        'LoanOutstandingInd': !exists(json, 'LoanOutstandingInd') ? undefined : json['LoanOutstandingInd'],
        'GrantToRelatedPersonInd': !exists(json, 'GrantToRelatedPersonInd') ? undefined : json['GrantToRelatedPersonInd'],
        'BusinessRlnWithOrgMemInd': !exists(json, 'BusinessRlnWithOrgMemInd') ? undefined : json['BusinessRlnWithOrgMemInd'],
        'BusinessRlnWithFamMemInd': !exists(json, 'BusinessRlnWithFamMemInd') ? undefined : json['BusinessRlnWithFamMemInd'],
        'BusinessRlnWith35CtrlEntInd': !exists(json, 'BusinessRlnWith35CtrlEntInd') ? undefined : json['BusinessRlnWith35CtrlEntInd'],
        'DeductibleNonCashContriInd': !exists(json, 'DeductibleNonCashContriInd') ? undefined : json['DeductibleNonCashContriInd'],
        'DeductibleArtContributionInd': !exists(json, 'DeductibleArtContributionInd') ? undefined : json['DeductibleArtContributionInd'],
        'TerminateOperationsInd': !exists(json, 'TerminateOperationsInd') ? undefined : json['TerminateOperationsInd'],
        'PartialLiquidationInd': !exists(json, 'PartialLiquidationInd') ? undefined : json['PartialLiquidationInd'],
        'DisregardedEntityInd': !exists(json, 'DisregardedEntityInd') ? undefined : json['DisregardedEntityInd'],
        'RelatedEntityInd': !exists(json, 'RelatedEntityInd') ? undefined : json['RelatedEntityInd'],
        'RelatedOrganizationCtrlEntInd': !exists(json, 'RelatedOrganizationCtrlEntInd') ? undefined : json['RelatedOrganizationCtrlEntInd'],
        'TransactionWithControlEntInd': !exists(json, 'TransactionWithControlEntInd') ? undefined : json['TransactionWithControlEntInd'],
        'TrnsfrExmptNonChrtblRltdOrgInd': !exists(json, 'TrnsfrExmptNonChrtblRltdOrgInd') ? undefined : json['TrnsfrExmptNonChrtblRltdOrgInd'],
        'ActivitiesConductedPrtshpInd': !exists(json, 'ActivitiesConductedPrtshpInd') ? undefined : json['ActivitiesConductedPrtshpInd'],
        'InfoInScheduleOPartVInd': !exists(json, 'InfoInScheduleOPartVInd') ? undefined : json['InfoInScheduleOPartVInd'],
        'IRPDocumentCnt': !exists(json, 'IRPDocumentCnt') ? undefined : json['IRPDocumentCnt'],
        'IRPDocumentW2GCnt': !exists(json, 'IRPDocumentW2GCnt') ? undefined : json['IRPDocumentW2GCnt'],
        'BackupWthldComplianceInd': !exists(json, 'BackupWthldComplianceInd') ? undefined : json['BackupWthldComplianceInd'],
        'EmployeeCnt': !exists(json, 'EmployeeCnt') ? undefined : json['EmployeeCnt'],
        'EmploymentTaxReturnsFiledInd': !exists(json, 'EmploymentTaxReturnsFiledInd') ? undefined : json['EmploymentTaxReturnsFiledInd'],
        'UnrelatedBusIncmOverLimitInd': !exists(json, 'UnrelatedBusIncmOverLimitInd') ? undefined : json['UnrelatedBusIncmOverLimitInd'],
        'Form990TFiledInd': !exists(json, 'Form990TFiledInd') ? undefined : json['Form990TFiledInd'],
        'ForeignFinancialAccountInd': !exists(json, 'ForeignFinancialAccountInd') ? undefined : json['ForeignFinancialAccountInd'],
        'ForeignCountryCd': !exists(json, 'ForeignCountryCd') ? undefined : json['ForeignCountryCd'],
        'ProhibitedTaxShelterTransInd': !exists(json, 'ProhibitedTaxShelterTransInd') ? undefined : json['ProhibitedTaxShelterTransInd'],
        'TaxablePartyNotificationInd': !exists(json, 'TaxablePartyNotificationInd') ? undefined : json['TaxablePartyNotificationInd'],
        'Form8886TFiledInd': !exists(json, 'Form8886TFiledInd') ? undefined : json['Form8886TFiledInd'],
        'NondeductibleContributionsInd': !exists(json, 'NondeductibleContributionsInd') ? undefined : json['NondeductibleContributionsInd'],
        'NondeductibleContriDisclInd': !exists(json, 'NondeductibleContriDisclInd') ? undefined : json['NondeductibleContriDisclInd'],
        'QuidProQuoContributionsInd': !exists(json, 'QuidProQuoContributionsInd') ? undefined : json['QuidProQuoContributionsInd'],
        'QuidProQuoContriDisclInd': !exists(json, 'QuidProQuoContriDisclInd') ? undefined : json['QuidProQuoContriDisclInd'],
        'Form8282PropertyDisposedOfInd': !exists(json, 'Form8282PropertyDisposedOfInd') ? undefined : json['Form8282PropertyDisposedOfInd'],
        'Form8282FiledCnt': !exists(json, 'Form8282FiledCnt') ? undefined : json['Form8282FiledCnt'],
        'RcvFndsToPayPrsnlBnftCntrctInd': !exists(json, 'RcvFndsToPayPrsnlBnftCntrctInd') ? undefined : json['RcvFndsToPayPrsnlBnftCntrctInd'],
        'PayPremiumsPrsnlBnftCntrctInd': !exists(json, 'PayPremiumsPrsnlBnftCntrctInd') ? undefined : json['PayPremiumsPrsnlBnftCntrctInd'],
        'Form8899Filedind': !exists(json, 'Form8899Filedind') ? undefined : json['Form8899Filedind'],
        'Form1098CFiledInd': !exists(json, 'Form1098CFiledInd') ? undefined : json['Form1098CFiledInd'],
        'DAFExcessBusinessHoldingsInd': !exists(json, 'DAFExcessBusinessHoldingsInd') ? undefined : json['DAFExcessBusinessHoldingsInd'],
        'TaxableDistributionsInd': !exists(json, 'TaxableDistributionsInd') ? undefined : json['TaxableDistributionsInd'],
        'DistributionToDonorInd': !exists(json, 'DistributionToDonorInd') ? undefined : json['DistributionToDonorInd'],
        'InitiationFeesAndCapContriAmt': !exists(json, 'InitiationFeesAndCapContriAmt') ? undefined : json['InitiationFeesAndCapContriAmt'],
        'GrossReceiptsForPublicUseAmt': !exists(json, 'GrossReceiptsForPublicUseAmt') ? undefined : json['GrossReceiptsForPublicUseAmt'],
        'MembersAndShrGrossIncomeAmt': !exists(json, 'MembersAndShrGrossIncomeAmt') ? undefined : json['MembersAndShrGrossIncomeAmt'],
        'OtherSourcesGrossIncomeAmt': !exists(json, 'OtherSourcesGrossIncomeAmt') ? undefined : json['OtherSourcesGrossIncomeAmt'],
        'OrgFiledInLieuOfForm1041Ind': !exists(json, 'OrgFiledInLieuOfForm1041Ind') ? undefined : json['OrgFiledInLieuOfForm1041Ind'],
        'TaxExemptInterestAmt': !exists(json, 'TaxExemptInterestAmt') ? undefined : json['TaxExemptInterestAmt'],
        'LicensedMoreThanOneStateInd': !exists(json, 'LicensedMoreThanOneStateInd') ? undefined : json['LicensedMoreThanOneStateInd'],
        'StateRequiredReservesAmt': !exists(json, 'StateRequiredReservesAmt') ? undefined : json['StateRequiredReservesAmt'],
        'ReservesMaintainedAmt': !exists(json, 'ReservesMaintainedAmt') ? undefined : json['ReservesMaintainedAmt'],
        'IndoorTanningServicesInd': !exists(json, 'IndoorTanningServicesInd') ? undefined : json['IndoorTanningServicesInd'],
        'Form720FiledInd': !exists(json, 'Form720FiledInd') ? undefined : json['Form720FiledInd'],
        'SubjToTaxRmnrtnExPrchtPymtInd': !exists(json, 'SubjToTaxRmnrtnExPrchtPymtInd') ? undefined : json['SubjToTaxRmnrtnExPrchtPymtInd'],
        'SubjectToExcsTaxNetInvstIncInd': !exists(json, 'SubjectToExcsTaxNetInvstIncInd') ? undefined : json['SubjectToExcsTaxNetInvstIncInd'],
        'ExcsTaxSect4951Or4952Or4953Ind': !exists(json, 'ExcsTaxSect4951Or4952Or4953Ind') ? undefined : json['ExcsTaxSect4951Or4952Or4953Ind'],
        'InfoInScheduleOPartVIInd': !exists(json, 'InfoInScheduleOPartVIInd') ? undefined : json['InfoInScheduleOPartVIInd'],
        'GoverningBodyVotingMembersCnt': !exists(json, 'GoverningBodyVotingMembersCnt') ? undefined : json['GoverningBodyVotingMembersCnt'],
        'IndependentVotingMemberCnt': !exists(json, 'IndependentVotingMemberCnt') ? undefined : json['IndependentVotingMemberCnt'],
        'FamilyOrBusinessRlnInd': !exists(json, 'FamilyOrBusinessRlnInd') ? undefined : json['FamilyOrBusinessRlnInd'],
        'DelegationOfMgmtDutiesInd': !exists(json, 'DelegationOfMgmtDutiesInd') ? undefined : json['DelegationOfMgmtDutiesInd'],
        'ChangeToOrgDocumentsInd': !exists(json, 'ChangeToOrgDocumentsInd') ? undefined : json['ChangeToOrgDocumentsInd'],
        'MaterialDiversionOrMisuseInd': !exists(json, 'MaterialDiversionOrMisuseInd') ? undefined : json['MaterialDiversionOrMisuseInd'],
        'MembersOrStockholdersInd': !exists(json, 'MembersOrStockholdersInd') ? undefined : json['MembersOrStockholdersInd'],
        'ElectionOfBoardMembersInd': !exists(json, 'ElectionOfBoardMembersInd') ? undefined : json['ElectionOfBoardMembersInd'],
        'DecisionsSubjectToApprovaInd': !exists(json, 'DecisionsSubjectToApprovaInd') ? undefined : json['DecisionsSubjectToApprovaInd'],
        'MinutesOfGoverningBodyInd': !exists(json, 'MinutesOfGoverningBodyInd') ? undefined : json['MinutesOfGoverningBodyInd'],
        'MinutesOfCommitteesInd': !exists(json, 'MinutesOfCommitteesInd') ? undefined : json['MinutesOfCommitteesInd'],
        'OfficerMailingAddressInd': !exists(json, 'OfficerMailingAddressInd') ? undefined : json['OfficerMailingAddressInd'],
        'LocalChaptersInd': !exists(json, 'LocalChaptersInd') ? undefined : json['LocalChaptersInd'],
        'PoliciesReferenceChaptersInd': !exists(json, 'PoliciesReferenceChaptersInd') ? undefined : json['PoliciesReferenceChaptersInd'],
        'Form990ProvidedToGvrnBodyInd': !exists(json, 'Form990ProvidedToGvrnBodyInd') ? undefined : json['Form990ProvidedToGvrnBodyInd'],
        'ConflictOfInterestPolicyInd': !exists(json, 'ConflictOfInterestPolicyInd') ? undefined : json['ConflictOfInterestPolicyInd'],
        'AnnualDisclosureCoveredPrsnInd': !exists(json, 'AnnualDisclosureCoveredPrsnInd') ? undefined : json['AnnualDisclosureCoveredPrsnInd'],
        'RegularMonitoringEnfrcInd': !exists(json, 'RegularMonitoringEnfrcInd') ? undefined : json['RegularMonitoringEnfrcInd'],
        'WhistleblowerPolicyInd': !exists(json, 'WhistleblowerPolicyInd') ? undefined : json['WhistleblowerPolicyInd'],
        'DocumentRetentionPolicyInd': !exists(json, 'DocumentRetentionPolicyInd') ? undefined : json['DocumentRetentionPolicyInd'],
        'CompensationProcessCEOInd': !exists(json, 'CompensationProcessCEOInd') ? undefined : json['CompensationProcessCEOInd'],
        'CompensationProcessOtherInd': !exists(json, 'CompensationProcessOtherInd') ? undefined : json['CompensationProcessOtherInd'],
        'InvestmentInJointVentureInd': !exists(json, 'InvestmentInJointVentureInd') ? undefined : json['InvestmentInJointVentureInd'],
        'WrittenPolicyOrProcedureInd': !exists(json, 'WrittenPolicyOrProcedureInd') ? undefined : json['WrittenPolicyOrProcedureInd'],
        'StatesWhereCopyOfReturnIsFldCd': !exists(json, 'StatesWhereCopyOfReturnIsFldCd') ? undefined : json['StatesWhereCopyOfReturnIsFldCd'],
        'OwnWebsiteInd': !exists(json, 'OwnWebsiteInd') ? undefined : json['OwnWebsiteInd'],
        'OtherWebsiteInd': !exists(json, 'OtherWebsiteInd') ? undefined : json['OtherWebsiteInd'],
        'UponRequestInd': !exists(json, 'UponRequestInd') ? undefined : json['UponRequestInd'],
        'OtherInd': !exists(json, 'OtherInd') ? undefined : json['OtherInd'],
        'BooksInCareOfDetail': !exists(json, 'BooksInCareOfDetail') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON(json['BooksInCareOfDetail']),
        'InfoInScheduleOPartVIIInd': !exists(json, 'InfoInScheduleOPartVIIInd') ? undefined : json['InfoInScheduleOPartVIIInd'],
        'NoListedPersonsCompensatedInd': !exists(json, 'NoListedPersonsCompensatedInd') ? undefined : json['NoListedPersonsCompensatedInd'],
        'Form990PartVIISectionAGrp': !exists(json, 'Form990PartVIISectionAGrp') ? undefined : ((json['Form990PartVIISectionAGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSON)),
        'TotalReportableCompFromOrgAmt': !exists(json, 'TotalReportableCompFromOrgAmt') ? undefined : json['TotalReportableCompFromOrgAmt'],
        'TotReportableCompRltdOrgAmt': !exists(json, 'TotReportableCompRltdOrgAmt') ? undefined : json['TotReportableCompRltdOrgAmt'],
        'TotalOtherCompensationAmt': !exists(json, 'TotalOtherCompensationAmt') ? undefined : json['TotalOtherCompensationAmt'],
        'IndivRcvdGreaterThan100KCnt': !exists(json, 'IndivRcvdGreaterThan100KCnt') ? undefined : json['IndivRcvdGreaterThan100KCnt'],
        'FormerOfcrEmployeesListedInd': !exists(json, 'FormerOfcrEmployeesListedInd') ? undefined : json['FormerOfcrEmployeesListedInd'],
        'CompensationFromOtherSrcsInd': !exists(json, 'CompensationFromOtherSrcsInd') ? undefined : json['CompensationFromOtherSrcsInd'],
        'ContractorCompensationGrp': !exists(json, 'ContractorCompensationGrp') ? undefined : ((json['ContractorCompensationGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSON)),
        'CntrctRcvdGreaterThan100KCnt': !exists(json, 'CntrctRcvdGreaterThan100KCnt') ? undefined : json['CntrctRcvdGreaterThan100KCnt'],
        'InfoInScheduleOPartVIIIInd': !exists(json, 'InfoInScheduleOPartVIIIInd') ? undefined : json['InfoInScheduleOPartVIIIInd'],
        'FederatedCampaignsAmt': !exists(json, 'FederatedCampaignsAmt') ? undefined : json['FederatedCampaignsAmt'],
        'MembershipDuesAmt': !exists(json, 'MembershipDuesAmt') ? undefined : json['MembershipDuesAmt'],
        'FundraisingAmt': !exists(json, 'FundraisingAmt') ? undefined : json['FundraisingAmt'],
        'RelatedOrganizationsAmt': !exists(json, 'RelatedOrganizationsAmt') ? undefined : json['RelatedOrganizationsAmt'],
        'GovernmentGrantsAmt': !exists(json, 'GovernmentGrantsAmt') ? undefined : json['GovernmentGrantsAmt'],
        'AllOtherContributionsAmt': !exists(json, 'AllOtherContributionsAmt') ? undefined : json['AllOtherContributionsAmt'],
        'NoncashContributionsAmt': !exists(json, 'NoncashContributionsAmt') ? undefined : json['NoncashContributionsAmt'],
        'TotalContributionsAmt': !exists(json, 'TotalContributionsAmt') ? undefined : json['TotalContributionsAmt'],
        'ProgramServiceRevenueGrp': !exists(json, 'ProgramServiceRevenueGrp') ? undefined : ((json['ProgramServiceRevenueGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSON)),
        'TotalOthProgramServiceRevGrp': !exists(json, 'TotalOthProgramServiceRevGrp') ? undefined : ((json['TotalOthProgramServiceRevGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'TotalProgramServiceRevenueAmt': !exists(json, 'TotalProgramServiceRevenueAmt') ? undefined : json['TotalProgramServiceRevenueAmt'],
        'InvestmentIncomeGrp': !exists(json, 'InvestmentIncomeGrp') ? undefined : ((json['InvestmentIncomeGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'IncmFromInvestBondProceedsGrp': !exists(json, 'IncmFromInvestBondProceedsGrp') ? undefined : ((json['IncmFromInvestBondProceedsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'RoyaltiesRevenueGrp': !exists(json, 'RoyaltiesRevenueGrp') ? undefined : ((json['RoyaltiesRevenueGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GrossRentsGrp': !exists(json, 'GrossRentsGrp') ? undefined : ((json['GrossRentsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON)),
        'LessRentalExpensesGrp': !exists(json, 'LessRentalExpensesGrp') ? undefined : ((json['LessRentalExpensesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON)),
        'RentalIncomeOrLossGrp': !exists(json, 'RentalIncomeOrLossGrp') ? undefined : ((json['RentalIncomeOrLossGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON)),
        'NetRentalIncomeOrLossGrp': !exists(json, 'NetRentalIncomeOrLossGrp') ? undefined : ((json['NetRentalIncomeOrLossGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GrossAmountSalesAssetsGrp': !exists(json, 'GrossAmountSalesAssetsGrp') ? undefined : ((json['GrossAmountSalesAssetsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON)),
        'LessCostOthBasisSalesExpnssGrp': !exists(json, 'LessCostOthBasisSalesExpnssGrp') ? undefined : ((json['LessCostOthBasisSalesExpnssGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON)),
        'GainOrLossGrp': !exists(json, 'GainOrLossGrp') ? undefined : ((json['GainOrLossGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON)),
        'NetGainOrLossInvestmentsGrp': !exists(json, 'NetGainOrLossInvestmentsGrp') ? undefined : ((json['NetGainOrLossInvestmentsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'ContriRptFundraisingEventAmt': !exists(json, 'ContriRptFundraisingEventAmt') ? undefined : json['ContriRptFundraisingEventAmt'],
        'FundraisingGrossIncomeAmt': !exists(json, 'FundraisingGrossIncomeAmt') ? undefined : json['FundraisingGrossIncomeAmt'],
        'FundraisingDirectExpensesAmt': !exists(json, 'FundraisingDirectExpensesAmt') ? undefined : json['FundraisingDirectExpensesAmt'],
        'NetIncmFromFundraisingEvtGrp': !exists(json, 'NetIncmFromFundraisingEvtGrp') ? undefined : ((json['NetIncmFromFundraisingEvtGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GamingGrossIncomeAmt': !exists(json, 'GamingGrossIncomeAmt') ? undefined : json['GamingGrossIncomeAmt'],
        'GamingDirectExpensesAmt': !exists(json, 'GamingDirectExpensesAmt') ? undefined : json['GamingDirectExpensesAmt'],
        'NetIncomeFromGamingGrp': !exists(json, 'NetIncomeFromGamingGrp') ? undefined : ((json['NetIncomeFromGamingGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GrossSalesOfInventoryAmt': !exists(json, 'GrossSalesOfInventoryAmt') ? undefined : json['GrossSalesOfInventoryAmt'],
        'CostOfGoodsSoldAmt': !exists(json, 'CostOfGoodsSoldAmt') ? undefined : json['CostOfGoodsSoldAmt'],
        'NetIncomeOrLossGrp': !exists(json, 'NetIncomeOrLossGrp') ? undefined : ((json['NetIncomeOrLossGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'OtherRevenueMiscGrp': !exists(json, 'OtherRevenueMiscGrp') ? undefined : ((json['OtherRevenueMiscGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscFromJSON)),
        'MiscellaneousRevenueGrp': !exists(json, 'MiscellaneousRevenueGrp') ? undefined : ((json['MiscellaneousRevenueGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'OtherRevenueTotalAmt': !exists(json, 'OtherRevenueTotalAmt') ? undefined : json['OtherRevenueTotalAmt'],
        'TotalRevenueGrp': !exists(json, 'TotalRevenueGrp') ? undefined : ((json['TotalRevenueGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'InfoInScheduleOPartIXInd': !exists(json, 'InfoInScheduleOPartIXInd') ? undefined : json['InfoInScheduleOPartIXInd'],
        'GrantsToDomesticOrgsGrp': !exists(json, 'GrantsToDomesticOrgsGrp') ? undefined : ((json['GrantsToDomesticOrgsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'GrantsToDomesticIndividualsGrp': !exists(json, 'GrantsToDomesticIndividualsGrp') ? undefined : ((json['GrantsToDomesticIndividualsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'ForeignGrantsGrp': !exists(json, 'ForeignGrantsGrp') ? undefined : ((json['ForeignGrantsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'BenefitsToMembersGrp': !exists(json, 'BenefitsToMembersGrp') ? undefined : ((json['BenefitsToMembersGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'CompCurrentOfcrDirectorsGrp': !exists(json, 'CompCurrentOfcrDirectorsGrp') ? undefined : ((json['CompCurrentOfcrDirectorsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'CompDisqualPersonsGrp': !exists(json, 'CompDisqualPersonsGrp') ? undefined : ((json['CompDisqualPersonsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OtherSalariesAndWagesGrp': !exists(json, 'OtherSalariesAndWagesGrp') ? undefined : ((json['OtherSalariesAndWagesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PensionPlanContributionsGrp': !exists(json, 'PensionPlanContributionsGrp') ? undefined : ((json['PensionPlanContributionsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OtherEmployeeBenefitsGrp': !exists(json, 'OtherEmployeeBenefitsGrp') ? undefined : ((json['OtherEmployeeBenefitsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PayrollTaxesGrp': !exists(json, 'PayrollTaxesGrp') ? undefined : ((json['PayrollTaxesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesManagementGrp': !exists(json, 'FeesForServicesManagementGrp') ? undefined : ((json['FeesForServicesManagementGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesLegalGrp': !exists(json, 'FeesForServicesLegalGrp') ? undefined : ((json['FeesForServicesLegalGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesAccountingGrp': !exists(json, 'FeesForServicesAccountingGrp') ? undefined : ((json['FeesForServicesAccountingGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesLobbyingGrp': !exists(json, 'FeesForServicesLobbyingGrp') ? undefined : ((json['FeesForServicesLobbyingGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesProfFundraising': !exists(json, 'FeesForServicesProfFundraising') ? undefined : ((json['FeesForServicesProfFundraising'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForSrvcInvstMgmntFeesGrp': !exists(json, 'FeesForSrvcInvstMgmntFeesGrp') ? undefined : ((json['FeesForSrvcInvstMgmntFeesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesOtherGrp': !exists(json, 'FeesForServicesOtherGrp') ? undefined : ((json['FeesForServicesOtherGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'AdvertisingGrp': !exists(json, 'AdvertisingGrp') ? undefined : ((json['AdvertisingGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OfficeExpensesGrp': !exists(json, 'OfficeExpensesGrp') ? undefined : ((json['OfficeExpensesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InformationTechnologyGrp': !exists(json, 'InformationTechnologyGrp') ? undefined : ((json['InformationTechnologyGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'RoyaltiesGrp': !exists(json, 'RoyaltiesGrp') ? undefined : ((json['RoyaltiesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OccupancyGrp': !exists(json, 'OccupancyGrp') ? undefined : ((json['OccupancyGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'TravelGrp': !exists(json, 'TravelGrp') ? undefined : ((json['TravelGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PymtTravelEntrtnmntPubOfclGrp': !exists(json, 'PymtTravelEntrtnmntPubOfclGrp') ? undefined : ((json['PymtTravelEntrtnmntPubOfclGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'ConferencesMeetingsGrp': !exists(json, 'ConferencesMeetingsGrp') ? undefined : ((json['ConferencesMeetingsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InterestGrp': !exists(json, 'InterestGrp') ? undefined : ((json['InterestGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PaymentsToAffiliatesGrp': !exists(json, 'PaymentsToAffiliatesGrp') ? undefined : ((json['PaymentsToAffiliatesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'DepreciationDepletionGrp': !exists(json, 'DepreciationDepletionGrp') ? undefined : ((json['DepreciationDepletionGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InsuranceGrp': !exists(json, 'InsuranceGrp') ? undefined : ((json['InsuranceGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OtherExpensesGrp': !exists(json, 'OtherExpensesGrp') ? undefined : ((json['OtherExpensesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'AllOtherExpensesGrp': !exists(json, 'AllOtherExpensesGrp') ? undefined : ((json['AllOtherExpensesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'TotalFunctionalExpensesGrp': !exists(json, 'TotalFunctionalExpensesGrp') ? undefined : ((json['TotalFunctionalExpensesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'JointCostsInd': !exists(json, 'JointCostsInd') ? undefined : json['JointCostsInd'],
        'TotalJointCostsGrp': !exists(json, 'TotalJointCostsGrp') ? undefined : ((json['TotalJointCostsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InfoInScheduleOPartXInd': !exists(json, 'InfoInScheduleOPartXInd') ? undefined : json['InfoInScheduleOPartXInd'],
        'CashNonInterestBearingGrp': !exists(json, 'CashNonInterestBearingGrp') ? undefined : ((json['CashNonInterestBearingGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'SavingsAndTempCashInvstGrp': !exists(json, 'SavingsAndTempCashInvstGrp') ? undefined : ((json['SavingsAndTempCashInvstGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'PledgesAndGrantsReceivableGrp': !exists(json, 'PledgesAndGrantsReceivableGrp') ? undefined : ((json['PledgesAndGrantsReceivableGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'AccountsReceivableGrp': !exists(json, 'AccountsReceivableGrp') ? undefined : ((json['AccountsReceivableGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'ReceivablesFromOfficersEtcGrp': !exists(json, 'ReceivablesFromOfficersEtcGrp') ? undefined : ((json['ReceivablesFromOfficersEtcGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'RcvblFromDisqualifiedPrsnGrp': !exists(json, 'RcvblFromDisqualifiedPrsnGrp') ? undefined : ((json['RcvblFromDisqualifiedPrsnGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OthNotesLoansReceivableNetGrp': !exists(json, 'OthNotesLoansReceivableNetGrp') ? undefined : ((json['OthNotesLoansReceivableNetGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InventoriesForSaleOrUseGrp': !exists(json, 'InventoriesForSaleOrUseGrp') ? undefined : ((json['InventoriesForSaleOrUseGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'PrepaidExpensesDefrdChargesGrp': !exists(json, 'PrepaidExpensesDefrdChargesGrp') ? undefined : ((json['PrepaidExpensesDefrdChargesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'LandBldgEquipCostOrOtherBssAmt': !exists(json, 'LandBldgEquipCostOrOtherBssAmt') ? undefined : json['LandBldgEquipCostOrOtherBssAmt'],
        'LandBldgEquipAccumDeprecAmt': !exists(json, 'LandBldgEquipAccumDeprecAmt') ? undefined : json['LandBldgEquipAccumDeprecAmt'],
        'LandBldgEquipBasisNetGrp': !exists(json, 'LandBldgEquipBasisNetGrp') ? undefined : ((json['LandBldgEquipBasisNetGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InvestmentsPubTradedSecGrp': !exists(json, 'InvestmentsPubTradedSecGrp') ? undefined : ((json['InvestmentsPubTradedSecGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InvestmentsOtherSecuritiesGrp': !exists(json, 'InvestmentsOtherSecuritiesGrp') ? undefined : ((json['InvestmentsOtherSecuritiesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InvestmentsProgramRelatedGrp': !exists(json, 'InvestmentsProgramRelatedGrp') ? undefined : ((json['InvestmentsProgramRelatedGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'IntangibleAssetsGrp': !exists(json, 'IntangibleAssetsGrp') ? undefined : ((json['IntangibleAssetsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OtherAssetsTotalGrp': !exists(json, 'OtherAssetsTotalGrp') ? undefined : ((json['OtherAssetsTotalGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotalAssetsGrp': !exists(json, 'TotalAssetsGrp') ? undefined : ((json['TotalAssetsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'AccountsPayableAccrExpnssGrp': !exists(json, 'AccountsPayableAccrExpnssGrp') ? undefined : ((json['AccountsPayableAccrExpnssGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'GrantsPayableGrp': !exists(json, 'GrantsPayableGrp') ? undefined : ((json['GrantsPayableGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'DeferredRevenueGrp': !exists(json, 'DeferredRevenueGrp') ? undefined : ((json['DeferredRevenueGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TaxExemptBondLiabilitiesGrp': !exists(json, 'TaxExemptBondLiabilitiesGrp') ? undefined : ((json['TaxExemptBondLiabilitiesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'EscrowAccountLiabilityGrp': !exists(json, 'EscrowAccountLiabilityGrp') ? undefined : ((json['EscrowAccountLiabilityGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'LoansFromOfficersDirectorsGrp': !exists(json, 'LoansFromOfficersDirectorsGrp') ? undefined : ((json['LoansFromOfficersDirectorsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'MortgNotesPyblScrdInvstPropGrp': !exists(json, 'MortgNotesPyblScrdInvstPropGrp') ? undefined : ((json['MortgNotesPyblScrdInvstPropGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'UnsecuredNotesLoansPayableGrp': !exists(json, 'UnsecuredNotesLoansPayableGrp') ? undefined : ((json['UnsecuredNotesLoansPayableGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OtherLiabilitiesGrp': !exists(json, 'OtherLiabilitiesGrp') ? undefined : ((json['OtherLiabilitiesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotalLiabilitiesGrp': !exists(json, 'TotalLiabilitiesGrp') ? undefined : ((json['TotalLiabilitiesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OrganizationFollowsFASB117Ind': !exists(json, 'OrganizationFollowsFASB117Ind') ? undefined : json['OrganizationFollowsFASB117Ind'],
        'NoDonorRestrictionNetAssetsGrp': !exists(json, 'NoDonorRestrictionNetAssetsGrp') ? undefined : ((json['NoDonorRestrictionNetAssetsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'DonorRestrictionNetAssetsGrp': !exists(json, 'DonorRestrictionNetAssetsGrp') ? undefined : ((json['DonorRestrictionNetAssetsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OrgDoesNotFollowFASB117Ind': !exists(json, 'OrgDoesNotFollowFASB117Ind') ? undefined : json['OrgDoesNotFollowFASB117Ind'],
        'CapStkTrPrinCurrentFundsGrp': !exists(json, 'CapStkTrPrinCurrentFundsGrp') ? undefined : ((json['CapStkTrPrinCurrentFundsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'PdInCapSrplsLandBldgEqpFundGrp': !exists(json, 'PdInCapSrplsLandBldgEqpFundGrp') ? undefined : ((json['PdInCapSrplsLandBldgEqpFundGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'RtnEarnEndowmentIncmOthFndsGrp': !exists(json, 'RtnEarnEndowmentIncmOthFndsGrp') ? undefined : ((json['RtnEarnEndowmentIncmOthFndsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotalNetAssetsFundBalanceGrp': !exists(json, 'TotalNetAssetsFundBalanceGrp') ? undefined : ((json['TotalNetAssetsFundBalanceGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotLiabNetAssetsFundBalanceGrp': !exists(json, 'TotLiabNetAssetsFundBalanceGrp') ? undefined : ((json['TotLiabNetAssetsFundBalanceGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InfoInScheduleOPartXIInd': !exists(json, 'InfoInScheduleOPartXIInd') ? undefined : json['InfoInScheduleOPartXIInd'],
        'ReconcilationRevenueExpnssAmt': !exists(json, 'ReconcilationRevenueExpnssAmt') ? undefined : json['ReconcilationRevenueExpnssAmt'],
        'NetUnrlzdGainsLossesInvstAmt': !exists(json, 'NetUnrlzdGainsLossesInvstAmt') ? undefined : json['NetUnrlzdGainsLossesInvstAmt'],
        'DonatedServicesAndUseFcltsAmt': !exists(json, 'DonatedServicesAndUseFcltsAmt') ? undefined : json['DonatedServicesAndUseFcltsAmt'],
        'InvestmentExpenseAmt': !exists(json, 'InvestmentExpenseAmt') ? undefined : json['InvestmentExpenseAmt'],
        'PriorPeriodAdjustmentsAmt': !exists(json, 'PriorPeriodAdjustmentsAmt') ? undefined : json['PriorPeriodAdjustmentsAmt'],
        'OtherChangesInNetAssetsAmt': !exists(json, 'OtherChangesInNetAssetsAmt') ? undefined : json['OtherChangesInNetAssetsAmt'],
        'InfoInScheduleOPartXIIInd': !exists(json, 'InfoInScheduleOPartXIIInd') ? undefined : json['InfoInScheduleOPartXIIInd'],
        'MethodOfAccountingCashInd': !exists(json, 'MethodOfAccountingCashInd') ? undefined : json['MethodOfAccountingCashInd'],
        'MethodOfAccountingAccrualInd': !exists(json, 'MethodOfAccountingAccrualInd') ? undefined : json['MethodOfAccountingAccrualInd'],
        'MethodOfAccountingOtherInd': !exists(json, 'MethodOfAccountingOtherInd') ? undefined : json['MethodOfAccountingOtherInd'],
        'AccountantCompileOrReviewInd': !exists(json, 'AccountantCompileOrReviewInd') ? undefined : json['AccountantCompileOrReviewInd'],
        'AcctCompileOrReviewBasisGrp': !exists(json, 'AcctCompileOrReviewBasisGrp') ? undefined : ((json['AcctCompileOrReviewBasisGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON)),
        'FSAuditedInd': !exists(json, 'FSAuditedInd') ? undefined : json['FSAuditedInd'],
        'FSAuditedBasisGrp': !exists(json, 'FSAuditedBasisGrp') ? undefined : ((json['FSAuditedBasisGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON)),
        'AuditCommitteeInd': !exists(json, 'AuditCommitteeInd') ? undefined : json['AuditCommitteeInd'],
        'FederalGrantAuditRequiredInd': !exists(json, 'FederalGrantAuditRequiredInd') ? undefined : json['FederalGrantAuditRequiredInd'],
        'FederalGrantAuditPerformedInd': !exists(json, 'FederalGrantAuditPerformedInd') ? undefined : json['FederalGrantAuditPerformedInd'],
    };
}

export function ExecRiskIRS990ToJSON(value?: ExecRiskIRS990 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SpecialConditionDesc': value.SpecialConditionDesc,
        'accountingPeriodChangeCd': value.accountingPeriodChangeCd,
        'accountingPeriodChangeApprvCd': value.accountingPeriodChangeApprvCd,
        'AddressChangeInd': value.AddressChangeInd,
        'NameChangeInd': value.NameChangeInd,
        'InitialReturnInd': value.InitialReturnInd,
        'FinalReturnInd': value.FinalReturnInd,
        'AmendedReturnInd': value.AmendedReturnInd,
        'ApplicationPendingInd': value.ApplicationPendingInd,
        'DoingBusinessAsName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.DoingBusinessAsName),
        'GrossReceiptsAmt': value.GrossReceiptsAmt,
        'PrincipalOfficerNm': value.PrincipalOfficerNm,
        'PrincipalOfcrBusinessName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.PrincipalOfcrBusinessName),
        'USAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'Organization501c3Ind': value.Organization501c3Ind,
        'organization_501_c_ind': value.organization_501_c_ind,
        'organization_501_c_type_text': value.organization_501_c_type_text,
        'Organization4947a1NotPFInd': value.Organization4947a1NotPFInd,
        'Organization527Ind': value.Organization527Ind,
        'WebsiteAddressTxt': value.WebsiteAddressTxt,
        'GroupReturnForAffiliatesInd': value.GroupReturnForAffiliatesInd,
        'AllAffiliatesIncludedInd': value.AllAffiliatesIncludedInd,
        'GroupExemptionNum': value.GroupExemptionNum,
        'TypeOfOrganizationCorpInd': value.TypeOfOrganizationCorpInd,
        'TypeOfOrganizationTrustInd': value.TypeOfOrganizationTrustInd,
        'TypeOfOrganizationAssocInd': value.TypeOfOrganizationAssocInd,
        'TypeOfOrganizationOtherInd': value.TypeOfOrganizationOtherInd,
        'OtherOrganizationDsc': value.OtherOrganizationDsc,
        'FormationYr': value.FormationYr,
        'LegalDomicileStateCd': value.LegalDomicileStateCd,
        'LegalDomicileCountryCd': value.LegalDomicileCountryCd,
        'ActivityOrMissionDesc': value.ActivityOrMissionDesc,
        'ContractTerminationInd': value.ContractTerminationInd,
        'VotingMembersGoverningBodyCnt': value.VotingMembersGoverningBodyCnt,
        'VotingMembersIndependentCnt': value.VotingMembersIndependentCnt,
        'TotalEmployeeCnt': value.TotalEmployeeCnt,
        'TotalVolunteersCnt': value.TotalVolunteersCnt,
        'TotalGrossUBIAmt': value.TotalGrossUBIAmt,
        'NetUnrelatedBusTxblIncmAmt': value.NetUnrelatedBusTxblIncmAmt,
        'PYContributionsGrantsAmt': value.PYContributionsGrantsAmt,
        'CYContributionsGrantsAmt': value.CYContributionsGrantsAmt,
        'PYProgramServiceRevenueAmt': value.PYProgramServiceRevenueAmt,
        'CYProgramServiceRevenueAmt': value.CYProgramServiceRevenueAmt,
        'PYInvestmentIncomeAmt': value.PYInvestmentIncomeAmt,
        'CYInvestmentIncomeAmt': value.CYInvestmentIncomeAmt,
        'PYOtherRevenueAmt': value.PYOtherRevenueAmt,
        'PYTotalRevenueAmt': value.PYTotalRevenueAmt,
        'CYTotalRevenueAmt': value.CYTotalRevenueAmt,
        'PYGrantsAndSimilarPaidAmt': value.PYGrantsAndSimilarPaidAmt,
        'CYGrantsAndSimilarPaidAmt': value.CYGrantsAndSimilarPaidAmt,
        'PYBenefitsPaidToMembersAmt': value.PYBenefitsPaidToMembersAmt,
        'CYBenefitsPaidToMembersAmt': value.CYBenefitsPaidToMembersAmt,
        'PYSalariesCompEmpBnftPaidAmt': value.PYSalariesCompEmpBnftPaidAmt,
        'CYSalariesCompEmpBnftPaidAmt': value.CYSalariesCompEmpBnftPaidAmt,
        'PYTotalProfFndrsngExpnsAmt': value.PYTotalProfFndrsngExpnsAmt,
        'CYTotalProfFndrsngExpnsAmt': value.CYTotalProfFndrsngExpnsAmt,
        'CYTotalFundraisingExpenseAmt': value.CYTotalFundraisingExpenseAmt,
        'PYOtherExpensesAmt': value.PYOtherExpensesAmt,
        'CYOtherExpensesAmt': value.CYOtherExpensesAmt,
        'PYTotalExpensesAmt': value.PYTotalExpensesAmt,
        'CYTotalExpensesAmt': value.CYTotalExpensesAmt,
        'PYRevenuesLessExpensesAmt': value.PYRevenuesLessExpensesAmt,
        'CYRevenuesLessExpensesAmt': value.CYRevenuesLessExpensesAmt,
        'TotalAssetsBOYAmt': value.TotalAssetsBOYAmt,
        'TotalAssetsEOYAmt': value.TotalAssetsEOYAmt,
        'NetAssetsOrFundBalancesBOYAmt': value.NetAssetsOrFundBalancesBOYAmt,
        'NetAssetsOrFundBalancesEOYAmt': value.NetAssetsOrFundBalancesEOYAmt,
        'InfoInScheduleOPartIIIInd': value.InfoInScheduleOPartIIIInd,
        'MissionDesc': value.MissionDesc,
        'SignificantNewProgramSrvcInd': value.SignificantNewProgramSrvcInd,
        'SignificantChangeInd': value.SignificantChangeInd,
        'ActivityCd': value.ActivityCd,
        'ExpenseAmt': value.ExpenseAmt,
        'GrantAmt': value.GrantAmt,
        'RevenueAmt': value.RevenueAmt,
        'Desc': value.Desc,
        'ProgSrvcAccomActy2Grp': value.ProgSrvcAccomActy2Grp === undefined ? undefined : ((value.ProgSrvcAccomActy2Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON)),
        'ProgSrvcAccomActy3Grp': value.ProgSrvcAccomActy3Grp === undefined ? undefined : ((value.ProgSrvcAccomActy3Grp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON)),
        'ProgSrvcAccomActyOtherGrp': value.ProgSrvcAccomActyOtherGrp === undefined ? undefined : ((value.ProgSrvcAccomActyOtherGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON)),
        'TotalOtherProgSrvcExpenseAmt': value.TotalOtherProgSrvcExpenseAmt,
        'TotalOtherProgSrvcGrantAmt': value.TotalOtherProgSrvcGrantAmt,
        'TotalOtherProgSrvcRevenueAmt': value.TotalOtherProgSrvcRevenueAmt,
        'TotalProgramServiceExpensesAmt': value.TotalProgramServiceExpensesAmt,
        'DescribedInSection501c3Ind': value.DescribedInSection501c3Ind,
        'ScheduleBRequiredInd': value.ScheduleBRequiredInd,
        'PoliticalCampaignActyInd': value.PoliticalCampaignActyInd,
        'LobbyingActivitiesInd': value.LobbyingActivitiesInd,
        'SubjectToProxyTaxInd': value.SubjectToProxyTaxInd,
        'DonorAdvisedFundInd': value.DonorAdvisedFundInd,
        'ConservationEasementsInd': value.ConservationEasementsInd,
        'CollectionsOfArtInd': value.CollectionsOfArtInd,
        'CreditCounselingInd': value.CreditCounselingInd,
        'DonorRstrOrQuasiEndowmentsInd': value.DonorRstrOrQuasiEndowmentsInd,
        'ReportLandBuildingEquipmentInd': value.ReportLandBuildingEquipmentInd,
        'ReportInvestmentsOtherSecInd': value.ReportInvestmentsOtherSecInd,
        'ReportProgramRelatedInvstInd': value.ReportProgramRelatedInvstInd,
        'ReportOtherAssetsInd': value.ReportOtherAssetsInd,
        'ReportOtherLiabilitiesInd': value.ReportOtherLiabilitiesInd,
        'IncludeFIN48FootnoteInd': value.IncludeFIN48FootnoteInd,
        'IndependentAuditFinclStmtInd': value.IndependentAuditFinclStmtInd,
        'ConsolidatedAuditFinclStmtInd': value.ConsolidatedAuditFinclStmtInd,
        'SchoolOperatingInd': value.SchoolOperatingInd,
        'ForeignOfficeInd': value.ForeignOfficeInd,
        'ForeignActivitiesInd': value.ForeignActivitiesInd,
        'MoreThan5000KToOrgInd': value.MoreThan5000KToOrgInd,
        'MoreThan5000KToIndividualsInd': value.MoreThan5000KToIndividualsInd,
        'ProfessionalFundraisingInd': value.ProfessionalFundraisingInd,
        'FundraisingActivitiesInd': value.FundraisingActivitiesInd,
        'GamingActivitiesInd': value.GamingActivitiesInd,
        'OperateHospitalInd': value.OperateHospitalInd,
        'AuditedFinancialStmtAttInd': value.AuditedFinancialStmtAttInd,
        'GrantsToOrganizationsInd': value.GrantsToOrganizationsInd,
        'GrantsToIndividualsInd': value.GrantsToIndividualsInd,
        'ScheduleJRequiredInd': value.ScheduleJRequiredInd,
        'TaxExemptBondsInd': value.TaxExemptBondsInd,
        'InvestTaxExemptBondsInd': value.InvestTaxExemptBondsInd,
        'EscrowAccountInd': value.EscrowAccountInd,
        'OnBehalfOfIssuerInd': value.OnBehalfOfIssuerInd,
        'EngagedInExcessBenefitTransInd': value.EngagedInExcessBenefitTransInd,
        'PYExcessBenefitTransInd': value.PYExcessBenefitTransInd,
        'LoanOutstandingInd': value.LoanOutstandingInd,
        'GrantToRelatedPersonInd': value.GrantToRelatedPersonInd,
        'BusinessRlnWithOrgMemInd': value.BusinessRlnWithOrgMemInd,
        'BusinessRlnWithFamMemInd': value.BusinessRlnWithFamMemInd,
        'BusinessRlnWith35CtrlEntInd': value.BusinessRlnWith35CtrlEntInd,
        'DeductibleNonCashContriInd': value.DeductibleNonCashContriInd,
        'DeductibleArtContributionInd': value.DeductibleArtContributionInd,
        'TerminateOperationsInd': value.TerminateOperationsInd,
        'PartialLiquidationInd': value.PartialLiquidationInd,
        'DisregardedEntityInd': value.DisregardedEntityInd,
        'RelatedEntityInd': value.RelatedEntityInd,
        'RelatedOrganizationCtrlEntInd': value.RelatedOrganizationCtrlEntInd,
        'TransactionWithControlEntInd': value.TransactionWithControlEntInd,
        'TrnsfrExmptNonChrtblRltdOrgInd': value.TrnsfrExmptNonChrtblRltdOrgInd,
        'ActivitiesConductedPrtshpInd': value.ActivitiesConductedPrtshpInd,
        'InfoInScheduleOPartVInd': value.InfoInScheduleOPartVInd,
        'IRPDocumentCnt': value.IRPDocumentCnt,
        'IRPDocumentW2GCnt': value.IRPDocumentW2GCnt,
        'BackupWthldComplianceInd': value.BackupWthldComplianceInd,
        'EmployeeCnt': value.EmployeeCnt,
        'EmploymentTaxReturnsFiledInd': value.EmploymentTaxReturnsFiledInd,
        'UnrelatedBusIncmOverLimitInd': value.UnrelatedBusIncmOverLimitInd,
        'Form990TFiledInd': value.Form990TFiledInd,
        'ForeignFinancialAccountInd': value.ForeignFinancialAccountInd,
        'ForeignCountryCd': value.ForeignCountryCd,
        'ProhibitedTaxShelterTransInd': value.ProhibitedTaxShelterTransInd,
        'TaxablePartyNotificationInd': value.TaxablePartyNotificationInd,
        'Form8886TFiledInd': value.Form8886TFiledInd,
        'NondeductibleContributionsInd': value.NondeductibleContributionsInd,
        'NondeductibleContriDisclInd': value.NondeductibleContriDisclInd,
        'QuidProQuoContributionsInd': value.QuidProQuoContributionsInd,
        'QuidProQuoContriDisclInd': value.QuidProQuoContriDisclInd,
        'Form8282PropertyDisposedOfInd': value.Form8282PropertyDisposedOfInd,
        'Form8282FiledCnt': value.Form8282FiledCnt,
        'RcvFndsToPayPrsnlBnftCntrctInd': value.RcvFndsToPayPrsnlBnftCntrctInd,
        'PayPremiumsPrsnlBnftCntrctInd': value.PayPremiumsPrsnlBnftCntrctInd,
        'Form8899Filedind': value.Form8899Filedind,
        'Form1098CFiledInd': value.Form1098CFiledInd,
        'DAFExcessBusinessHoldingsInd': value.DAFExcessBusinessHoldingsInd,
        'TaxableDistributionsInd': value.TaxableDistributionsInd,
        'DistributionToDonorInd': value.DistributionToDonorInd,
        'InitiationFeesAndCapContriAmt': value.InitiationFeesAndCapContriAmt,
        'GrossReceiptsForPublicUseAmt': value.GrossReceiptsForPublicUseAmt,
        'MembersAndShrGrossIncomeAmt': value.MembersAndShrGrossIncomeAmt,
        'OtherSourcesGrossIncomeAmt': value.OtherSourcesGrossIncomeAmt,
        'OrgFiledInLieuOfForm1041Ind': value.OrgFiledInLieuOfForm1041Ind,
        'TaxExemptInterestAmt': value.TaxExemptInterestAmt,
        'LicensedMoreThanOneStateInd': value.LicensedMoreThanOneStateInd,
        'StateRequiredReservesAmt': value.StateRequiredReservesAmt,
        'ReservesMaintainedAmt': value.ReservesMaintainedAmt,
        'IndoorTanningServicesInd': value.IndoorTanningServicesInd,
        'Form720FiledInd': value.Form720FiledInd,
        'SubjToTaxRmnrtnExPrchtPymtInd': value.SubjToTaxRmnrtnExPrchtPymtInd,
        'SubjectToExcsTaxNetInvstIncInd': value.SubjectToExcsTaxNetInvstIncInd,
        'ExcsTaxSect4951Or4952Or4953Ind': value.ExcsTaxSect4951Or4952Or4953Ind,
        'InfoInScheduleOPartVIInd': value.InfoInScheduleOPartVIInd,
        'GoverningBodyVotingMembersCnt': value.GoverningBodyVotingMembersCnt,
        'IndependentVotingMemberCnt': value.IndependentVotingMemberCnt,
        'FamilyOrBusinessRlnInd': value.FamilyOrBusinessRlnInd,
        'DelegationOfMgmtDutiesInd': value.DelegationOfMgmtDutiesInd,
        'ChangeToOrgDocumentsInd': value.ChangeToOrgDocumentsInd,
        'MaterialDiversionOrMisuseInd': value.MaterialDiversionOrMisuseInd,
        'MembersOrStockholdersInd': value.MembersOrStockholdersInd,
        'ElectionOfBoardMembersInd': value.ElectionOfBoardMembersInd,
        'DecisionsSubjectToApprovaInd': value.DecisionsSubjectToApprovaInd,
        'MinutesOfGoverningBodyInd': value.MinutesOfGoverningBodyInd,
        'MinutesOfCommitteesInd': value.MinutesOfCommitteesInd,
        'OfficerMailingAddressInd': value.OfficerMailingAddressInd,
        'LocalChaptersInd': value.LocalChaptersInd,
        'PoliciesReferenceChaptersInd': value.PoliciesReferenceChaptersInd,
        'Form990ProvidedToGvrnBodyInd': value.Form990ProvidedToGvrnBodyInd,
        'ConflictOfInterestPolicyInd': value.ConflictOfInterestPolicyInd,
        'AnnualDisclosureCoveredPrsnInd': value.AnnualDisclosureCoveredPrsnInd,
        'RegularMonitoringEnfrcInd': value.RegularMonitoringEnfrcInd,
        'WhistleblowerPolicyInd': value.WhistleblowerPolicyInd,
        'DocumentRetentionPolicyInd': value.DocumentRetentionPolicyInd,
        'CompensationProcessCEOInd': value.CompensationProcessCEOInd,
        'CompensationProcessOtherInd': value.CompensationProcessOtherInd,
        'InvestmentInJointVentureInd': value.InvestmentInJointVentureInd,
        'WrittenPolicyOrProcedureInd': value.WrittenPolicyOrProcedureInd,
        'StatesWhereCopyOfReturnIsFldCd': value.StatesWhereCopyOfReturnIsFldCd,
        'OwnWebsiteInd': value.OwnWebsiteInd,
        'OtherWebsiteInd': value.OtherWebsiteInd,
        'UponRequestInd': value.UponRequestInd,
        'OtherInd': value.OtherInd,
        'BooksInCareOfDetail': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON(value.BooksInCareOfDetail),
        'InfoInScheduleOPartVIIInd': value.InfoInScheduleOPartVIIInd,
        'NoListedPersonsCompensatedInd': value.NoListedPersonsCompensatedInd,
        'Form990PartVIISectionAGrp': value.Form990PartVIISectionAGrp === undefined ? undefined : ((value.Form990PartVIISectionAGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAToJSON)),
        'TotalReportableCompFromOrgAmt': value.TotalReportableCompFromOrgAmt,
        'TotReportableCompRltdOrgAmt': value.TotReportableCompRltdOrgAmt,
        'TotalOtherCompensationAmt': value.TotalOtherCompensationAmt,
        'IndivRcvdGreaterThan100KCnt': value.IndivRcvdGreaterThan100KCnt,
        'FormerOfcrEmployeesListedInd': value.FormerOfcrEmployeesListedInd,
        'CompensationFromOtherSrcsInd': value.CompensationFromOtherSrcsInd,
        'ContractorCompensationGrp': value.ContractorCompensationGrp === undefined ? undefined : ((value.ContractorCompensationGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationToJSON)),
        'CntrctRcvdGreaterThan100KCnt': value.CntrctRcvdGreaterThan100KCnt,
        'InfoInScheduleOPartVIIIInd': value.InfoInScheduleOPartVIIIInd,
        'FederatedCampaignsAmt': value.FederatedCampaignsAmt,
        'MembershipDuesAmt': value.MembershipDuesAmt,
        'FundraisingAmt': value.FundraisingAmt,
        'RelatedOrganizationsAmt': value.RelatedOrganizationsAmt,
        'GovernmentGrantsAmt': value.GovernmentGrantsAmt,
        'AllOtherContributionsAmt': value.AllOtherContributionsAmt,
        'NoncashContributionsAmt': value.NoncashContributionsAmt,
        'TotalContributionsAmt': value.TotalContributionsAmt,
        'ProgramServiceRevenueGrp': value.ProgramServiceRevenueGrp === undefined ? undefined : ((value.ProgramServiceRevenueGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueToJSON)),
        'TotalOthProgramServiceRevGrp': value.TotalOthProgramServiceRevGrp === undefined ? undefined : ((value.TotalOthProgramServiceRevGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'TotalProgramServiceRevenueAmt': value.TotalProgramServiceRevenueAmt,
        'InvestmentIncomeGrp': value.InvestmentIncomeGrp === undefined ? undefined : ((value.InvestmentIncomeGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'IncmFromInvestBondProceedsGrp': value.IncmFromInvestBondProceedsGrp === undefined ? undefined : ((value.IncmFromInvestBondProceedsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'RoyaltiesRevenueGrp': value.RoyaltiesRevenueGrp === undefined ? undefined : ((value.RoyaltiesRevenueGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GrossRentsGrp': value.GrossRentsGrp === undefined ? undefined : ((value.GrossRentsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON)),
        'LessRentalExpensesGrp': value.LessRentalExpensesGrp === undefined ? undefined : ((value.LessRentalExpensesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON)),
        'RentalIncomeOrLossGrp': value.RentalIncomeOrLossGrp === undefined ? undefined : ((value.RentalIncomeOrLossGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON)),
        'NetRentalIncomeOrLossGrp': value.NetRentalIncomeOrLossGrp === undefined ? undefined : ((value.NetRentalIncomeOrLossGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GrossAmountSalesAssetsGrp': value.GrossAmountSalesAssetsGrp === undefined ? undefined : ((value.GrossAmountSalesAssetsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON)),
        'LessCostOthBasisSalesExpnssGrp': value.LessCostOthBasisSalesExpnssGrp === undefined ? undefined : ((value.LessCostOthBasisSalesExpnssGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON)),
        'GainOrLossGrp': value.GainOrLossGrp === undefined ? undefined : ((value.GainOrLossGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON)),
        'NetGainOrLossInvestmentsGrp': value.NetGainOrLossInvestmentsGrp === undefined ? undefined : ((value.NetGainOrLossInvestmentsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'ContriRptFundraisingEventAmt': value.ContriRptFundraisingEventAmt,
        'FundraisingGrossIncomeAmt': value.FundraisingGrossIncomeAmt,
        'FundraisingDirectExpensesAmt': value.FundraisingDirectExpensesAmt,
        'NetIncmFromFundraisingEvtGrp': value.NetIncmFromFundraisingEvtGrp === undefined ? undefined : ((value.NetIncmFromFundraisingEvtGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GamingGrossIncomeAmt': value.GamingGrossIncomeAmt,
        'GamingDirectExpensesAmt': value.GamingDirectExpensesAmt,
        'NetIncomeFromGamingGrp': value.NetIncomeFromGamingGrp === undefined ? undefined : ((value.NetIncomeFromGamingGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GrossSalesOfInventoryAmt': value.GrossSalesOfInventoryAmt,
        'CostOfGoodsSoldAmt': value.CostOfGoodsSoldAmt,
        'NetIncomeOrLossGrp': value.NetIncomeOrLossGrp === undefined ? undefined : ((value.NetIncomeOrLossGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'OtherRevenueMiscGrp': value.OtherRevenueMiscGrp === undefined ? undefined : ((value.OtherRevenueMiscGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscToJSON)),
        'MiscellaneousRevenueGrp': value.MiscellaneousRevenueGrp === undefined ? undefined : ((value.MiscellaneousRevenueGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'OtherRevenueTotalAmt': value.OtherRevenueTotalAmt,
        'TotalRevenueGrp': value.TotalRevenueGrp === undefined ? undefined : ((value.TotalRevenueGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'InfoInScheduleOPartIXInd': value.InfoInScheduleOPartIXInd,
        'GrantsToDomesticOrgsGrp': value.GrantsToDomesticOrgsGrp === undefined ? undefined : ((value.GrantsToDomesticOrgsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'GrantsToDomesticIndividualsGrp': value.GrantsToDomesticIndividualsGrp === undefined ? undefined : ((value.GrantsToDomesticIndividualsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'ForeignGrantsGrp': value.ForeignGrantsGrp === undefined ? undefined : ((value.ForeignGrantsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'BenefitsToMembersGrp': value.BenefitsToMembersGrp === undefined ? undefined : ((value.BenefitsToMembersGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'CompCurrentOfcrDirectorsGrp': value.CompCurrentOfcrDirectorsGrp === undefined ? undefined : ((value.CompCurrentOfcrDirectorsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'CompDisqualPersonsGrp': value.CompDisqualPersonsGrp === undefined ? undefined : ((value.CompDisqualPersonsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OtherSalariesAndWagesGrp': value.OtherSalariesAndWagesGrp === undefined ? undefined : ((value.OtherSalariesAndWagesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PensionPlanContributionsGrp': value.PensionPlanContributionsGrp === undefined ? undefined : ((value.PensionPlanContributionsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OtherEmployeeBenefitsGrp': value.OtherEmployeeBenefitsGrp === undefined ? undefined : ((value.OtherEmployeeBenefitsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PayrollTaxesGrp': value.PayrollTaxesGrp === undefined ? undefined : ((value.PayrollTaxesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesManagementGrp': value.FeesForServicesManagementGrp === undefined ? undefined : ((value.FeesForServicesManagementGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesLegalGrp': value.FeesForServicesLegalGrp === undefined ? undefined : ((value.FeesForServicesLegalGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesAccountingGrp': value.FeesForServicesAccountingGrp === undefined ? undefined : ((value.FeesForServicesAccountingGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesLobbyingGrp': value.FeesForServicesLobbyingGrp === undefined ? undefined : ((value.FeesForServicesLobbyingGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesProfFundraising': value.FeesForServicesProfFundraising === undefined ? undefined : ((value.FeesForServicesProfFundraising as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForSrvcInvstMgmntFeesGrp': value.FeesForSrvcInvstMgmntFeesGrp === undefined ? undefined : ((value.FeesForSrvcInvstMgmntFeesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesOtherGrp': value.FeesForServicesOtherGrp === undefined ? undefined : ((value.FeesForServicesOtherGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'AdvertisingGrp': value.AdvertisingGrp === undefined ? undefined : ((value.AdvertisingGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OfficeExpensesGrp': value.OfficeExpensesGrp === undefined ? undefined : ((value.OfficeExpensesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InformationTechnologyGrp': value.InformationTechnologyGrp === undefined ? undefined : ((value.InformationTechnologyGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'RoyaltiesGrp': value.RoyaltiesGrp === undefined ? undefined : ((value.RoyaltiesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OccupancyGrp': value.OccupancyGrp === undefined ? undefined : ((value.OccupancyGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'TravelGrp': value.TravelGrp === undefined ? undefined : ((value.TravelGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PymtTravelEntrtnmntPubOfclGrp': value.PymtTravelEntrtnmntPubOfclGrp === undefined ? undefined : ((value.PymtTravelEntrtnmntPubOfclGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'ConferencesMeetingsGrp': value.ConferencesMeetingsGrp === undefined ? undefined : ((value.ConferencesMeetingsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InterestGrp': value.InterestGrp === undefined ? undefined : ((value.InterestGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PaymentsToAffiliatesGrp': value.PaymentsToAffiliatesGrp === undefined ? undefined : ((value.PaymentsToAffiliatesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'DepreciationDepletionGrp': value.DepreciationDepletionGrp === undefined ? undefined : ((value.DepreciationDepletionGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InsuranceGrp': value.InsuranceGrp === undefined ? undefined : ((value.InsuranceGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OtherExpensesGrp': value.OtherExpensesGrp === undefined ? undefined : ((value.OtherExpensesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'AllOtherExpensesGrp': value.AllOtherExpensesGrp === undefined ? undefined : ((value.AllOtherExpensesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'TotalFunctionalExpensesGrp': value.TotalFunctionalExpensesGrp === undefined ? undefined : ((value.TotalFunctionalExpensesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'JointCostsInd': value.JointCostsInd,
        'TotalJointCostsGrp': value.TotalJointCostsGrp === undefined ? undefined : ((value.TotalJointCostsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InfoInScheduleOPartXInd': value.InfoInScheduleOPartXInd,
        'CashNonInterestBearingGrp': value.CashNonInterestBearingGrp === undefined ? undefined : ((value.CashNonInterestBearingGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'SavingsAndTempCashInvstGrp': value.SavingsAndTempCashInvstGrp === undefined ? undefined : ((value.SavingsAndTempCashInvstGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'PledgesAndGrantsReceivableGrp': value.PledgesAndGrantsReceivableGrp === undefined ? undefined : ((value.PledgesAndGrantsReceivableGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'AccountsReceivableGrp': value.AccountsReceivableGrp === undefined ? undefined : ((value.AccountsReceivableGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'ReceivablesFromOfficersEtcGrp': value.ReceivablesFromOfficersEtcGrp === undefined ? undefined : ((value.ReceivablesFromOfficersEtcGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'RcvblFromDisqualifiedPrsnGrp': value.RcvblFromDisqualifiedPrsnGrp === undefined ? undefined : ((value.RcvblFromDisqualifiedPrsnGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OthNotesLoansReceivableNetGrp': value.OthNotesLoansReceivableNetGrp === undefined ? undefined : ((value.OthNotesLoansReceivableNetGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InventoriesForSaleOrUseGrp': value.InventoriesForSaleOrUseGrp === undefined ? undefined : ((value.InventoriesForSaleOrUseGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'PrepaidExpensesDefrdChargesGrp': value.PrepaidExpensesDefrdChargesGrp === undefined ? undefined : ((value.PrepaidExpensesDefrdChargesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'LandBldgEquipCostOrOtherBssAmt': value.LandBldgEquipCostOrOtherBssAmt,
        'LandBldgEquipAccumDeprecAmt': value.LandBldgEquipAccumDeprecAmt,
        'LandBldgEquipBasisNetGrp': value.LandBldgEquipBasisNetGrp === undefined ? undefined : ((value.LandBldgEquipBasisNetGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InvestmentsPubTradedSecGrp': value.InvestmentsPubTradedSecGrp === undefined ? undefined : ((value.InvestmentsPubTradedSecGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InvestmentsOtherSecuritiesGrp': value.InvestmentsOtherSecuritiesGrp === undefined ? undefined : ((value.InvestmentsOtherSecuritiesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InvestmentsProgramRelatedGrp': value.InvestmentsProgramRelatedGrp === undefined ? undefined : ((value.InvestmentsProgramRelatedGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'IntangibleAssetsGrp': value.IntangibleAssetsGrp === undefined ? undefined : ((value.IntangibleAssetsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OtherAssetsTotalGrp': value.OtherAssetsTotalGrp === undefined ? undefined : ((value.OtherAssetsTotalGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotalAssetsGrp': value.TotalAssetsGrp === undefined ? undefined : ((value.TotalAssetsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'AccountsPayableAccrExpnssGrp': value.AccountsPayableAccrExpnssGrp === undefined ? undefined : ((value.AccountsPayableAccrExpnssGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'GrantsPayableGrp': value.GrantsPayableGrp === undefined ? undefined : ((value.GrantsPayableGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'DeferredRevenueGrp': value.DeferredRevenueGrp === undefined ? undefined : ((value.DeferredRevenueGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TaxExemptBondLiabilitiesGrp': value.TaxExemptBondLiabilitiesGrp === undefined ? undefined : ((value.TaxExemptBondLiabilitiesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'EscrowAccountLiabilityGrp': value.EscrowAccountLiabilityGrp === undefined ? undefined : ((value.EscrowAccountLiabilityGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'LoansFromOfficersDirectorsGrp': value.LoansFromOfficersDirectorsGrp === undefined ? undefined : ((value.LoansFromOfficersDirectorsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'MortgNotesPyblScrdInvstPropGrp': value.MortgNotesPyblScrdInvstPropGrp === undefined ? undefined : ((value.MortgNotesPyblScrdInvstPropGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'UnsecuredNotesLoansPayableGrp': value.UnsecuredNotesLoansPayableGrp === undefined ? undefined : ((value.UnsecuredNotesLoansPayableGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OtherLiabilitiesGrp': value.OtherLiabilitiesGrp === undefined ? undefined : ((value.OtherLiabilitiesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotalLiabilitiesGrp': value.TotalLiabilitiesGrp === undefined ? undefined : ((value.TotalLiabilitiesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OrganizationFollowsFASB117Ind': value.OrganizationFollowsFASB117Ind,
        'NoDonorRestrictionNetAssetsGrp': value.NoDonorRestrictionNetAssetsGrp === undefined ? undefined : ((value.NoDonorRestrictionNetAssetsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'DonorRestrictionNetAssetsGrp': value.DonorRestrictionNetAssetsGrp === undefined ? undefined : ((value.DonorRestrictionNetAssetsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OrgDoesNotFollowFASB117Ind': value.OrgDoesNotFollowFASB117Ind,
        'CapStkTrPrinCurrentFundsGrp': value.CapStkTrPrinCurrentFundsGrp === undefined ? undefined : ((value.CapStkTrPrinCurrentFundsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'PdInCapSrplsLandBldgEqpFundGrp': value.PdInCapSrplsLandBldgEqpFundGrp === undefined ? undefined : ((value.PdInCapSrplsLandBldgEqpFundGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'RtnEarnEndowmentIncmOthFndsGrp': value.RtnEarnEndowmentIncmOthFndsGrp === undefined ? undefined : ((value.RtnEarnEndowmentIncmOthFndsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotalNetAssetsFundBalanceGrp': value.TotalNetAssetsFundBalanceGrp === undefined ? undefined : ((value.TotalNetAssetsFundBalanceGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotLiabNetAssetsFundBalanceGrp': value.TotLiabNetAssetsFundBalanceGrp === undefined ? undefined : ((value.TotLiabNetAssetsFundBalanceGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InfoInScheduleOPartXIInd': value.InfoInScheduleOPartXIInd,
        'ReconcilationRevenueExpnssAmt': value.ReconcilationRevenueExpnssAmt,
        'NetUnrlzdGainsLossesInvstAmt': value.NetUnrlzdGainsLossesInvstAmt,
        'DonatedServicesAndUseFcltsAmt': value.DonatedServicesAndUseFcltsAmt,
        'InvestmentExpenseAmt': value.InvestmentExpenseAmt,
        'PriorPeriodAdjustmentsAmt': value.PriorPeriodAdjustmentsAmt,
        'OtherChangesInNetAssetsAmt': value.OtherChangesInNetAssetsAmt,
        'InfoInScheduleOPartXIIInd': value.InfoInScheduleOPartXIIInd,
        'MethodOfAccountingCashInd': value.MethodOfAccountingCashInd,
        'MethodOfAccountingAccrualInd': value.MethodOfAccountingAccrualInd,
        'MethodOfAccountingOtherInd': value.MethodOfAccountingOtherInd,
        'AccountantCompileOrReviewInd': value.AccountantCompileOrReviewInd,
        'AcctCompileOrReviewBasisGrp': value.AcctCompileOrReviewBasisGrp === undefined ? undefined : ((value.AcctCompileOrReviewBasisGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON)),
        'FSAuditedInd': value.FSAuditedInd,
        'FSAuditedBasisGrp': value.FSAuditedBasisGrp === undefined ? undefined : ((value.FSAuditedBasisGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON)),
        'AuditCommitteeInd': value.AuditCommitteeInd,
        'FederalGrantAuditRequiredInd': value.FederalGrantAuditRequiredInd,
        'FederalGrantAuditPerformedInd': value.FederalGrantAuditPerformedInd,
    };
}

