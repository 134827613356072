import { useV1APIMutation } from '@explorer/query';
import { useQuerySnackbar } from '@explorer/hooks';
import { V1IntegrationsWebAdminSentineloneVerifyTokenPostRequest } from '@explorer/open-api-client';

export const useMutationVerifySentinelOneAccount = (params: {
  client_id: string;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const { client_id } = params;
  const { snackError } = useQuerySnackbar();

  return useV1APIMutation({
    mutationKey: ['v1IntegrationsWebAdminSentineloneVerifyTokenPost'],
    mutationFn(
      args: V1IntegrationsWebAdminSentineloneVerifyTokenPostRequest,
      client,
    ) {
      return client.v1IntegrationsWebAdminSentineloneVerifyTokenPost({
        JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody: {
          accessToken: args.JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody.accessToken,
          sentinelOneManagementURL: args.JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody.sentinelOneManagementURL,
        }
      });
    },
    onSuccess: () => {
      if (params.onSuccess) {
        params.onSuccess();
      }
    },
    explorerAPIOptions: {
      clientId: client_id,
      onError: (error) => {
        if (params.onError) {
          params.onError(error);
        }
        
        snackError(
          'Failed to verify account. Please try again later.',
          error.error,
        );
      },
    },
  });
};
