/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetorgstaleaccountsMicrosoftStaleAccount } from './JumboGetorgstaleaccountsMicrosoftStaleAccount';
import {
    JumboGetorgstaleaccountsMicrosoftStaleAccountFromJSON,
    JumboGetorgstaleaccountsMicrosoftStaleAccountFromJSONTyped,
    JumboGetorgstaleaccountsMicrosoftStaleAccountToJSON,
} from './JumboGetorgstaleaccountsMicrosoftStaleAccount';

/**
 * 
 * @export
 * @interface JumboGetorgstaleaccountsGetOrgStaleAccountsResponse
 */
export interface JumboGetorgstaleaccountsGetOrgStaleAccountsResponse {
    /**
     * 
     * @type {Array<JumboGetorgstaleaccountsMicrosoftStaleAccount>}
     * @memberof JumboGetorgstaleaccountsGetOrgStaleAccountsResponse
     */
    accounts?: Array<JumboGetorgstaleaccountsMicrosoftStaleAccount>;
}

/**
 * Check if a given object implements the JumboGetorgstaleaccountsGetOrgStaleAccountsResponse interface.
 */
export function instanceOfJumboGetorgstaleaccountsGetOrgStaleAccountsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetorgstaleaccountsGetOrgStaleAccountsResponseFromJSON(json: any): JumboGetorgstaleaccountsGetOrgStaleAccountsResponse {
    return JumboGetorgstaleaccountsGetOrgStaleAccountsResponseFromJSONTyped(json, false);
}

export function JumboGetorgstaleaccountsGetOrgStaleAccountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetorgstaleaccountsGetOrgStaleAccountsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accounts': !exists(json, 'accounts') ? undefined : ((json['accounts'] as Array<any>).map(JumboGetorgstaleaccountsMicrosoftStaleAccountFromJSON)),
    };
}

export function JumboGetorgstaleaccountsGetOrgStaleAccountsResponseToJSON(value?: JumboGetorgstaleaccountsGetOrgStaleAccountsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accounts': value.accounts === undefined ? undefined : ((value.accounts as Array<any>).map(JumboGetorgstaleaccountsMicrosoftStaleAccountToJSON)),
    };
}

