/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody
 */
export interface JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody {
    /**
     * 
     * @type {string}
     * @memberof JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody
     */
    sentinelOneManagementURL: string;
}

/**
 * Check if a given object implements the JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody interface.
 */
export function instanceOfJumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "sentinelOneManagementURL" in value;

    return isInstance;
}

export function JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBodyFromJSON(json: any): JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody {
    return JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBodyFromJSONTyped(json, false);
}

export function JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'sentinelOneManagementURL': json['sentinelOneManagementURL'],
    };
}

export function JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBodyToJSON(value?: JumboPostsentineloneverifytokenPostSentinelOneVerifyTokenRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'sentinelOneManagementURL': value.sentinelOneManagementURL,
    };
}

