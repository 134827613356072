/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome {
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    NetSTCapitalGainAdjNetIncmGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    RecoveriesPYDistributionsGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    OtherGrossIncomeGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    AdjustedGrossIncomeGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    DepreciationDepletionGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    ProductionIncomeGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    OtherExpensesGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
    /**
     * 
     * @type {Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome
     */
    TotalAdjustedNetIncomeGrp?: Array<ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmt>;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'NetSTCapitalGainAdjNetIncmGrp': !exists(json, 'NetSTCapitalGainAdjNetIncmGrp') ? undefined : ((json['NetSTCapitalGainAdjNetIncmGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'RecoveriesPYDistributionsGrp': !exists(json, 'RecoveriesPYDistributionsGrp') ? undefined : ((json['RecoveriesPYDistributionsGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'OtherGrossIncomeGrp': !exists(json, 'OtherGrossIncomeGrp') ? undefined : ((json['OtherGrossIncomeGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'AdjustedGrossIncomeGrp': !exists(json, 'AdjustedGrossIncomeGrp') ? undefined : ((json['AdjustedGrossIncomeGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'DepreciationDepletionGrp': !exists(json, 'DepreciationDepletionGrp') ? undefined : ((json['DepreciationDepletionGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'ProductionIncomeGrp': !exists(json, 'ProductionIncomeGrp') ? undefined : ((json['ProductionIncomeGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'OtherExpensesGrp': !exists(json, 'OtherExpensesGrp') ? undefined : ((json['OtherExpensesGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
        'TotalAdjustedNetIncomeGrp': !exists(json, 'TotalAdjustedNetIncomeGrp') ? undefined : ((json['TotalAdjustedNetIncomeGrp'] as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtFromJSON)),
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncomeToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAMinimumAssetAmountAdjustedNetIncome | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'NetSTCapitalGainAdjNetIncmGrp': value.NetSTCapitalGainAdjNetIncmGrp === undefined ? undefined : ((value.NetSTCapitalGainAdjNetIncmGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'RecoveriesPYDistributionsGrp': value.RecoveriesPYDistributionsGrp === undefined ? undefined : ((value.RecoveriesPYDistributionsGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'OtherGrossIncomeGrp': value.OtherGrossIncomeGrp === undefined ? undefined : ((value.OtherGrossIncomeGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'AdjustedGrossIncomeGrp': value.AdjustedGrossIncomeGrp === undefined ? undefined : ((value.AdjustedGrossIncomeGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'DepreciationDepletionGrp': value.DepreciationDepletionGrp === undefined ? undefined : ((value.DepreciationDepletionGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'ProductionIncomeGrp': value.ProductionIncomeGrp === undefined ? undefined : ((value.ProductionIncomeGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'OtherExpensesGrp': value.OtherExpensesGrp === undefined ? undefined : ((value.OtherExpensesGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
        'TotalAdjustedNetIncomeGrp': value.TotalAdjustedNetIncomeGrp === undefined ? undefined : ((value.TotalAdjustedNetIncomeGrp as Array<any>).map(ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesPriorCurrentYearAmtToJSON)),
    };
}

