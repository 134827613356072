/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
 */
export interface ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    person_name?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    business_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    us_address?: ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    foreign_address?: ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    service_type_txt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp
     */
    compensation_amt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpFromJSON(json: any): ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp {
    return ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_name': !exists(json, 'person_name') ? undefined : json['person_name'],
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
        'service_type_txt': !exists(json, 'service_type_txt') ? undefined : json['service_type_txt'],
        'compensation_amt': !exists(json, 'compensation_amt') ? undefined : json['compensation_amt'],
    };
}

export function ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrpToJSON(value?: ExecRiskApiAppCoreModelsScansIRS990EZCompensationOfHghstPdCntrctGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_name': value.person_name,
        'business_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
        'us_address': ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
        'service_type_txt': value.service_type_txt,
        'compensation_amt': value.compensation_amt,
    };
}

