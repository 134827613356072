/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt
     */
    OtherAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt
     */
    SecuritiesAmt?: number;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'OtherAmt': !exists(json, 'OtherAmt') ? undefined : json['OtherAmt'],
        'SecuritiesAmt': !exists(json, 'SecuritiesAmt') ? undefined : json['SecuritiesAmt'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OtherAmt': value.OtherAmt,
        'SecuritiesAmt': value.SecuritiesAmt,
    };
}

