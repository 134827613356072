/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboStructsWizerUser } from './JumboStructsWizerUser';
import {
    JumboStructsWizerUserFromJSON,
    JumboStructsWizerUserFromJSONTyped,
    JumboStructsWizerUserToJSON,
} from './JumboStructsWizerUser';

/**
 * 
 * @export
 * @interface JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody
 */
export interface JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody {
    /**
     * 
     * @type {Array<JumboStructsWizerUser>}
     * @memberof JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody
     */
    users: Array<JumboStructsWizerUser>;
}

/**
 * Check if a given object implements the JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody interface.
 */
export function instanceOfJumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBodyFromJSON(json: any): JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody {
    return JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBodyFromJSONTyped(json, false);
}

export function JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(JumboStructsWizerUserFromJSON)),
    };
}

export function JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBodyToJSON(value?: JumboPostwizercompanyinviteusersPostWizerCompanyInviteUsersRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': ((value.users as Array<any>).map(JumboStructsWizerUserToJSON)),
    };
}

