/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Text } from './Text';
import {
    TextFromJSON,
    TextFromJSONTyped,
    TextToJSON,
} from './Text';

/**
 * 
 * @export
 * @interface ControlTagDefinition
 */
export interface ControlTagDefinition {
    /**
     * 
     * @type {Text}
     * @memberof ControlTagDefinition
     */
    text: Text;
    /**
     * 
     * @type {string}
     * @memberof ControlTagDefinition
     */
    hex_color?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTagDefinition
     */
    font_color: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTagDefinition
     */
    background_color: string;
}

/**
 * Check if a given object implements the ControlTagDefinition interface.
 */
export function instanceOfControlTagDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "font_color" in value;
    isInstance = isInstance && "background_color" in value;

    return isInstance;
}

export function ControlTagDefinitionFromJSON(json: any): ControlTagDefinition {
    return ControlTagDefinitionFromJSONTyped(json, false);
}

export function ControlTagDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlTagDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': TextFromJSON(json['text']),
        'hex_color': !exists(json, 'hex_color') ? undefined : json['hex_color'],
        'font_color': json['font_color'],
        'background_color': json['background_color'],
    };
}

export function ControlTagDefinitionToJSON(value?: ControlTagDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': TextToJSON(value.text),
        'hex_color': value.hex_color,
        'font_color': value.font_color,
        'background_color': value.background_color,
    };
}

