/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits
 */
export interface JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits {
    /**
     * 
     * @type {number}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits
     */
    enabled?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits
     */
    lockedOut?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits
     */
    suspended?: number;
    /**
     * 
     * @type {number}
     * @memberof JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits
     */
    warning?: number;
}

/**
 * Check if a given object implements the JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits interface.
 */
export function instanceOfJumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsFromJSON(json: any): JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits {
    return JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsFromJSONTyped(json, false);
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'lockedOut': !exists(json, 'lockedOut') ? undefined : json['lockedOut'],
        'suspended': !exists(json, 'suspended') ? undefined : json['suspended'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
    };
}

export function JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnitsToJSON(value?: JumboMicrosoftMicrosoftOrganizationSubscriptionPrepaidUnits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'lockedOut': value.lockedOut,
        'suspended': value.suspended,
        'warning': value.warning,
    };
}

