/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppSchemasComplianceFrameworkCategoryDefinition } from './ControlAppSchemasComplianceFrameworkCategoryDefinition';
import {
    ControlAppSchemasComplianceFrameworkCategoryDefinitionFromJSON,
    ControlAppSchemasComplianceFrameworkCategoryDefinitionFromJSONTyped,
    ControlAppSchemasComplianceFrameworkCategoryDefinitionToJSON,
} from './ControlAppSchemasComplianceFrameworkCategoryDefinition';
import type { ControlTag } from './ControlTag';
import {
    ControlTagFromJSON,
    ControlTagFromJSONTyped,
    ControlTagToJSON,
} from './ControlTag';

/**
 * 
 * @export
 * @interface ControlFrameworkDefinition
 */
export interface ControlFrameworkDefinition {
    /**
     * 
     * @type {string}
     * @memberof ControlFrameworkDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFrameworkDefinition
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlFrameworkDefinition
     */
    description: string;
    /**
     * 
     * @type {Array<ControlAppSchemasComplianceFrameworkCategoryDefinition>}
     * @memberof ControlFrameworkDefinition
     */
    categories?: Array<ControlAppSchemasComplianceFrameworkCategoryDefinition>;
    /**
     * 
     * @type {string}
     * @memberof ControlFrameworkDefinition
     */
    logo_url?: string;
    /**
     * 
     * @type {Array<ControlTag>}
     * @memberof ControlFrameworkDefinition
     */
    tags?: Array<ControlTag>;
}

/**
 * Check if a given object implements the ControlFrameworkDefinition interface.
 */
export function instanceOfControlFrameworkDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function ControlFrameworkDefinitionFromJSON(json: any): ControlFrameworkDefinition {
    return ControlFrameworkDefinitionFromJSONTyped(json, false);
}

export function ControlFrameworkDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFrameworkDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(ControlAppSchemasComplianceFrameworkCategoryDefinitionFromJSON)),
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(ControlTagFromJSON)),
    };
}

export function ControlFrameworkDefinitionToJSON(value?: ControlFrameworkDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(ControlAppSchemasComplianceFrameworkCategoryDefinitionToJSON)),
        'logo_url': value.logo_url,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(ControlTagToJSON)),
    };
}

