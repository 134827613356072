/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CRSFPayloadFieldsInput
 */
export interface CRSFPayloadFieldsInput {
    /**
     * 
     * @type {string}
     * @memberof CRSFPayloadFieldsInput
     */
    agency?: string;
    /**
     * 
     * @type {number}
     * @memberof CRSFPayloadFieldsInput
     */
    attachment_point?: number;
    /**
     * 
     * @type {string}
     * @memberof CRSFPayloadFieldsInput
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFPayloadFieldsInput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFPayloadFieldsInput
     */
    insurance_market?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CRSFPayloadFieldsInput
     */
    is_excess?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CRSFPayloadFieldsInput
     */
    labels?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CRSFPayloadFieldsInput
     */
    quote_origination?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFPayloadFieldsInput
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof CRSFPayloadFieldsInput
     */
    updated?: string;
}

/**
 * Check if a given object implements the CRSFPayloadFieldsInput interface.
 */
export function instanceOfCRSFPayloadFieldsInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CRSFPayloadFieldsInputFromJSON(json: any): CRSFPayloadFieldsInput {
    return CRSFPayloadFieldsInputFromJSONTyped(json, false);
}

export function CRSFPayloadFieldsInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFPayloadFieldsInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency': !exists(json, 'agency') ? undefined : json['agency'],
        'attachment_point': !exists(json, 'attachment_point') ? undefined : json['attachment_point'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'insurance_market': !exists(json, 'insurance_market') ? undefined : json['insurance_market'],
        'is_excess': !exists(json, 'is_excess') ? undefined : json['is_excess'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'quote_origination': !exists(json, 'quote_origination') ? undefined : json['quote_origination'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function CRSFPayloadFieldsInputToJSON(value?: CRSFPayloadFieldsInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency': value.agency,
        'attachment_point': value.attachment_point,
        'created': value.created,
        'description': value.description,
        'insurance_market': value.insurance_market,
        'is_excess': value.is_excess,
        'labels': value.labels,
        'quote_origination': value.quote_origination,
        'summary': value.summary,
        'updated': value.updated,
    };
}

