/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsPlaidAlertPreference
 */
export interface JumboStructsPlaidAlertPreference {
    /**
     * 
     * @type {boolean}
     * @memberof JumboStructsPlaidAlertPreference
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsPlaidAlertPreference
     */
    name?: JumboStructsPlaidAlertPreferenceNameEnum;
    /**
     * 
     * @type {number}
     * @memberof JumboStructsPlaidAlertPreference
     */
    threshold?: number;
}


/**
 * @export
 */
export const JumboStructsPlaidAlertPreferenceNameEnum = {
    ToNewMerchantOverThreshold: 'toNewMerchantOverThreshold',
    AllTransactionsOverThreshold: 'allTransactionsOverThreshold'
} as const;
export type JumboStructsPlaidAlertPreferenceNameEnum = typeof JumboStructsPlaidAlertPreferenceNameEnum[keyof typeof JumboStructsPlaidAlertPreferenceNameEnum];


/**
 * Check if a given object implements the JumboStructsPlaidAlertPreference interface.
 */
export function instanceOfJumboStructsPlaidAlertPreference(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsPlaidAlertPreferenceFromJSON(json: any): JumboStructsPlaidAlertPreference {
    return JumboStructsPlaidAlertPreferenceFromJSONTyped(json, false);
}

export function JumboStructsPlaidAlertPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsPlaidAlertPreference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
    };
}

export function JumboStructsPlaidAlertPreferenceToJSON(value?: JumboStructsPlaidAlertPreference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'name': value.name,
        'threshold': value.threshold,
    };
}

