/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult } from './JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult';
import {
    JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultFromJSON,
    JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultFromJSONTyped,
    JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultToJSON,
} from './JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult';

/**
 * 
 * @export
 * @interface JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult
 */
export interface JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult {
    /**
     * 
     * @type {Array<JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult>}
     * @memberof JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult
     */
    agents?: Array<JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResult>;
}

/**
 * Check if a given object implements the JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult interface.
 */
export function instanceOfJumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResultFromJSON(json: any): JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult {
    return JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResultFromJSONTyped(json, false);
}

export function JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agents': !exists(json, 'agents') ? undefined : ((json['agents'] as Array<any>).map(JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultFromJSON)),
    };
}

export function JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResultToJSON(value?: JumboGetsentineloneapplicationsidagentsGetSentineloneApplicationsIDAgentsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agents': value.agents === undefined ? undefined : ((value.agents as Array<any>).map(JumboGetsentineloneapplicationsidagentsSentinelOneApplicationAgentResultToJSON)),
    };
}

