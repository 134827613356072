/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress
 */
export interface ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress {
    /**
     * 
     * @type {ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress
     */
    CollegeUniversityName?: ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress
     */
    CityNm?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress
     */
    StateAbbreviationCd?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress
     */
    CountryCd?: string;
}

/**
 * Check if a given object implements the ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress interface.
 */
export function instanceOfExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressFromJSON(json: any): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress {
    return ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressFromJSONTyped(json, false);
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CollegeUniversityName': !exists(json, 'CollegeUniversityName') ? undefined : ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['CollegeUniversityName']),
        'CityNm': !exists(json, 'CityNm') ? undefined : json['CityNm'],
        'StateAbbreviationCd': !exists(json, 'StateAbbreviationCd') ? undefined : json['StateAbbreviationCd'],
        'CountryCd': !exists(json, 'CountryCd') ? undefined : json['CountryCd'],
    };
}

export function ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddressToJSON(value?: ExecRiskApiAppApiV1EagerTasksForm990ModelsForm990ScheduleAAgriculturalNameAndAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CollegeUniversityName': ExecRiskApiAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.CollegeUniversityName),
        'CityNm': value.CityNm,
        'StateAbbreviationCd': value.StateAbbreviationCd,
        'CountryCd': value.CountryCd,
    };
}

