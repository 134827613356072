/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress';
import type { ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress } from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';
import {
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSONTyped,
    ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON,
} from './ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail
 */
export interface ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail
     */
    person_name?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail
     */
    business_name?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail
     */
    phone_num?: string;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail
     */
    us_address?: ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress}
     * @memberof ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail
     */
    foreign_address?: ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddress;
}

/**
 * Check if a given object implements the ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail interface.
 */
export function instanceOfExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetailFromJSON(json: any): ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail {
    return ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetailFromJSONTyped(json, false);
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person_name': !exists(json, 'person_name') ? undefined : json['person_name'],
        'business_name': !exists(json, 'business_name') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameFromJSON(json['business_name']),
        'phone_num': !exists(json, 'phone_num') ? undefined : json['phone_num'],
        'us_address': !exists(json, 'us_address') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressFromJSON(json['us_address']),
        'foreign_address': !exists(json, 'foreign_address') ? undefined : ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressFromJSON(json['foreign_address']),
    };
}

export function ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetailToJSON(value?: ExecRiskApiAppCoreModelsScansForm990CommonTypesBooksInCareOfDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person_name': value.person_name,
        'business_name': ExecRiskApiAppCoreModelsScansForm990CommonTypesBusinessNameToJSON(value.business_name),
        'phone_num': value.phone_num,
        'us_address': ExecRiskApiAppCoreModelsScansForm990CommonTypesUSAddressToJSON(value.us_address),
        'foreign_address': ExecRiskApiAppCoreModelsScansForm990CommonTypesForeignAddressToJSON(value.foreign_address),
    };
}

