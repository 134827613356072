/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JumboStructsEDRAccountData
 */
export interface JumboStructsEDRAccountData {
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAccountData
     */
    managementHost?: string;
    /**
     * 
     * @type {string}
     * @memberof JumboStructsEDRAccountData
     */
    microsoftDefenderWorkspaceId?: string;
}

/**
 * Check if a given object implements the JumboStructsEDRAccountData interface.
 */
export function instanceOfJumboStructsEDRAccountData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JumboStructsEDRAccountDataFromJSON(json: any): JumboStructsEDRAccountData {
    return JumboStructsEDRAccountDataFromJSONTyped(json, false);
}

export function JumboStructsEDRAccountDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumboStructsEDRAccountData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managementHost': !exists(json, 'managementHost') ? undefined : json['managementHost'],
        'microsoftDefenderWorkspaceId': !exists(json, 'microsoftDefenderWorkspaceId') ? undefined : json['microsoftDefenderWorkspaceId'],
    };
}

export function JumboStructsEDRAccountDataToJSON(value?: JumboStructsEDRAccountData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managementHost': value.managementHost,
        'microsoftDefenderWorkspaceId': value.microsoftDefenderWorkspaceId,
    };
}

