import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@explorer/core';
import { DeleteUser } from '@explorer/forms/presets';
import CloseIcon from '@mui/icons-material/Close';
import { DialogConfirm } from '@explorer/core/presets';

/**
 * DeleteUserDialog
 */
export const DeleteUserDialog = ({
  item,
  open,
  forceRender,
  onClose,
}: DeleteUserDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      stopPropagation
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              label={{ id: 'tables-presets.adminusers-delete-user-title' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box textAlign="right">
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <Typography
            variant="bodyM"
            label={{ id: 'tables-presets.adminusers-explain-delete-user' }}
          />
          <Typography variant="subtitleM" my={1}>
            {item?.email}
          </Typography>
        </Box>
        <DeleteUser
          userId={item.user_id}
          handleClose={onClose}
          refresh={forceRender}
        />
      </DialogContent>
    </Dialog>
  );
};

type Item = { email: string; user_id: string };

export interface DeleteUserDialogProps {
  item: Item;
  open: boolean;
  forceRender: () => void;
  onClose: () => void;
}
