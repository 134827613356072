/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAdminSearchResponseEntity } from './ControlAdminSearchResponseEntity';
import {
    ControlAdminSearchResponseEntityFromJSON,
    ControlAdminSearchResponseEntityFromJSONTyped,
    ControlAdminSearchResponseEntityToJSON,
} from './ControlAdminSearchResponseEntity';

/**
 * 
 * @export
 * @interface ControlAdminSearchResponse
 */
export interface ControlAdminSearchResponse {
    /**
     * 
     * @type {Array<ControlAdminSearchResponseEntity>}
     * @memberof ControlAdminSearchResponse
     */
    results?: Array<ControlAdminSearchResponseEntity>;
    /**
     * 
     * @type {number}
     * @memberof ControlAdminSearchResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof ControlAdminSearchResponse
     */
    page_size: number;
    /**
     * 
     * @type {number}
     * @memberof ControlAdminSearchResponse
     */
    count: number;
}

/**
 * Check if a given object implements the ControlAdminSearchResponse interface.
 */
export function instanceOfControlAdminSearchResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "page_size" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function ControlAdminSearchResponseFromJSON(json: any): ControlAdminSearchResponse {
    return ControlAdminSearchResponseFromJSONTyped(json, false);
}

export function ControlAdminSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAdminSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ControlAdminSearchResponseEntityFromJSON)),
        'page': json['page'],
        'page_size': json['page_size'],
        'count': json['count'],
    };
}

export function ControlAdminSearchResponseToJSON(value?: ControlAdminSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ControlAdminSearchResponseEntityToJSON)),
        'page': value.page,
        'page_size': value.page_size,
        'count': value.count,
    };
}

