/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlAppSchemasChecklistV2FormCategoryDefinition } from './ControlAppSchemasChecklistV2FormCategoryDefinition';
import {
    ControlAppSchemasChecklistV2FormCategoryDefinitionFromJSON,
    ControlAppSchemasChecklistV2FormCategoryDefinitionFromJSONTyped,
    ControlAppSchemasChecklistV2FormCategoryDefinitionToJSON,
} from './ControlAppSchemasChecklistV2FormCategoryDefinition';
import type { ControlQuestionDefinition } from './ControlQuestionDefinition';
import {
    ControlQuestionDefinitionFromJSON,
    ControlQuestionDefinitionFromJSONTyped,
    ControlQuestionDefinitionToJSON,
} from './ControlQuestionDefinition';
import type { ControlTagDefinition } from './ControlTagDefinition';
import {
    ControlTagDefinitionFromJSON,
    ControlTagDefinitionFromJSONTyped,
    ControlTagDefinitionToJSON,
} from './ControlTagDefinition';
import type { Description } from './Description';
import {
    DescriptionFromJSON,
    DescriptionFromJSONTyped,
    DescriptionToJSON,
} from './Description';
import type { Title } from './Title';
import {
    TitleFromJSON,
    TitleFromJSONTyped,
    TitleToJSON,
} from './Title';

/**
 * 
 * @export
 * @interface ControlFormDefinition
 */
export interface ControlFormDefinition {
    /**
     * 
     * @type {string}
     * @memberof ControlFormDefinition
     */
    id: string;
    /**
     * 
     * @type {Title}
     * @memberof ControlFormDefinition
     */
    title: Title;
    /**
     * 
     * @type {Description}
     * @memberof ControlFormDefinition
     */
    description: Description;
    /**
     * 
     * @type {Array<ControlAppSchemasChecklistV2FormCategoryDefinition>}
     * @memberof ControlFormDefinition
     */
    categories?: Array<ControlAppSchemasChecklistV2FormCategoryDefinition>;
    /**
     * 
     * @type {Array<ControlQuestionDefinition>}
     * @memberof ControlFormDefinition
     */
    questions?: Array<ControlQuestionDefinition>;
    /**
     * 
     * @type {Array<ControlTagDefinition>}
     * @memberof ControlFormDefinition
     */
    tags?: Array<ControlTagDefinition>;
    /**
     * 
     * @type {string}
     * @memberof ControlFormDefinition
     */
    logo_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlFormDefinition
     */
    ready?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlFormDefinition
     */
    visible?: boolean;
}

/**
 * Check if a given object implements the ControlFormDefinition interface.
 */
export function instanceOfControlFormDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function ControlFormDefinitionFromJSON(json: any): ControlFormDefinition {
    return ControlFormDefinitionFromJSONTyped(json, false);
}

export function ControlFormDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlFormDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': TitleFromJSON(json['title']),
        'description': DescriptionFromJSON(json['description']),
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(ControlAppSchemasChecklistV2FormCategoryDefinitionFromJSON)),
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(ControlQuestionDefinitionFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(ControlTagDefinitionFromJSON)),
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'ready': !exists(json, 'ready') ? undefined : json['ready'],
        'visible': !exists(json, 'visible') ? undefined : json['visible'],
    };
}

export function ControlFormDefinitionToJSON(value?: ControlFormDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': TitleToJSON(value.title),
        'description': DescriptionToJSON(value.description),
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(ControlAppSchemasChecklistV2FormCategoryDefinitionToJSON)),
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(ControlQuestionDefinitionToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(ControlTagDefinitionToJSON)),
        'logo_url': value.logo_url,
        'ready': value.ready,
        'visible': value.visible,
    };
}

