/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlUpdateMSPClientAssociationBody
 */
export interface ControlUpdateMSPClientAssociationBody {
    /**
     * 
     * @type {string}
     * @memberof ControlUpdateMSPClientAssociationBody
     */
    msp_client_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUpdateMSPClientAssociationBody
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof ControlUpdateMSPClientAssociationBody
     */
    note?: string;
}

/**
 * Check if a given object implements the ControlUpdateMSPClientAssociationBody interface.
 */
export function instanceOfControlUpdateMSPClientAssociationBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "msp_client_id" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function ControlUpdateMSPClientAssociationBodyFromJSON(json: any): ControlUpdateMSPClientAssociationBody {
    return ControlUpdateMSPClientAssociationBodyFromJSONTyped(json, false);
}

export function ControlUpdateMSPClientAssociationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlUpdateMSPClientAssociationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msp_client_id': json['msp_client_id'],
        'action': json['action'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function ControlUpdateMSPClientAssociationBodyToJSON(value?: ControlUpdateMSPClientAssociationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msp_client_id': value.msp_client_id,
        'action': value.action,
        'note': value.note,
    };
}

