import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@explorer/core';
import CloseIcon from '@mui/icons-material/Close';

/**
 * DeleteUserDialog
 */
export const DeletePrepurchaseDialog = ({
  open,
  onClose,
  onConfirm,
}: DeletePrepurchaseDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      stopPropagation
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              label={{ id: 'forms-presets.module-prepurchase-deletion-title' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box textAlign="right">
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <Typography
            component="div"
            variant="bodyM"
            label={{
              id: 'forms-presets.module-prepurchase-deletion-confirmation',
              values: {
                p: (chunks) => <p>{chunks}</p>,
                b: (chunks) => <b>{chunks}</b>,
              },
            }}
          />
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          gap={2}
          p={2}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            label={{ id: 'global.confirm' }}
            onClick={onConfirm}
          />
          <Button
            variant="outlined"
            color="primary"
            size="large"
            label={{ id: 'global.cancel' }}
            onClick={onClose}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export interface DeletePrepurchaseDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
