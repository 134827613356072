/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitISPProviderResult } from './MonitISPProviderResult';
import {
    MonitISPProviderResultFromJSON,
    MonitISPProviderResultFromJSONTyped,
    MonitISPProviderResultToJSON,
} from './MonitISPProviderResult';

/**
 * 
 * @export
 * @interface MonitISPAggResult
 */
export interface MonitISPAggResult {
    /**
     * 
     * @type {number}
     * @memberof MonitISPAggResult
     */
    total?: number;
    /**
     * 
     * @type {Array<MonitISPProviderResult>}
     * @memberof MonitISPAggResult
     */
    providers?: Array<MonitISPProviderResult>;
}

/**
 * Check if a given object implements the MonitISPAggResult interface.
 */
export function instanceOfMonitISPAggResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitISPAggResultFromJSON(json: any): MonitISPAggResult {
    return MonitISPAggResultFromJSONTyped(json, false);
}

export function MonitISPAggResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitISPAggResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'providers': !exists(json, 'providers') ? undefined : ((json['providers'] as Array<any>).map(MonitISPProviderResultFromJSON)),
    };
}

export function MonitISPAggResultToJSON(value?: MonitISPAggResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'providers': value.providers === undefined ? undefined : ((value.providers as Array<any>).map(MonitISPProviderResultToJSON)),
    };
}

